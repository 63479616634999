import { isObjectDeepFalsy } from 'util/object'
import { createValidator, maxLength, required } from 'util/validation'

import { isPreenchendoRPS, PeriogramaSimplificadoModel } from './PeriogramaSimplificadoField'

export const periogramaSimplificadoValidator = createValidator<PeriogramaSimplificadoModel>({}, (values, errors) => {
  const {
    avaliacaoSextante1,
    avaliacaoSextante2,
    avaliacaoSextante3,
    avaliacaoSextante4,
    avaliacaoSextante5,
    avaliacaoSextante6,
    observacao,
  } = values || {}

  if (
    isPreenchendoRPS([
      avaliacaoSextante1,
      avaliacaoSextante2,
      avaliacaoSextante3,
      avaliacaoSextante4,
      avaliacaoSextante5,
      avaliacaoSextante6,
    ])
  ) {
    const validations = {
      avaliacaoSextante1: required(avaliacaoSextante1),
      avaliacaoSextante2: required(avaliacaoSextante2),
      avaliacaoSextante3: required(avaliacaoSextante3),
      avaliacaoSextante4: required(avaliacaoSextante4),
      avaliacaoSextante5: required(avaliacaoSextante5),
      avaliacaoSextante6: required(avaliacaoSextante6),
    } as PeriogramaSimplificadoModel

    return {
      ...validations,
      observacao: maxLength(400)(observacao),
      error: !isObjectDeepFalsy(validations) && 'Preenchimento obrigatório de todos os sextantes.',
    }
  } else {
    return {}
  }
})
