import { Text, VFlow } from 'bold-ui'
import { Cns, Cpf, Telefone } from 'components/label'
import { EMPTY } from 'components/table/render'
import React, { ReactNode } from 'react'
import { OrigemAtendimentoRecord } from 'types/enums'
import { formatDate } from 'util/date/formatDate'
import { isObjectDeepEmpty } from 'util/object'

import {
  AcompanhamentoCidadaosVinculadosRowModel,
  AcompanhamentoCidadaosVinculadosTelefoneModel,
} from '../../model-acompanhamentoCidadaosVinculados'
import { TagOrigemAcompanhamentoCidadaosVinculados } from '../TagOrigemAcompanhamentoCidadaosVinculados'

export function renderCidadaoAcompanhamentoCidadaosVinculados(
  row: AcompanhamentoCidadaosVinculadosRowModel
): ReactNode {
  const { nome, cpf, cns } = row
  return (
    <VFlow vSpacing={0.3}>
      <Text fontWeight='bold'>{nome.titleCase()}</Text>
      {cpf ? <Cpf value={cpf} /> : cns ? <Cns value={cns} /> : 'Cidadão sem CNS e CPF'}
    </VFlow>
  )
}

export function renderTelefoneAcompanhamentoCidadaosVinculados(
  telefone: AcompanhamentoCidadaosVinculadosTelefoneModel
): ReactNode {
  const { telefoneCelular, telefoneContato, telefoneResidencial } = telefone

  const hasTelefone = !isObjectDeepEmpty(telefone)

  return hasTelefone ? (
    <VFlow vSpacing={0}>
      <Telefone value={telefoneCelular} />
      <Telefone value={telefoneContato} />
      <Telefone value={telefoneResidencial} />
    </VFlow>
  ) : (
    EMPTY
  )
}

export function renderUltimaAtualizacaoCadastralEOrigem(dataUltimoAtendimento: string, origem: string): ReactNode {
  const date = formatDate(dataUltimoAtendimento)

  return (
    <VFlow vSpacing={0.3}>
      {date}
      <TagOrigemAcompanhamentoCidadaosVinculados origem={OrigemAtendimentoRecord[origem]} />
    </VFlow>
  )
}
