import api from 'api'
import { dateAsYyyyMmDdHhMm } from 'util/date/formatDate'
import { defaultHandleError, downloadFile } from 'util/downloadFile'

export const downloadArquivo = (input: ID, fileName: string, fileType: string) => {
  return api.arquivoTemporario
    .downloadArquivo(input)
    .then((responseBody) => {
      downloadFile(new Blob([responseBody.data]), `${fileName}.${fileType}`)
    })
    .catch(defaultHandleError)
}

export const formatRelatorioFileName = (fileName: string, date: Date) => `${fileName}-${dateAsYyyyMmDdHhMm(date)}`
