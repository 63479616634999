/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Theme, useTheme } from 'bold-ui'
import { AcessoProfissionalType } from 'components/auth/model-authorization'
import { FormValueSpy } from 'components/form'
import { AccordionField } from 'components/form/field/AccordionField'
import { PageContent } from 'components/layout/PageContent'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useHeight } from 'hooks/useMeasure'
import { useWidth } from 'hooks/useMeasure'
import { useEffect, useState } from 'react'
import { AccordionItem } from 'react-accessible-accordion'
import { FormSpy, useField } from 'react-final-form'
import { meta, SoapState } from 'view/atendimentos/atendimento-individual/model'

import { AntecedentesSection } from './antecedentes/AntecedentesSection'
import { AsideView } from './aside/AsideView'
import { convertToStatuses } from './aside/converter-aside'
import { Problema } from './aside/types/ProblemaModel'
import { AvaliacaoSection } from './avaliacao/AvaliacaoSection'
import { ProblemaCondicaoModel } from './avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import {
  hasProblemaCondicaoComCiapW78Resolvido,
  hasProblemaCondicaoDePreNatal,
  hasProblemaCondicaoGravidezAltoRiscoAtivoOuLatente,
  hasProblemaNaoEvolucaoEncerraGestacao,
} from './avaliacao/components/problemas-condicoes/utils/verifications-problemasCondicoes'
import { FinalizacaoAtendimentoSection } from './finalizacao'
import { ObjetivoSection } from './objetivo'
import { PlanoSection } from './plano/PlanoSection'
import { TipoPreNatal } from './pre-natal/model-preNatal'
import { isInicioOuContinuacaoPreNatal } from './pre-natal/util-preNatal'
import SoapSections from './SoapSections'
import { SoapViewProps } from './SoapView'
import { SubjetivoSection } from './subjetivo'

const metasPreNatal = { metaPreNatal: meta.preNatal }

interface SoapPageProps
  extends Pick<
    SoapViewProps,
    | 'atendimentoProfissional'
    | 'cidadao'
    | 'dataAtendimento'
    | 'prontuarioId'
    | 'ciapCidPreNatal'
    | 'atendimentoId'
    | 'agendamentoAtendimentoId'
    | 'agendamentosDia'
    | 'gestacoes'
    | 'headerHeight'
  > {
  dataAtualizacaoObstetricosAntecedentes: number
  dataAtualizacaoPuericulturaAntecedentes: number
  acesso: AcessoProfissionalType
  handleFormChange: (values: SoapState) => void
  profissionalId: string
  problemasAtivosELatentes: Problema[]
  problemasResolvidos: Problema[]
  concluiAgendamento: boolean
  hasProblemaComCiapW78AtivoPersistido: boolean
  hasIvcfPreenchidoEmAtendObservacaoAnterior: boolean
}

export function SoapPage(props: SoapPageProps) {
  const {
    atendimentoProfissional,
    dataAtualizacaoObstetricosAntecedentes,
    dataAtualizacaoPuericulturaAntecedentes,
    cidadao,
    dataAtendimento,
    prontuarioId,
    ciapCidPreNatal,
    acesso,
    atendimentoId,
    agendamentoAtendimentoId,
    agendamentosDia,
    handleFormChange,
    profissionalId,
    gestacoes,
    problemasAtivosELatentes,
    problemasResolvidos,
    concluiAgendamento,
    headerHeight,
    hasProblemaComCiapW78AtivoPersistido,
    hasIvcfPreenchidoEmAtendObservacaoAnterior,
  } = props

  const theme = useTheme()
  const [asideContainerRef, asideContainerWidth] = useWidth()
  const [asideRef, asideHeight] = useHeight()
  const styles = createStyles(theme, asideHeight)

  const [tipoPreNatal, setTipoPreNatal] = useState<TipoPreNatal>()

  const {
    cidadao: { isGestante },
    atendimentoProfissional: { id: atendimentoProfissionalId },
    permissoes: { hasPermissionPreNatal, somenteCiap },
    observacao: { isAtendimentoObservacao, isObservacaoAndAuxiliar },
    tiposAtendimento: { isAtendimentoOdonto, isAtendimentoProcedimentos },
    isRegistroTardio,
    isRetificacao,
  } = useAtendimentoContext()

  const {
    input: { value: problemasCondicoes },
  } = useField<ProblemaCondicaoModel[]>(meta.avaliacao.problemasECondicoes.absolutePath(), {
    subscription: { value: true },
  })

  useEffect(() => {
    if (hasPermissionPreNatal) {
      if (hasProblemaCondicaoComCiapW78Resolvido(problemasCondicoes)) {
        setTipoPreNatal(TipoPreNatal.W78_RESOLVIDO_NA_AVALIACAO)
      } else if (hasProblemaNaoEvolucaoEncerraGestacao(problemasCondicoes)) {
        setTipoPreNatal(TipoPreNatal.ENCERRAMENTO_GESTACAO)
      } else if (hasProblemaCondicaoDePreNatal(problemasCondicoes, hasProblemaComCiapW78AtivoPersistido)) {
        setTipoPreNatal(isGestante ? TipoPreNatal.CONTINUACAO_PRE_NATAL : TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL)
      } else {
        setTipoPreNatal(null)
      }
    }
  }, [tipoPreNatal, problemasCondicoes, hasPermissionPreNatal, isGestante, hasProblemaComCiapW78AtivoPersistido])

  const renderAntecedentes = () => (
    <AccordionItem uuid='AN' key={meta.antecedentes.alias}>
      <FormSpy<SoapState> subscription={{ values: true }}>
        {({ values: formValues }) => (
          <AntecedentesSection
            name={meta.antecedentes}
            dataAtualizacaoObstetricos={dataAtualizacaoObstetricosAntecedentes}
            dataAtualizacaoPuericultura={dataAtualizacaoPuericulturaAntecedentes}
            problemasAvaliados={formValues?.avaliacao?.problemasECondicoes}
            problemasResolvidos={problemasResolvidos}
          />
        )}
      </FormSpy>
    </AccordionItem>
  )

  const renderSubjetivo = () => (
    <AccordionItem uuid='S' key={meta.subjetivo.alias}>
      <SubjetivoSection name={meta.subjetivo} isAtendimentoOdonto={isAtendimentoOdonto} />
    </AccordionItem>
  )

  const renderObjetivo = () => (
    <AccordionItem uuid='O' key={meta.objetivo.alias}>
      <ObjetivoSection
        name={meta.objetivo}
        metaPreNatal={meta.preNatal}
        cidadao={cidadao}
        dataAtendimento={dataAtendimento}
        prontuarioId={prontuarioId}
        tipoPreNatal={tipoPreNatal}
        metaProblemasCondicoes={meta.avaliacao.problemasECondicoes}
        metaAgendarProximasConsultas={meta.finalizacao.agendamentoConsultas.proximasConsultas}
        gestacoes={gestacoes}
        isAtendimentoOdonto={isAtendimentoOdonto}
        isAtendimentoProcedimentos={isAtendimentoProcedimentos}
        hasProblemaComCiapW78AtivoPersistido={hasProblemaComCiapW78AtivoPersistido}
      />
    </AccordionItem>
  )

  const renderAvaliacao = () => (
    <AccordionItem uuid='A' key={meta.avaliacao.alias}>
      <AvaliacaoSection
        name={meta.avaliacao}
        metasPreNatal={metasPreNatal}
        tipoPreNatal={tipoPreNatal}
        cidadao={cidadao}
        prontuarioId={prontuarioId}
        acessoProfissional={acesso}
        isAtendimentoOdonto={isAtendimentoOdonto}
        dataAtendimento={dataAtendimento}
        ciapCidPreNatal={ciapCidPreNatal}
        somenteCiap={somenteCiap}
        problemasAvaliacao={problemasCondicoes}
        problemasAtivosLatentes={problemasAtivosELatentes}
        hasProblemaComCiapW78AtivoPersistido={hasProblemaComCiapW78AtivoPersistido}
        hasIvcfPreenchidoEmAtendObservacaoAnterior={hasIvcfPreenchidoEmAtendObservacaoAnterior}
      />
    </AccordionItem>
  )

  const renderPlano = () => (
    <AccordionItem uuid='P' key={meta.plano.alias}>
      <PlanoSection
        name={meta.plano}
        prontuarioId={prontuarioId}
        dataAtendimento={dataAtendimento}
        isAtendimentoOdonto={isAtendimentoOdonto}
        isAtendimentoProcedimentos={isAtendimentoProcedimentos}
        atendimentoId={atendimentoId}
        atendimentoProfissional={atendimentoProfissional}
        isRegistroTardio={isRegistroTardio}
      />
    </AccordionItem>
  )

  const renderFinalizacaoAtendimento = () => (
    <AccordionItem uuid='D' key={meta.finalizacao.alias}>
      <FinalizacaoAtendimentoSection
        name={meta.finalizacao}
        atendimentoId={atendimentoId}
        profissionalId={profissionalId}
        dataAtendimento={dataAtendimento}
        agendamentoAtendimentoId={agendamentoAtendimentoId}
        concluiAgendamento={concluiAgendamento}
        agendamentosDia={agendamentosDia}
        isAtendimentoOdonto={isAtendimentoOdonto}
        isAtendimentoProcedimentos={isAtendimentoProcedimentos}
        tipoPreNatal={tipoPreNatal}
        isRegistroTardio={isRegistroTardio}
      />
    </AccordionItem>
  )

  return (
    <PageContent fluid type='filled'>
      <Grid gap={2}>
        <Cell size={3} style={styles.cellAside}>
          <div ref={asideContainerRef} css={styles.containerLateral}>
            <FormSpy<SoapState> subscription={{ values: true }}>
              {({ values: formValues }) => (
                <AsideView
                  ref={asideRef}
                  lotacao={atendimentoProfissional.lotacao}
                  prontuarioId={prontuarioId}
                  atendimentoId={atendimentoId}
                  dataAtendimento={dataAtendimento}
                  cidadao={cidadao}
                  isGestante={isGestante}
                  problemasAvaliacao={problemasCondicoes}
                  problemasLPC={formValues?.problemasECondicoes}
                  gestacoes={gestacoes}
                  alergiasAtendimentoAtual={formValues?.avaliacao?.alergias}
                  medicoesAtendimentoAtual={formValues?.objetivo?.medicoes}
                  lembretesCache={formValues?.lembretes}
                  vacinacaoEmDia={formValues?.objetivo?.vacinacaoEmDia}
                  puericultura={formValues?.objetivo?.atendimentosEspecificos?.puericultura}
                  preNatal={formValues?.preNatal}
                  dum={formValues?.objetivo?.dum}
                  resultadosExames={formValues?.objetivo?.resultadosExames}
                  isGravidezAltoRisco={
                    hasPermissionPreNatal &&
                    hasProblemaCondicaoGravidezAltoRiscoAtivoOuLatente(formValues?.avaliacao?.problemasECondicoes)
                  }
                  ciapCidPreNatal={ciapCidPreNatal}
                  somenteCiap={somenteCiap}
                  width={asideContainerWidth ?? 0}
                  headerHeight={headerHeight}
                  idadeGestacional={formValues?.acompanhamentoPreNatal?.idadeGestacional}
                  hasPermissionPreNatal={hasPermissionPreNatal}
                  atendimentoProfissionalId={atendimentoProfissionalId}
                  atendimentoStatuses={convertToStatuses(
                    isAtendimentoProcedimentos,
                    isAtendimentoObservacao,
                    isObservacaoAndAuxiliar,
                    isInicioOuContinuacaoPreNatal(tipoPreNatal),
                    isRetificacao
                  )}
                  ivcfAtendimentoAtual={formValues?.objetivo?.atendimentosEspecificos?.ivcf}
                />
              )}
            </FormSpy>
          </div>
        </Cell>
        <Cell size={9}>
          <AccordionField allowMultipleExpanded allowZeroExpanded name={meta.openedAccordions}>
            <FormValueSpy<SoapState> onChange={handleFormChange} />
            <div css={styles.containerSoap}>
              <SoapSections
                tipoAtendProf={atendimentoProfissional.tipo}
                isObservacaoAndAuxiliar={isObservacaoAndAuxiliar}
                renderAntecedentes={renderAntecedentes}
                renderSubjetivo={renderSubjetivo}
                renderObjetivo={renderObjetivo}
                renderAvaliacao={renderAvaliacao}
                renderPlano={renderPlano}
                renderFinalizacaoAtendimento={renderFinalizacaoAtendimento}
              />
            </div>
          </AccordionField>
        </Cell>
      </Grid>
    </PageContent>
  )
}

const createStyles = (theme: Theme, asideHeight: number) => ({
  cellAside: css`
    padding-right: 0;
    border-right: 1px solid ${theme.pallete.divider};
  `,
  containerSoap: css`
    margin-left: -0.3rem;
  `,
  // É necessário manter o min-height com o tamanho do conteúdo da barra lateral
  // para que o scroll fique estável quando a lateral é mais alta que o SOAP.
  containerLateral: css`
    width: 100%;
    height: 100%;
    min-height: ${asideHeight}px;
  `,
})
