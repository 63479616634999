import { css } from '@emotion/core'
import { HFlow, Icon, PagedTable, Text } from 'bold-ui'
import { blue, green, red } from 'bold-ui/lib/styles/colors'
import { Ellipsis } from 'components/Ellipsis'
import { TableBox, usePagedTableProps } from 'components/table'
import { useHistoricoAcessoTableQuery } from 'graphql/hooks.generated'
import { HistoricoAcessoQueryInput, HistoricoAcessoTableQuery } from 'graphql/types.generated'
import React, { useState } from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { humanizeDateTime } from 'util/date/humanize-date'

const renderDataEHora = (row: HistoricoAcessosItem) => (
  <Text>
    {formatDateAndHoursMinutes(row.data)} <br /> Há {humanizeDateTime(row.data)}
  </Text>
)

const renderSucesso = (row: HistoricoAcessosItem) => (
  <HFlow
    hSpacing={0.5}
    style={css`
      width: 5.125rem;
      align-items: center;
    `}
  >
    {row.sucesso ? (
      <>
        <Icon icon='checkCircleOutline' color={green.c40} size={1} />
        <Text
          color='success'
          style={css`
            line-height: 2;
          `}
        >
          Sucesso
        </Text>
      </>
    ) : (
      <>
        <Icon icon='banOutline' color={red.c40} size={1} />
        <Text
          color='danger'
          style={css`
            line-height: 2;
          `}
          x
        >
          Erro
        </Text>
      </>
    )}
  </HFlow>
)

const renderCliente = (row: HistoricoAcessosItem) => (
  <Ellipsis maxWidth='20rem' maxLines={2}>
    {row.cliente}
  </Ellipsis>
)

const renderLocalEIp = (row: HistoricoAcessosItem) => (
  <Text>
    {row.local} | {row.ip}
  </Text>
)

const renderAcessoAtual = (row: HistoricoAcessosItem) =>
  row.acessoAtual && (
    <HFlow
      hSpacing={0.5}
      style={css`
        align-items: center;
      `}
    >
      <Icon icon='infoCircleOutline' color={blue.c40} size={1} />
      <Text
        color='info'
        style={css`
          line-height: 2;
        `}
      >
        Acesso atual
      </Text>
    </HFlow>
  )

export type HistoricoAcessosItem = HistoricoAcessoTableQuery['historicoAcessos']['content'][0]

export function HistoricoAcessosTable() {
  const [filter, setFilter] = useState<HistoricoAcessoQueryInput>({
    pageParams: {
      sort: ['-data'],
    },
  })

  const {
    data: { historicoAcessos },
    loading,
  } = useHistoricoAcessoTableQuery({ variables: { input: filter } })

  const tableProps = usePagedTableProps({
    result: historicoAcessos,
    loading,
    onChange: setFilter,
  })

  return (
    <TableBox>
      <PagedTable<HistoricoAcessosItem>
        {...tableProps}
        columns={[
          {
            name: 'data',
            header: 'Data e hora do acesso',
            render: renderDataEHora,
            style: { width: '12rem', padding: '0.25rem 1rem' },
            sortable: true,
          },
          { name: 'sucesso', header: 'Autenticação', render: renderSucesso, style: { width: '7.125rem' } },
          { name: 'cliente', header: 'Tipo de acesso | Versão', render: renderCliente, style: { width: '20rem' } },
          { name: 'ip', header: 'Local | Endereço de IP', render: renderLocalEIp },
          { name: 'acessoAtual', header: '', render: renderAcessoAtual, style: { width: '8.175rem' } },
        ]}
      />
    </TableBox>
  )
}
