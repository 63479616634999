/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Tag, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { Form, FormRenderProps } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { useAlterarConfiguracaoCadastroDomiciliarViaCdsMutation } from 'graphql/hooks.generated'
import { useConfiguracoes } from 'hooks/configuracoes'

interface ConfiguracoesBloqueioFcdtViaCdsBoxProps {
  habilitado: boolean
}

export function ConfiguracoesBloqueioFcdtViaCdsBox(props: ConfiguracoesBloqueioFcdtViaCdsBoxProps) {
  const { habilitado } = props

  const [alterarConfiguracaoCadastroDomiciliarViaCds] = useAlterarConfiguracaoCadastroDomiciliarViaCdsMutation()
  const alert = useAlert()
  const { update } = useConfiguracoes()

  const submitChange = async (msg: String) => {
    await alterarConfiguracaoCadastroDomiciliarViaCds({
      variables: {
        input: !habilitado,
      },
    })
    alert('success', msg)
  }

  const handleSubmit = async () => {
    if (!habilitado) {
      await confirm({
        title: 'Deseja habilitar?',
        body:
          'Isso pode aumentar a ocorrência de inconsistências nos núcleos familiares. Recomendamos que o município utilize o Acompanhamento do território, que provê todas as funcionalidades que existiam na Ficha de Cadastro Domiciliar do CDS e ainda fornece meios para qualificação dos cadastros.',
        onConfirm: async () => await submitChange('Cadastros de imóveis e famílias via CDS habilitadas com sucesso'),
        confirmLabel: 'Habilitar mesmo assim',
      })()
    }
    if (habilitado) {
      await submitChange('Cadastros de imóveis e famílias via CDS desabilitadas com sucesso')
    }
    return update()
  }

  const renderForm = (formProps: FormRenderProps) => (
    <Box style={styles.box}>
      <HFlow>
        <VFlow vSpacing={0.5}>
          <Heading level={3}>Cadastros de imóveis e famílias via CDS</Heading>
          <Text>
            Pode comprometer a qualidade dos cadastros em relação à consistência dos núcleos familiares. O
            Acompanhamento do território já substitui e otimiza esse fluxo, inibindo a ocorrência de inconsistências e
            facilitando a gestão dos cadastros.
          </Text>
          <Button kind='primary' size='small' onClick={formProps.handleSubmit}>
            {habilitado ? 'Desabilitar' : 'Habilitar'}
          </Button>
        </VFlow>
        {habilitado ? (
          <Tag type='success' icon='checkCircleFilled'>
            Habilitado
          </Tag>
        ) : (
          <Tag icon='banFilled'>Desabilitado</Tag>
        )}
      </HFlow>
    </Box>
  )

  return <Form onSubmit={handleSubmit} render={renderForm} />
}

const styles = {
  box: css`
    margin-top: 1rem;
  `,
}
