/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { IvcfPerguntaEnum } from 'graphql/types.generated'
import { useMemo } from 'react'

import { IVCF_PERGUNTAS } from '../../model-ivcf'

interface IvcfPerguntaWrapperProps {
  pergunta: IvcfPerguntaEnum
  children: JSX.Element
  hideBorderBottom?: boolean
  isEditing?: boolean
}

export function IvcfPerguntaWrapper(props: IvcfPerguntaWrapperProps) {
  const { pergunta, children, hideBorderBottom, isEditing } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, hideBorderBottom, isEditing), [hideBorderBottom, isEditing, theme])
  const { layout, label } = IVCF_PERGUNTAS[pergunta]

  return (
    <div css={styles.question}>
      {layout === 'horizontal' ? (
        <HFlow hSpacing={2} alignItems='center' justifyContent='space-between'>
          <Text>{label}</Text>
          {children}
        </HFlow>
      ) : (
        <VFlow vSpacing={0.5}>
          <Text>{label}</Text>
          {children}
        </VFlow>
      )}
    </div>
  )
}

const createStyles = (theme: Theme, hideBorderBottom, isEditing: boolean) => ({
  question: css`
    padding: 0.5rem 1rem;
    border-bottom: ${hideBorderBottom ? 0 : 1}px solid ${theme.pallete.gray.c80};
    background: ${isEditing ? theme.pallete.primary.c90 : theme.pallete.surface.main};
  `,
})
