import { css } from '@emotion/core'
import { Button, HFlow, Tooltip } from 'bold-ui'
import { useUltimoArquivoTemporarioProntoEmProcessamentoLazyQuery } from 'graphql/hooks.generated'
import {
  ArquivoTemporarioIdentificadorEnum,
  ArquivoTemporarioStatusEnum,
  TerritorioMicroareaQueryInput,
} from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import React, { useEffect, useState } from 'react'
import { downloadFile } from 'util/downloadFile'
import { downloadArquivo } from 'view/arquivo/downloadsArquivo'
import { ArquivoTemporarioFormato, relatoriosRecentesRecord } from 'view/arquivo/model-arquivo'
import StatusArquivoTemporarioLabel from 'view/arquivo/StatusArquivoTemporarioLabel'

import { useAsyncTerritorioProcessingPolling } from '../hooks/useAsyncTerritorioProcessingPolling'
import { downloadCsvAcompanhamentoTerritorio } from '../relatorio-acompanhamento-territorio'

interface RelatorioAcompanhamentoTerritorioProps {
  usuarioId: ID
  microareaInput: TerritorioMicroareaQueryInput
  hasImoveisLogradouros: boolean
  hasTerritorioIndigenaPermission: boolean
}

const TIME_OUT_MS = 5000

export const RelatorioAcompanhamentoTerritorio = (props: RelatorioAcompanhamentoTerritorioProps) => {
  const { usuarioId, microareaInput, hasImoveisLogradouros, hasTerritorioIndigenaPermission } = props
  const [relatorioStatus, setRelatorioStatus] = useState<ArquivoTemporarioStatusEnum>(null)
  const [downloadRealizado, setDownloadRealizado] = useState(false)

  const arquivoIdentificador = ArquivoTemporarioIdentificadorEnum.RELATORIO_ACOMPANHAMENTO_TERRITORIO_MICROAREA
  const { atmospherePath, fileName } = relatoriosRecentesRecord[arquivoIdentificador]
  const isRelatorioProcessing = relatorioStatus === ArquivoTemporarioStatusEnum.EM_PROCESSAMENTO

  const handleForceDownloadOnTimeout = () => {
    setRelatorioStatus(ArquivoTemporarioStatusEnum.PRONTO)
    executeQuery()
  }

  const { startPolling, stopPolling } = useAsyncTerritorioProcessingPolling({
    key: `${atmospherePath}/${usuarioId}`,
    timeOutMs: TIME_OUT_MS,
    onProcessFinished: handleForceDownloadOnTimeout,
  })

  const [executeQuery, { data }] = useUltimoArquivoTemporarioProntoEmProcessamentoLazyQuery({
    variables: {
      input: { arquivoTemporarioIdentificadorEnum: arquivoIdentificador },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (relatorioStatus === null) {
        setRelatorioStatus(data?.ultimoArquivoTemporarioProntoEmProcessamento?.status)
      }

      if (!downloadRealizado && relatorioStatus && data?.ultimoArquivoTemporarioProntoEmProcessamento) {
        setDownloadRealizado(true)
        downloadArquivo(
          data.ultimoArquivoTemporarioProntoEmProcessamento.id,
          `${fileName}`,
          data.ultimoArquivoTemporarioProntoEmProcessamento.formato.toLocaleLowerCase()
        )
      }
    },
  })

  const handleClickDownloadCsv = () => {
    setDownloadRealizado(false)
    downloadCsvAcompanhamentoTerritorio(microareaInput)
    setRelatorioStatus(ArquivoTemporarioStatusEnum.EM_PROCESSAMENTO)
  }

  useEffect(() => {
    if (isRelatorioProcessing) {
      startPolling()
    } else if (relatorioStatus === null) {
      executeQuery()
    }
  }, [executeQuery, isRelatorioProcessing, relatorioStatus, startPolling])

  useAtmosphere<ArquivoTemporarioFormato>({
    topic: `${atmospherePath}/${usuarioId}`,
    onMessage: (msg) => {
      stopPolling()

      if (msg.arquivo !== null && !downloadRealizado) {
        setRelatorioStatus(ArquivoTemporarioStatusEnum.PRONTO)
        setDownloadRealizado(true)
        const arquivo = new Uint8Array(msg.arquivo)
        downloadFile(new Blob([arquivo]), `${fileName}.csv`)
        executeQuery()
      } else if (msg.arquivo === null) {
        setRelatorioStatus(ArquivoTemporarioStatusEnum.ERRO)
      }
    },
  })

  return (
    <HFlow
      hSpacing={1}
      justifyContent='flex-end'
      alignItems='center'
      style={css`
        margin-top: 1rem;
      `}
    >
      {relatorioStatus && (
        <StatusArquivoTemporarioLabel
          arquivoTemporarioStatus={relatorioStatus}
          dataInicioGerado={data?.ultimoArquivoTemporarioProntoEmProcessamento?.dataInicioGerado}
          isApenasUltimoRelatorio={true}
        />
      )}
      <Tooltip
        text={`${
          hasTerritorioIndigenaPermission
            ? 'Funcionalidade ainda não disponível para instalações de Saúde Indígena.'
            : hasImoveisLogradouros
            ? 'Baixar a relação de imóveis da microárea'
            : 'Nenhum imóvel atende aos filtros aplicados.'
        }`}
      >
        <Button
          onClick={handleClickDownloadCsv}
          disabled={isRelatorioProcessing || !hasImoveisLogradouros || hasTerritorioIndigenaPermission}
        >
          Exportar CSV
        </Button>
      </Tooltip>
    </HFlow>
  )
}
