/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import { EvolucaoAvaliacao } from 'graphql/types.generated'
import { Fragment } from 'react'
import { isEmpty } from 'util/validation/Util'
import { naoRegistradoNesseAtendimento } from 'view/atendimentos/detail/historico/utils/utils-historico'
import { EvolucaoAlergia } from 'view/atendimentos/detail/soap/aside/types/AlergiaModel'
import { EvolucaoProblema } from 'view/atendimentos/detail/soap/aside/types/ProblemaModel'

import HistoricoDetailTable from '../../../../table/HistoricoDetailTable'
import HistoricoAvaliacaoAlergiaTableContent from './soap/plano/avaliacao/HistoricoAvaliacaoAlergiaTableContent'
import HistoricoAvaliacaoProblemasCondicoesTableContent from './soap/plano/avaliacao/HistoricoAvaliacaoProblemasCondicoesTableContent'

interface HistoricoAvaliacaoPecPanelProps {
  evolucaoAvaliacao: EvolucaoAvaliacao
  evolucaoAlergia?: EvolucaoAlergia[]
  evolucaoProblema?: EvolucaoProblema[]
}

export default function HistoricoAvaliacaoPecPanel(props: HistoricoAvaliacaoPecPanelProps) {
  const { evolucaoAvaliacao, evolucaoAlergia, evolucaoProblema } = props
  const hasDescricaoAvaliacao = evolucaoAvaliacao?.descricao?.removeTags()

  const hasAvaliacao =
    hasDescricaoAvaliacao ||
    !isEmpty(evolucaoAvaliacao.ciapsCids) ||
    !isEmpty(evolucaoProblema) ||
    !isEmpty(evolucaoAlergia)

  return (
    <Fragment>
      {hasAvaliacao ? (
        <VFlow>
          {evolucaoAvaliacao?.descricao?.removeTags() && (
            <Text dangerouslySetInnerHTML={{ __html: evolucaoAvaliacao.descricao }} />
          )}
          <HistoricoDetailTable
            title='Problemas e/ou condições avaliados e registrados neste atendimento'
            sectionStyle='A'
            hasLines
          >
            <HistoricoAvaliacaoProblemasCondicoesTableContent
              evolucaoAvaliacao={evolucaoAvaliacao}
              evolucaoProblema={evolucaoProblema}
            />
          </HistoricoDetailTable>
          {!isEmpty(evolucaoAlergia) && (
            <HistoricoDetailTable title='Alergias e reações adversas' sectionStyle='A' hasLines>
              <HistoricoAvaliacaoAlergiaTableContent evolucaoAlergia={evolucaoAlergia} />
            </HistoricoDetailTable>
          )}
        </VFlow>
      ) : (
        <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
      )}
    </Fragment>
  )
}
