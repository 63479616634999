/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, Heading, InfoLabel } from 'bold-ui'
import { HistoricoObjetivoPecFragmentFragment } from 'graphql/types.generated'
import { IVCF_RESULTADOS } from 'view/atendimentos/detail/soap/objetivo/ivcf/model-ivcf'

import HistoricoDetailTable from '../../table/HistoricoDetailTable'

type ivcf = HistoricoObjetivoPecFragmentFragment['ivcf']

interface HistoricoIvcfTableBoxProps {
  ivcf?: ivcf
}

export function HistoricoIvcfTableBox({ ivcf }: HistoricoIvcfTableBoxProps) {
  return (
    <HistoricoDetailTable title='Índice de Vulnerabilidade Clínico-Funcional (IVCF-20)' sectionStyle='P'>
      <Grid gapVertical={0.5}>
        <Cell size={12}>
          <Heading level={4}>
            {IVCF_RESULTADOS[ivcf.riscoVulnerabilidade].longTitle} | {ivcf.resultado}{' '}
            {ivcf.resultado === 1 ? 'ponto' : 'pontos'}
          </Heading>
        </Cell>
        <Cell size={12}>
          <InfoLabel title='Dimensões alteradas:'>{ivcf.dimensoesAlteradas ?? 'Nenhuma alteração'}.</InfoLabel>
        </Cell>
      </Grid>
    </HistoricoDetailTable>
  )
}
