/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Spinner, Text, Theme, useTheme } from 'bold-ui'
import { ArquivoTemporarioStatusEnum } from 'graphql/types.generated'
import { Fragment } from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'

export interface StatusArquivoLabelProps {
  arquivoTemporarioStatus: ArquivoTemporarioStatusEnum
  dataInicioGerado?: Instant
  isApenasUltimoRelatorio: boolean
}

export default function StatusArquivoTemporarioLabel(props: StatusArquivoLabelProps) {
  const { arquivoTemporarioStatus, isApenasUltimoRelatorio, dataInicioGerado } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <HFlow hSpacing={0.5}>
      {arquivoTemporarioStatus === ArquivoTemporarioStatusEnum.EM_PROCESSAMENTO ? (
        <Fragment>
          <Spinner size={0.8} borderWidth={2} style={[styles.spinner, styles.processandoText]} />
          <Text style={styles.processandoText}>Em processamento</Text>
        </Fragment>
      ) : arquivoTemporarioStatus === ArquivoTemporarioStatusEnum.PRONTO ? (
        <Fragment>
          <Icon icon='checkCircleOutline' style={styles.successIcon} size={1} />
          <Text style={styles.successIcon}>
            {isApenasUltimoRelatorio
              ? `Último relatório gerado em ${formatDateAndHoursMinutes(dataInicioGerado)}`
              : 'Pronto'}
          </Text>
        </Fragment>
      ) : (
        <Fragment>
          <Icon icon='banOutline' style={styles.errorIcon} size={1} />
          <Text style={styles.errorIcon}>Erro no processamento</Text>
        </Fragment>
      )}
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  spinner: css`
    margin: 0.1rem 0.07rem 0 0.13rem;
  `,
  successIcon: css`
    color: ${theme.pallete.status.success.main};
  `,
  errorIcon: css`
    color: ${theme.pallete.status.danger.main};
  `,
  processandoText: css`
    color: ${theme.pallete.primary.main};
  `,
})
