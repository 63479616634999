import { HFlow, Icon, InfoLabel, Theme, Tooltip, useTheme } from 'bold-ui'
import { DateTime } from 'components/date'
import { formatDuration } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { css } from 'emotion'
import { CuidadoCompartilhado } from 'graphql/types.generated'
import React from 'react'

interface DateTimeInfoCuidadoCompartilhadoProps {
  cuidadoCompartilhado: Pick<CuidadoCompartilhado, 'iniciadoEm' | 'tempoDecorridoUltimaEvolucao'>
}

export function DateTimeInfoCuidadoCompartilhado(props: DateTimeInfoCuidadoCompartilhadoProps) {
  const {
    cuidadoCompartilhado: { iniciadoEm, tempoDecorridoUltimaEvolucao },
  } = props
  const theme = useTheme()
  const classes = styles(theme)

  const duracaoFormatado = formatDuration({ hours: tempoDecorridoUltimaEvolucao }, { locale: ptBR, zero: true })

  return (
    <HFlow hSpacing={2}>
      <InfoLabel title='Data de início'>
        <DateTime value={iniciadoEm} format='DD/MM/YYYY' />
      </InfoLabel>
      <InfoLabel title='Horário de início'>
        <DateTime value={iniciadoEm} format='HH:mm' />
      </InfoLabel>
      <HFlow hSpacing={0.5}>
        <InfoLabel title='Tempo decorrido'>{duracaoFormatado}</InfoLabel>
        <Tooltip text='Duração desde a última evolução'>
          <Icon icon='infoCircleFilled' fill='normal' size={1} style={classes.infoIcon} />
        </Tooltip>
      </HFlow>
    </HFlow>
  )
}

const styles = (theme: Theme) => ({
  infoIcon: css`
    &:hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
})
