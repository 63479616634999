import { Cell, Grid } from 'bold-ui'
import { DateField, MunicipioSelectField, RadioGroupField, TextField } from 'components/form'
import { PaisSelectField } from 'components/form/field/select/PaisSelectField'
import { NacionalidadeEnum } from 'graphql/types.generated'
import React from 'react'
import { onlyNumbersLetters } from 'util/mask'
import { MetaRoot } from 'util/metaPath'

import { NacionalidadeFormModel } from '../model-cidadaoFci'

interface NacionalidadeFieldGroupProps {
  meta: MetaRoot<NacionalidadeFormModel>
  maxDate: Date
  values: NacionalidadeFormModel
}

export const NacionalidadeFieldGroup = ({ meta, maxDate, values }: NacionalidadeFieldGroupProps) => {
  const isNaturalizado = values?.nacionalidade === NacionalidadeEnum.NATURALIZADA
  const isEstrangeiro = values?.nacionalidade === NacionalidadeEnum.ESTRANGEIRA

  return (
    <Grid>
      <Cell size={6}>
        <RadioGroupField
          label='Nacionalidade'
          name={meta.nacionalidade}
          options={[
            { value: NacionalidadeEnum.BRASILEIRA, label: 'Brasileira' },
            { value: NacionalidadeEnum.NATURALIZADA, label: 'Naturalizado' },
            { value: NacionalidadeEnum.ESTRANGEIRA, label: 'Estrangeiro' },
          ]}
          required
        />
      </Cell>
      <Cell size={6} />
      <Cell size={6}>
        {values?.nacionalidade === NacionalidadeEnum.BRASILEIRA && (
          <MunicipioSelectField name={meta.municipioNascimento} label='Município de nascimento' required />
        )}
        {isEstrangeiro && (
          <PaisSelectField name={meta.paisNascimento} omiteBrasil={true} label='País de nascimento' required />
        )}
        {isNaturalizado && (
          <TextField
            name={meta.portariaNaturalizacao}
            label='Portaria de naturalização'
            parse={onlyNumbersLetters}
            maxLength={16}
            required
          />
        )}
      </Cell>
      <Cell size={3}>
        {isEstrangeiro && (
          <DateField name={meta.dataEntradaPais} maxDate={maxDate} label='Data de entrada no Brasil' required />
        )}
        {isNaturalizado && (
          <DateField name={meta.dataNaturalizacao} maxDate={maxDate} label='Data de naturalização' required />
        )}
      </Cell>
      <Cell size={3} />
    </Grid>
  )
}
