import { ApolloQueryResult } from 'apollo-client'
import { IconProps } from 'bold-ui'
import { IconColor } from 'bold-ui/lib/components/Icon/Icon'
import { ButtonAction } from 'bold-ui/lib/components/Modal/auto/ModalAuto'
import { EstrategiaVacinacaoSelectFieldModel } from 'components/form/field/select/EstrategiaVacinacaoSelectField'
import { isBefore, isWithinInterval, parseISO, subDays } from 'date-fns'
import {
  FaixaEtariaVacinacaoQuery,
  FaixaEtariaVacinacaoQueryVariables,
  LoteHasCadastroQuery,
  LoteHasCadastroQueryVariables,
  RegistroAplicado,
  TipoRegistroVacinacaoEnum,
} from 'graphql/types.generated'
import { Dispatch } from 'react'
import { calculateAge } from 'util/date/calculateAge'
import { formatDate } from 'util/date/formatDate'
import { MetaRoot } from 'util/metaPath'
import { covidImunosRecord } from 'view/lote-imunobiologico/model'

import { createVacinacaoErrorModal } from '../detail/vacinacao/VacinacaoCalendarioView'
import { CidadaoAtendimento } from '../types/CidadaoAtendimento'
import { RegistroVacinacaoFormModel, StatusDoseEnum, VacinacaoDoseIdEnum, VacinacaoRegistrosLink } from './model'
import { SubModalVacinacaoOptions } from './VacinacaoModal'
import { ageInDays, getFaixaEtariaRecomendada } from './vacinacaoUtils'
import { getRegistroVacinacao } from './vacinacaoUtils/getRegistroVacinacao'

export interface ValidateRegistroVacinaError {
  hasError: boolean
  title?: string
  subTitle?: string
  actions?: ButtonAction[]
  type?: IconColor
  icon?: IconProps['icon']
}

export interface ValidateRegistroVacinaInput {
  cidadao?: CidadaoAtendimento
  tipoRegistroVacinacao?: TipoRegistroVacinacaoEnum
  imunobiologicoId?: ID
  doseId?: ID
  estrategiaVacinacao?: EstrategiaVacinacaoSelectFieldModel
  isRegistroAnterior?: boolean
  dataAtendimento?: number
  dataAprazamento?: string
  loteDataValidade?: string
  registrosAplicados?: RegistroAplicado[]
  registrosAplicadosAtendimento?: ReadonlyArray<RegistroVacinacaoFormModel>
  //idUnicoRegistro é utilizado para validar, ou não, registros em edição
  idUnicoRegistro?: ID
  handleOnSubmit?(): void
  refetchFaixaEtaria?: (
    variables?: FaixaEtariaVacinacaoQueryVariables
  ) => Promise<ApolloQueryResult<FaixaEtariaVacinacaoQuery>>
  handleSubModalVacinacaoClose?(): void
  tipoModal?: SubModalVacinacaoOptions
  refetchLoteHasCadastro?: (
    variables?: LoteHasCadastroQueryVariables
  ) => Promise<ApolloQueryResult<LoteHasCadastroQuery>>
  isCadastrarNovoLote?: boolean
  fabricanteNome?: string
  loteNome?: string
  status?: StatusDoseEnum
}

export interface VacinacaoRegistrosAplicadosFilterInput {
  registrosAplicados?: RegistroAplicado[]
  registrosAplicadosAtendimento?: RegistroVacinacaoFormModel[]
  imunobiologicoId: ID
  doseId?: ID
  isRegistroAnterior?: boolean
  isAprazamento?: boolean
  tipoRegistro?: TipoRegistroVacinacaoEnum
  filterDuasListas?: boolean
  idUnicoRegistro?: ID
}

export interface ModaisVacinacao {
  impeditivos: ValidateRegistroVacinaError[]
  naoImpeditivos: ValidateRegistroVacinaError[]
  handleOnSubmit?(): void
}

export enum ValidacoesVacinacaoEnum {
  FEBRE_AMARELA,
  DTPA_ADULTO,
  GESTANTE,
  BCG,
}

export const imunosComValidacoesIds: Record<ValidacoesVacinacaoEnum, string[]> = {
  [ValidacoesVacinacaoEnum.FEBRE_AMARELA]: ['14'],
  [ValidacoesVacinacaoEnum.DTPA_ADULTO]: ['57'],
  [ValidacoesVacinacaoEnum.GESTANTE]: [
    '14',
    '15',
    '17',
    '24',
    '36',
    '42',
    '43',
    '56',
    '67',
    '82',
    '85',
    '88',
    '89',
    '99',
    '102',
  ],
  [ValidacoesVacinacaoEnum.BCG]: ['15'],
}

export const submitImunobiologicoValidate = async (input: ValidateRegistroVacinaInput): Promise<ModaisVacinacao> => {
  const {
    cidadao,
    tipoRegistroVacinacao,
    imunobiologicoId,
    doseId,
    estrategiaVacinacao,
    isRegistroAnterior,
    dataAtendimento,
    dataAprazamento,
    refetchFaixaEtaria,
    loteDataValidade,
    registrosAplicados,
    registrosAplicadosAtendimento,
    idUnicoRegistro,
    handleOnSubmit,
    refetchLoteHasCadastro,
    isCadastrarNovoLote,
    loteNome,
    fabricanteNome,
    status,
  } = input

  let errors: ValidateRegistroVacinaError[] = []
  let errorsImpeditivos: ValidateRegistroVacinaError[] = []
  const isCovid = covidImunosRecord[imunobiologicoId]

  if (tipoRegistroVacinacao === TipoRegistroVacinacaoEnum.APLICACAO && !isRegistroAnterior) {
    errors.push(
      await validateFaixaEtaria(
        refetchFaixaEtaria,
        cidadao.dataNascimento,
        dataAtendimento,
        dataAprazamento,
        imunobiologicoId,
        doseId,
        estrategiaVacinacao.id,
        idUnicoRegistro,
        status,
        false,
        !!isCovid
      )
    )

    if (imunosComValidacoesIds[ValidacoesVacinacaoEnum.FEBRE_AMARELA][0] === imunobiologicoId) {
      errorsImpeditivos.push(validateFebreAmarelaDoseUnica(registrosAplicados, registrosAplicadosAtendimento, doseId))
      errorsImpeditivos.push(validateFebreAmarelaDose(registrosAplicados, doseId, registrosAplicadosAtendimento))
    }

    if (isCadastrarNovoLote) {
      errorsImpeditivos.push(
        await validateLoteHasCadastro(
          refetchLoteHasCadastro,
          loteNome,
          fabricanteNome,
          imunobiologicoId,
          loteDataValidade
        )
      )
    }

    errorsImpeditivos.push(
      validateImunobiologicoJaAplicado(registrosAplicadosAtendimento, imunobiologicoId, idUnicoRegistro)
    )

    errors.push(validateDoseJaAplicada(doseId, imunobiologicoId, registrosAplicados, idUnicoRegistro))
    errors.push(validateDataValidade(loteDataValidade, dataAtendimento))
  }

  if (isRegistroAnterior) {
    if (imunosComValidacoesIds[ValidacoesVacinacaoEnum.FEBRE_AMARELA][0] === imunobiologicoId) {
      errorsImpeditivos.push(validateFebreAmarelaDoseUnica(registrosAplicados, registrosAplicadosAtendimento, doseId))
      errorsImpeditivos.push(validateFebreAmarelaDose(registrosAplicados, doseId, registrosAplicadosAtendimento))
    }

    errors.push(validateDoseJaAplicada(doseId, imunobiologicoId, registrosAplicados, idUnicoRegistro))
  }

  if (tipoRegistroVacinacao === TipoRegistroVacinacaoEnum.APRAZAMENTO) {
    errorsImpeditivos.push(
      validateDoseJaAprazada(doseId, imunobiologicoId, idUnicoRegistro, registrosAplicadosAtendimento)
    )
    errors.push(
      await validateFaixaEtaria(
        refetchFaixaEtaria,
        cidadao.dataNascimento,
        dataAtendimento,
        dataAprazamento,
        imunobiologicoId,
        doseId,
        estrategiaVacinacao.id,
        idUnicoRegistro,
        status,
        true,
        !!isCovid
      )
    )
  }

  return {
    impeditivos: errorsImpeditivos.filter((error) => error),
    naoImpeditivos: errors.filter((error) => error),
    handleOnSubmit,
  }
}

const validateDataValidade = (loteDataValidade: string, dataAtendimento: Instant): ValidateRegistroVacinaError => {
  if (isBefore(parseISO(loteDataValidade), subDays(dataAtendimento, 1))) {
    return {
      hasError: true,
      title: 'Deseja confirmar o registro?',
      subTitle: 'A data de validade do lote de imunobiológico sendo cadastrado está vencida.',
      type: 'primary',
      icon: 'questionMarkFilled',
    }
  }
}

const validateFaixaEtaria = async (
  refetchFaixaEtaria: (
    variables?: FaixaEtariaVacinacaoQueryVariables
  ) => Promise<ApolloQueryResult<FaixaEtariaVacinacaoQuery>>,
  dataNascimento: string,
  dataAtendimento: number,
  dataAprazamento: string,
  imunobiologicoId: ID,
  doseId: ID,
  estrategiaId: ID,
  idUnicoRegistro: ID,
  status: StatusDoseEnum,
  isAprazamento: boolean,
  isCovid: boolean
): Promise<ValidateRegistroVacinaError> => {
  let error: ValidateRegistroVacinaError = undefined

  const aplicacaoEmEdicacao = !isAprazamento && idUnicoRegistro

  if (aplicacaoEmEdicacao) {
    return error
  }

  if (status === StatusDoseEnum.DISPONIVEL && !isCovid) {
    return error
  }

  const age = calculateAge(dataNascimento, isAprazamento ? dataAprazamento : dataAtendimento)

  const idadeCalculoFaixa = ageInDays(age)

  const result = await refetchFaixaEtaria({
    input: {
      imunobiologicoId,
      doseId,
      estrategiaId,
    },
  }).catch(() => {
    error = {
      hasError: true,
      title: `Deseja confirmar o registro?`,
      subTitle: 'Não foi possível verificar se esta dose de imunobiológico está dentro da faixa etária recomendada.',
      type: 'primary',
      icon: 'questionMarkOutline',
    }
  })

  const faixasEtarias = result && result.data?.faixaEtariaVacinacao

  if (faixasEtarias.length > 0) {
    const faixaEtariaRecomendada = getFaixaEtariaRecomendada(
      faixasEtarias,
      dataNascimento,
      dataAtendimento,
      status === StatusDoseEnum.APLICADA,
      idadeCalculoFaixa
    )

    if (
      faixaEtariaRecomendada &&
      !isWithinInterval(idadeCalculoFaixa, {
        start: faixaEtariaRecomendada.diasInicio,
        end: faixaEtariaRecomendada.diasFim,
      })
    ) {
      const msgAplicacao = `Esta dose de imunobiológico não é recomendada para cidadãos fora da faixa etária: <br/><br/>
        ${faixaEtariaRecomendada.descricao}.<br/><br/>
        Deseja confirmar o registro da dose?
        `

      const msgAprazamento = `Nesta data de aprazamento o cidadão estará fora da faixa etária recomendada para receber esta dose de imunobiológico: <br/><br/>
        ${faixaEtariaRecomendada.descricao}.<br/><br/>
        Deseja confirmar o registro da dose?`

      error = {
        hasError: true,
        title: `Deseja confirmar o registro de ${isAprazamento ? 'aprazamento ' : 'aplicação'}?`,
        subTitle: isAprazamento ? msgAprazamento : msgAplicacao,
        type: 'primary',
        icon: 'questionMarkOutline',
      }
    }
  }

  return error
}

const validateLoteHasCadastro = async (
  loteHasCadastro: (variables?: LoteHasCadastroQueryVariables) => Promise<ApolloQueryResult<LoteHasCadastroQuery>>,
  loteNome: string,
  nomeFabricante: string,
  idImunobiologico: string,
  validade: string
): Promise<ValidateRegistroVacinaError> => {
  let error: ValidateRegistroVacinaError = undefined

  const result = await loteHasCadastro({
    input: {
      lote: loteNome,
      nomeFabricante,
      idImunobiologico,
      validade,
    },
  }).catch(() => {
    error = {
      hasError: true,
      title: `Deseja confirmar o registro?`,
      subTitle: 'Não foi possível verificar se este lote de imunobiológico já está cadastrado.',
      type: 'primary',
      icon: 'questionMarkOutline',
    }
  })

  if (result && result.data?.loteHasCadastro) {
    error = {
      hasError: true,
      title: 'Não é possível salvar o registro',
      subTitle: 'Este lote de imunobiológico já está cadastrado.',
      actions: [{ label: 'OK', kind: 'primary' }],
      type: 'danger',
      icon: 'questionMarkOutline',
    }
  }

  return error
}

const validateFebreAmarelaDoseUnica = (
  registrosAplicados: ReadonlyArray<RegistroAplicado>,
  registrosAplicadosAtendimento: ReadonlyArray<RegistroVacinacaoFormModel>,
  doseId: string
): ValidateRegistroVacinaError => {
  const input = {
    imunobiologicoId: imunosComValidacoesIds[ValidacoesVacinacaoEnum.FEBRE_AMARELA][0],
    tipoRegistro: TipoRegistroVacinacaoEnum.APLICACAO,
    doseId: VacinacaoDoseIdEnum.DOSE,
    isAprazamento: false,
    isRegistroAnterior: true,
  }
  const registroEncontrado =
    getRegistroVacinacao(input, { registros: registrosAplicadosAtendimento, isRegistrosAplicados: false }) ||
    getRegistroVacinacao(input, { registros: registrosAplicados, isRegistrosAplicados: true })

  if (registroEncontrado && doseId === VacinacaoDoseIdEnum.DOSE_UNICA) {
    return {
      hasError: true,
      title: 'Não é possível salvar o registro',
      subTitle: 'O cidadão já recebeu a dose D deste imunobiológico, que é equivalente à DU (Dose Única).',
      actions: [{ label: 'OK', kind: 'primary' }],
      type: 'danger',
      icon: 'exclamationTriangleOutline',
    }
  }
}
const validateImunobiologicoJaAplicado = (
  registrosAplicadosAtendimento: ReadonlyArray<RegistroVacinacaoFormModel>,
  imunobiologicoId: string,
  idUnicoRegistro: ID
): ValidateRegistroVacinaError => {
  const registroEncontrado = getRegistroVacinacao(
    {
      imunobiologicoId,
      tipoRegistro: TipoRegistroVacinacaoEnum.APLICACAO,
      isRegistroAnterior: false,
      isAprazamento: false,
      idUnicoRegistro,
    },
    {
      registros: registrosAplicadosAtendimento,
      isRegistrosAplicados: false,
    }
  ) as RegistroVacinacaoFormModel

  if (registroEncontrado && registroEncontrado?.isRegistroAnterior !== true) {
    return {
      hasError: true,
      title: 'Não é possível salvar o registro',
      subTitle: 'Este Imunobiológico já está cadastrado.',
      actions: [{ label: 'OK', kind: 'primary' }],
      type: 'danger',
      icon: 'exclamationTriangleOutline',
    }
  }
}

const validateDoseJaAplicada = (
  doseId: string,
  imunobiologicoId: string,
  registrosAplicados: RegistroAplicado[],
  idUnicoRegistro: ID
): ValidateRegistroVacinaError => {
  const registroEncontrado = getRegistroVacinacao(
    {
      imunobiologicoId,
      doseId,
      isAprazamento: false,
    },
    { registros: registrosAplicados, isRegistrosAplicados: true }
  ) as RegistroAplicado

  if (registroEncontrado && !idUnicoRegistro) {
    return {
      hasError: true,
      title: 'Deseja confirmar o registro?',
      subTitle: `O cidadão já recebeu a ${registroEncontrado.doseSigla} do imunobiológico ${registroEncontrado.imunobiologicoNome}.`,
      type: 'primary',
      icon: 'questionMarkFilled',
    }
  }
}

const validateDoseJaAprazada = (
  doseId: string,
  imunobiologicoId: string,
  idUnicoRegistro: string,
  registrosAplicadosAtendimento: ReadonlyArray<RegistroVacinacaoFormModel>
): ValidateRegistroVacinaError => {
  const registroEncontrado = getRegistroVacinacao(
    {
      imunobiologicoId,
      doseId,
      tipoRegistro: TipoRegistroVacinacaoEnum.APRAZAMENTO,
      idUnicoRegistro,
    },
    { registros: registrosAplicadosAtendimento, isRegistrosAplicados: false }
  )

  if (registroEncontrado) {
    return {
      hasError: true,
      title: 'Não é possível salvar o registro',
      subTitle: `Esta dose deste imunobiológico já possui um aprazamento cadastrado neste atendimento. Um novo aprazamento poderá ser registrado em um atendimento posterior.`,
      actions: [{ label: 'OK', kind: 'primary' }],
      type: 'danger',
      icon: 'exclamationTriangleOutline',
    }
  }
}

const validateFebreAmarelaDose = (
  registrosAplicados: ReadonlyArray<RegistroAplicado>,
  doseClicadaId: string,
  registrosAplicadosAtendimento: ReadonlyArray<RegistroVacinacaoFormModel>
): ValidateRegistroVacinaError => {
  const input = {
    registrosAplicados,
    registrosAplicadosAtendimento,
    imunobiologicoId: imunosComValidacoesIds[ValidacoesVacinacaoEnum.FEBRE_AMARELA][0],
    doseId: VacinacaoDoseIdEnum.DOSE_UNICA,
    isRegistroAnterior: true,
    tipoRegistro: TipoRegistroVacinacaoEnum.APLICACAO,
  }

  const registroEncontrado =
    getRegistroVacinacao(input, { registros: registrosAplicadosAtendimento, isRegistrosAplicados: false }) ||
    getRegistroVacinacao(input, { registros: registrosAplicados, isRegistrosAplicados: true })

  if (doseClicadaId === VacinacaoDoseIdEnum.DOSE && registroEncontrado) {
    return {
      hasError: true,
      title: 'Não é possível registrar esse imunobiológico',
      subTitle: 'O cidadão já recebeu a DU (Dose Única) deste imunobiológico, que é equivalente à dose D.',
      actions: [{ label: 'OK', kind: 'primary' }],
      type: 'danger',
      icon: 'exclamationTriangleOutline',
    }
  }
}

const validateImunoContraIndicadoGestante = (input: ValidateRegistroVacinaInput): ValidateRegistroVacinaError => {
  const { imunobiologicoId, tipoModal, idUnicoRegistro, handleSubModalVacinacaoClose } = input
  if (
    tipoModal === 'APLICACAO' &&
    idUnicoRegistro === undefined &&
    imunosComValidacoesIds[ValidacoesVacinacaoEnum.GESTANTE].find((id) => id === input.imunobiologicoId)
  ) {
    const subTitle = covidImunosRecord[imunobiologicoId]
      ? 'Essa vacina não é recomendada para gestantes!'
      : 'Gestantes e mulheres amamentando devem receber a dose desta vacina somente em casos de surtos, epidemias ou com recomendação médica!'

    return {
      hasError: true,
      title: `Deseja aplicar esta vacina?`,
      subTitle: subTitle,
      actions: [
        { label: 'Não', onClick: () => handleSubModalVacinacaoClose() },
        { label: 'Sim', kind: 'primary' },
      ],
      type: 'primary',
      icon: 'questionMarkFilled',
    }
  }
}

export const validateSubModal = (input: ValidateRegistroVacinaInput, isGestante: boolean): ModaisVacinacao => {
  let errors: ValidateRegistroVacinaError[] = []
  let errorsImpeditivos: ValidateRegistroVacinaError[] = []

  if (
    imunosComValidacoesIds[ValidacoesVacinacaoEnum.FEBRE_AMARELA][0] === input.imunobiologicoId &&
    input.tipoModal !== 'APRAZAMENTO'
  ) {
    errorsImpeditivos.push(
      validateFebreAmarelaDose(input.registrosAplicados, input.doseId, input.registrosAplicadosAtendimento)
    )
    errorsImpeditivos.push(
      validateFebreAmarelaDoseUnica(input.registrosAplicados, input.registrosAplicadosAtendimento, input.doseId)
    )
  }

  if (isGestante) {
    errors.push(validateImunoContraIndicadoGestante(input))
  }

  return {
    impeditivos: errorsImpeditivos.filter((error) => error),
    naoImpeditivos: errors.filter((error) => error),
    handleOnSubmit: input.handleOnSubmit,
  }
}

export const validateFinalizarAtendimento = (
  input: ValidateRegistroVacinaInput,
  cpfCns: string,
  handleClick: Dispatch<void>,
  isAtendimentoVacinacao: boolean
) => {
  let error: ValidateRegistroVacinaError = { hasError: false }

  if (input.registrosAplicadosAtendimento.length === 0) {
    error = {
      hasError: true,
      title: 'Para finalizar o atendimento é necessário preencher no mínimo um dos tipos de registro:',
      subTitle: ' - Aplicação<br>- Aprazamento<br>- Transcrição de caderneta<br>',
      actions: [{ label: 'OK', kind: 'primary' }],
      type: 'danger',
      icon: 'exclamationTriangleOutline',
    }
  } else if (!cpfCns) {
    error = validateCpfCns(cpfCns, handleClick, isAtendimentoVacinacao)
  }

  return error
}

export const validateCpfCns = (
  cpfCns: string,
  handleClick: Dispatch<void>,
  isAtendimentoVacinacao: boolean
): ValidateRegistroVacinaError => {
  if (!cpfCns) {
    return {
      hasError: true,
      title: `É necessário cadastrar um CPF ou CNS para o cidadão.`,
      subTitle: isAtendimentoVacinacao
        ? `Para finalizar o atendimento de vacinação, é obrigatório que o cidadão possua um CPF ou CNS cadastrado.</br></br>
      O atendimento atual ficará salvo como rascunho e não poderá ser finalizado enquanto o cadastro do cidadão não for atualizado.`
        : `Para realizar a transcrição de caderneta, é obrigatório que o cidadão possua um CPF ou CNS cadastrado.`,
      actions: [
        { label: isAtendimentoVacinacao ? 'Continuar aqui' : 'Cancelar' },
        {
          label: 'Atualizar cadastro do cidadão',
          kind: 'primary',
          onClick: () => handleClick(),
        },
      ],
      type: 'alert',
      icon: 'exclamationTriangleOutline',
    }
  }
}

export const validateContraIndicacaoAplicacaoOutrosImunos = (
  imunobiologicoId: string,
  formChange: (name: string, value?: any) => void,
  meta: MetaRoot<RegistroVacinacaoFormModel>
) => {
  const error = validateImunoContraIndicadoGestante({
    imunobiologicoId: imunobiologicoId,
    tipoModal: 'APLICACAO',
    handleSubModalVacinacaoClose: () => {
      formChange(meta.outrosImunosImunobiologico.absolutePath(), null)
    },
  })

  if (error?.hasError) {
    createVacinacaoErrorModal(error)
  }
}

export const checkUpdateAprazamentoAutomatico = (
  aplicacao: RegistroVacinacaoFormModel,
  onSuccess: () => void,
  handleSubmit: (input: RegistroVacinacaoFormModel) => void,
  registrosAplicadosAtendimento: RegistroVacinacaoFormModel[]
) => {
  const aplicacaoVacinaNome = aplicacao.estrategiaVacinacao.nome?.capitalize()
  const onSubmit = (input: RegistroVacinacaoFormModel) => {
    handleSubmit(input)
    onSuccess()
  }

  // Aplicação da vacina com aprazamento da próxima dose, porém a próxima dose já foi cadastrada previamente
  if (
    aplicacao.idUnicoRegistro === undefined ||
    (aplicacao.idUnicoRegistro && aplicacao.aprazamentoProxDoseLink === undefined)
  ) {
    let registroEncontrado = getRegistroVacinacao(
      {
        imunobiologicoId: aplicacao.imunobiologicoId || aplicacao.outrosImunosImunobiologico.id,
        doseId: aplicacao.proximaDoseId,
        tipoRegistro: TipoRegistroVacinacaoEnum.APRAZAMENTO,
        isRegistroAnterior: false,
        isAprazamento: true,
      },
      { registros: registrosAplicadosAtendimento, isRegistrosAplicados: true }
    ) as RegistroVacinacaoFormModel

    if (registroEncontrado) {
      let newAplicacaoAprazamentoLink = {
        imunobiologicoId: registroEncontrado?.imunobiologicoId || registroEncontrado?.outrosImunosImunobiologico?.id,
        idUnicoRegistro: registroEncontrado?.idUnicoRegistro,
        doseId: registroEncontrado?.doseId,
      } as VacinacaoRegistrosLink

      if (
        registroEncontrado.dataAprazamento !== aplicacao.dataAprazamentoProxDose &&
        registroEncontrado.estrategiaVacinacao.id !== aplicacao.estrategiaVacinacao.id
      ) {
        createVacinacaoErrorModal(
          createUpdateAprazamentoProxDoseError(
            aplicacao,
            `Deseja alterar a estratégia para ${aplicacaoVacinaNome} e a data para ${formatDate(
              aplicacao.dataAprazamentoProxDose
            )}?`,
            () =>
              onSubmit({
                ...aplicacao,
                aprazamentoProxDoseLink: {
                  ...newAplicacaoAprazamentoLink,
                  dataAprazamentoProxDose: aplicacao.dataAprazamentoProxDose,
                  estrategiaVacinacao: aplicacao.estrategiaVacinacao,
                },
              }),
            () => onSubmit({ ...aplicacao, dataAprazamentoProxDose: undefined }),
            registroEncontrado
          )
        )
      } else if (
        registroEncontrado.dataAprazamento !== aplicacao.dataAprazamentoProxDose &&
        registroEncontrado.estrategiaVacinacao.id === aplicacao.estrategiaVacinacao.id
      ) {
        createVacinacaoErrorModal(
          createUpdateAprazamentoProxDoseError(
            aplicacao,
            `Deseja alterar a data para ${formatDate(aplicacao.dataAprazamentoProxDose)}?`,
            () =>
              onSubmit({
                ...aplicacao,
                aprazamentoProxDoseLink: {
                  ...newAplicacaoAprazamentoLink,
                  dataAprazamentoProxDose: aplicacao.dataAprazamentoProxDose,
                  estrategiaVacinacao: registroEncontrado.estrategiaVacinacao,
                },
              }),
            () => onSubmit({ ...aplicacao, dataAprazamentoProxDose: undefined }),
            registroEncontrado
          )
        )
      } else if (
        registroEncontrado.estrategiaVacinacao.id !== aplicacao.estrategiaVacinacao.id &&
        registroEncontrado.dataAprazamento === aplicacao.dataAprazamentoProxDose
      ) {
        createVacinacaoErrorModal(
          createUpdateAprazamentoProxDoseError(
            aplicacao,
            `Deseja alterar a estratégia para ${aplicacaoVacinaNome}?`,
            () =>
              onSubmit({
                ...aplicacao,
                aprazamentoProxDoseLink: {
                  ...newAplicacaoAprazamentoLink,
                  dataAprazamentoProxDose: registroEncontrado.dataAprazamento,
                  estrategiaVacinacao: aplicacao.estrategiaVacinacao,
                },
              }),
            () => onSubmit({ ...aplicacao, dataAprazamentoProxDose: undefined }),
            registroEncontrado
          )
        )
      } else {
        onSubmit(aplicacao)
      }
    } else {
      onSubmit(aplicacao)
    }
    // Edição da aplicação dá a opção de editar o aprazamento da pŕoxima
  } else if (aplicacao.idUnicoRegistro && aplicacao.aprazamentoProxDoseLink !== undefined) {
    if (
      aplicacao.aprazamentoProxDoseLink.dataAprazamentoProxDose !== aplicacao.dataAprazamentoProxDose &&
      aplicacao.aprazamentoProxDoseLink.estrategiaVacinacao.id !== aplicacao.estrategiaVacinacao.id
    ) {
      createVacinacaoErrorModal(
        createUpdateAprazamentoProxDoseError(
          aplicacao,
          `Deseja alterar a estratégia para ${aplicacaoVacinaNome} e a data para ${formatDate(
            aplicacao.dataAprazamentoProxDose
          )}?`,
          () =>
            onSubmit({
              ...aplicacao,
              aprazamentoProxDoseLink: {
                ...aplicacao.aprazamentoProxDoseLink,
                dataAprazamentoProxDose: aplicacao.dataAprazamentoProxDose,
                estrategiaVacinacao: aplicacao.estrategiaVacinacao,
              },
            }),
          () => onSubmit(aplicacao)
        )
      )
    } else if (
      aplicacao.aprazamentoProxDoseLink.dataAprazamentoProxDose !== aplicacao.dataAprazamentoProxDose &&
      aplicacao.aprazamentoProxDoseLink.estrategiaVacinacao.id === aplicacao.estrategiaVacinacao.id
    ) {
      createVacinacaoErrorModal(
        createUpdateAprazamentoProxDoseError(
          aplicacao,
          `Deseja alterar a data para ${formatDate(aplicacao.dataAprazamentoProxDose)}?`,
          () =>
            onSubmit({
              ...aplicacao,
              aprazamentoProxDoseLink: {
                ...aplicacao.aprazamentoProxDoseLink,
                dataAprazamentoProxDose: aplicacao.dataAprazamentoProxDose,
              },
            }),
          () => onSubmit(aplicacao)
        )
      )
    } else if (
      aplicacao.aprazamentoProxDoseLink.estrategiaVacinacao.id !== aplicacao.estrategiaVacinacao.id &&
      aplicacao.aprazamentoProxDoseLink.dataAprazamentoProxDose === aplicacao.dataAprazamentoProxDose
    ) {
      createVacinacaoErrorModal(
        createUpdateAprazamentoProxDoseError(
          aplicacao,
          `Deseja alterar a estratégia para ${aplicacaoVacinaNome}?`,
          () =>
            onSubmit({
              ...aplicacao,
              aprazamentoProxDoseLink: {
                ...aplicacao.aprazamentoProxDoseLink,
                estrategiaVacinacao: aplicacao.estrategiaVacinacao,
              },
            }),
          () => onSubmit(aplicacao)
        )
      )
    } else {
      onSubmit(aplicacao)
    }
  } else {
    onSubmit(aplicacao)
  }
}

const createUpdateAprazamentoProxDoseError = (
  aplicacao: RegistroVacinacaoFormModel,
  appendSubtitle: String,
  onSimClick: () => void,
  onNaoClick: () => void,
  aprazamentoExistente?: RegistroVacinacaoFormModel
): ValidateRegistroVacinaError => {
  return {
    hasError: true,
    title: 'Deseja confirmar a alteração?',
    subTitle:
      `Já existe um aprazamento de ${aplicacao.imunobiologicoNome} - ${aplicacao.proximaDoseSigla}, estratégia ${
        aplicacao.aprazamentoProxDoseLink?.estrategiaVacinacao.nome?.capitalize() ||
        aprazamentoExistente?.estrategiaVacinacao.nome?.capitalize()
      } para ${formatDate(
        aprazamentoExistente?.dataAprazamento || aplicacao.aprazamentoProxDoseLink?.dataAprazamentoProxDose
      )}. ` + appendSubtitle,
    type: 'primary',
    icon: 'questionMarkFilled',
    actions: [
      {
        label: 'Não',
        onClick: () => {
          onNaoClick()
        },
      },
      {
        label: 'Sim',
        kind: 'primary',
        onClick: () => {
          onSimClick()
        },
      },
    ],
  }
}

// Trata a sobreposição de erros
export const checkErrors = (errors: ModaisVacinacao) => {
  let counter = 0

  const submitModal = (final: boolean) => {
    if (final) {
      errors.handleOnSubmit()
    } else {
      counter++
      createVacinacaoErrorModal(errors.naoImpeditivos[counter], () =>
        submitModal(counter === errors.naoImpeditivos.length - 1)
      )
    }
  }

  if (errors.impeditivos.length > 0) {
    createVacinacaoErrorModal(errors.impeditivos[0])
  } else if (errors.naoImpeditivos.length > 0) {
    createVacinacaoErrorModal(errors.naoImpeditivos[counter], () =>
      submitModal(counter === errors.naoImpeditivos.length - 1)
    )
  }
}
