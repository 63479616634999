import { Cell } from 'bold-ui'
import { NumberField } from 'components/form'
import { AnimaisDomicilioSelectField } from 'components/form/field/select/AnimaisDomicilioSelectField/AnimaisDomicilioSelectField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { css } from 'emotion'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import { AnimaisImovelFormModel } from 'view/cadastro-imovel/model-cadastroImovel'

import { SimNaoRadioGroupField } from '../../../components/form/field/SimNaoRadioGroupField'
import { simNaoEnumToBooleanWrapper } from '../utils-cadastroImovel'

interface AnimaisDomicilioGroupFieldProps {
  name: MetaPath<AnimaisImovelFormModel>
  isDomicilio: boolean
  disabled: boolean
}

export function AnimaisDomicilioFieldGroup(props: AnimaisDomicilioGroupFieldProps) {
  const { name, isDomicilio, disabled } = props
  const {
    input: { value: values },
  } = usePecField<AnimaisImovelFormModel>({ name: name.absolutePath() })

  const hasAnimais = simNaoEnumToBooleanWrapper(values?.possuiAnimais)
  const disableAnimaisDomicilio = !hasAnimais || !isDomicilio || disabled

  return (
    <>
      <Cell
        size={3}
        style={css`
          margin-right: -6rem;
        `}
      >
        <SimNaoRadioGroupField
          name={name.possuiAnimais}
          label='Possui animais no domicílio?'
          disabled={!isDomicilio || disabled}
        />
      </Cell>
      <Cell size={4}>
        <AnimaisDomicilioSelectField
          name={name.animais}
          label='Tipos de animais no domicílio'
          disabled={disableAnimaisDomicilio}
          multiple
        />
      </Cell>
      <Cell size={2}>
        <NumberField
          name={name.quantidadeAnimais}
          label='Quantos?'
          maxLength={2}
          clearable={false}
          disabled={!hasAnimais || !isDomicilio}
        />
      </Cell>
      <Cell size={3} />
    </>
  )
}
