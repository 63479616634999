import { Alert, HeadingSection, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageLoading } from 'components/loading'
import { useCidadaoFciAtualizacaoQuery } from 'graphql/hooks.generated'
import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { CidadaoFciForm } from './CidadaoFciForm'
import { convertToCidadaoFciFormModel } from './converter-cidadaoFci'
import { CidadaoFciFormModel } from './model-cidadaoFci'

interface UrlParams {
  cidadaoId: string
}

export function CidadaoFciEditView() {
  const match = useRouteMatch<UrlParams>()
  const cidadaoId = match.params.cidadaoId

  const {
    data: { cidadao },
    loading,
  } = useCidadaoFciAtualizacaoQuery({
    variables: {
      id: cidadaoId,
    },
    fetchPolicy: 'network-only',
  })

  const initialValues = useMemo<CidadaoFciFormModel>(() => cidadao && convertToCidadaoFciFormModel(cidadao), [cidadao])

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/cidadao/${cidadaoId}`} />
      <Breadcrumb title='Edição' />
      <HeadingSection level={1} title='Cadastro do cidadão' />
      <VFlow>
        <Alert type='info'>
          O módulo tem como objetivo atualizar ou registrar os dados cadastrais do cidadão no sistema. Esses dados
          também podem ser provenientes das informações da Ficha de Cadastro Individual ou Ficha de Avaliação de
          Elegibilidade após seu processamento.
        </Alert>
        {!loading && <CidadaoFciForm initialValues={initialValues} />}
        {loading && <PageLoading message='Carregando dados' />}
      </VFlow>
    </PageContent>
  )
}
