/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, DateRange, Grid, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { Box } from 'components/Box'
import { CheckboxField, Form, FormRenderProps } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { LoadingIndicator } from 'components/loading'
import { TitleGroup } from 'components/TitleGroup'
import createDecorator from 'final-form-calculate'
import { useInfoRndsErrosQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { noop } from 'lodash'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { convertDateRangeToInstantRange } from 'util/date/dateRange'
import { metaPath } from 'util/metaPath'

import { calculatorRndsErrosImpressao } from '../calculator-rndsErros'
import { downloadRelatorioErrosRndsCsv } from '../downloadRelatorio'
import { RndsErrosImpressaoModel } from '../model-rndsErros'
import { RndsErrosImpressaoModalBoxCheckbox } from './RndsErrosImpressaoModalBoxCheckbox'

interface RndsErrosImpressaoModalProps {
  open: boolean
  onClose(): void
}

const meta = metaPath<RndsErrosImpressaoModel>()

const decorator = [createDecorator(...calculatorRndsErrosImpressao(meta))]

const initialValues: RndsErrosImpressaoModel = {
  periodo: {
    startDate: new Date(),
    endDate: new Date(),
  },
  todos: false,
  riacERiar: false,
  rac: false,
  rpm: false,
}

export function RndsErrosImpressaoModal({ open, onClose }: RndsErrosImpressaoModalProps) {
  const { analytics } = useFirebase()

  const [periodo, setPeriodo] = useState<DateRange>({
    startDate: new Date(),
    endDate: new Date(),
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!open) {
      setPeriodo({
        startDate: new Date(),
        endDate: new Date(),
      })
    }
  }, [open])

  const instantPeriodo = useMemo(() => convertDateRangeToInstantRange(periodo), [periodo])

  const {
    data: { infoErrosRnds },
    loading: infoErrosRndsLoading,
  } = useInfoRndsErrosQuery({
    variables: {
      input: {
        ...instantPeriodo,
      },
    },
    skip: !periodo,
  })

  const renderForm = (formProps: FormRenderProps<RndsErrosImpressaoModel>) => {
    const { handleSubmit, values } = formProps

    return (
      <Fragment>
        <ModalBody>
          <VFlow vSpacing={1}>
            <TitleGroup
              title='Gerar relatório de erros'
              subtitle='Selecione o período e os registros que deseja gerar como relatório.'
            />
            <Box style={styles.box}>
              <div css={styles.boxTitleContainer}>
                <div css={styles.boxTitle}>
                  <DateRangeField
                    required
                    name={meta.periodo.absolutePath()}
                    value={periodo}
                    onChange={(date: DateRange) => setPeriodo(date)}
                    label='Período'
                  />
                </div>
              </div>
              {!infoErrosRndsLoading ? (
                <VFlow>
                  <Box>
                    <CheckboxField label='Marcar todos' name={meta.todos.absolutePath()} />
                  </Box>
                  <RndsErrosImpressaoModalBoxCheckbox name={meta.riacERiar.absolutePath()}>
                    <Text fontWeight='bold'>RIAR e RIAC</Text>
                    <Text>{'registro'.pluralizeAndConcatValue(infoErrosRnds?.qtdRiacERiar)} neste período</Text>
                  </RndsErrosImpressaoModalBoxCheckbox>
                  <RndsErrosImpressaoModalBoxCheckbox name={meta.rac.absolutePath()}>
                    <Text fontWeight='bold'>RAC</Text>
                    <Text>{'registro'.pluralizeAndConcatValue(infoErrosRnds?.qtdRac)} neste período</Text>
                  </RndsErrosImpressaoModalBoxCheckbox>
                  <RndsErrosImpressaoModalBoxCheckbox name={meta.rpm.absolutePath()}>
                    <Text fontWeight='bold'>RPM</Text>
                    <Text>{'registro'.pluralizeAndConcatValue(infoErrosRnds?.qtdRpm)} neste período</Text>
                  </RndsErrosImpressaoModalBoxCheckbox>
                </VFlow>
              ) : (
                <Grid style={styles.loadingBox} alignItems='center' justifyContent='center' direction='column'>
                  <LoadingIndicator message='Em processamento' />
                </Grid>
              )}
            </Box>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button kind='normal' onClick={onClose}>
              Cancelar
            </Button>
            <Button
              kind='primary'
              disabled={!values.riacERiar && !values.rac && !values.rpm}
              loading={loading}
              onClick={handleSubmit}
            >
              Gerar relatório CSV
            </Button>
          </HFlow>
        </ModalFooter>
      </Fragment>
    )
  }

  const onSubmit = (values: RndsErrosImpressaoModel) => {
    if (!loading) {
      setLoading(true)
      downloadRelatorioErrosRndsCsv({ startDate: values.periodo.startDate, endDate: values.periodo.endDate, ...values })
        .then(() => onClose())
        .catch(noop)
        .finally(() => {
          setLoading(false)
        })
      analytics.logEvent('gerar_relatorio_erros_rnds')
    }
  }

  return (
    <Modal title='Gerar relatório de erros' open={open} onClose={onClose} style={styles}>
      <Form<RndsErrosImpressaoModel>
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={renderForm}
        decorators={decorator}
      />
    </Modal>
  )
}

const styles = {
  boxTitleContainer: css`
    height: 0;
  `,
  boxTitle: css`
    position: relative;
    top: -4.5rem;
    padding: 0 0.5rem;
    background-color: white;
    width: 21rem;
  `,
  modal: css`
    width: 42.5;
  `,
  boxItem: css`
    padding: 0.5rem 1rem;
    border-color: ${gray.c20};
  `,
  box: css`
    margin-top: 2rem;
    padding-top: 2rem;
  `,
  loadingBox: css`
    height: 21.3rem;
  `,
}
