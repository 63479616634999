import { HFlow } from 'bold-ui'
import { CheckBoxButtonField } from 'components/form/field/CheckBoxButtonField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField/GrupoCondicaoSelectField'
import { GrupoCondicaoEnum } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { MetaRoot } from 'util/metaPath'

import { AcompanhamentoCondicaoSaudeFormModel } from '../model-acompanhamentoCondicaoSaude'

interface GrupoCondicaoBuscaRapidaProps {
  path: MetaRoot<AcompanhamentoCondicaoSaudeFormModel>
  value: AcompanhamentoCondicaoSaudeFormModel
  onChange: (name: string, value: Partial<GrupoCondicaoSelectModel>[]) => void
  handleClearCheckbox: (numberOfGroups: number) => void
}

export default function GrupoCondicaoBuscaRapida(props: GrupoCondicaoBuscaRapidaProps) {
  const { path, value, onChange, handleClearCheckbox } = props
  const [numberOfGroups, setNumberOfGroups] = useState<number>(0)

  useEffect(() => {
    handleClearCheckbox(numberOfGroups)
  }, [handleClearCheckbox, numberOfGroups])

  const pushInGrupo = (grupoEnum: GrupoCondicaoEnum) => {
    const grupo = { grupoCondicao: grupoEnum } as Partial<GrupoCondicaoSelectModel>
    const list = value?.grupoCondicao ? [...value?.grupoCondicao, grupo] : [grupo]
    setNumberOfGroups(list.length)
    return list
  }

  const popFromGrupo = (grupoEnum: GrupoCondicaoEnum) => {
    const list =
      value?.grupoCondicao?.filter((item: { grupoCondicao: GrupoCondicaoEnum }) => grupoEnum !== item.grupoCondicao) ||
      []
    setNumberOfGroups(list.length)
    return list
  }

  const handleChangeDiabetes = () => {
    !value.isDiabetesCheck
      ? onChange(path.grupoCondicao.absolutePath(), pushInGrupo(GrupoCondicaoEnum.DIABETES))
      : onChange(path.grupoCondicao.absolutePath(), popFromGrupo(GrupoCondicaoEnum.DIABETES))
  }

  const handleChangeHipertensao = () => {
    !value.isHipertensaoCheck
      ? onChange(path.grupoCondicao.absolutePath(), pushInGrupo(GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL))
      : onChange(path.grupoCondicao.absolutePath(), popFromGrupo(GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL))
  }

  const handleChangeObesidade = () => {
    !value.isObesidadeCheck
      ? onChange(path.grupoCondicao.absolutePath(), pushInGrupo(GrupoCondicaoEnum.OBESIDADE))
      : onChange(path.grupoCondicao.absolutePath(), popFromGrupo(GrupoCondicaoEnum.OBESIDADE))
  }

  const handleChangeGravidez = () => {
    !value.isGravidezCheck
      ? onChange(path.grupoCondicao.absolutePath(), pushInGrupo(GrupoCondicaoEnum.GRAVIDEZ))
      : onChange(path.grupoCondicao.absolutePath(), popFromGrupo(GrupoCondicaoEnum.GRAVIDEZ))
  }

  return (
    <HFlow hSpacing={0.5}>
      <CheckBoxButtonField
        name={path.isDiabetesCheck.absolutePath()}
        label='Diabetes'
        onChange={handleChangeDiabetes}
      />
      <CheckBoxButtonField
        name={path.isHipertensaoCheck.absolutePath()}
        label='Hipertensão arterial'
        onChange={handleChangeHipertensao}
      />
      <CheckBoxButtonField
        name={path.isObesidadeCheck.absolutePath()}
        label='Obesidade'
        onChange={handleChangeObesidade}
      />
      <CheckBoxButtonField
        name={path.isGravidezCheck.absolutePath()}
        label='Gravidez'
        onChange={handleChangeGravidez}
      />
    </HFlow>
  )
}
