import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoAcessoDomicilioEnum } from 'graphql/types.generated'
import React from 'react'

import { tipoAcessoDomicilioEnumRecord } from './condicoes-moradia-model'

export interface TipoAcessoDomicilioSelectFieldProps
  extends Omit<SelectFieldProps<TipoAcessoDomicilioEnum>, 'items' | 'itemToString'> {}

export function TipoAcessoDomicilioSelectField(props: TipoAcessoDomicilioSelectFieldProps) {
  return <SelectField<TipoAcessoDomicilioEnum> items={items} itemToString={itemToString} {...props} />
}

const items = Object.values(TipoAcessoDomicilioEnum)
const itemToString = (item: TipoAcessoDomicilioEnum) => item && tipoAcessoDomicilioEnumRecord[item]
