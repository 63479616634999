/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tag, Theme, Tooltip, useTheme } from 'bold-ui'
import { green } from 'bold-ui/lib/styles/colors/green'

interface StatusCadastroImovelTagProps {
  isCadastroCompleto: boolean
  isStatusRecusaCadastro: boolean
}

export function StatusCadastroImovelTag({ isCadastroCompleto, isStatusRecusaCadastro }: StatusCadastroImovelTagProps) {
  const isCompletoText = isCadastroCompleto
    ? 'Todos os campos do cadastro estão preenchidos.'
    : 'Alguns campos do cadastro ainda não foram preenchidos.'

  const theme = useTheme()
  const classes = createStyles(theme)

  if (isStatusRecusaCadastro) {
    return <Tag style={classes.tagIncompletoOuRecusado}> Recusa de cadastro </Tag>
  }

  return (
    <Tooltip text={isCompletoText}>
      {isCadastroCompleto ? (
        <Tag style={classes.tagCompleto}>Completo</Tag>
      ) : (
        <Tag style={classes.tagIncompletoOuRecusado}>Incompleto</Tag>
      )}
    </Tooltip>
  )
}

const createStyles = (theme: Theme) => ({
  tagCompleto: css`
    background-color: transparent;
    border: 1px solid;
    color: ${green.c40};
  `,
  tagIncompletoOuRecusado: css`
    background-color: transparent;
    border: 1px solid;
    color: ${theme.pallete.gray.c40};
  `,
})
