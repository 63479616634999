import ReactPDF, { StyleSheet, Text } from '@react-pdf/renderer'
import React from 'react'

import { PrintSignature } from './components/PrintSignature'
import { ConselhoClasseImpressao } from './model-print'
import { usePrinterStyles } from './usePrinterStyles'

export interface PrintSignatureProfissionalProps {
  nomeProfissional?: string
  cboProfissional?: string
  conselhoClasse?: ConselhoClasseImpressao
  data: string
  municipio: string
  uf: string
}

export const PrintSignatureProfissional = (props: PrintSignatureProfissionalProps) => {
  const { nomeProfissional, cboProfissional, conselhoClasse, data, municipio, uf } = props
  const { config } = usePrinterStyles()
  const classes = createStyles(config)

  return (
    <PrintSignature>
      <Text style={classes.signatureTextBold}>
        {nomeProfissional}
        {conselhoClasse?.numero &&
          ' - ' + conselhoClasse.sigla + ' - ' + conselhoClasse.uf.sigla + ' ' + conselhoClasse.numero}
      </Text>
      {cboProfissional && <Text style={classes.signatureText}> {cboProfissional}</Text>}

      <Text style={classes.signatureText}>
        {municipio.titleCase()} - {uf}, {data}
      </Text>
    </PrintSignature>
  )
}

const createStyles = (config: ReactPDF.Styles) =>
  StyleSheet.create({
    signatureText: {
      ...config.main,
      textAlign: 'center',
    },
    signatureTextBold: {
      ...config.main,
      fontFamily: 'Lato Bold',
      textAlign: 'center',
    },
  })
