/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HFlow, Radio } from 'bold-ui'
import { IvcfPerguntaEnum } from 'graphql/types.generated'

import { IVCF_PERGUNTAS, IvcfAplicacaoModel } from '../../model-ivcf'
import { IvcfPerguntaWrapper } from '../components/IvcfPerguntaWrapper'

interface IvcfPerguntaViewProps {
  pergunta: IvcfPerguntaEnum
  value: IvcfAplicacaoModel
  hideBorderBottom?: boolean
}

export function IvcfPerguntaView(props: IvcfPerguntaViewProps) {
  const { pergunta, value, hideBorderBottom } = props
  const { options } = IVCF_PERGUNTAS[pergunta]
  const currentValue = value[pergunta]

  return (
    <IvcfPerguntaWrapper pergunta={pergunta} hideBorderBottom={hideBorderBottom}>
      <HFlow>
        {Object.entries(options).map(([optionValue, label]) => (
          <Radio key={pergunta + optionValue} label={label} checked={optionValue === currentValue} disabled />
        ))}
      </HFlow>
    </IvcfPerguntaWrapper>
  )
}
