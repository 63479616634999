/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ActionableToastList, HFlow, Icon, Text, Tooltip } from 'bold-ui'
import { useToastMessages } from 'bold-ui/lib/hooks'
import { Ellipsis } from 'components/Ellipsis'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'
import { useAlertasSdc } from 'hooks/sdc/useAlertasSdc'
import React, { useCallback, useEffect, useRef } from 'react'

import { SdcPopperButtonProps } from './SdcPopperButton'

export const SdcToastList = (props: SdcPopperButtonProps) => {
  const { setIsDetalhesModalOpen, setAlertaSelecionado } = props

  const { marcarAlertaComoLido, marcarAlertaComoExibido } = useAlertasSdc()

  const { toastMessages, showToast } = useToastMessages()
  const { alertasSdc } = useAlertasSdc()

  const { analytics } = useFirebase()

  const handleLerButtonClick = useCallback(
    (alerta: AlertaSuporteDecisaoClinica) => {
      alerta.visualizado = true
      setAlertaSelecionado(alerta)
      setIsDetalhesModalOpen(true)
      marcarAlertaComoLido(alerta)
      analytics.logEvent('abrir_modal_SDC')
    },
    [analytics, marcarAlertaComoLido, setAlertaSelecionado, setIsDetalhesModalOpen]
  )

  const handleCloseButtonClick = useCallback(() => {
    analytics.logEvent('fechar_toast_SDC')
  }, [analytics])

  const lastAlertasSdc = useRef<Map<string, AlertaSuporteDecisaoClinica>>(new Map())

  const newToastMessage = useCallback(
    (alerta: AlertaSuporteDecisaoClinica) => {
      return {
        newToast: true,
        title: 'Suporte à Decisão Clínica',
        buttonLabel: <LerAlertaButtonLabel />,
        action: () => handleLerButtonClick(alerta),
        message: (
          <Ellipsis maxLines={2}>
            <Text>{alerta.subtitulo}</Text>
            <Text fontWeight='normal'> {alerta.descricao}</Text>
          </Ellipsis>
        ),
        secondsVisible: 5,
        onClose: handleCloseButtonClick,
      }
    },
    [handleCloseButtonClick, handleLerButtonClick]
  )

  useEffect(() => {
    const newAlertasSdc = alertasSdc.filter((alerta) => !alerta.exibido && !lastAlertasSdc.current.has(alerta.id))

    newAlertasSdc.forEach((alertaSdc) => {
      showToast(newToastMessage(alertaSdc))
      marcarAlertaComoExibido(alertaSdc)
    })
    lastAlertasSdc.current.clear()
    alertasSdc.forEach((alerta) => lastAlertasSdc.current.set(alerta.id, alerta))
  }, [alertasSdc, marcarAlertaComoExibido, newToastMessage, showToast])

  return (
    <div css={styles.toastListContainer}>
      <ActionableToastList data={toastMessages} />
    </div>
  )
}

const LerAlertaButtonLabel = React.memo(() => (
  <Tooltip text='Ler toda orientação'>
    <HFlow hSpacing={0.5}>
      <Icon icon='zoomOutline' /> Ler orientação
    </HFlow>
  </Tooltip>
))

const styles = {
  toastListContainer: css`
    width: 0;
    position: relative;
    right: calc(16rem - 100%);
    top: 1rem;

    > div {
      position: unset;
      height: 0;
      overflow: visible;
      div.hidden {
        transform: translateX(200%);
      }
    }
  `,
}
