import { Cell, Grid, Heading, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { TextField, UnidadeSaudeSelectField, UnidadeSaudeSelectModel } from 'components/form'
import { InepSelectField, InepSelectFieldModel } from 'components/form/field/select/InepSelectField/InepSelectField'
import React from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

export interface LocalAtividadeFieldModel {
  inep?: InepSelectFieldModel
  unidadeSaudeLocalAtividade?: UnidadeSaudeSelectModel
  outraLocalidade?: string
}

interface LocalAtividadeFieldProps {
  name: MetaPath<LocalAtividadeFieldModel>
  hideInep?: boolean
  onlyInepEnabled?: boolean
}

export function LocalAtividadeField(props: LocalAtividadeFieldProps) {
  const { name, hideInep, onlyInepEnabled } = props
  const {
    input: { value },
  } = useField<LocalAtividadeFieldModel>(name.absolutePath())

  const size = hideInep ? 6 : 4

  return (
    <VFlow vSpacing={0.5}>
      <Heading level={5}>Local da atividade</Heading>
      <Box>
        <Grid gap={2}>
          {!hideInep && (
            <Cell size={size}>
              <InepSelectField
                name={name.inep}
                label='INEP'
                title='INEP'
                placeholder='Busque por um INEP'
                required={onlyInepEnabled}
                disabled={!!value.outraLocalidade || !!value.unidadeSaudeLocalAtividade}
              />
            </Cell>
          )}

          <Cell size={size}>
            <UnidadeSaudeSelectField
              name={name.unidadeSaudeLocalAtividade}
              label='CNES'
              title='CNES'
              placeholder='Busque por um CNES'
              disabled={!!value.outraLocalidade || !!value.inep || onlyInepEnabled}
            />
          </Cell>

          <Cell size={size}>
            <TextField
              name={name.outraLocalidade}
              label='Outro'
              title='Outro'
              placeholder='Informe o local da atividade'
              maxLength={250}
              disabled={!!value.unidadeSaudeLocalAtividade || !!value.inep || onlyInepEnabled}
            />
          </Cell>
        </Grid>
      </Box>
    </VFlow>
  )
}
