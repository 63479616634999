/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, FormControl, Grid, Heading, HFlow, Tag, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { Form, PasswordField, SubmitButton, TextField } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { confirm } from 'components/modals/confirm'
import { useAlterarConfiguracaoPrescricaoDigitalMutation } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useMemo } from 'react'
import { FormRenderProps } from 'react-final-form'
import { isHttpsConnection } from 'util/https'
import { metaPath } from 'util/metaPath'

import { ConfiguracaoAssinaturaDigitalModel } from './model-assinaturaDigital'
import { validate } from './validator-assinaturaDigital'

interface ConfiguracaoAssinaturaDigitalProps {
  internetHabilitada: boolean
  smtpConfigurado: boolean
  assinaturaDigitalHabilitado: boolean
  usuario?: string
  senha?: string

  load(): void
}

const meta = metaPath<ConfiguracaoAssinaturaDigitalModel>()

export function ConfiguracaoAssinaturaDigital(props: ConfiguracaoAssinaturaDigitalProps) {
  const { internetHabilitada, smtpConfigurado, assinaturaDigitalHabilitado, usuario, senha } = props

  const { analytics } = useFirebase()
  const alert = useAlert()
  const [alterarConfiguracaoAssinaturaDigital] = useAlterarConfiguracaoPrescricaoDigitalMutation()
  const habilitarAssinaturaDigitalDisabled = !internetHabilitada || !smtpConfigurado || !isHttpsConnection

  const submitAlteracao = async (values: ConfiguracaoAssinaturaDigitalModel, msg: String) => {
    await alterarConfiguracaoAssinaturaDigital({
      variables: {
        input: {
          habilitado: !assinaturaDigitalHabilitado,
          usuario: values.usuario,
          senha: values.senha,
        },
      },
    })
    alert('success', msg)
  }

  const handleSubmit = async (formValues: ConfiguracaoAssinaturaDigitalModel) => {
    if (assinaturaDigitalHabilitado) {
      const confirmed = await confirm({
        title: 'Deseja desabilitar a assinatura digital?',
        confirmLabel: 'Desabilitar',
      })()

      if (confirmed) {
        analytics.logEvent('assinaturaDigital_desabilitado')
        await submitAlteracao(formValues, 'Integração com o serviço de assinatura digital desabilitada com sucesso.')
      }
    } else {
      analytics.logEvent('assinaturaDigital_habilitado')
      await submitAlteracao(formValues, 'Integração com o serviço de assinatura digital habilitada com sucesso.')
    }
  }

  const renderForm = (formProps: FormRenderProps<ConfiguracaoAssinaturaDigitalModel>) => (
    <Box>
      <VFlow>
        <HFlow justifyContent='space-between'>
          <Heading level={3} component='span'>
            <HFlow hSpacing={0.5} alignItems='center'>
              Assinatura digital
              <InfoIcon
                icon='infoCircleFilled'
                text='Para habilitar a assinatura digital, é necessário solicitar as credenciais abaixo. A instalação deve ter um servidor SMTP configurado e utilizar HTTPS.'
                style={css`
                  max-width: 20rem;
                `}
              />
            </HFlow>
          </Heading>

          {assinaturaDigitalHabilitado ? (
            <Tag type='success' icon='checkCircleFilled'>
              Habilitado
            </Tag>
          ) : (
            <Tag type='normal' icon='banFilled'>
              Desabilitado
            </Tag>
          )}
        </HFlow>

        {!assinaturaDigitalHabilitado ? (
          <Grid>
            <Cell size={4}>
              <TextField label='Login' name={meta.usuario} required disabled={habilitarAssinaturaDigitalDisabled} />
            </Cell>

            <Cell size={4}>
              <PasswordField label='Senha' name={meta.senha} required disabled={habilitarAssinaturaDigitalDisabled} />
            </Cell>

            <Cell size={4}>
              <FormControl label={<span>&nbsp;</span>}>
                <Tooltip text={getHabilitarDisableTooltip(internetHabilitada, smtpConfigurado, isHttpsConnection)}>
                  <SubmitButton
                    kind='primary'
                    size='small'
                    handleSubmit={formProps.handleSubmit}
                    disabled={habilitarAssinaturaDigitalDisabled}
                  >
                    Habilitar
                  </SubmitButton>
                </Tooltip>
              </FormControl>
            </Cell>
          </Grid>
        ) : (
          <SubmitButton kind='primary' size='small' handleSubmit={formProps.handleSubmit}>
            Desabilitar
          </SubmitButton>
        )}
      </VFlow>
    </Box>
  )

  const initialValues = useMemo(() => ({ usuario, senha }), [usuario, senha])

  return (
    <Form<ConfiguracaoAssinaturaDigitalModel>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={renderForm}
    />
  )
}

function getHabilitarDisableTooltip(
  internetHabilitada: boolean,
  smtpDesabilitado: boolean,
  isHttpsConnection: boolean
): string {
  if (!internetHabilitada) return 'Não pode ser habilitada sem conexão com a internet.'
  if (!smtpDesabilitado)
    return 'Para habilitar o serviço de assinatura digital, é necessário possuir um servidor SMTP configurado.'
  if (!isHttpsConnection)
    return 'Para habilitar o serviço de assinatura digital, é necessário que a instalação utilize o protocolo HTTPS.'
}
