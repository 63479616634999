import { convertLotacoesToLotacaoEditableTableModel } from 'components/form/editable-table/converter-lotacaoEditableTable'
import { AtividadeColetivaInput } from 'graphql/types.generated'

import { AtividadeColetivaConvertFormModel, AtividadeColetivaFormModel } from '../model-atividadeColetiva'

export const convertAtividadeColetivaReuniaoFormModelToInput = (
  model: AtividadeColetivaFormModel
): AtividadeColetivaInput => ({
  id: model.id ?? undefined,
  tipoAtividadeEnum: model.tipoAtividade,
  lotacaoResponsavelId: model.lotacaoResponsavel?.lotacao.id,
  dataAtividade: model.dataAtividade,
  turnoEnum: model.turno,
  numeroParticipantes: model.numeroParticipantes,
  unidadeSaudeLocalAtividadeId: model.localAtividade?.unidadeSaudeLocalAtividade?.id,
  outraLocalidade: model.localAtividade?.outraLocalidade,
  atividadeEducacaoPse: false,
  atividadeSaudePse: false,
  lotacaoEnvolvidaIds: model.lotacoesEnvolvidas?.map((item) => item.lotacao.id),
  temaReuniaoEnums: model.temasReuniao,
  anotacoes: model.anotacoes,
})

export const convertAtividadeColetivaReuniaoToFormModel = (
  atividadeColetiva: AtividadeColetivaConvertFormModel
): AtividadeColetivaFormModel => ({
  id: atividadeColetiva.id,
  tipoAtividade: atividadeColetiva.tipoAtividade,
  lotacaoResponsavel: { lotacao: atividadeColetiva.lotacaoResponsavel },
  dataAtividade: atividadeColetiva.dataAtividade,
  turno: atividadeColetiva.turno,
  numeroParticipantes: atividadeColetiva.numeroParticipantes,
  localAtividade: {
    unidadeSaudeLocalAtividade: atividadeColetiva.unidadeSaudeLocalAtividade,
    outraLocalidade: atividadeColetiva.outraLocalidade,
  },
  lotacoesEnvolvidas: convertLotacoesToLotacaoEditableTableModel(atividadeColetiva.lotacoesEnvolvidas),
  temasReuniao: atividadeColetiva.temasReuniao,
  anotacoes: atividadeColetiva.anotacoes,
})
