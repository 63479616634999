import { Alert, Heading, VFlow } from 'bold-ui'
import { BoxGroup } from 'components/BoxGroup'
import { LoadingIndicator } from 'components/loading'
import { useAnexoArquivosQuery } from 'graphql/hooks.generated'
import React from 'react'

import HabilitarAnexoArquivosBox from './components/HabilitarAnexoArquivosBox'

export function AnexoArquivosView() {
  const { data, loading } = useAnexoArquivosQuery()

  if (loading) {
    return <LoadingIndicator />
  }

  const anexos = data?.anexoArquivos

  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={2}>Anexo de arquivos na instalação</Heading>
        {!anexos?.habilitado && (
          <Alert type='warning'>
            Para habilitar essa funcionalidade, certifique-se de que seu servidor possui a infraestrutura necessária
            para o armazenamento dos arquivos.
          </Alert>
        )}
      </VFlow>
      <BoxGroup>
        <HabilitarAnexoArquivosBox habilitado={anexos?.habilitado} diretorio={anexos?.diretorio} />
      </BoxGroup>
    </VFlow>
  )
}
