import { differenceInYears, parseISO } from 'date-fns'
import { isCidadaoPuericultura } from 'util/isCidadaoPuericultura'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { MedicaoAnteriorAcompanhamentoPuericultura } from '../model'

export const vacinacaoEmDiaMaisRecente = (
  medicoes: HistoricoMedicaoModel[],
  dataNascimento: string
): MedicaoAnteriorAcompanhamentoPuericultura => {
  const medicoesVacinacaoEmDia =
    medicoes?.length &&
    medicoes
      .filter(
        (medicao) =>
          medicao?.valorVacinacaoEmDia?.toString() && isMedicaoAntes19Anos(medicao?.dataMedicao, dataNascimento)
      )
      ?.reduce((prev, curr) => (prev.dataMedicao > curr.dataMedicao ? prev : curr), {} as HistoricoMedicaoModel)
  return medicoesVacinacaoEmDia?.dataMedicao
    ? {
        dataMedicao: medicoesVacinacaoEmDia?.dataMedicao,
        valorMedicao: medicoesVacinacaoEmDia?.valorVacinacaoEmDia,
      }
    : undefined
}

const isMedicaoAntes19Anos = (dataMedicao: Instant, dataNascimento: string) =>
  isCidadaoPuericultura(differenceInYears(dataMedicao, parseISO(dataNascimento)))
