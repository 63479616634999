import { compact, isFunction } from 'lodash'
import { getFieldLabel } from 'util/fields-labels/getFieldLabel'
import { LabelMap } from 'util/fields-labels/model'
import { MetaPath, MetaRoot } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { ErrorObject } from 'util/validation'

import { getFieldLabelConfig } from './getFieldLabelConfig'
import { ValidationErrorModalConfig, ValidationErrorModalItemModel } from './model'

const DEFAULT_ERROR_MESSAGE = 'Existem campos preenchidos de forma incorreta.'

export function convertErrorsToModalItems<FormModel>(
  errors: ErrorObject<FormModel>,
  labels: LabelMap<FormModel>,
  config: ValidationErrorModalConfig<FormModel>,
  metaRoot: MetaRoot<FormModel>,
  parentName: MetaPath<any> = null
): ValidationErrorModalItemModel[] {
  return (
    errors &&
    compact(
      Object.entries(errors).map(([key, error]: [string, string | object]) => {
        if (!isObjectDeepEmpty(error)) {
          const name = parentName ? parentName[key] : metaRoot[key]
          const label = getFieldLabel(name, labels)
          const { titleColor, hideChildrenOnModal, hideLabelOnModal, extractError } = getFieldLabelConfig(name, config)

          const showLabelOnModal = !(isFunction(hideLabelOnModal) ? hideLabelOnModal(error) : hideLabelOnModal)
          const showChildrens = !(isFunction(hideChildrenOnModal) ? hideChildrenOnModal(error) : hideChildrenOnModal)
          const extractedError = extractError?.(error) ?? error

          if (typeof extractedError === 'object') {
            const fields = showChildrens ? convertErrorsToModalItems(error, labels, config, name) : []

            return {
              label,
              titleColor,
              showLabelOnModal,
              fields,
              error: showChildrens ? null : DEFAULT_ERROR_MESSAGE,
            }
          }

          return { label, titleColor, showLabelOnModal, error: extractedError, fields: [] }
        }

        return null
      })
    )
  )
}
