import { ExternalStyles, HFlow, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { CheckboxField, CheckboxFieldProps } from 'components/form'
import { usePecField } from 'components/form/final-form/hooks/useField'
import React from 'react'

interface RndsErrosImpressaoModalBoxCheckboxProps extends CheckboxFieldProps {
  name: string
  boxStyle?: ExternalStyles
  children?: React.ReactNode
}

export function RndsErrosImpressaoModalBoxCheckbox({
  boxStyle,
  children,
  ...props
}: RndsErrosImpressaoModalBoxCheckboxProps) {
  const { input, meta, ...rest } = usePecField({ type: 'checkbox', ...props })
  return (
    <Box style={boxStyle}>
      <HFlow alignItems='center'>
        <CheckboxField {...input} {...rest} />
        <VFlow vSpacing={0}>{children}</VFlow>
      </HFlow>
    </Box>
  )
}
