import { Breadcrumb } from 'components/breadcrumb'
import { PageContainer } from 'components/layout/PageContainer'
import { useBuscaDetailCidadaoQuery } from 'graphql/hooks.generated'
import qs from 'qs'
import React from 'react'
import { useLocation, useRouteMatch } from 'react-router'

import { CidadaoUrlParams } from '../types/CidadaoUrlParams'
import { CidadaoCabecalhoSection } from './CidadaoCabecalhoSection'
import { CidadaoTabSection } from './CidadaoTabSection'

interface UrlParams {
  cidadaoId: string
}

export function CidadaoDadosView() {
  const match = useRouteMatch<UrlParams>()
  const location = useLocation()
  const { data } = useBuscaDetailCidadaoQuery({ variables: { id: match.params.cidadaoId } })

  const cidadaoUrlParams: CidadaoUrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const showActionButtons = !cidadaoUrlParams.callbackUrl

  return (
    <>
      <Breadcrumb title='Visualização do cidadão' />
      {data?.cidadao && (
        <>
          <PageContainer fluid>
            <CidadaoCabecalhoSection
              cidadao={data.cidadao}
              sizeBotaoAtualizar='medium'
              botaoExcluirCidadao={showActionButtons}
              botaoSelecionar={!showActionButtons}
              callbackUrl={cidadaoUrlParams.callbackUrl}
              callbackParams={cidadaoUrlParams.callbackParams}
              cadImovelCallBackParams={cidadaoUrlParams.cadImovelUrlParams}
            />
          </PageContainer>

          <CidadaoTabSection cidadao={data.cidadao} />
        </>
      )}
    </>
  )
}
