import { css } from '@emotion/core'
import { Button, HFlow, Icon, Theme, Tooltip, useTheme } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { ArquivoTemporarioIdentificadorEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import useAtmosphere from 'hooks/useAtmosphere'
import { useServerTime } from 'hooks/useServerTime'
import React, { useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { downloadFile } from 'util/downloadFile'
import { formatRelatorioFileName } from 'view/arquivo/downloadsArquivo'
import { ArquivoTemporarioFormato, relatoriosRecentesRecord } from 'view/arquivo/model-arquivo'

import { downloadCSVExportarCadastrosDuplicados } from '../downloadExportarCadastrosDuplicados'

const TOOLTIP_MESSAGE = 'O relatório compara informações dos cadastros e retorna aqueles possivelmente duplicados.'

interface ExportarCadastrosDuplicadosButtonProps {
  identificadorModuloArquivo: ArquivoTemporarioIdentificadorEnum
}

export function ExportarCadastrosDuplicadosButton({
  identificadorModuloArquivo,
}: ExportarCadastrosDuplicadosButtonProps) {
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()
  const theme = useTheme()
  const classes = createStyles(theme)
  const { analytics } = useFirebase()
  const { profissional } = useAcessoLotacaoOrEstagio()
  const { atmospherePath, fileName } = relatoriosRecentesRecord[identificadorModuloArquivo]
  const usuarioId = profissional?.usuario?.id
  const fileNameFormatted = formatRelatorioFileName(fileName, now)

  const [isLoading, setIsLoading] = useState(false)

  useAtmosphere<ArquivoTemporarioFormato>({
    topic: `${atmospherePath}/${usuarioId}`,
    onMessage: (responseBody) => {
      if (!isUndefinedOrNull(responseBody.arquivo)) {
        const arquivoTemporario = new Uint8Array(responseBody.arquivo)
        downloadFile(new Blob([arquivoTemporario]), `${fileNameFormatted}.${responseBody.formato.toLocaleLowerCase()}`)
        setIsLoading(false)
      }
    },
  })

  const handleClickExportarCadastrosDuplicados = () => {
    analytics.logEvent('exportar-cadastros-duplicados')
    setIsLoading(true)
    downloadCSVExportarCadastrosDuplicados()
  }

  return (
    <HFlow hSpacing={0.8} alignItems='center'>
      <Tooltip text={TOOLTIP_MESSAGE} style={classes.tooltip}>
        <Icon icon='infoCircleFilled' size={1} />
      </Tooltip>
      <Button
        kind='primary'
        skin='outline'
        size='small'
        style={classes.button}
        onClick={handleClickExportarCadastrosDuplicados}
        loading={isLoading}
      >
        Exportar cadastros duplicados
      </Button>
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  tooltip: css`
    background: ${theme.pallete.gray.c20};
  `,
  button: css`
    padding: 0.25rem 1.5rem;
  `,
})
