import { css } from '@emotion/core'
import { Cell, Grid, HFlow, Icon, Text, Tooltip } from 'bold-ui'
import {
  CnsField,
  ConselhoClasseSelectField,
  CpfField,
  DateField,
  SexoSelectField,
  TelefoneField,
  TextField,
  UfSelectField,
} from 'components/form'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { InfoIcon } from 'components/InfoIcon'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { MetaPath, MetaRoot } from 'util/metaPath'

import ProfissionalFormModel from './ProfissionalFormModel'

export interface ProfissionalDadosFieldGroupProps {
  name: MetaPath<ProfissionalFormModel> | MetaRoot<ProfissionalFormModel>
  edit?: boolean
  possuiLotacaoOrEstagio?: boolean
  formProps: FormRenderProps<any>
}

export function ProfissionalDadosFieldGroup(props: ProfissionalDadosFieldGroupProps) {
  const { name, edit, possuiLotacaoOrEstagio, formProps } = props
  const values = resolveValue(formProps.values, name) || ({} as ProfissionalFormModel)
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()

  const clearConselhoClasse = () => {
    formProps.form.change(name.ufEmissoraConselhoClasse.absolutePath(), null)
    formProps.form.change(name.numeroConselhoClasse.absolutePath(), null)
  }

  return (
    <Grid wrap>
      <Cell size={6}>
        <TextField name={name.nomeCivil} label='Nome civil' required />
      </Cell>
      <Cell size={6}>
        <TextField
          name={name.nomeSocial}
          label={
            <HFlow alignItems='center' hSpacing={0.5}>
              <Text fontWeight='bold'>Nome social</Text>
              <InfoIcon icon='infoCircleFilled' text={tooltipText} tooltipStyle={styles.tooltipStyle} />
            </HFlow>
          }
        />
      </Cell>
      <Cell size={3}>
        <CpfField name={name.cpf} label='CPF' required disabled={edit} />
      </Cell>
      <Cell size={3}>
        <CnsField name={name.cns} label='CNS' required={possuiLotacaoOrEstagio} clearable={false} />
      </Cell>
      <Cell size={6} />
      <Cell size={3}>
        <DateField name={name.dataNascimento} maxDate={now} label='Data de nascimento' />
      </Cell>
      <Cell size={3}>
        <SexoSelectField name={name.sexo} label='Sexo' />
      </Cell>
      <Cell size={6} />
      <Cell size={3}>
        <ConselhoClasseSelectField
          name={name.conselhoClasse}
          label={
            <HFlow alignItems='center' hSpacing={0}>
              Conselho de classe
              <Tooltip text='Atenção: Atendimentos realizados por profissionais sem as informações do Conselho de Classe não serão enviados à RNDS.'>
                <Icon icon='infoCircleFilled' size={1} style={{ marginLeft: '4px' }} />
              </Tooltip>
            </HFlow>
          }
          onChange={clearConselhoClasse}
        />
      </Cell>
      <Cell size={3}>
        <UfSelectField
          name={name.ufEmissoraConselhoClasse}
          label='Estado emissor'
          disabled={!values.conselhoClasse}
          required={!!values.conselhoClasse}
        />
      </Cell>
      <Cell size={3}>
        <TextField
          name={name.numeroConselhoClasse}
          label='Registro no conselho de classe'
          disabled={!values.conselhoClasse}
          required={!!values.conselhoClasse}
          maxLength={10}
        />
      </Cell>
      <Cell size={6}>
        <TextField name={name.email} label='E-mail' lowercase />
      </Cell>
      <Cell size={2}>
        <TelefoneField name={name.telefone} label='Telefone' />
      </Cell>
    </Grid>
  )
}

const tooltipText =
  'Ao adicionar o nome social do profissional, o sistema passará a exibir somente o nome social em todos os locais do e-SUS APS PEC e aplicativos. Inclusive nas impressões e nas prescrições eletrônicas.'

const styles = {
  tooltipStyle: css`
    width: 20rem;
  `,
}
