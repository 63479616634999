import { IconImage } from 'bold-ui'

export enum FieldStatusEnum {
  ADICIONADO = 'ADICIONADO',
  EDITADO = 'EDITADO',
  REMOVIDO = 'REMOVIDO',
  NAO_MODIFICADO = 'NAO_MODIFICADO',
}

interface FieldStatusModel {
  value: string
  icon: IconImage
}

export const fieldStatusRecord: Record<FieldStatusEnum, FieldStatusModel> = {
  ADICIONADO: { value: 'Adicionado', icon: 'clockOutline' },
  EDITADO: { value: 'Editado', icon: 'penOutline' },
  REMOVIDO: { value: 'Removido', icon: 'trashOutline' },
  NAO_MODIFICADO: null,
}
