import { Alert, HeadingSection, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { getLotacaoResponsavel } from 'components/lotacao-responsavel/utils/utils-lotacaoResponsavel'
import { format } from 'date-fns'
import { useImovelCadastroQuery } from 'graphql/hooks.generated'
import { TipoEnderecoEnum, TipoImovelEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { ImovelForm } from './components/ImovelForm'
import {
  convertCidadaoTerritorioToCidadaoTerritorioFormModel,
  convertInitialValuesToAnimaisImovelFieldGroupModel,
  convertInitialValuesToCondicaoMoradiaFieldGroupModel,
  convertInitialValuesToEnderecoFieldGroupModel,
  convertInitialValuesToEnderecoIndigenaFieldGroupModel,
} from './converter-cadastroImovel'
import { ImovelFormModel } from './model-cadastroImovel'
import { cidadaoIdByCpfCns, possuiOutrosProfissionaisToSimNaoEnum } from './utils-cadastroImovel'

interface UrlParams {
  imovelId: string
}

export default function EditarImovelView() {
  const match = useRouteMatch<UrlParams>()
  const { getServerTimeNow } = useServerTime()
  const dataAtual = format(getServerTimeNow(), 'yyyy-MM-dd')

  const {
    data: { imovel },
    loading,
  } = useImovelCadastroQuery({
    variables: {
      input: match.params.imovelId,
    },
    fetchPolicy: 'network-only',
  })

  const initialValues = useMemo<ImovelFormModel>(
    () => ({
      id: imovel?.id,
      isDomicilio: imovel?.tipoImovel === TipoImovelEnum.DOMICILIO,
      responsabilidade: getLotacaoResponsavel(imovel?.profissional, imovel?.cbo, imovel?.equipe, imovel?.unidadeSaude),
      tipoEndereco: imovel?.tipoEndereco ? imovel?.tipoEndereco : TipoEnderecoEnum.LOGRADOURO,
      endereco: convertInitialValuesToEnderecoFieldGroupModel(imovel),
      enderecoIndigena: convertInitialValuesToEnderecoIndigenaFieldGroupModel(imovel),
      microarea: imovel?.microarea,
      stMicroareaPoloBase: imovel?.statusMicroareaPoloBase,
      statusForaArea: imovel?.microarea === 'FA' ? true : false,
      statusRecusa: imovel?.statusRecusa,
      telefoneContato: imovel?.telefoneContato,
      telefoneResidencial: imovel?.telefoneResidencial,
      dataColeta: dataAtual,
      condicoesMoradia: convertInitialValuesToCondicaoMoradiaFieldGroupModel(imovel?.condicoesMoradia),
      animaisNoDomicilio: convertInitialValuesToAnimaisImovelFieldGroupModel(imovel?.animaisImovel),
      numeroMoradores: imovel?.numeroMoradores,
      tipoImovel: imovel?.tipoImovel,
      instituicaoPermanencia: !imovel?.statusRecusa
        ? {
            nomeInstituicaoPermanencia: imovel?.nomeInstituicaoPermanencia,
            possuiOutrosProfissionaisVinculados: possuiOutrosProfissionaisToSimNaoEnum(
              imovel?.possuiOutrosProfsVinculados
            ),
            responsavelTecnico: {
              nome: imovel?.responsavelTecnico?.nome,
              cargo: imovel?.responsavelTecnico?.cargo,
              cns: imovel?.responsavelTecnico?.cns,
              contato: imovel?.responsavelTecnico?.contato,
            },
          }
        : null,
      possuiFamiliasSalvas: !!imovel?.familias?.isNotEmpty(),
      familias: imovel?.familias.map((familia) => {
        return {
          _id: familia.id,
          isNovoCadastro: false,
          isMudouSe: false,
          isResponsavelCadastrado: familia.isResponsavelCadastrado,
          isResponsavelDeclarado: familia.isResponsavelDeclarado,
          numeroProntuarioFamiliar: familia.numeroProntuarioFamiliar,
          numeroMembros: familia.numeroMembros,
          rendaFamiliar: familia.rendaFamiliar,
          resideDesde: familia.resideDesde,
          cidadaos: familia.cidadaos?.map((cidadao) => convertCidadaoTerritorioToCidadaoTerritorioFormModel(cidadao)),
          responsavelId: cidadaoIdByCpfCns(familia.cpfCnsResponsavel, familia.cidadaos),
        }
      }),
    }),
    [dataAtual, imovel]
  )

  if (loading) return <LoadingIndicator />
  return (
    <PageContent type='filled'>
      <Breadcrumb title='Editar imóvel' />
      <HeadingSection level={1} title='Editar imóvel' />
      <VFlow>
        <Alert type='info'>Salvar este cadastro atualizará automaticamente o cadastro dos moradores envolvidos.</Alert>
        <ImovelForm initialValues={initialValues} />
      </VFlow>
    </PageContent>
  )
}
