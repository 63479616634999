import { Heading, HFlow, Icon, InfoLabel, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import React from 'react'

import { CidadaoHistorico, HistoricoAtendimentoModel } from '../../../model/model-historico'

interface RemoveAtendimentoConfirmModalProps {
  modalRemoveOpen: boolean
  setModalRemoveOpen: (arg: boolean) => void
  handleRemoveAtendimento: () => void
  loading: boolean
  cidadao: CidadaoHistorico
  historicoAtendimento: HistoricoAtendimentoModel
}

export default function RemoveAtendimentoConfirmModal(props: RemoveAtendimentoConfirmModalProps) {
  const { modalRemoveOpen, setModalRemoveOpen, handleRemoveAtendimento, loading, cidadao, historicoAtendimento } = props
  return (
    <Modal open={modalRemoveOpen} onClose={() => setModalRemoveOpen(false)} size='small'>
      <ModalBody>
        <VFlow>
          <Heading level={2}>
            <HFlow alignItems='center'>
              <Icon icon='exclamationTriangleOutline' size={3} fill='danger' />
              Deseja excluir o atendimento de vacinação de {(cidadao.nomeSocial ?? cidadao.nome).titleCase()}?
            </HFlow>
          </Heading>
          <VFlow>
            {`Todos os registros de vacinação deste atendimento serão excluídos do PEC e-SUS APS e ${
              historicoAtendimento.isAtendRecente ? 'não serão enviados para a base nacional' : 'da base nacional'
            }.`}
            <InfoLabel title='Registros que serão removidos:'>
              <Text>{historicoAtendimento.dadosClinicos}</Text>
            </InfoLabel>
            {!historicoAtendimento.isAtendRecente &&
              'Os registros removidos poderão ser visualizados na Trilha de Auditoria do PEC e-SUS APS.'}
          </VFlow>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <FooterButton kind='normal' onClick={() => setModalRemoveOpen(false)} size='medium'>
            Cancelar
          </FooterButton>
          <FooterButton kind='danger' onClick={handleRemoveAtendimento} size='medium' loading={loading}>
            Excluir atendimento
          </FooterButton>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
