import { AlertType } from 'bold-ui'
import { ArquivoTemporarioStatusEnum } from 'graphql/types.generated'

export const IMPORTAR_CIDADAO_PATH = 'importar-cidadao'

export type ImportarCidadaoMessageModel = { mensagem: string; status: ArquivoTemporarioStatusEnum }

export const statusArquivoToAlertLevel: Record<ArquivoTemporarioStatusEnum, AlertType> = {
  [ArquivoTemporarioStatusEnum.EM_PROCESSAMENTO]: 'info',
  [ArquivoTemporarioStatusEnum.PRONTO]: 'success',
  [ArquivoTemporarioStatusEnum.ERRO]: 'danger',
}
