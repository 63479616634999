import { RadioGroupField, RadioGroupFieldProps, RadioOption } from 'components/form'
import { useFlags } from 'config/useFlagsContext'
import { ListaEsperaMotivoSaidaEnum } from 'graphql/types.generated'
import React from 'react'

import { motivoSaidaRecord } from '../model-garantiaAcesso'

interface MotivoSaidaRadioGroupFieldProps extends Omit<RadioGroupFieldProps<ListaEsperaMotivoSaidaEnum>, 'options'> {
  faleceu?: boolean
}

export function MotivoSaidaRadioGroupField(props: MotivoSaidaRadioGroupFieldProps) {
  const { faleceu } = props
  const { GARANTIA_ACESSO_CIDADAO_OBITO } = useFlags()

  const filteredOptions = faleceu ? optionsObito : options

  return <RadioGroupField options={GARANTIA_ACESSO_CIDADAO_OBITO ? filteredOptions : options} lines={3} {...props} />
}

const options: RadioOption<ListaEsperaMotivoSaidaEnum>[] = [
  {
    value: ListaEsperaMotivoSaidaEnum.ATENDIDO_OUTRO_ESTABELECIMENTO,
    label: motivoSaidaRecord[ListaEsperaMotivoSaidaEnum.ATENDIDO_OUTRO_ESTABELECIMENTO],
  },
  {
    value: ListaEsperaMotivoSaidaEnum.MUDOU_TERRITORIO,
    label: motivoSaidaRecord[ListaEsperaMotivoSaidaEnum.MUDOU_TERRITORIO],
  },
  {
    value: ListaEsperaMotivoSaidaEnum.ATENDIMENTO_AGENDADO,
    label: motivoSaidaRecord[ListaEsperaMotivoSaidaEnum.ATENDIMENTO_AGENDADO],
  },
  {
    value: ListaEsperaMotivoSaidaEnum.OUTROS,
    label: motivoSaidaRecord[ListaEsperaMotivoSaidaEnum.OUTROS],
  },
  {
    value: ListaEsperaMotivoSaidaEnum.NAO_RESPONDEU_TENTATIVAS_CONTATO,
    label: motivoSaidaRecord[ListaEsperaMotivoSaidaEnum.NAO_RESPONDEU_TENTATIVAS_CONTATO],
  },
]

const optionsObito: RadioOption<ListaEsperaMotivoSaidaEnum>[] = [
  {
    value: ListaEsperaMotivoSaidaEnum.OBITO,
    label: motivoSaidaRecord[ListaEsperaMotivoSaidaEnum.OBITO],
  },
]
