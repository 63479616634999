import React, { useEffect, useState } from 'react'
import { CidadaoObitoModal } from 'view/prontuario/justificativa/modal/CidadaoObitoModal'

interface CheckObitoModalCuidadoCompartilhadoProps {
  showModalObito: boolean
  children: JSX.Element
}

export function CheckObitoModalCuidadoCompartilhado(props: CheckObitoModalCuidadoCompartilhadoProps): JSX.Element {
  const { showModalObito, children } = props

  const [isCidadaoObitoModalOpen, setIsCidadaoObitoModalOpen] = useState(showModalObito)

  useEffect(() => {
    setIsCidadaoObitoModalOpen(showModalObito)
  }, [showModalObito])

  return (
    <>
      <CidadaoObitoModal
        isModalOpen={isCidadaoObitoModalOpen && showModalObito}
        onClose={() => setIsCidadaoObitoModalOpen(false)}
      />
      {children}
    </>
  )
}
