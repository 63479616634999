import { Button, HFlow, Icon, Theme, useTheme, VFlow } from 'bold-ui'
import { Form, FormRenderProps } from 'components/form'
import { TipoEnderecoEnum } from 'graphql/types.generated'
import React, { Fragment, useMemo, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { convertToInitialValuesMicroareasFilter } from 'view/visualizacaoTerritorial/converter'
import { createVisualizacaoMicroareaFilterCalculator } from 'view/visualizacaoTerritorial/utils/microarea-filter-calculator'

import { VisualizacaoMicroareasFilterFormModel, VisualizacaoMicroareasFilterModel } from '../../model'
import { MicroareasFilterModal } from './MicroareasFilterModal'
import { MicroareasFilterTags } from './MicroareasFilterTags'
interface MicroareasFilterProps {
  onSubmit(values: VisualizacaoMicroareasFilterFormModel): void
  microareasFilter?: VisualizacaoMicroareasFilterModel
  tipoEndereco: TipoEnderecoEnum
}

export const meta = metaPath<VisualizacaoMicroareasFilterFormModel>()

export function MicroareasFilter(props: MicroareasFilterProps) {
  const { onSubmit, microareasFilter, tipoEndereco } = props
  const [isFiltrosAvancadosOpen, setIsFiltrosAvancadosOpen] = useState(false)

  const decorators = useMemo(() => [createVisualizacaoMicroareaFilterCalculator(meta)], [])
  const theme = useTheme()
  const styles = createStyles(theme)

  const handleLimparCampos = (formReset: () => void) => {
    formReset()
    onSubmit(null)
  }

  const renderForm = ({
    handleSubmit,
    values,
    form: { reset },
  }: FormRenderProps<VisualizacaoMicroareasFilterFormModel>) => {
    return (
      <Fragment>
        <HFlow alignItems='center' justifyContent='space-between'>
          <MicroareasFilterTags microareasFilter={microareasFilter} />
          <Button size='small' kind='primary' onClick={() => setIsFiltrosAvancadosOpen(true)}>
            <HFlow hSpacing={0.5}>
              <Icon icon='filterOutline' />
              Filtros
            </HFlow>
          </Button>
        </HFlow>
        {!isObjectDeepEmpty(microareasFilter) && <div style={styles.divider}></div>}

        {isFiltrosAvancadosOpen && (
          <MicroareasFilterModal
            open={isFiltrosAvancadosOpen}
            onModalClose={() => setIsFiltrosAvancadosOpen(false)}
            onSubmit={handleSubmit}
            onLimparCampos={() => handleLimparCampos(reset)}
            tipoLocalizacao={values?.condicoesMoradia?.tipoLocalizacao}
            hasAnimaisDomicilio={values?.animaisImovelFilter?.possuiAnimais}
            hasEnergiaEletrica={values?.condicoesMoradia?.isEnergiaEletricaDisponivel}
            tipoEndereco={tipoEndereco}
          />
        )}
      </Fragment>
    )
  }

  return (
    <VFlow>
      <Form<VisualizacaoMicroareasFilterFormModel>
        render={renderForm}
        onSubmit={onSubmit}
        decorators={decorators}
        initialValues={convertToInitialValuesMicroareasFilter(microareasFilter)}
      />
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  divider: {
    borderBottom: `1px solid ${theme.pallete.divider}`,
    margin: '.5rem 0',
  },
})
