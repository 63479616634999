/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors, FormControl, HFlow, VFlow } from 'bold-ui'
import { CheckboxField, SwitchField } from 'components/form'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { useOdontogramasQuery } from 'graphql/hooks.generated'
import { Odontograma, SituacaoCoroaEnum, SituacaoFaceEnum } from 'graphql/types.generated'
import { ParteBucalEnum } from 'graphql/types.generated'
import { useCallback, useEffect, useMemo } from 'react'
import { useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { getDentePosicaoProtese } from '../../util-EvolucoesOdontologicasPlano'
import { FillColor } from '../odontograma/types/Coroa'
import ArcadasField, { ArcadaFieldModel } from './arcadas-field/ArcadasField'
import { CondicoesDenteFieldModel } from './components/CondicoesDenteField'
import { OdontogramaInputs } from './components/OdontogramaInputs'
import { PeriodoOdontogramaSelectModel } from './components/PeriodoOdontogramaSelect/PeriodoOdontogramaSelect'
import { convertOdontogramaQueryModelToOdontogramaFieldModel } from './converter'
import DentesField, { DenteFieldModel } from './dentes-field/DentesField'
import DentesSupranumerariosField, {
  EvolucaoDenteSupranumerarioModel,
} from './dentes-supranumerarios/DentesSupranumerariosField'
import { OdontogramaPosicaoProtese, OdontogramaSetActiveRef, OdontogramaUseMode, OdontogramaViewMode } from './model'
import { OdontogramaView } from './OdontogramaView'
import OutrosField, { OutrosFieldModel } from './outros-field/OutrosField'
import { getShowDeciduosByAge } from './util'

export type OdontogramaQueryModel = Partial<Odontograma>

type CacheOdontogramaModel = {
  condicoes?: Record<ParteBucalEnum, CondicoesDenteFieldModel>
  alreadySettedAsAusente: {
    superior: boolean
    inferior: boolean
  }
}

export interface OdontogramaFieldModel {
  dentes?: Record<ParteBucalEnum, DenteFieldModel>
  arcadas?: Record<ParteBucalEnum, ArcadaFieldModel>
  outros?: OutrosFieldModel[]
  dentesSupranumerarios?: EvolucaoDenteSupranumerarioModel[]
  proteseTotalSuperior?: boolean
  proteseTotalInferior?: boolean
  possuiAparelho?: boolean
  possuiContencao?: boolean
  // cache do Odontograma
  cache: CacheOdontogramaModel
}

interface OdontogramaFieldProps {
  name: MetaPath<OdontogramaFieldModel>
  prontuarioId: ID
  cidadaoDataNascimento: LocalDate
  dataInicioAtendimento: LocalDate
  activeParteBucal: ParteBucalEnum
  showDeciduos: boolean
  viewMode: OdontogramaViewMode
  useMode: OdontogramaUseMode
  activeRef: { ref: SVGSVGElement | HTMLButtonElement }
  setActiveParteBucal: (activeDente: ParteBucalEnum) => void
  setShowDeciduos: (show: boolean) => void
  setViewMode: (viewMode: OdontogramaViewMode) => void
  setUseMode: (useMode: OdontogramaUseMode) => void
  setActiveRef: OdontogramaSetActiveRef
}

export interface ViewingOdontogramaParams {
  odontogramaId: ID
  atendimentoId: ID
  dataIniciado: Date
}

export const odontogramaFillColors: FillColor<SituacaoCoroaEnum>[] = [
  {
    id: 1,
    title: 'Não tratado',
    color: colors.red.c40,
    value: SituacaoCoroaEnum.A_SER_TRATADO,
  },
  {
    id: 2,
    title: 'Tratado',
    color: colors.blue.c40,
    value: SituacaoCoroaEnum.TRATADO,
  },
]

export const OdontogramaField = (props: OdontogramaFieldProps) => {
  const {
    name,
    prontuarioId,
    cidadaoDataNascimento,
    dataInicioAtendimento,
    activeParteBucal,
    showDeciduos,
    viewMode,
    useMode,
    activeRef,
    setActiveParteBucal,
    setShowDeciduos,
    setViewMode,
    setUseMode,
    setActiveRef,
  } = props

  const [initialValue, setInitialValue] = useState<OdontogramaFieldModel>()
  const [viewingOdontogramaParams, setViewingOdontogramaParams] = useState<ViewingOdontogramaParams>()

  const dataAtendimento = useMemo(() => toDate(dataInicioAtendimento), [dataInicioAtendimento])

  const {
    mutators: { changeValue },
  } = useForm()
  const { values: formValues } = useFormState<SoapState>({ subscription: { values: true } })

  const {
    data: { odontogramas },
  } = useOdontogramasQuery({
    variables: {
      input: {
        prontuarioId: prontuarioId,
        pageParams: {
          size: 1,
        },
      },
    },
  })

  const proteseTotalSuperior = resolveValue<OdontogramaFieldModel['proteseTotalSuperior']>(
    formValues,
    props.name.proteseTotalSuperior
  )
  const proteseTotalInferior = resolveValue<OdontogramaFieldModel['proteseTotalInferior']>(
    formValues,
    props.name.proteseTotalInferior
  )
  const possuiAparelho = resolveValue<OdontogramaFieldModel['possuiAparelho']>(formValues, props.name.possuiAparelho)
  const possuiContencao = resolveValue<OdontogramaFieldModel['possuiContencao']>(formValues, props.name.possuiContencao)
  const dentes = resolveValue<OdontogramaFieldModel['dentes']>(formValues, props.name.dentes)
  const cache = resolveValue<OdontogramaFieldModel['cache']>(formValues, props.name.cache)

  const toggleDeciduos = () => {
    setShowDeciduos(!showDeciduos)
  }

  const updateCache = (values: CacheOdontogramaModel) => {
    changeValue(name.cache, values)
  }

  const getDenteCondicoesByProtese = (posicaoProtese: OdontogramaPosicaoProtese) => {
    if (!dentes) return null
    return Object.fromEntries(
      Object.entries(dentes)
        ?.map(([key, value]) => [key, value.condicoesDente])
        .filter(([key]: [ParteBucalEnum]) => getDentePosicaoProtese(key) === posicaoProtese)
    ) as Record<ParteBucalEnum, CondicoesDenteFieldModel>
  }

  const changeCondicoesDentesByPosicaoProtese = (
    posicaoProtese: OdontogramaPosicaoProtese,
    newValue?: CondicoesDenteFieldModel,
    funcValue?: (k: ParteBucalEnum) => any
  ) => {
    dentes &&
      changeValue(
        name.dentes,
        Object.fromEntries(
          Object.entries(dentes).map(([key, value]: [ParteBucalEnum, DenteFieldModel]) => [
            key,
            {
              ...value,
              condicoesDente:
                getDentePosicaoProtese(key) === posicaoProtese ? newValue || funcValue?.(key) : value.condicoesDente,
            },
          ])
        )
      )
  }

  const clearDenteCondicoesByProtese = (posicaoProtese: OdontogramaPosicaoProtese) => {
    changeCondicoesDentesByPosicaoProtese(posicaoProtese, null)
  }

  const populateDentesCondicoesFromCache = (posicaoProtese: OdontogramaPosicaoProtese) => {
    cache?.condicoes && changeCondicoesDentesByPosicaoProtese(posicaoProtese, null, (k) => cache.condicoes[k])
  }

  const setDentesAsAusente = (posicaoProtese: OdontogramaPosicaoProtese) => {
    changeCondicoesDentesByPosicaoProtese(posicaoProtese, [SituacaoFaceEnum.AUSENTE])
    setAlreadySettedAsAusente(posicaoProtese)
  }

  const setAlreadySettedAsAusente = (posicaoProtese: OdontogramaPosicaoProtese) => {
    if (posicaoProtese === 'superior')
      updateCache({
        ...cache,
        alreadySettedAsAusente: { superior: true, inferior: cache?.alreadySettedAsAusente?.inferior },
      })

    if (posicaoProtese === 'inferior')
      updateCache({
        ...cache,
        alreadySettedAsAusente: { inferior: true, superior: cache?.alreadySettedAsAusente?.superior },
      })
  }

  const onChangePeriodo = useCallback(
    (periodo: PeriodoOdontogramaSelectModel) => {
      if (!periodo || periodo.odontogramaId === null) {
        setViewingOdontogramaParams(undefined)
        setUseMode(OdontogramaUseMode.INSERTING)
        setShowDeciduos(getShowDeciduosByAge(cidadaoDataNascimento, dataAtendimento))
      } else {
        setViewingOdontogramaParams({ ...periodo })
        setUseMode(OdontogramaUseMode.VIEWING)
        setShowDeciduos(getShowDeciduosByAge(cidadaoDataNascimento, periodo.dataIniciado))
      }
    },
    [cidadaoDataNascimento, dataAtendimento, setShowDeciduos, setUseMode]
  )

  const onChangeProtese = (posicaoProtese: OdontogramaPosicaoProtese, possuiProtese: boolean) => {
    const condicoesDentes = getDenteCondicoesByProtese(posicaoProtese)

    if (!possuiProtese) {
      updateCache({
        ...cache,
        condicoes: { ...cache?.condicoes, ...condicoesDentes },
      })

      clearDenteCondicoesByProtese(posicaoProtese)
    } else {
      if (posicaoProtese === 'superior') {
        if (initialValue?.proteseTotalSuperior && !cache?.alreadySettedAsAusente?.superior) {
          setDentesAsAusente('superior')
        } else {
          populateDentesCondicoesFromCache(posicaoProtese)
        }
      }

      if (posicaoProtese === 'inferior') {
        if (initialValue?.proteseTotalInferior && !cache?.alreadySettedAsAusente?.inferior) {
          setDentesAsAusente('inferior')
        } else {
          populateDentesCondicoesFromCache(posicaoProtese)
        }
      }
    }
  }

  useEffect(() => {
    const odontograma = odontogramas?.content[0]

    odontograma &&
      setInitialValue(convertOdontogramaQueryModelToOdontogramaFieldModel(odontograma, odontogramaFillColors))
  }, [odontogramas])

  useEffect(() => {
    setShowDeciduos(getShowDeciduosByAge(cidadaoDataNascimento, dataAtendimento))
  }, [cidadaoDataNascimento, dataAtendimento, setShowDeciduos])

  return (
    <VFlow>
      <OdontogramaInputs
        prontuarioId={prontuarioId}
        viewMode={viewMode}
        useMode={useMode}
        showDeciduos={showDeciduos}
        toggleViewMode={setViewMode}
        toggleShowDeciduos={toggleDeciduos}
        onChangePeriodo={onChangePeriodo}
      />

      {useMode === OdontogramaUseMode.INSERTING ? (
        <VFlow>
          <HFlow
            alignItems='center'
            hSpacing={2}
            style={css`
              padding: 0 0.5rem;
            `}
          >
            <FormControl
              label={
                <span
                  css={css`
                    white-space: nowrap;
                  `}
                >
                  Possui aparelho
                </span>
              }
            >
              <SwitchField
                name={name.possuiAparelho}
                label={possuiAparelho ? 'Sim' : 'Não'}
                style={{ marginTop: '0.25rem' }}
                initialValue={initialValue?.possuiAparelho}
              />
            </FormControl>

            <FormControl label='Possui contenção'>
              <SwitchField
                name={name.possuiContencao}
                label={possuiContencao ? 'Sim' : 'Não'}
                style={{ marginTop: '0.25rem' }}
                initialValue={initialValue?.possuiContencao}
              />
            </FormControl>

            <FormControl label='Prótese'>
              <HFlow
                style={css`
                  margin-left: -0.25rem;
                `}
              >
                <CheckboxField
                  name={name.proteseTotalSuperior}
                  label='Total superior'
                  style={css`
                    white-space: nowrap;
                    margin-top: 0.25rem;
                  `}
                  initialValue={initialValue?.proteseTotalSuperior}
                  onChange={() => onChangeProtese('superior', proteseTotalSuperior)}
                />
                <CheckboxField
                  name={name.proteseTotalInferior}
                  label='Total inferior'
                  style={css`
                    white-space: nowrap;
                    margin-top: 0.25rem;
                  `}
                  initialValue={initialValue?.proteseTotalInferior}
                  onChange={() => onChangeProtese('inferior', proteseTotalInferior)}
                />
              </HFlow>
            </FormControl>
          </HFlow>

          {viewMode === OdontogramaViewMode.DENTES && (
            <VFlow vSpacing={0.5}>
              <DentesField
                name={name.dentes}
                cidadaoDataNascimento={cidadaoDataNascimento}
                dataAtendimento={dataInicioAtendimento}
                showDeciduos={showDeciduos}
                fillColors={odontogramaFillColors}
                proteseTotalSuperior={proteseTotalSuperior}
                proteseTotalInferior={proteseTotalInferior}
                initialValue={initialValue?.dentes}
                activeRef={activeRef}
                activeDente={activeParteBucal}
                setActiveDente={setActiveParteBucal}
                setActiveRef={setActiveRef}
              />

              <DentesSupranumerariosField
                name={name.dentesSupranumerarios}
                cidadaoDataNascimento={cidadaoDataNascimento}
              />
            </VFlow>
          )}

          {viewMode === OdontogramaViewMode.ARCADAS && (
            <ArcadasField
              name={name.arcadas}
              cidadaoDataNascimento={cidadaoDataNascimento}
              dataAtendimento={dataInicioAtendimento}
              proteseTotalSuperior={proteseTotalSuperior}
              proteseTotalInferior={proteseTotalInferior}
              activeArcada={activeParteBucal}
              activeRef={activeRef}
              setActiveArcada={setActiveParteBucal}
              setActiveRef={setActiveRef}
            />
          )}

          {viewMode === OdontogramaViewMode.OUTROS && (
            <OutrosField
              name={name.outros}
              cidadaoDataNascimento={cidadaoDataNascimento}
              dataAtendimento={dataInicioAtendimento}
            />
          )}
        </VFlow>
      ) : (
        viewingOdontogramaParams && (
          <OdontogramaView
            odontogramaId={viewingOdontogramaParams.odontogramaId}
            atendimentoId={viewingOdontogramaParams.atendimentoId}
            prontuarioId={prontuarioId}
            showDeciduos={showDeciduos}
            fillColors={odontogramaFillColors}
            viewMode={viewMode}
            dataReferencia={dataInicioAtendimento}
          />
        )
      )}
    </VFlow>
  )
}
