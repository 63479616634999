/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Heading, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { EditableValueField } from 'components/form'
import {
  CompartilharVideochamadaAlertData,
  CompartilharVideochamadaForm,
  CompartilharVideochamadaModel,
} from 'components/videochamada'
import { useEffect, useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

const meta = metaPath<CompartilharVideochamadaModel>()

interface CompartilharVideochamadaDetalhesAgendamentoCompartilhadoModalProps {
  open: boolean
  onClose(): void
  videochamadaUuid: string
  emailDestinatario: string
}

export function CompartilharVideochamadaDetalhesAgendamentoCompartilhadoModal(
  props: CompartilharVideochamadaDetalhesAgendamentoCompartilhadoModalProps
) {
  const { onClose, open, videochamadaUuid, emailDestinatario } = props
  const [compartilharVideochamadaAlertData, setCompartilharVideochamadaAlertData] = useState<
    CompartilharVideochamadaAlertData
  >()

  const alert = useAlert()

  useEffect(() => {
    !open && setCompartilharVideochamadaAlertData(null)
  }, [open])

  const handleAlert = ({ type, message }: CompartilharVideochamadaAlertData) => {
    if (type === 'danger') setCompartilharVideochamadaAlertData({ type, message })
    else alert(type, message)
  }

  const handleSubmitSucceeded = () => {
    onClose?.()
  }

  const render = ({ handleSubmit, submitting }: FormRenderProps<CompartilharVideochamadaModel>) => (
    <form onSubmit={handleSubmit} noValidate>
      <ModalBody>
        <VFlow vSpacing={0.5}>
          {compartilharVideochamadaAlertData && (
            <Alert type={compartilharVideochamadaAlertData.type}>{compartilharVideochamadaAlertData.message}</Alert>
          )}
          <Heading level={2}>Enviar chamada por e-mail</Heading>
          <Text>
            Para compartilhar a chamada, é possível enviar para o e-mail de convidados de dentro ou de fora do e-SUS
            APS.
          </Text>
          <EditableValueField
            name={meta.emailDestinatario}
            label='E-mail'
            required
            placeholder='Adicione o e-mail do convidado'
            aria-label='Input de texto de email'
            disabled={submitting}
            valuePosition='bottom'
          />
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button style={styles.button} onClick={onClose}>
            Cancelar
          </Button>
          <Button kind='primary' loading={submitting} style={styles.button} onClick={handleSubmit}>
            Enviar
          </Button>
        </HFlow>
      </ModalFooter>
    </form>
  )

  return (
    <Modal depthLevel={2} open={open} onClose={onClose} size='small'>
      <CompartilharVideochamadaForm
        videochamadaUuid={videochamadaUuid}
        emailDestinatario={emailDestinatario}
        render={render}
        onSubmitSucceeded={handleSubmitSucceeded}
        onAlert={handleAlert}
        suppressNotificationError
      />
    </Modal>
  )
}

const styles = {
  button: css`
    min-width: 9rem;
  `,
}
