import { TipoAtividadeEnum } from 'graphql/types.generated'
import { createValidator, empty, ErrorObject, oneDeepTruthyValueRequired, required } from 'util/validation'
import { isEmpty } from 'util/validation/Util'

import { LocalAtividadeFieldModel } from '../componentes/LocalAtividadeField'
import { ParticipanteEditableTableModel } from '../componentes/ParticipantesEditableTable/ParticipanteEditableTable'
import { AtividadeColetivaFormModel } from '../model-atividadeColetiva'
import { getAtividadeColetivaCommonValidationRules } from '../validator-atividadeColetivaCommonForm'
import {
  isAtividadePseRequired,
  isCidadaosParticipantesRequired,
  isNotInPraticasSaudeAtendimentoEmGrupo,
  isPraticaSaudeDisabled,
  isProcedimentoDisabled,
  isPse,
  isTemasSaudeRequired,
} from './utils-atividadeColetivaSaude'

export const atividadeColetivaSaudeFormValidator = (now: Date) =>
  createValidator<AtividadeColetivaFormModel>(
    {
      ...getAtividadeColetivaCommonValidationRules(now),
      temasReuniao: empty,
      publicoAlvo: required,
    },
    (values: AtividadeColetivaFormModel, errors: ErrorObject<AtividadeColetivaFormModel>) => {
      if (isAtividadePseRequired(values)) {
        errors.atividadePse = oneDeepTruthyValueRequired(values.atividadePse)
      }

      if (isPse(values)) {
        errors.localAtividade = localAtividadePseValidator(values.localAtividade) ?? errors.localAtividade
      }

      if (isPraticaSaudeDisabled(values)) {
        errors.praticasSaude = empty(values?.praticasSaude)
      } else if (values?.tipoAtividade === TipoAtividadeEnum.AVALIACAO_PROCEDIMENTO_COLETIVO) {
        errors.praticasSaude = required(values?.praticasSaude)
      } else if (
        values?.tipoAtividade === TipoAtividadeEnum.ATENDIMENTO_EM_GRUPO &&
        values?.praticasSaude?.some(isNotInPraticasSaudeAtendimentoEmGrupo)
      ) {
        errors.praticasSaude = `Existem valores selecionados que não podem ser informados no Atendimento em grupo`
      }

      if (isProcedimentoDisabled(values)) {
        errors.procedimento = empty(values?.procedimento)
      }

      if (isTemasSaudeRequired(values)) {
        errors.temasSaude = required(values?.temasSaude)
      }

      return errors
    }
  )

const localAtividadePseValidator = createValidator<LocalAtividadeFieldModel>({
  inep: required,
  unidadeSaudeLocalAtividade: empty,
  outraLocalidade: empty,
})

export const validateParticipantes = (model: {
  tipoAtividade: TipoAtividadeEnum
  participantes?: ParticipanteEditableTableModel[]
}) =>
  isCidadaosParticipantesRequired(model.tipoAtividade) &&
  isEmpty(model.participantes) &&
  'O tipo de atividade informado requer a adição de participantes'
