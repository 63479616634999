import { LotacaoAgendaSelectFieldModel } from 'components/form'
import { CriarAgendamentoConsultaCompartilhadaInput, GarantiaAcessoAgendarConsultaInput } from 'graphql/types.generated'
import { convertAgendarConsulta } from 'view/atendimentos/detail/soap/finalizacao'

import { GarantiaAcessoAgendaModel } from './GarantiaAcessoAgendarConsultaModalForm'

export const convertModelToInput = (
  values: GarantiaAcessoAgendaModel,
  garantiaAcessoId: ID,
  videochamadaUuid: string,
  isConsultaCompartilhada: boolean
): GarantiaAcessoAgendarConsultaInput => {
  const fieldInput: GarantiaAcessoAgendarConsultaInput = { garantiaAcessoId: garantiaAcessoId }
  if (isConsultaCompartilhada) {
    fieldInput['agendamentoConsultaCompartilhadaInput'] = convertConsultaCompartilhadaToInput(
      values.agendamentoConsultaCompartilhadaInput,
      values.lotacaoResponsavel,
      videochamadaUuid
    )
  } else {
    fieldInput['agendamentoConsultaInput'] = convertAgendarConsulta(values.agendamentoConsultaInput)
  }

  return fieldInput
}

const convertConsultaCompartilhadaToInput = (
  values: GarantiaAcessoAgendaModel['agendamentoConsultaCompartilhadaInput'],
  lotacaoResponsavel: LotacaoAgendaSelectFieldModel,
  videochamadaUuid: string
): CriarAgendamentoConsultaCompartilhadaInput => {
  return {
    horarioFinal: Number(values.horario.final),
    horarioInicial: Number(values.horario.inicial),
    observacoes: values.observacoes,
    lotacaoId: lotacaoResponsavel.id,
    lotacaoParticipanteId: values.lotacaoConvidada.id,
    videochamadaUuid: videochamadaUuid,
  }
}
