import { VFlow } from 'bold-ui'
import { RichTextField } from 'components/form'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { MotivoConsultaField, MotivoConsultaFieldModel } from './components/MotivoConsultaField'

export interface SubjetivoFormModel {
  texto: string
  motivos: MotivoConsultaFieldModel[]
}

export interface SubjetivoFormProps {
  name: MetaPath<SubjetivoFormModel>
  isAtendimentoOdonto: boolean
}

export const SubjetivoForm = React.memo((props: SubjetivoFormProps) => {
  const { name, isAtendimentoOdonto } = props
  const { cidadao, isRetificacao } = useAtendimentoContext()
  return (
    <VFlow>
      <RichTextField
        name={name.texto}
        maxLength={4000}
        placeholder='Insira as impressões subjetivas do profissional e as expressadas pelo cidadão.'
        showModifiedStatus={isRetificacao}
      />

      {!isAtendimentoOdonto && <MotivoConsultaField name={name.motivos} cidadao={cidadao} />}
    </VFlow>
  )
})
