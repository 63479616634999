/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { SkinProps } from 'bold-ui/lib/components/Button/Button/ButtonSkins'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { AcessoCbo, LotacaoSugestaoAgendamentoFragment } from 'graphql/types.generated'
import { useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { formatHoursMinutes, formatWeekdayDayMonthYear } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'
import { isObjectDeepFalsy } from 'util/object'

import { ProntuarioCard } from '../../../aside/ProntuarioCard'
import { AgendarConsultaSectionModel } from '../AgendarConsultaSection'
import { AgendarProximaConsultaForm } from './AgendarProximaConsultaForm'
import { agendamentoPropsRecord, ProximaConsultaTypeEnum } from './model'

interface AgendarProximaConsultaCardProps {
  name: MetaPath<AgendarConsultaSectionModel>
  type: ProximaConsultaTypeEnum
  lotacaoSugerida?: LotacaoSugestaoAgendamentoFragment
  dataSugerida: LocalDate
  cbosAcesso?: AcessoCbo[]
}

export function AgendarProximaConsultaCard(props: AgendarProximaConsultaCardProps) {
  const { name, type, lotacaoSugerida, dataSugerida, cbosAcesso } = props

  const [isFormOpen, setIsFormOpen] = useState(false)

  const theme = useTheme()
  const styles = createStyles(theme)

  const {
    input: { value: agendamentoProximaConsultaValues },
    tools: { resetToInitialValue },
  } = usePecField({ name, subscription: { value: true } })

  const isFormFilled = !isObjectDeepFalsy(agendamentoProximaConsultaValues)

  const initialValues = isFormFilled
    ? agendamentoProximaConsultaValues
    : {
        lotacao: lotacaoSugerida,
        data: dataSugerida,
      }

  const agendamentoDescription = agendamentoPropsRecord[type].description
  const { lotacao } = initialValues

  const { dataFormatted, horarioFormatted, equipeFormatted } = formatValues(initialValues)
  const { tooltipEditar, tooltipAgendar, tooltipExcluir } = getTooltipsButtons(isFormFilled, isFormOpen)

  const handleEditar = () => setIsFormOpen(true)
  const handleExcluir = () => resetToInitialValue()

  const cardTitle = (
    <HFlow alignItems='center'>
      <Icon icon='calendarFilled' fill='primary' size={1} />
      {agendamentoDescription} - {dataFormatted}
      {horarioFormatted}
    </HFlow>
  )
  const semLotacaoComAgenda = isUndefinedOrNull(lotacao)

  const cardTitleIcon = (
    <HFlow>
      <Tooltip text={tooltipEditar}>
        <Button size='small' skin='ghost' disabled={!isFormFilled} onClick={handleEditar}>
          <Icon icon='penOutline' size={1.25} />
        </Button>
      </Tooltip>
      <Tooltip text={tooltipExcluir}>
        <Button size='small' skin='ghost' disabled={!isFormFilled} onClick={handleExcluir}>
          <Icon icon='trashOutline' size={1.25} />
        </Button>
      </Tooltip>
    </HFlow>
  )

  return (
    <ProntuarioCard title={cardTitle} content={cardTitleIcon}>
      <VFlow vSpacing={0}>
        <HFlow alignItems='flex-end' justifyContent='space-between' style={styles.container}>
          {semLotacaoComAgenda ? (
            <Text fontStyle='italic' style={styles.semProfDescription}>
              Não existem profissionais com agenda configurada
            </Text>
          ) : (
            <VFlow vSpacing={0}>
              <Text fontWeight='bold'>{lotacao?.profissional?.nome}</Text>
              <HFlow alignItems='flex-start' justifyContent='space-between'>
                <Text style={styles.profDescription}>
                  <Text fontWeight='bold'>{lotacao?.cbo?.nome}</Text> | {equipeFormatted}
                </Text>
              </HFlow>
            </VFlow>
          )}
          <Tooltip text={tooltipAgendar}>
            <Button
              size='small'
              disabled={isFormFilled || isFormOpen || semLotacaoComAgenda}
              onClick={handleEditar}
              {...getAgendarButtonSkinProps(isFormFilled, isFormOpen)}
            >
              {isFormFilled && <Icon icon='checkDefault' style={styles.editIcon} />}
              {isFormFilled ? 'Selecionado' : 'Agendar'}
            </Button>
          </Tooltip>
        </HFlow>
        {isFormOpen && (
          <AgendarProximaConsultaForm
            name={name}
            initialValues={initialValues}
            setIsFormOpen={setIsFormOpen}
            cbosAcesso={cbosAcesso}
          />
        )}
      </VFlow>
    </ProntuarioCard>
  )
}

function formatValues(values: AgendarConsultaSectionModel) {
  const { lotacao, data, horario } = values

  const dataFormatted = formatWeekdayDayMonthYear(data).capitalize()
  const horarioFormatted = horario?.inicial && ` às ${formatHoursMinutes(horario.inicial.getTime())}`
  const equipeFormatted = lotacao?.equipe ? `Equipe ${lotacao.equipe.ine} - ${lotacao.equipe.nome}` : 'Sem equipe'

  return { dataFormatted, horarioFormatted, equipeFormatted }
}

function getTooltipsButtons(isFormFilled: boolean, isFormOpen: boolean) {
  const tooltipEditar = isFormFilled ? 'Editar' : 'Preencha os dados do agendamento'
  const tooltipAgendar = isFormOpen
    ? 'Preencha os dados do agendamento'
    : isFormFilled
    ? 'Dados do agendamento já foram preenchidos'
    : ''
  const tooltipExcluir = isFormFilled ? 'Excluir' : 'Preencha os dados do agendamento'
  return { tooltipEditar, tooltipAgendar, tooltipExcluir }
}

function getAgendarButtonSkinProps(isFormFilled: boolean, isFormOpen: boolean): SkinProps {
  return isFormFilled
    ? { kind: 'normal', skin: 'ghost' }
    : isFormOpen
    ? { kind: 'normal', skin: 'outline' }
    : { kind: 'primary', skin: 'default' }
}

const createStyles = (theme: Theme) => ({
  container: css`
    padding: 0.75rem 1rem 0.5rem;
  `,
  semProfDescription: css`
    margin: 0 0 0.3rem;
  `,
  profDescription: css`
    color: ${theme.pallete.gray.c20};
  `,
  editIcon: css`
    margin-right: 0.25rem;
  `,
})
