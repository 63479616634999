/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { SwitchField } from 'components/form'
import { resolveName } from 'components/form/final-form/hooks/useField'
import { RichTextField } from 'components/form/final-form/RichTextField'
import { useFlags } from 'config/useFlagsContext'
import { TipoAtendimentoEnum, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { ChangeEvent } from 'react'
import { Fragment } from 'react'
import { FormSpy, useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath/metaPath'
import { meta } from 'view/atendimentos/atendimento-individual/model'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { getTiposEstabelecimentoCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import { getSoapFieldLabel } from '../validation-errors-modal/getSoapFieldLabel'
import {
  grupoCboCuidadoCompartilhado,
  grupoCboEncaminhamentoExterno,
  grupoCboIniciarObservacao,
  grupoCboOrientacoes,
} from './acessos'
import AtestadoView, { AtestadoPlanoModel } from './atestados/AtestadosView'
import {
  IntervencoesProcedimentosPlanoModel,
  IntervencoesProcedimentosView,
} from './components/IntervencoesProcedimentosView'
import { PlanoTabs, PlanoTabsEnum } from './components/PlanoTabs'
import { CuidadoCompartilhadoView } from './cuidado-compartilhado/CuidadoCompartilhadoView'
import { CuidadoCompartilhadoPlanoModel } from './cuidado-compartilhado/model-cuidadocompartilhado'
import { DeclaracaoComparecimentoFormView } from './declaracao-comparecimento/DeclaracaoComparecimentoFormView'
import EncaminhamentoExternoView from './encaminhamento-externo/EncaminhamentoExternoView'
import { EncaminhamentoExternoEspecializadoPlanoModel } from './encaminhamento-externo/model'
import EvolucoesOdontologicasView, { OdontologiaModel } from './evolucoes-odontologicas/EvolucoesOdontologicasView'
import { ExamesView } from './exames/ExamesView'
import { SolicitacaoExameModel } from './exames/model'
import { HistoricoPlanoCuidadoPanel } from './observacao/HistoricoPlanoCuidadoPanel'
import { START_OBSERVACAO_REGISTRO_TARDIO_TOOLTIP, START_OBSERVACAO_TOOLTIP } from './observacao/model'
import { OrientacoesView } from './orientacoes/OrientacoesView'
import { OrientacaoFormModel } from './orientacoes/types/OrientacoesModel'
import PrescricaoMedicamentoView, {
  PrescricaoMedicamentoPlanoModel,
} from './prescricao-medicamento/PrescricaoMedicamentoView'

export interface PlanoFormModel {
  texto?: string
  atestado?: AtestadoPlanoModel
  solicitacoesExame?: SolicitacaoExameModel[]
  prescricaoMedicamento?: PrescricaoMedicamentoPlanoModel
  odontologia?: OdontologiaModel
  encaminhamentoExterno?: EncaminhamentoExternoEspecializadoPlanoModel
  orientacao?: OrientacaoFormModel[]
  intervencoesProcedimentos?: IntervencoesProcedimentosPlanoModel
  cuidadosCompartilhado?: CuidadoCompartilhadoPlanoModel[]
  startObservacao?: boolean
}

export interface PlanoFormProps {
  name: MetaPath<PlanoFormModel>
  prontuarioId: ID
  isAtendimentoOdonto: boolean
  isAtendimentoProcedimentos: boolean
  atendimentoId: ID
  atendimentoProfissional: AtendimentoProfissional
  dataAtendimento: Instant
  isRegistroTardio: boolean
}

export const PlanoForm = React.memo((props: PlanoFormProps) => {
  const {
    name,
    prontuarioId,
    isAtendimentoOdonto,
    isAtendimentoProcedimentos,
    atendimentoId,
    atendimentoProfissional,
    dataAtendimento,
    isRegistroTardio,
  } = props

  const alert = useAlert()
  const theme = useTheme()
  const classes = styles(theme)

  const {
    cidadao,
    tipoEstabelecimento,
    observacao: { isAtendimentoObservacao, ultimoResponsavelObservacao, isObservacaoAndAuxiliar },
    isRetificacao,
  } = useAtendimentoContext()

  const { PILOTO_VINCULACAO_CUIDADO_ENABLED } = useFlags()

  const {
    input: { value: isStartObservacao },
  } = useField<boolean>(resolveName(name.startObservacao), { subscription: { value: true } })

  const {
    input: { value: tipoAtendimentoValue },
  } = useField<TipoAtendimentoEnum>(resolveName(meta.finalizacao.tipoAtendimento), { subscription: { value: true } })

  const onChangeStartObservacao = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      tipoEstabelecimento === TipoEstabelecimentoEnum.UPA &&
      tipoAtendimentoValue !== TipoAtendimentoEnum.URGENCIA &&
      event.target.checked
    ) {
      alert('info', 'O tipo de atendimento foi alterado para Urgente.')
    }
  }

  const startObservacaoLabel = getSoapFieldLabel(name.startObservacao)

  return (
    <VFlow>
      {isAtendimentoObservacao ? (
        <HistoricoPlanoCuidadoPanel
          atendProfId={atendimentoProfissional?.id}
          ultimoResponsavelPelaObservacao={ultimoResponsavelObservacao}
        />
      ) : (
        //Profissional de nível superior não tem permissão de iniciar observação
        //ao revisar um atendimento de procedimentos feito por estagiário de nível técnico.
        !isAtendimentoProcedimentos && (
          <CheckPermission permission={grupoCboIniciarObservacao} acceptEstagio={false}>
            <HFlow hSpacing={0.5} alignItems='center'>
              <SwitchField
                name={name.startObservacao}
                label={startObservacaoLabel}
                style={classes.switchStartObservacao}
                onChange={onChangeStartObservacao}
              />

              <Tooltip text={isRegistroTardio ? START_OBSERVACAO_REGISTRO_TARDIO_TOOLTIP : START_OBSERVACAO_TOOLTIP}>
                <Icon icon='infoCircleFilled' fill='normal' size={1} style={classes.infoIcon} />
              </Tooltip>
            </HFlow>
          </CheckPermission>
        )
      )}

      {!isAtendimentoOdonto && (
        <RichTextField
          name={name.texto}
          label={(isStartObservacao || isAtendimentoObservacao) && 'Plano de cuidado'}
          maxLength={4000}
          placeholder='Insira informações adicionais sobre o plano de cuidado.'
          required={isStartObservacao || isAtendimentoObservacao}
          showModifiedStatus={isRetificacao}
        />
      )}

      <IntervencoesProcedimentosView
        cidadao={cidadao}
        name={name.intervencoesProcedimentos}
        isAtendimentoOdonto={isAtendimentoOdonto}
        isAtendimentoProcedimentos={isAtendimentoProcedimentos}
        dataAtendimento={dataAtendimento}
      />

      {isAtendimentoOdonto && (
        <EvolucoesOdontologicasView
          name={name.odontologia}
          prontuarioId={prontuarioId}
          cidadaoDataNascimento={cidadao.dataNascimento}
          dataInicioAtendimento={atendimentoProfissional.iniciadoEm}
        />
      )}

      <PlanoTabs
        isAtendimentoProcedimentos={isAtendimentoProcedimentos}
        isObservacaoAndAuxiliar={isObservacaoAndAuxiliar}
        isRetificacao={isRetificacao}
      >
        {(currentTab) => (
          <FormSpy subscription={{ values: true, pristine: true }}>
            {(soap) => (
              <Fragment>
                {currentTab === PlanoTabsEnum.ATESTADO && (
                  <AtestadoView
                    name={name.atestado}
                    prontuarioId={prontuarioId}
                    cidadao={cidadao}
                    atendimentoProfissional={atendimentoProfissional}
                    atendimentoId={atendimentoId}
                  />
                )}
                {currentTab === PlanoTabsEnum.EXAMES && (
                  <ExamesView
                    name={name.solicitacoesExame}
                    cidadao={cidadao}
                    prontuarioId={prontuarioId}
                    atendimentoId={atendimentoId}
                    atendimentoProfissional={atendimentoProfissional}
                    isObservacaoAndAuxiliar={isObservacaoAndAuxiliar}
                  />
                )}
                {currentTab === PlanoTabsEnum.ORIENTACOES && (
                  <CheckPermission permission={grupoCboOrientacoes.visualizar}>
                    <OrientacoesView
                      name={name.orientacao}
                      cidadao={cidadao}
                      prontuarioId={prontuarioId}
                      atendimentoId={atendimentoId}
                      orientacoes={soap.values?.plano?.orientacao || null}
                      atendimentoProfissional={atendimentoProfissional}
                    />
                  </CheckPermission>
                )}
                {currentTab === PlanoTabsEnum.ENCAMINHAMENTO_EXTERNO && (
                  <CheckPermission permission={grupoCboEncaminhamentoExterno}>
                    <EncaminhamentoExternoView
                      name={name.encaminhamentoExterno}
                      prontuarioId={prontuarioId}
                      atendimentoId={atendimentoId}
                      cidadao={cidadao}
                      isAtendimentoOdonto={isAtendimentoOdonto}
                      cboAtendProf={atendimentoProfissional.lotacao.cbo.cbo2002}
                      atendimentoProfissional={atendimentoProfissional}
                    />
                  </CheckPermission>
                )}
                {currentTab === PlanoTabsEnum.DECLARACAO_COMPARECIMENTO && (
                  <DeclaracaoComparecimentoFormView atendId={atendimentoId} />
                )}
                {currentTab === PlanoTabsEnum.PRESCRICAO_MEDICAMENTOS && (
                  <PrescricaoMedicamentoView
                    name={name.prescricaoMedicamento}
                    prontuarioId={prontuarioId}
                    atendimentoId={atendimentoId}
                    medicamentos={soap.values?.plano?.prescricaoMedicamento?.medicamentos}
                    isAtendimentoProcedimentos={isAtendimentoProcedimentos}
                    dataReferencia={dataAtendimento}
                    nomeCidadao={cidadao.nomeSocial ?? cidadao.nome}
                  />
                )}
                {currentTab === PlanoTabsEnum.CUIDADO_COMPARTILHADO && (
                  <CheckPermission
                    permission={grupoCboCuidadoCompartilhado}
                    tiposEstabelecimento={
                      isAtendimentoOdonto
                        ? getTiposEstabelecimentoCuidadoCompartilhado(PILOTO_VINCULACAO_CUIDADO_ENABLED)
                        : null
                    }
                    acceptEstagio={false}
                  >
                    <CuidadoCompartilhadoView
                      name={name.cuidadosCompartilhado}
                      isAtendimentoOdonto={isAtendimentoOdonto}
                    />
                  </CheckPermission>
                )}
              </Fragment>
            )}
          </FormSpy>
        )}
      </PlanoTabs>
    </VFlow>
  )
})

const styles = (theme: Theme) => ({
  switchStartObservacao: css`
    & > span {
      font-weight: bold;
    }
  `,
  infoIcon: css`
    &:hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
})
