/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Tag, Text, Theme, useTheme } from 'bold-ui'
import { ColorScale } from 'bold-ui/lib/styles/colors'
import { isUndefinedOrNull } from 'util/checks'

import { CategoriaImcPreGestacionalEnum, categoriaImcPreGestacionalRecord } from './model-graficoGanhoPeso'

interface TagIMCPreGestacionalProps {
  categoriaImcPreGestacional: CategoriaImcPreGestacionalEnum
}

export function TagIMCPreGestacional(props: TagIMCPreGestacionalProps) {
  const { categoriaImcPreGestacional } = props
  const theme = useTheme()

  const color = categoriaImcPreGestacionalRecord[categoriaImcPreGestacional].color
  const imcMin = categoriaImcPreGestacionalRecord[categoriaImcPreGestacional].imcMin
  const imcMax = categoriaImcPreGestacionalRecord[categoriaImcPreGestacional].imcMax

  const styles = createStyles(theme, color)

  return (
    <HFlow alignItems='center' style={styles.container}>
      <Text fontWeight='bold'>Ganho de peso (kg)</Text>
      <Tag style={styles.tag}>
        {categoriaImcPreGestacional} ({formatImcRange(imcMin, imcMax)})
      </Tag>
    </HFlow>
  )
}

function formatImcRange(imcMin: number, imcMax: number): string {
  if (!isUndefinedOrNull(imcMin) && !isUndefinedOrNull(imcMax)) {
    return `IMC ≥ ${imcMin}kg/m² e < ${imcMax}kg/m²`
  } else if (!!imcMin) {
    return `IMC ≥ ${imcMin} kg/m²`
  } else if (!!imcMax) {
    return `IMC < ${imcMax} kg/m²`
  }
}

const createStyles = (theme: Theme, color: ColorScale) => ({
  tag: css`
    color: ${theme.pallete.surface.main};
    background-color: ${color.c40};
  `,
  container: css`
    margin-top: 1rem;
    margin-left: 3rem;
  `,
})
