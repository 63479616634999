import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { parseDateFromLocalStorage } from 'components/form'
import { PageLoading } from 'components/loading'
import { confirm } from 'components/modals/confirm'
import { useFlags } from 'config/useFlagsContext'
import { startOfDay } from 'date-fns'
import {
  useAtualizarContatoCidadaoMutation,
  useIdadeGestacionalQuery,
  useSalvarCuidadoCompartilhadoMutation,
} from 'graphql/hooks.generated'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import { useLocalStorageState } from 'hooks/useStorage'
import { cloneDeep } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { convertConsultaModelToUpdateContatoCidadaoInput } from 'view/agenda/converter-agenda'
import { useVerificarAgendamentosConflitantes } from 'view/agenda/hooks/useVerificarAgendamentosConflitantes'

import { CuidadoCompartilhadoHeaderTabs } from '../components/CuidadoCompartilhadoHeaderTabs'
import { LCC_TABS_ROUTE } from '../CuidadosCompartilhadosRootView'
import {
  AgendarConsultaCuidadoCompartilhadoFieldsModel,
  CidadaoCuidadoCompartilhado,
  CondutaGroupEnum,
  CuidadoCompartilhadoModel,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoFieldsModel,
  DiscussaoCasoFormModel,
  DiscussaoCasoSolicitanteFieldsModel,
  ResponsavelCuidadoCompartilhado,
  TipoAgendamentoCuidadoCompartilhado,
} from '../model-cuidadoCompartilhado'
import { getFieldsByResponsavel, getResponsabilidadeCuidadoCompartilhado } from '../util-cuidadoCompartilhado'
import { convertDiscussaoCasoFormModelToSalvarCuidadoCompartilhadoInput } from './discussao-caso/converter-discussaoCaso'
import { DiscussaoCasoView } from './discussao-caso/DiscussaoCasoView'
import { CheckJustificativaAcessoCuidadoCompartilhado } from './justificativa/CheckJustificativaCuidadoCompartilhado'
import { logRegistrarDiscussao } from './logRegistrarDiscussao'

interface CuidadoCompartilhadoViewProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
}

const initialValuesCuidadoCompartilhado = (
  cidadao: CuidadoCompartilhadoModel['cidadao']
): Partial<DiscussaoCasoFieldsModel> => ({
  trocaExecutante: null,
  agendamento: {
    tipoAgendamento: TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO,
    consultaComCidadao: { data: null, horario: null, observacoes: null },
    consultaCompartilhada: {
      data: null,
      horario: null,
      observacoes: null,
      cidadaoParticipante: {
        cidadao: {
          ...cidadao,
          contato: {
            email: cidadao?.email,
            telefoneCelular: cidadao?.telefoneCelular,
          },
        },
        contato: {
          email: cidadao?.email,
          telefoneCelular: cidadao?.telefoneCelular,
        },
      },
      imprimirAgendamento: false,
    },
  },
  garantiaAcesso: null,
})

const initialValuesExecutante: DiscussaoCasoExecutanteFieldsModel = {
  condutaGroup: CondutaGroupEnum.DEVOLUTIVA_DISCUSSAO_CASO,
  devolutiva: null,
  sugestaoAgendamentoGrupo: null,
}

const initialValuesCidadaoObito: DiscussaoCasoExecutanteFieldsModel = {
  condutaGroup: CondutaGroupEnum.DEVOLUTIVA_DISCUSSAO_CASO,
  devolutiva: { conduta: CondutaCuidadoCompartilhadoEnum.RECONDUCAO_CUIDADO, resposta: null },
}

const initialValuesSolicitante: DiscussaoCasoSolicitanteFieldsModel = {
  condutaGroup: CondutaGroupEnum.NOVA_PERGUNTA,
  pergunta: null,
}

const computeInitialValues = (
  responsavel: ResponsavelCuidadoCompartilhado,
  cidadao: CuidadoCompartilhadoModel['cidadao'],
  CUIDADO_COMPARTILHADO_CIDADAO_OBITO: boolean
): DiscussaoCasoFormModel => {
  const initialValues = initialValuesCuidadoCompartilhado(cidadao)
  const cidadaoFaleceu = CUIDADO_COMPARTILHADO_CIDADAO_OBITO && cidadao?.faleceu

  switch (responsavel) {
    case 'EXECUTANTE':
      const executanteFields = cidadaoFaleceu
        ? { ...initialValuesExecutante, ...initialValuesCidadaoObito }
        : { ...initialValuesExecutante }

      return {
        executanteFields: { ...initialValues, ...executanteFields },
        solicitanteFields: null,
      }
    case 'SOLICITANTE':
      return { solicitanteFields: { ...initialValues, ...initialValuesSolicitante }, executanteFields: null }
  }
}

export const CuidadoCompartilhadoView = (props: CuidadoCompartilhadoViewProps) => {
  const { cuidadoCompartilhado } = props

  const alert = useAlert()
  const { getServerTimeNow } = useServerTime()
  const history = useHistory()
  const match = useRouteMatch()
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()
  const { verificarAgendamentosConflitantes } = useVerificarAgendamentosConflitantes()
  const [salvar] = useSalvarCuidadoCompartilhadoMutation()
  const videochamadaUuidState = useState<string>()
  const [videochamadaUuid] = videochamadaUuidState
  const { CUIDADO_COMPARTILHADO_CIDADAO_OBITO } = useFlags()

  const responsavelCuidado: ResponsavelCuidadoCompartilhado = getResponsabilidadeCuidadoCompartilhado(
    acesso.id,
    cuidadoCompartilhado.lotacaoExecutanteAtual.id,
    cuidadoCompartilhado.lotacaoSolicitante.id
  )

  const prontuario = cuidadoCompartilhado.cidadao.prontuario
  const dataAtendimento = Number(startOfDay(getServerTimeNow()))
  const ultimaDumPreNatalAtivo = prontuario?.preNatalAtivo?.ultimaDum

  const { loading: loadingIdadeGestacional, data: dataIdadeGestacional } = useIdadeGestacionalQuery({
    variables: {
      input: {
        prontuarioId: prontuario.id,
        dataAtendimento: dateAsYyyyMmDd(dataAtendimento),
        dum: ultimaDumPreNatalAtivo,
      },
    },
    skip: !prontuario.id || !ultimaDumPreNatalAtivo,
  })
  const idadeGestacional = dataIdadeGestacional?.idadeGestacional

  const [cacheState, setCacheState, deleteCacheState] = useLocalStorageState<DiscussaoCasoFormModel>(
    `${acesso.id}/cuidado-compartilhado/${cuidadoCompartilhado.id}`,
    { checkVersionCompatibility: true, modifier: cacheParser }
  )
  const [initialStateLoaded, setInitialStateLoaded] = useState(!!cacheState)

  const goBackRoute = `${match.path.substring(0, match.path.lastIndexOf('/', match.path.lastIndexOf('/') - 1))}/${
    LCC_TABS_ROUTE[responsavelCuidado]
  }`

  const goBack = useCallback(() => history.push(goBackRoute), [goBackRoute, history])

  const [atualizarContatoCidadao] = useAtualizarContatoCidadaoMutation()

  const updateContatoCidadao = useCallback(
    async (values: AgendarConsultaCuidadoCompartilhadoFieldsModel['consultaCompartilhada']) => {
      const cidadao = values?.cidadaoParticipante?.cidadao
      const contatoCidadao = cidadao?.contato
      const contatoAtualizado = values?.cidadaoParticipante?.contato

      const telefoneCelularAlterado = contatoCidadao?.telefoneCelular !== contatoAtualizado?.telefoneCelular
      const emailAlterado = contatoCidadao?.email !== contatoAtualizado?.email

      if (cidadao && (telefoneCelularAlterado || emailAlterado)) {
        try {
          return await atualizarContatoCidadao({
            variables: {
              input: convertConsultaModelToUpdateContatoCidadaoInput(cidadao.id, values.cidadaoParticipante.contato),
            },
          })
        } catch (err) {
          alert('danger', 'Erro ao salvar dados do cidadão.')
          throw err
        }
      }
    },
    [alert, atualizarContatoCidadao]
  )

  const handleSave = useCallback(
    async (values: DiscussaoCasoFormModel) => {
      await salvar({
        variables: {
          input: convertDiscussaoCasoFormModelToSalvarCuidadoCompartilhadoInput(
            values,
            cuidadoCompartilhado,
            acesso.id,
            videochamadaUuid
          ),
        },
      })
      alert('success', 'Conduta para cuidado compartilhado registrada com sucesso.')
      logRegistrarDiscussao(analytics.logEvent, values, responsavelCuidado)
      deleteCacheState(false)
      goBack()
    },
    [
      acesso.id,
      alert,
      analytics.logEvent,
      cuidadoCompartilhado,
      deleteCacheState,
      goBack,
      responsavelCuidado,
      salvar,
      videochamadaUuid,
    ]
  )

  const handleSubmit = useCallback(
    async (values: DiscussaoCasoFormModel) => {
      const fields = getFieldsByResponsavel(responsavelCuidado, values)

      if (fields?.condutaGroup === CondutaGroupEnum.AGENDAR_CONSULTA) {
        const { cidadaoId, cidadaoNome, agendamentos } = getAgendamentosConflitantesInput(
          acesso.id,
          profissional.id,
          cuidadoCompartilhado.cidadao,
          fields?.agendamento
        )

        if (fields?.agendamento?.tipoAgendamento === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COMPARTILHADA) {
          await updateContatoCidadao(fields.agendamento.consultaCompartilhada)
        }

        const confirmouHorariosConflitantes = await verificarAgendamentosConflitantes(
          cidadaoId,
          agendamentos,
          cidadaoNome
        )

        if (!confirmouHorariosConflitantes) return
      }

      return handleSave(values)
    },
    [
      acesso.id,
      cuidadoCompartilhado.cidadao,
      handleSave,
      profissional.id,
      responsavelCuidado,
      updateContatoCidadao,
      verificarAgendamentosConflitantes,
    ]
  )

  const handleCancel = () => {
    confirm({
      title: 'Deseja cancelar o cuidado compartilhado?',
      body: 'As alterações realizadas serão perdidas.',
      confirmLabel: 'Sim',
      cancelLabel: 'Não',
      onConfirm: () => {
        deleteCacheState(false)
        goBack()
      },
    })()
  }

  useEffect(() => {
    if (!initialStateLoaded) {
      const initialValues = computeInitialValues(
        responsavelCuidado,
        cuidadoCompartilhado.cidadao,
        CUIDADO_COMPARTILHADO_CIDADAO_OBITO
      )
      setCacheState(initialValues)
      setInitialStateLoaded(true)
    }
  }, [
    CUIDADO_COMPARTILHADO_CIDADAO_OBITO,
    cuidadoCompartilhado.cidadao,
    initialStateLoaded,
    responsavelCuidado,
    setCacheState,
  ])

  const renderDiscussaoCaso = (cidadao: CidadaoCuidadoCompartilhado, headerHeight: number) => (
    <CheckJustificativaAcessoCuidadoCompartilhado
      cidadaoId={cuidadoCompartilhado.cidadao.id}
      prontuarioId={cidadao.prontuario.id}
      cuidadoCompartilhadoId={cuidadoCompartilhado.id}
      isDiscutirCaso={true}
      basePath={match.path.substring(0, match.path.lastIndexOf('/'))}
    >
      <DiscussaoCasoView
        cuidadoCompartilhado={cuidadoCompartilhado}
        cidadao={cidadao}
        headerHeight={headerHeight}
        cacheState={cacheState}
        responsavelCuidado={responsavelCuidado}
        videochamadaUuidState={videochamadaUuidState}
        updateCacheState={setCacheState}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        idadeGestacional={idadeGestacional}
      />
    </CheckJustificativaAcessoCuidadoCompartilhado>
  )

  if (!initialStateLoaded || loadingIdadeGestacional) {
    return <PageLoading message='Carregando dados do cuidado compartilhado...' />
  }

  return (
    <CuidadoCompartilhadoHeaderTabs
      cuidadoCompartilhado={cuidadoCompartilhado}
      idadeGestacional={idadeGestacional}
      renderDiscussaoCaso={renderDiscussaoCaso}
      handleCancel={handleCancel}
      isVisualizacao={false}
    />
  )
}

const cacheParser = (values: DiscussaoCasoFormModel) => {
  const clone = cloneDeep(values)

  if (clone?.executanteFields?.agendamento) {
    clone.executanteFields.agendamento = parseAgendamento(clone.executanteFields.agendamento)
  }

  if (clone?.solicitanteFields?.agendamento) {
    clone.solicitanteFields.agendamento = parseAgendamento(clone.solicitanteFields.agendamento)
  }

  return clone
}

const parseAgendamento = (
  input: AgendarConsultaCuidadoCompartilhadoFieldsModel
): AgendarConsultaCuidadoCompartilhadoFieldsModel => {
  const { tipoAgendamento, consultaComCidadao, consultaCompartilhada } = input ?? {}

  return {
    tipoAgendamento,
    consultaComCidadao: consultaComCidadao
      ? { ...consultaComCidadao, horario: parseDateFromLocalStorage(consultaComCidadao.horario) }
      : null,
    consultaCompartilhada: consultaCompartilhada
      ? { ...consultaCompartilhada, horario: parseDateFromLocalStorage(consultaCompartilhada.horario) }
      : null,
  }
}

const getAgendamentosConflitantesInput = (
  lotacaoId: ID,
  profissionalId: ID,
  cidadao: CuidadoCompartilhadoModel['cidadao'],
  agendamento: AgendarConsultaCuidadoCompartilhadoFieldsModel
) => {
  const horarioInicial =
    agendamento.tipoAgendamento === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO
      ? agendamento.consultaComCidadao?.horario?.inicial
      : agendamento.consultaCompartilhada?.horario?.inicial

  return {
    cidadaoId: cidadao.id,
    cidadaoNome: cidadao.nomeSocial ?? cidadao.nome,
    agendamentos: [
      {
        horario: Number(horarioInicial),
        lotacaoId,
        profissionalId,
      },
    ],
  }
}
