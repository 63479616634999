import { FormApi } from 'final-form'
import { useCidadaoInformacoesContatoQuery } from 'graphql/hooks.generated'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import {
  assinaturaDigitalCancelMessage,
  defaultAssinaturaDigitalErrorMessage,
  DocumentoDigitalErrorCodeEnum,
  documentoDigitalErrorCodeToMessage,
  TipoDocumentoAssinaturaDigitalPopup,
} from 'hooks/assinatura-digital/model-assinaturaDigitalPopup'
import { useAssinaturaDigitalPopup } from 'hooks/assinatura-digital/useAssinaturaDigitalPopup'
import { useSessionHasConselhoClasse } from 'hooks/conselho-classe/useSessionHasConselhoClasse'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useCallback, useEffect, useState } from 'react'

import AtestadoModel from '../components/model-atestado'
import { AtestadoRecenteImpressaoInput } from '../model-atestado'
import { AtestadoOnSubmitCallback } from './model-atestadoAssinaturaDigital'

interface AtestadoAssinaturaDigitalDataToSubmit {
  values: AtestadoModel
  formApi: FormApi<AtestadoModel>
}

interface AtestadoAssinaturaDigitalFormResult {
  emailCidadao: string
  handleSubmitWrapper: AtestadoOnSubmitCallback
  isAssinando: boolean
  isModalOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  errorMessage?: React.ReactNode
  onAlertClose: () => void
}

interface AtestadoAssinaturaDigitalFormProps {
  cidadaoId: ID
  atendimentoId: ID
  prontuarioId: ID
  tipoAtestado: TipoAtestadoEnum
  dataAtendimento: Instant
  onSubmit: AtestadoOnSubmitCallback
}

export function useAtestadoAssinaturaDigitalForm(
  props: AtestadoAssinaturaDigitalFormProps
): AtestadoAssinaturaDigitalFormResult {
  const { cidadaoId, atendimentoId, prontuarioId, tipoAtestado, dataAtendimento, onSubmit } = props

  const sessionHasConselhoClasse = useSessionHasConselhoClasse()

  const [isAssinando, setIsAssinando] = useState(false)
  const [isReadyToSubmitAtestadoAfterAssinatura, setIsReadyToSubmitAtestadoAfterAssinatura] = useState(false)
  const [popupDataIdentifier, setPopupDataIdentifier] = useState<string>(null)
  const [valuesToSubmit, setValuesToSubmit] = useState<AtestadoAssinaturaDigitalDataToSubmit>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null)

  const { data: infoCidadao } = useCidadaoInformacoesContatoQuery({
    variables: { id: cidadaoId },
  })

  const emailCidadao = infoCidadao?.contatoCidadao?.email

  const { data: popupData, openPopup, closePopup } = useAssinaturaDigitalPopup<AtestadoRecenteImpressaoInput>(
    {
      atendimentoId: atendimentoId,
      cidadaoId: cidadaoId,
      emailCidadao: emailCidadao,
    },
    TipoDocumentoAssinaturaDigitalPopup.ATESTADO
  )

  const { analytics } = useFirebase()

  if (!isAssinando) {
    closePopup()
  }

  if (valuesToSubmit && isReadyToSubmitAtestadoAfterAssinatura) {
    onSubmit(valuesToSubmit.values, valuesToSubmit.formApi)
    setValuesToSubmit(null)
    setIsReadyToSubmitAtestadoAfterAssinatura(false)
  }

  const handleError = useCallback((error: DocumentoDigitalErrorCodeEnum) => {
    setErrorMessage(error ? documentoDigitalErrorCodeToMessage[error] : defaultAssinaturaDigitalErrorMessage)
    setIsAssinando(false)
  }, [])

  const handleCancel = useCallback(() => {
    setIsAssinando(false)
    setErrorMessage(assinaturaDigitalCancelMessage)
  }, [])

  useEffect(() => {
    if (isAssinando && popupData?.identifier !== popupDataIdentifier) {
      switch (popupData?.status) {
        case 'success':
          setIsModalOpen(false)
          setIsAssinando(false)
          setIsReadyToSubmitAtestadoAfterAssinatura(true)
          break
        case 'canceled':
          handleCancel()
          break
        case 'failure':
          handleError(popupData.error)
          break
      }
    }
    setPopupDataIdentifier(popupData?.identifier)
  }, [handleCancel, handleError, isAssinando, popupData, popupDataIdentifier])

  const handleSubmitWrapper = async (values: AtestadoModel, formApi: FormApi<AtestadoModel>) => {
    if (values.assinadoDigitalmente) {
      if (!sessionHasConselhoClasse) {
        setErrorMessage(documentoDigitalErrorCodeToMessage[DocumentoDigitalErrorCodeEnum.PROFISSIONAL_SEM_CONSELHO])
        return
      }
      setValuesToSubmit({ values, formApi })
      setIsModalOpen(true)
    } else {
      onSubmit(values, formApi)
    }
  }

  const onConfirm = async () => {
    analytics.logEvent('click_gerar_atestado_digital')
    try {
      setIsAssinando(true)
      await openPopup({
        tipo: tipoAtestado,
        descricao: valuesToSubmit?.values.descricao,
        atendimentoId: atendimentoId,
        prontuarioId: prontuarioId,
        atendimentoProfissionalIniciadoEm: dataAtendimento,
      })
    } catch {
      handleError(popupData?.error)
    }
  }

  const onCancel = () => {
    setIsModalOpen(false)
    setErrorMessage(null)
  }

  const onAlertClose = () => {
    setErrorMessage(null)
  }

  return {
    emailCidadao,
    handleSubmitWrapper,
    isAssinando,
    isModalOpen,
    onConfirm,
    onCancel,
    errorMessage,
    onAlertClose,
  }
}
