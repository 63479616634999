/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, VFlow } from 'bold-ui'
import { FormApi } from 'final-form'
import { RegistroObitoInput } from 'graphql/types.generated'
import { Fragment, useMemo } from 'react'
import { MetaArray } from 'util/metaPath'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { convertToCidadaoCardModel } from '../converter-cadastroImovel'
import {
  CadastrarCidadaoFamiliaFormModel,
  CadastroImovelCacheState,
  CidadaoCadastroImovel,
  CidadaoTerritorioFormModel,
  CidadaoTerritorioModel,
  FamiliaFormModel,
  UpdateCadastroImovelCacheState,
} from '../model-cadastroImovel'
import {
  getCamposNulosCidadaoTerritorioModel,
  getResponsavelDependentesForm,
  setCadastroCidadaoCacheState,
} from '../utils-cadastroImovel'
import { CidadaoCardWrapper } from './CidadaoCardWrapper'
import { CadastrarCidadaoFamiliaModal } from './modal/CadastrarCidadaoFamiliaModal'

interface CidadaoSectionProps {
  name: MetaArray<CidadaoTerritorioFormModel>
  cidadaos: Array<CidadaoTerritorioModel>
  isModalOpen: boolean
  familia: FamiliaFormModel
  cidadaoModal: CidadaoCadastroImovel
  cacheState: CadastroImovelCacheState
  shouldRenderCache: boolean
  hasPermission: boolean
  cadastroImovelPath: string
  dataColeta: LocalDate
  onCloseModal: () => void
  onDefinirResponsavelFamiliar: (cidadaoId: ID) => void
  onChange: UpdateCadastroImovelCacheState
  isCidadaoPresenteFamilias(cidadaoId: ID): boolean
  onScrollAfterRedirect: () => void
}

export const CidadaoSection = (props: CidadaoSectionProps) => {
  const {
    name,
    cidadaos,
    familia,
    isModalOpen,
    cidadaoModal,
    cacheState,
    shouldRenderCache,
    hasPermission,
    cadastroImovelPath,
    dataColeta,
    onCloseModal,
    onScrollAfterRedirect,
    onDefinirResponsavelFamiliar,
    onChange,
    isCidadaoPresenteFamilias,
  } = props

  const {
    handleSubmit,
    handleRowChanged,
    removeItem,
    input: { value },
  } = useEditableListField({
    name,
  })

  const handleSubmitCidadao = (cidadao: CidadaoTerritorioFormModel, formApi: FormApi) => handleSubmit(cidadao, formApi)
  const handleRemoveCidadao = (cidadao: CidadaoTerritorioFormModel) => {
    if (familia?.responsavelId === cidadao.id) {
      onDefinirResponsavelFamiliar(null)
    }
    removeItem(cidadao)
  }
  const handleDeleteCadastroCidadaoFamiliaCache = () =>
    onChange((previous) => setCadastroCidadaoCacheState(null, false, previous))

  const handleSaveCadastroCidadaoFamiliaCache = (form: CadastrarCidadaoFamiliaFormModel) =>
    onChange((previous) => setCadastroCidadaoCacheState(form, true, previous))

  const handleCloseModal = () => {
    onCloseModal()
    handleDeleteCadastroCidadaoFamiliaCache()
  }

  const handleAtualizarCidadaoCache = (updateCidado: boolean) =>
    onChange((previous) => ({
      ...previous,
      familiaCidadaoModalState: { ...previous.familiaCidadaoModalState, atualizacaoCidadao: updateCidado },
    }))

  const handleCidadaoMudouSe = (cidadaoTerritorioFormModel: CidadaoTerritorioFormModel) => {
    return (mudouSe: boolean) => handleRowChanged({ ...cidadaoTerritorioFormModel, mudouSe })
  }

  const handleCidadaoObito = (cidadaoTerritorioFormModel: CidadaoTerritorioFormModel) => {
    return (registroObito: RegistroObitoInput) =>
      handleRowChanged({ ...cidadaoTerritorioFormModel, registroObito: registroObito })
  }

  const { responsavelFormModel, dependentesFormModel } = getResponsavelDependentesForm(
    familia.responsavelId,
    value || []
  )

  const renderModal = shouldRenderModal(cacheState, shouldRenderCache, familia)

  const cidadaoModelMap = useMemo(() => new Map(cidadaos?.map((cidadao) => [cidadao.id, cidadao])), [cidadaos])

  const imovelId = cacheState?.form?.id

  const responsavelData = cidadaoModelMap.get(responsavelFormModel?.id)

  const cadastrarCidadaoInitialValues = useMemo(
    () => cadastroCidadaoFamiliaInitialValues(renderModal, cidadaoModal, familia),
    [cidadaoModal, familia, renderModal]
  )

  return (
    <Fragment>
      {
        <Grid>
          {responsavelFormModel && responsavelData && (
            <Cell size={12}>
              <CidadaoCardWrapper
                isResponsavel
                isFamiliaMudouSe={familia.isMudouSe}
                hasPermission={hasPermission}
                camposObrigatoriosNulos={getCamposNulosCidadaoTerritorioModel(
                  cidadaoModelMap.get(responsavelFormModel.id)
                )}
                imovelId={imovelId}
                dataColeta={dataColeta}
                onAtualizarCidadaoCache={handleAtualizarCidadaoCache}
                cidadao={convertToCidadaoCardModel(responsavelFormModel, responsavelData)}
                onDefinirResponsavelFamiliar={onDefinirResponsavelFamiliar}
                onCidadaoMudouSe={handleCidadaoMudouSe(responsavelFormModel)}
                onRemoverCidadao={() => handleRemoveCidadao(responsavelFormModel)}
                onCidadaoObito={handleCidadaoObito(responsavelFormModel)}
                onScrollAfterRedirect={onScrollAfterRedirect}
                isAtualizacaoCidadao={cacheState?.familiaCidadaoModalState?.atualizacaoCidadao}
              />
            </Cell>
          )}
          <Cell size={12}>
            <VFlow vSpacing={1}>
              {dependentesFormModel.map((dependenteFormModel: CidadaoTerritorioFormModel) => {
                const dependenteData = cidadaoModelMap.get(dependenteFormModel.id)
                return (
                  dependenteData && (
                    <CidadaoCardWrapper
                      key={dependenteFormModel.id}
                      isFamiliaMudouSe={familia.isMudouSe}
                      hasPermission={hasPermission}
                      camposObrigatoriosNulos={getCamposNulosCidadaoTerritorioModel(
                        cidadaoModelMap.get(dependenteFormModel.id)
                      )}
                      dataColeta={dataColeta}
                      imovelId={imovelId}
                      onAtualizarCidadaoCache={handleAtualizarCidadaoCache}
                      cidadao={convertToCidadaoCardModel(dependenteFormModel, dependenteData)}
                      onDefinirResponsavelFamiliar={onDefinirResponsavelFamiliar}
                      onCidadaoMudouSe={handleCidadaoMudouSe(dependenteFormModel)}
                      onRemoverCidadao={() => handleRemoveCidadao(dependenteFormModel)}
                      onCidadaoObito={handleCidadaoObito(dependenteFormModel)}
                      onScrollAfterRedirect={onScrollAfterRedirect}
                      isAtualizacaoCidadao={cacheState?.familiaCidadaoModalState?.atualizacaoCidadao}
                    />
                  )
                )
              })}
            </VFlow>
          </Cell>
        </Grid>
      }
      {(isModalOpen || renderModal) && (
        <CadastrarCidadaoFamiliaModal
          open={isModalOpen || renderModal}
          initialValues={cadastrarCidadaoInitialValues}
          cadastroImovelPath={cadastroImovelPath}
          imovelId={imovelId}
          hasPermission={hasPermission}
          isCidadaoPresenteFamilias={isCidadaoPresenteFamilias}
          onClose={handleCloseModal}
          onSubmit={handleSubmitCidadao}
          onScrollAfterRedirect={onScrollAfterRedirect}
          onSaveCadastroCidadaoFamiliaCache={handleSaveCadastroCidadaoFamiliaCache}
        />
      )}
    </Fragment>
  )
}

const shouldRenderModal = (
  cacheState: CadastroImovelCacheState,
  shouldRenderCache: boolean,
  familia: FamiliaFormModel
) => {
  const cadastroCidadaoModal = cacheState?.familiaCidadaoModalState?.cadastroCidadaoModal

  return shouldRenderCache && cadastroCidadaoModal?.modalOpen && cadastroCidadaoModal?.form?.familiaId === familia?._id
}

const cadastroCidadaoFamiliaInitialValues = (
  renderModal: boolean,
  cidadaoModal: CidadaoCadastroImovel,
  familia: FamiliaFormModel
) => ({ cidadao: renderModal ? cidadaoModal : null, familiaId: familia._id })
