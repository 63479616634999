import { HFlow, Tag, Text, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { SelectField } from 'components/form/final-form'
import { EstabelecimentosCuidadoCompartilhadoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  EstabelecimentosCuidadoCompartilhadoSelectFieldQuery,
  EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables,
  LotacaoOuEstagio,
} from 'graphql/types.generated'
import React from 'react'
import { isTipoEquipeEmulti } from 'util/equipe/util-equipe'

export type EstabelecimentosCuidadoCompartilhadoSelectModel = EstabelecimentosCuidadoCompartilhadoSelectFieldQuery['estabelecimentosCuidadoCompartilhado']['content'][0]

export interface EstabelecimentosCuidadoCompartilhadoSelectFieldProps
  extends AsyncSelectFieldProps<EstabelecimentosCuidadoCompartilhadoSelectModel> {
  disabled?: boolean
  cboCodigo2002: string
  isVinculado: boolean
}

export function EstabelecimentosCuidadoCompartilhadoSelectField(
  props: EstabelecimentosCuidadoCompartilhadoSelectFieldProps
) {
  const { cboCodigo2002, isVinculado, ...rest } = props

  const { acesso } = useAcessoLotacaoOrEstagio()

  const { selectProps } = useAsyncQuerySelect<
    EstabelecimentosCuidadoCompartilhadoSelectModel,
    EstabelecimentosCuidadoCompartilhadoSelectFieldQuery,
    EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables
  >({
    query: EstabelecimentosCuidadoCompartilhadoSelectFieldDocument,
    extractItems: (data) =>
      data && data.estabelecimentosCuidadoCompartilhado && data.estabelecimentosCuidadoCompartilhado.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        cboCodigo2002,
        isVinculado,
      },
    }),
    skip: () => props.disabled,
    debounceTime: 500,
  })

  return (
    <SelectField<EstabelecimentosCuidadoCompartilhadoSelectModel>
      itemToString={itemToString}
      renderItem={(item) => renderItem(item, acesso)}
      {...selectProps}
      {...rest}
    />
  )
}

const itemToString = (option: EstabelecimentosCuidadoCompartilhadoSelectModel) => option && option.nome

const renderItem = (item: EstabelecimentosCuidadoCompartilhadoSelectModel, acesso: LotacaoOuEstagio) => {
  return (
    <>
      <HFlow alignItems='center' justifyContent='space-between'>
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>
            {item.nome}
            {!item.ativo && ' (Inativa)'}
          </Text>
          <HFlow hSpacing={0.5}>
            <Text fontWeight='bold'>CNES:</Text>
            {item.cnes}
          </HFlow>
        </VFlow>
        {item.cnes === acesso.unidadeSaude.cnes && (
          <Tag style={{ alignContent: 'flex-end' }}>
            {isTipoEquipeEmulti(acesso.equipe?.tipoEquipe.codigoMs) ? 'eMulti/Outras equipes' : 'eMulti'}
          </Tag>
        )}
      </HFlow>
    </>
  )
}
