import { isAfter, isEqual, parseISO } from 'date-fns'
import { useLoadAntecedentesQuery } from 'graphql/hooks.generated'
import React from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import { isObjectDeepFalsy } from 'util/object'

import { ProntuarioCard } from '../../soap/aside/ProntuarioCard'
import { AntecedentesFolhaRostoCard } from './AntecedentesFolhaRostoCard'
import { convertAntecedenteToFolhaRostoInformacoesObstetricasModel } from './converter'
import { FolhaRostoCirurgiaInternacaoModel, FolhaRostoInformacoesObstetricasModel } from './model'

interface AntecedentesFolhaRostoSectionProps {
  prontuarioId: ID
  cidadaoDataNascimento: Date
}

export function AntecedentesFolhaRostoSection(props: AntecedentesFolhaRostoSectionProps) {
  const { prontuarioId, cidadaoDataNascimento } = props

  const {
    data: { antecedente },
    loading,
  } = useLoadAntecedentesQuery({
    variables: {
      id: prontuarioId,
    },
  })

  const informacoesObstetricas: FolhaRostoInformacoesObstetricasModel = convertAntecedenteToFolhaRostoInformacoesObstetricasModel(
    antecedente
  )

  const cirurgiasInternacoesSorted =
    antecedente?.cirurgiasInternacoes
      ?.map<FolhaRostoCirurgiaInternacaoModel>((cirurgiaInternacao) => {
        const dataCirurgiaInternacao =
          cirurgiaInternacao.dataCirurgiaInternacao && parseISO(cirurgiaInternacao.dataCirurgiaInternacao)
        const idadeTexto =
          dataCirurgiaInternacao &&
          humanizeAge(dateAsYyyyMmDd(cidadaoDataNascimento), dateAsYyyyMmDd(dataCirurgiaInternacao))

        return {
          idCirurgiaInternacao: cirurgiaInternacao.id,
          descricao: cirurgiaInternacao.descricao,
          dataRealizada: dataCirurgiaInternacao &&
            (isAfter(dataCirurgiaInternacao, cidadaoDataNascimento) ||
              isEqual(dataCirurgiaInternacao, cidadaoDataNascimento)) && {
              data: dataCirurgiaInternacao,
              idade: idadeTexto,
            },
        }
      })
      .sort((cirurgiaInternacao1, cirurgiaInternacao2) => {
        return Number(cirurgiaInternacao2.dataRealizada?.data) - Number(cirurgiaInternacao1.dataRealizada?.data)
      }) || []

  const showCard = isInformacoesObstetricasPreenchidas(informacoesObstetricas) || cirurgiasInternacoesSorted.length > 0
  const tooltip = !showCard
    ? 'Nenhum antecedente registrado'
    : cirurgiasInternacoesSorted.length > 5 && 'Ver registros de antecedentes'

  return (
    <ProntuarioCard title='Antecedentes' tooltip={tooltip}>
      {showCard && (
        <AntecedentesFolhaRostoCard
          loading={loading}
          informacoesObstetricas={informacoesObstetricas}
          cirurgiasInternacoes={cirurgiasInternacoesSorted}
          cidadaoDataNascimento={cidadaoDataNascimento}
        />
      )}
    </ProntuarioCard>
  )
}

export const isInformacoesObstetricasPreenchidas = (informacoesObstetricas) => {
  const { partoMenosDeUmAno, ...informaçoesObstetricasSemPartoMenosDeUmAno } = informacoesObstetricas
  return partoMenosDeUmAno || !isObjectDeepFalsy(informaçoesObstetricasSemPartoMenosDeUmAno)
}
