/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Heading, HFlow, Modal, ModalBody, ModalFooter, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, RadioField, SubmitButton } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { FormApi } from 'final-form'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { FormRenderProps } from 'react-final-form'
import { useHistory, useRouteMatch } from 'react-router'
import { metaPath } from 'util/metaPath'

import { RELATORIOS_OPERACIONAIS_URL } from '../AcompanhamentoCondicaoSaudeView'
import { downloadRelatorio } from '../downloadRelatorio'
import {
  RelatorioOperacionalAuthorization,
  RelatoriosOperacionaisModel,
  TipoRelatorioOperacionalEnum,
  tipoRelatorioOperacionalLabel,
} from '../model-acompanhamentoCondicaoSaude'

interface RelatoriosOperacionaisModalProps {
  filter: AcompanhamentoCondicaoSaudeQueryInput
  permissions: RelatorioOperacionalAuthorization
}

export function RelatoriosOperacionaisModal(props: RelatoriosOperacionaisModalProps) {
  const { filter, permissions } = props
  const { analytics } = useFirebase()
  const path = metaPath<RelatoriosOperacionaisModel>()
  const alert = useAlert()
  const history = useHistory()
  const match = useRouteMatch()

  const handleCloseModal = () => {
    history.push(match.url.replace(`/${RELATORIOS_OPERACIONAIS_URL}`, ''))
  }

  const renderModal = (props: FormRenderProps<RelatoriosOperacionaisModel>) => {
    const { values, handleSubmit } = props
    return (
      <Modal size='small' open onClose={handleCloseModal} closeOnBackdropClick={false}>
        <ModalBody>
          <Heading level={1}>Gerar relatório operacional</Heading>
          <Alert
            type='info'
            styles={{
              wrapper: css`
                margin: 1rem 0;
              `,
              container: css`
                padding: 0.5rem 0;
              `,
            }}
          >
            Serão apresentados apenas cidadãos que estejam vinculados a um responsável familiar e este vinculado a um
            domicílio do território. Caso algum relatório esteja desabilitado, verificar suas permissões.
          </Alert>
          <Text>Selecione qual relatório deseja gerar:</Text>
          <VFlow
            vSpacing={0.5}
            style={css`
              margin-top: 1.5rem;
            `}
          >
            <HFlow hSpacing={0.5} style={styles.radioContainer}>
              <RadioField
                name={path.tipoRelatorioOperacionalEnum}
                value={TipoRelatorioOperacionalEnum.CRIANCA}
                label={tipoRelatorioOperacionalLabel[TipoRelatorioOperacionalEnum.CRIANCA]}
                disabled={!permissions.hasCriancaPermission}
              />
              <InfoIcon
                icon='infoCircleFilled'
                text='Fonte de informação: FCI, FCDT, FAE, FAI, FP, FAOI, FVDT, Atendimento PEC, Aplicativo e-SUS Território.'
              />
            </HFlow>
            <HFlow hSpacing={0.5} style={styles.radioContainer}>
              <RadioField
                name={path.tipoRelatorioOperacionalEnum}
                value={TipoRelatorioOperacionalEnum.GESTANTE}
                label={tipoRelatorioOperacionalLabel[TipoRelatorioOperacionalEnum.GESTANTE]}
                disabled={!permissions.hasGestantePermission}
              />
              <InfoIcon
                icon='infoCircleFilled'
                text='Fonte de informação: FCI, FCDT, FAE, FAI, FAOI, FVDT, Atendimento PEC, Aplicativo e-SUS Território.'
              />
            </HFlow>
            <HFlow hSpacing={0.5} style={styles.radioContainer}>
              <RadioField
                name={path.tipoRelatorioOperacionalEnum}
                value={TipoRelatorioOperacionalEnum.RISCO_CARDIOVASCULAR}
                label={tipoRelatorioOperacionalLabel[TipoRelatorioOperacionalEnum.RISCO_CARDIOVASCULAR]}
                disabled={!permissions.hasHipertensaoPermission}
              />
              <InfoIcon
                icon='infoCircleFilled'
                text='Fonte de informação: FCI, FCDT, FAE, FAI, Atendimento PEC, Aplicativo e-SUS Território.'
              />
            </HFlow>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={handleCloseModal}>Cancelar</Button>
            <Tooltip text={!values?.tipoRelatorioOperacionalEnum && 'Selecione o tipo de relatório'}>
              <SubmitButton kind='primary' disabled={!values?.tipoRelatorioOperacionalEnum} handleSubmit={handleSubmit}>
                Gerar relatório
              </SubmitButton>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  const handleModalSubmit = (values: RelatoriosOperacionaisModel, formApi: FormApi<RelatoriosOperacionaisModel>) => {
    analytics.logEvent('relatorio_ope_acomp_cond_saude', {
      relatorio: tipoRelatorioOperacionalLabel[values.tipoRelatorioOperacionalEnum],
    })
    return downloadRelatorio(values.tipoRelatorioOperacionalEnum, filter)
      .then(() => {
        alert('success', 'Relatório operacional gerado com sucesso.')
        handleCloseModal()
        setTimeout(() => formApi.reset())
      })
      .catch((errorMessage) => {
        alert('warning', errorMessage)
        handleCloseModal()
        setTimeout(() => formApi.reset())
      })
  }
  return <Form<RelatoriosOperacionaisModel> render={renderModal} onSubmit={handleModalSubmit} />
}

const styles = {
  radioContainer: css`
    display: flex;
    align-items: center;
  `,
}
