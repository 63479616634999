import { TipoEnderecoEnum } from 'graphql/types.generated'
import { cep, composeRules, createValidator, ErrorObject, maxLength, required } from 'util/validation'

import { LogradouroEditFormModel, VisualizacaoTerritorialBuscaFormModel } from './model'

export const validateVisualizacaoTerritorialForm = createValidator<VisualizacaoTerritorialBuscaFormModel>(
  {
    tipoEndereco: [required],
    equipe: [required],
    unidadeSaude: [required],
  },
  (value, errors): ErrorObject<VisualizacaoTerritorialBuscaFormModel> => {
    if (TipoEnderecoEnum.LOGRADOURO === value.tipoEndereco) {
      errors.cep = composeRules([...cep])(value.cep)
    } else {
      errors.dsei = required(value.dsei)
      errors.poloBase = required(value.poloBase)
    }

    return errors
  }
)

export const validateLogradouroEditForm = () =>
  createValidator<LogradouroEditFormModel>(
    {
      cep: [required, cep],
      logradouro: [required, maxLength(72)],
      tipoLogradouro: [required],
    },
    (value, errors): ErrorObject<LogradouroEditFormModel> => {
      errors.bairro = composeRules([required, maxLength(72)])(value.bairro?.nome)
      return errors
    }
  )
