import { VFlow } from 'bold-ui'
import { parseISO } from 'date-fns'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'

import { IvcfBox } from './components/IvcfBox'
import { IvcfResultadoAccordion } from './components/IvcfResultadoAccordion'
import { IvcfAplicacaoView } from './ivcf-aplicacao/ivcf-aplicacao-view/IvcfAplicacaoView'
import { IvcfResultadoView } from './ivcf-resultado/IvcfResultadoView'
import { IvcfViewModel } from './model-ivcf'

interface IvcfViewProps {
  values: IvcfViewModel
}

export function IvcfView(props: IvcfViewProps) {
  const { values } = props

  const {
    cidadao: { dataNascimento },
    atendimentoProfissional: { iniciadoEm },
  } = useAtendimentoContext()

  return (
    <IvcfBox isIvcfPreenchido>
      <VFlow>
        <IvcfResultadoView values={values} />
        <IvcfResultadoAccordion
          disableEdit
          disableEditTooltip='Não é possível editar o registro de IVCF-20 dentro de um atendimento de observação.'
        >
          {() => (
            <IvcfAplicacaoView
              value={values.aplicacao}
              cidadaoDataNascimento={dateAsYyyyMmDd(parseISO(dataNascimento))}
              dataReferencia={dateAsYyyyMmDd(iniciadoEm)}
            />
          )}
        </IvcfResultadoAccordion>
      </VFlow>
    </IvcfBox>
  )
}
