import { css } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { information } from 'components/modals/information'
import { ImovelDto } from 'graphql/types.generated'
import React, { useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory, useRouteMatch } from 'react-router'
import { LogradouroCadastroDuplicadoFormModel } from 'view/cadastro-imovel/model-cadastroImovel'

import { ImovelDuplicadoCard } from '../ImovelDuplicadoCard'

interface LogradouroCadastroDuplicadoModalProps {
  isModalOpen: boolean
  onClose(): void
  imoveis: Array<ImovelDto>
  deleteCacheState(): void
}

export function LogradouroCadastroDuplicadoModal(props: LogradouroCadastroDuplicadoModalProps) {
  const { isModalOpen, onClose, imoveis, deleteCacheState } = props
  const [selectedImovelIndex, setSelectedImovelIndex] = useState(null)

  const match = useRouteMatch()
  const history = useHistory()

  const imovel = imoveis[0]
  const initialValues = {
    logradouro: imovel.logradouro,
    numero: imovel.numero,
    complemento: imovel.complemento,
    imoveis: imoveis,
  }

  const handleSubmit = () => {
    setTimeout(() => {
      deleteCacheState()
      history.push(
        `${match.url.replace('cadastrar', 'editar')}/${
          imoveis[selectedImovelIndex].id
        }?redirect=informacoes&callbackUrl=${match.url}`
      )
    })
  }

  const handleInfoNaoDuplicarCadastroModal = () => {
    information({
      type: 'primary',
      title: 'Preencha com atenção!',
      body:
        'Revise os campos já preenchidos e altere, no mínimo, o campo de Complemento, para não duplicar o cadastro.',
      depthLevel: 2,
      manageOverflow: false,
      showCloseButton: true,
      closeLabel: 'Entendi',
    })()
  }

  const handleClose = () => {
    handleInfoNaoDuplicarCadastroModal()
    onClose()
  }

  const renderForm = (formProps: FormRenderProps<LogradouroCadastroDuplicadoFormModel>) => {
    const { values } = formProps
    const imoveisCount = values.imoveis.length

    return (
      <Modal
        size='auto'
        open={isModalOpen}
        closeOnBackdropClick={false}
        onClose={handleClose}
        style={{ width: '45rem' }}
      >
        <ModalBody>
          <Grid>
            <Cell size={12}>
              <VFlow vSpacing={0}>
                <Heading level={1}>{pluralizeTitle(imoveisCount)}</Heading>
                <Text>{pluralizeAtualizacaoCadastroMessage(imoveisCount)}</Text>
              </VFlow>
            </Cell>
            <Cell size={12} />
            <Cell size={12}>
              <Text fontWeight='bold'>{pluralizeDescription(imoveisCount, values)}</Text>
            </Cell>
            <Cell size={12}>
              <VFlow vSpacing={1}>
                {values.imoveis.map((imovel, index) => (
                  <ImovelDuplicadoCard
                    key={index}
                    imovel={imovel}
                    isSelected={index === selectedImovelIndex}
                    onSelectImovel={() => setSelectedImovelIndex(index)}
                  />
                ))}
              </VFlow>
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button kind='normal' size='medium' onClick={handleClose}>
              Cadastro novo
            </Button>
            <Button
              kind='primary'
              style={css`
                width: 8rem;
              `}
              onClick={handleSubmit}
              disabled={selectedImovelIndex === null}
            >
              Atualizar
            </Button>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }
  return (
    <Form<LogradouroCadastroDuplicadoFormModel>
      onSubmit={handleSubmit}
      render={renderForm}
      initialValues={initialValues}
    />
  )
}

const pluralizeTitle = (numeroImoveisDuplicados: number) => {
  return numeroImoveisDuplicados > 1 ? 'Encontramos imóveis com as mesmas informações' : 'Imóvel já cadastrado'
}

const pluralizeDescription = (numeroImoveisDuplicados: number, values: LogradouroCadastroDuplicadoFormModel) => {
  return (
    (numeroImoveisDuplicados > 1 ? `Imóveis encontrados` : `Imóvel encontrado`) +
    ` para "${values.logradouro.titleCase()}, ${values.numero}
                ${values.complemento ? ` ${values.complemento.titleCase()}` : ''}."`
  )
}

const pluralizeAtualizacaoCadastroMessage = (numeroImoveisDuplicados: number) => {
  return numeroImoveisDuplicados > 1
    ? `Já existem outros cadastros de imóveis com estas mesmas informações de identificação. Para evitar duplicação de cadastro você pode escolher um deles na lista abaixo para atualizar`
    : `Já existe um cadastro de imóvel com estas mesmas informações de identificação. Informe se deseja cadastrar um novo imóvel ou atualizar o este que já existe.`
}
