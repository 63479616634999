import { css } from '@emotion/core'
import { Alert, Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import React from 'react'

import { tituloAtestado } from '../atestadoUtils'
import { AtestadoContentBox } from '../components/AtestadoContentBox'

interface AtestadoConfirmarAssinaturaDigitalModalProps {
  emailCidadao: string
  onConfirm: () => void
  onCancel: () => void
  isAssinando: boolean
  errorMessage?: React.ReactNode
  onAlertClose: () => void
  tipoAtestado: TipoAtestadoEnum
  descricao: string
}

export const AtestadoConfirmarAssinaturaDigitalModal = (props: AtestadoConfirmarAssinaturaDigitalModalProps) => {
  const { emailCidadao, onConfirm, onCancel, isAssinando, errorMessage, onAlertClose, tipoAtestado, descricao } = props

  return (
    <Modal onClose={onCancel} open depthLevel={2}>
      <ModalBody>
        <VFlow vSpacing={2}>
          <HFlow>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={3} />
            <div>
              <Heading level={1}>Confira os dados do atestado</Heading>
              <Text>
                Este atestado será assinado e enviado ao cidadão via e-mail, verifique atentamente os dados incluídos no
                documento.{' '}
              </Text>
              <Text fontWeight='bold'>
                Após gerar e assinar um atestado digital não será possível editar ou excluir o registro.
              </Text>
            </div>
          </HFlow>
          <VFlow vSpacing={0.5}>
            <HFlow>
              <div>
                <Text fontWeight='bold'>Enviar para: </Text>
                <Text>{emailCidadao}</Text>
              </div>
            </HFlow>
            {errorMessage && (
              <Alert inline type='warning' onCloseClick={onAlertClose}>
                {errorMessage}
              </Alert>
            )}
          </VFlow>
          <AtestadoContentBox title={tituloAtestado(tipoAtestado)} content={descricao} />
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' size='medium' onClick={onCancel} style={styles.buttons}>
            Cancelar
          </Button>
          <Button
            kind='primary'
            skin='default'
            size='medium'
            onClick={onConfirm}
            disabled={isAssinando}
            loading={isAssinando}
            style={styles.buttons}
          >
            Enviar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const styles = {
  buttons: css`
    min-width: 9rem;
  `,
}
