import { Cell, Grid } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { DefaultFormFooter, Form, FormPrompt, FormRenderProps } from 'components/form'
import { useSalvarCidadaoFciMutation } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath } from 'util/metaPath'

import { createCidadaoFciCalculator } from './calculator-cidadaoFci'
import { DadosPessoaisSection } from './components/DadosPessoaisSection'
import { InformacoesSocioEconomicasSection } from './components/InformacoesSocioEconomicasSection'
import { convertToCidadaoFciInput } from './converter-cidadaoFci'
import { CidadaoFciFormModel } from './model-cidadaoFci'
import { cidadaoFciValidator } from './validator-cidadaoFci'

interface CidadaoFciFormProps {
  initialValues?: CidadaoFciFormModel
}

const meta = metaPath<CidadaoFciFormModel>()

export function CidadaoFciForm({ initialValues }: CidadaoFciFormProps) {
  const [save, { loading }] = useSalvarCidadaoFciMutation()
  const handleRejection = useErrorHandler()

  const decorators = useMemo(() => [createCidadaoFciCalculator(meta)], [])
  const serverTime = useServerTime().getServerTimeNow()
  const history = useHistory()

  // TODO NP - Deve ser feito o redirecionamento correto em issus posteriores.
  const handleSubmit = (values: CidadaoFciFormModel) =>
    save({ variables: { input: convertToCidadaoFciInput(values) } })
      .then(() => {
        setTimeout(() => history.push(`/cidadao`))
      })
      .catch(handleRejection)

  const renderForm = (formProps: FormRenderProps<CidadaoFciFormModel>) => {
    return (
      <Grid>
        <FormPrompt />
        <Cell size={12}>
          <DadosPessoaisSection
            meta={meta.dadosPessoais}
            values={formProps.values.dadosPessoais}
            serverTime={serverTime}
            isEditCnsEnabled={!(!!initialValues?.id && !isUndefinedOrNull(initialValues?.dadosPessoais?.cns))}
          />
        </Cell>
        <Cell size={12}>
          <InformacoesSocioEconomicasSection
            meta={meta.informacoesSocioEconomicas}
            values={formProps.values?.informacoesSocioEconomicas}
          />
        </Cell>
        <Cell size={12}>
          <DefaultFormFooter handleSubmit={formProps.handleSubmit} loading={loading} />
        </Cell>
      </Grid>
    )
  }

  return (
    <Form<CidadaoFciFormModel>
      initialValues={initialValues}
      decorators={decorators}
      onSubmit={handleSubmit}
      render={renderForm}
      validate={cidadaoFciValidator()}
    />
  )
}
