import { Alert, Button, HeadingSection, Text, VFlow } from 'bold-ui'
import { EnderecoFieldGroup, Form, FormFooter, FormPrompt, FormRenderProps, RadioGroupField } from 'components/form'
import { EnderecoIndigenaFieldGroup } from 'components/form/field/enderecoindigena/EnderecoIndigenaFieldGroup'
import { confirm } from 'components/modals/confirm'
import { AnyObject } from 'final-form'
import {
  useConfiguracaoCertificadoQuery,
  useHasCidadaoAgendamentoFuturoQuery,
  useSalvarCidadaoMutation,
} from 'graphql/hooks.generated'
import { NacionalidadeEnum } from 'graphql/types.generated'
import qs from 'qs'
import React, { useMemo } from 'react'
import { FormSpy } from 'react-final-form'
import { useHistory, useLocation } from 'react-router'
import { metaPath } from 'util/metaPath'
import { CADASTRAR_IMOVEL_PATH, EDITAR_IMOVEL_PATH } from 'view/cadastro-imovel/constants-cadastroImovel'
import { tipoEnderecoIndigena, tipoEnderecoOptions } from 'view/cadastro-imovel/model-cadastroImovel'

import { useSaudeIndigenaAuthorization } from '../authorization/useSaudeIndigenaAuthorization'
import convertModelToInput from '../types/converter-cidadao'
import CidadaoFormModel from '../types/model-cidadao'
import { useNavigateToCidadaoCallbackUrl } from '../useNavigateToCidadaoCallbackUrl'
import { cidadaoCalculator } from './calculator-cidadao'
import { createCidadaoAldeadoCalculator } from './calculator-cidadaoAldeado'
import { createEnderecoCalculator } from './calculator-enderecoCidadao'
import { infoComplementaresCalculator } from './calculator-infoComplementares'
import { CidadaoDadosFieldGroup } from './CidadaoDadosFieldGroup'
import { CompartilhamentoProntuarioFieldGroup } from './CompartilhamentoProntuarioFieldGroup'
import { ContatosFieldGroup } from './ContatosFieldGroup'
import { InformacoesComplementaresFieldGroup } from './InformacoesComplementaresFieldGroup'
import { UrlParams } from './ModalStatusSincronizacaoCadsus'
import { PeriodoAusenciaFieldGroup } from './PeriodoAusenciaFieldGroup'
import { RaniFieldGroup } from './RaniFieldGroup'
import { validator as validateCidadaoForm } from './validator-cidadaoForm'
import { VincularCidadaoComponentRoot } from './vinculacao/VincularCidadaoComponentRoot'

export interface CidadaoFormProps {
  initialValues: CidadaoFormModel
  sincronizarCadsus(cidadaoId: string, cns: string): void
  needLoginGovBr: Boolean
  hasAllConfigs: Boolean
  isEdit?: Boolean
}

const path = metaPath<CidadaoFormModel>()

export function CidadaoDadosForm(props: CidadaoFormProps) {
  const history = useHistory()
  const location = useLocation()
  const [save, { loading }] = useSalvarCidadaoMutation()
  const { data: certificado } = useConfiguracaoCertificadoQuery()
  const isCertificadoConfigurado = useMemo(() => certificado.configuracaoCertificadoHabilitado?.cadsusHabilitado, [
    certificado.configuracaoCertificadoHabilitado,
  ])
  const enderecoDecorator = useMemo(() => createEnderecoCalculator(path), [])
  const cidadaoAldeadoDecorator = useMemo(() => createCidadaoAldeadoCalculator(path), [])
  const { hasCadastrarEditarAldeadoPermission } = useSaudeIndigenaAuthorization()

  const urlParams: UrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const { refetch } = useHasCidadaoAgendamentoFuturoQuery({ skip: true })

  // TODO (NP) #23449: verificar se o useNavigateToCidadaoCallbackUrl funcionaria para a ação de Cancelar.
  const handleOnCancelarClicked = () => {
    sessionStorage.removeItem('cidadaoCadsus')
    switch (urlParams?.callbackUrl) {
      case 'registroTardio':
        history.push(`/${urlParams.callbackUrl}/${urlParams.callbackParams}`)
        break
      case 'registroTardio/cidadao-ausente':
        history.push(`/registroTardio?${urlParams.callbackParams}`)
        break
      case 'lista-atendimento/cidadao-ausente':
        history.push(`/lista-atendimento?${urlParams.callbackParams}`)
        break
      case '/acompanhamentos/territorio':
        history.push(`${urlParams.callbackUrl}?redirect=true`)
        break
      case 'lista-atendimento/atendimento-vacinacao':
        history.push(`/${urlParams.callbackUrl}/${urlParams.callbackParams}`)
        break
      case EDITAR_IMOVEL_PATH:
        history.push(
          `${urlParams.callbackUrl}/${urlParams.cadImovelUrlParams.imovelId}?redirect=${urlParams.cadImovelUrlParams.redirect}`
        )
        break
      case CADASTRAR_IMOVEL_PATH:
        history.push(
          `${urlParams.callbackUrl}?redirect=${urlParams.cadImovelUrlParams.redirect}${
            !!urlParams.cadImovelUrlParams.endereco ? '&endereco=true' : ''
          }`
        )
        break
      case 'visualizar-escuta':
      case 'escuta-inicial':
        history.push(`/lista-atendimento/${urlParams.callbackUrl}/${urlParams.callbackParams}/cadastro-cidadao`)
        break
      default:
        history.push('/cidadao' + history.location.search)
        break
    }
  }

  function buildMessage(values: CidadaoFormModel, hasCidadaoObitoAgendamentoFuturo: boolean = false) {
    const listItem: string[] = []

    if (!values.vinculacao && !values.cidadaoFaleceu) {
      listItem.push(
        'O cidadão não está vinculado a nenhuma equipe. O cadastro permanecerá sem vínculo com uma equipe responsável.'
      )
    }
    if (!values.cpf && !values.cns) {
      listItem.push(
        'Cidadão sem CPF ou CNS registrado. Este cadastro não será considerado elegível para contabilização nos indicadores de saúde do município.'
      )
    }
    if (
      !values.vinculacao &&
      values.cidadaoFaleceu &&
      props.initialValues?.vinculacao &&
      !props.initialValues?.cidadaoFaleceu
    ) {
      listItem.push('Ao informar óbito do cidadão, ele perderá o vínculo com a sua equipe responsável.')
    }
    if (hasCidadaoObitoAgendamentoFuturo) {
      listItem.push('Ao informar óbito do cidadão, os agendamentos futuros serão cancelados.')
    }
    return listItem
  }
  const navigateToCidadaoCallbackUrl = useNavigateToCidadaoCallbackUrl()

  const handleOnConcluir = (cidadaoId: string) => {
    sessionStorage.removeItem('cidadaoCadsus')
    if (urlParams?.callbackUrl) {
      navigateToCidadaoCallbackUrl(cidadaoId)
    } else {
      history.push(`/cidadao/${cidadaoId}`)
    }
  }

  const onHandleSubmit = (values: CidadaoFormModel) =>
    save({ variables: { input: convertModelToInput(values) } }).then((result) => {
      return props.hasAllConfigs && !props.needLoginGovBr
        ? props.sincronizarCadsus(result.data.salvarCidadao.id, result.data.salvarCidadao.cns)
        : setTimeout(() => handleOnConcluir(result.data.salvarCidadao.id))
    })

  const dialog = (listItem: string[], handleSubmit: () => Promise<AnyObject>) => {
    confirm({
      title: 'Deseja salvar o cadastro do cidadão?',
      body: (
        <Text>
          <ul>
            {listItem.map((value, idx) => {
              return <li key={idx}>{value}</li>
            })}

            {!isCertificadoConfigurado && (
              <li>
                <strong>Atenção:</strong> Para que o PEC envie ou atualize os dados no CADSUS, é necessário configurar o
                certificado com o serviço habilitado e fazer o login no gov.br. Entre em contato com o Administrador
                Municipal da instalação.
              </li>
            )}
          </ul>
        </Text>
      ),
      cancelLabel: 'Cancelar',
      confirmLabel: 'Salvar',
      onConfirm: () => {
        return handleSubmit()
      },
    })()
  }

  const salvar = (formProps: FormRenderProps<CidadaoFormModel>) => () => {
    const { values, handleSubmit } = formProps
    const processAgendamentoFuturo = () => {
      return ({ data }) => {
        const listItem = buildMessage(values, data.hasAgendamentoFuturo)
        if (listItem.length > 0 || !isCertificadoConfigurado) {
          dialog(listItem, handleSubmit)
        } else {
          handleSubmit()
        }
      }
    }

    if (values.id && values.cidadaoFaleceu && values.dataObito) {
      return refetch({
        input: { id: values.id, dataReferencia: values.dataObito },
      }).then(processAgendamentoFuturo())
    } else {
      const listItem = buildMessage(values)
      if (listItem.length > 0 || !isCertificadoConfigurado) {
        return dialog(listItem, handleSubmit)
      } else {
        handleSubmit()
      }
    }
  }

  const renderForm = (formProps: FormRenderProps<CidadaoFormModel>) => {
    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <FormPrompt />
        <VFlow vSpacing={2}>
          <VFlow>
            <Alert type='info' inline>
              O módulo tem como objetivo atualizar ou registrar os dados cadastrais do cidadão no sistema. Esses dados
              também podem ser provenientes das informações da Ficha de Cadastro Individual ou Ficha de Avaliação de
              Elegibilidade após seu processamento.
            </Alert>
          </VFlow>
          <HeadingSection level={2} title='Dados pessoais'>
            <CidadaoDadosFieldGroup name={path} formProps={formProps} isEdit={props.isEdit} />
          </HeadingSection>
          <HeadingSection level={2} title='Contatos'>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                const contatoObrigatorio =
                  values.nacionalidade !== NacionalidadeEnum.ESTRANGEIRA && !values.isCidadaoAldeado
                return <ContatosFieldGroup name={path.contato} formProps={formProps} required={contatoObrigatorio} />
              }}
            </FormSpy>
          </HeadingSection>
          <HeadingSection level={2} title='Equipe responsável pelo cidadão'>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <VincularCidadaoComponentRoot
                  name={path.vinculacao}
                  values={values}
                  formProps={formProps}
                  cnsCidadao={values.cns}
                  cpfCidadao={values.cpf}
                  uuidFicha={values.uuidFicha}
                  faleceu={values.cidadaoFaleceu}
                />
              )}
            </FormSpy>
          </HeadingSection>
          <HeadingSection level={2} title='Residência'>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <VFlow vSpacing={0.5}>
                  {hasCadastrarEditarAldeadoPermission && values.isCidadaoAldeado && (
                    <RadioGroupField
                      label='Localização'
                      name={path.tipoEndereco}
                      options={tipoEnderecoOptions}
                      required
                    />
                  )}
                  {!tipoEnderecoIndigena.includes(values.tipoEndereco) && (
                    <EnderecoFieldGroup
                      name={path.endereco}
                      formProps={formProps}
                      formModelValue={values}
                      renderPaisAreaMicroArea
                      renderTipoLogradouro
                      isCidadaoAldeado={values.isCidadaoAldeado}
                    />
                  )}
                  {hasCadastrarEditarAldeadoPermission && tipoEnderecoIndigena.includes(values.tipoEndereco) && (
                    <EnderecoIndigenaFieldGroup
                      name={path.enderecoIndigena}
                      formProps={formProps}
                      formModelValue={values}
                      isCadastroCidadao
                    />
                  )}
                </VFlow>
              )}
            </FormSpy>
          </HeadingSection>

          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <>
                {values.isCidadaoAldeado && hasCadastrarEditarAldeadoPermission && (
                  <VFlow vSpacing={2}>
                    <HeadingSection level={2} title='RANI - Registro Administrativo de Nascimento Indígena'>
                      <RaniFieldGroup name={path.cidadaoAldeadoInput} />
                    </HeadingSection>

                    <HeadingSection level={2} title='Período de ausência'>
                      <PeriodoAusenciaFieldGroup name={path.cidadaoAldeadoInput} formProps={formProps} />
                    </HeadingSection>
                  </VFlow>
                )}
              </>
            )}
          </FormSpy>

          <HeadingSection level={2} title='Informações complementares'>
            <InformacoesComplementaresFieldGroup name={path.informacoesComplementares} formProps={formProps} />
          </HeadingSection>

          <HeadingSection level={2} title='Compartilhamento de prontuário'>
            <CompartilhamentoProntuarioFieldGroup name={path} />
          </HeadingSection>

          <FormFooter>
            <Button onClick={handleOnCancelarClicked || history.goBack} data-cy='FormFooter.cancelar'>
              Cancelar
            </Button>
            <Button kind='primary' onClick={salvar(formProps)} data-testid='FormFooter.salvar' loading={loading}>
              Salvar
            </Button>
          </FormFooter>
        </VFlow>
      </form>
    )
  }
  return (
    <Form<CidadaoFormModel>
      decorators={[cidadaoCalculator, cidadaoAldeadoDecorator, enderecoDecorator, infoComplementaresCalculator]}
      validate={validateCidadaoForm}
      render={renderForm}
      onSubmit={onHandleSubmit}
      {...props}
    />
  )
}
