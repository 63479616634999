import { Calculation } from 'final-form-calculate'
import { MetaPath } from 'util/metaPath'
import { isObjectDeepFalsy } from 'util/object'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { isPreenchendoRPS } from './evolucoes-odontologicas/periograma/periograma-simplificado/PeriogramaSimplificadoField'
import { PeriodontiaModel } from './evolucoes-odontologicas/periograma/PeriogramaView'

export const createPlanoOdontoCalculations = (periodontia: MetaPath<PeriodontiaModel>): Calculation[] => [
  {
    field: periodontia.periogramaCompleto.dados.absolutePath(),
    updates: {
      [periodontia.periogramaCompleto.observacao.absolutePath()]: (values, allValues: SoapState) => {
        if (!isObjectDeepFalsy(values))
          return allValues?.plano?.odontologia?.periodontia?.periogramaCompleto?.observacao
      },
    },
  },
  {
    field: [
      periodontia.periogramaSimplificado.avaliacaoSextante1.absolutePath(),
      periodontia.periogramaSimplificado.avaliacaoSextante2.absolutePath(),
      periodontia.periogramaSimplificado.avaliacaoSextante3.absolutePath(),
      periodontia.periogramaSimplificado.avaliacaoSextante4.absolutePath(),
      periodontia.periogramaSimplificado.avaliacaoSextante5.absolutePath(),
      periodontia.periogramaSimplificado.avaliacaoSextante6.absolutePath(),
    ],
    updates: {
      [periodontia.periogramaSimplificado.observacao.absolutePath()]: (_, allValues: SoapState) => {
        const {
          avaliacaoSextante1,
          avaliacaoSextante2,
          avaliacaoSextante3,
          avaliacaoSextante4,
          avaliacaoSextante5,
          avaliacaoSextante6,
        } = allValues.plano?.odontologia?.periodontia?.periogramaSimplificado

        if (
          isPreenchendoRPS([
            avaliacaoSextante1,
            avaliacaoSextante2,
            avaliacaoSextante3,
            avaliacaoSextante4,
            avaliacaoSextante5,
            avaliacaoSextante6,
          ])
        ) {
          return allValues.plano.odontologia.periodontia.periogramaSimplificado.observacao
        }
      },
    },
  },
]
