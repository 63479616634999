import { Button, DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { DropdownButton } from 'components/dropdown'
import { confirm } from 'components/modals/confirm'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useState } from 'react'
import { OBSERVACAO_DISABLE_EDIT_AND_REMOVE_ATESTADOS_TOOLTIP_TEXT } from 'view/atendimentos/atendimento-individual/atendimento-observacao/model-atendObservacao'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'

import { grupoCboAtestado } from '../../acessos'
import AtestadoModel, { CidadaoAtestado } from '../components/model-atestado'
import AtestadoEmBrancoFormModal from '../em-branco/AtestadoEmBrancoFormModal'
import { downloadAtestado } from '../impressao/downloadAtestado'
import LicencaMaternidadeFormModal from '../licenca-maternidade/LicencaMaternidadeFormModal'
import { AtestadoImpressaoWrapperModel } from '../model-atestado'
import AtestadoPadraoFormModal from '../padrao/AtestadoPadraoFormModal'
import { RegistroLista } from './AtestadosTable'
import AtestadosVisualizacaoModal from './AtestadosVisualizacaoModal'

export interface AtestadoTableButtonsProps {
  registro: RegistroLista
  cidadao: CidadaoAtestado
  atestadoRecente?: AtestadoModel
  atendimentoId?: ID
  prontuarioId: ID
  update(index: number, value: AtestadoModel): void
  remove(index: number): void
  readOnly: boolean
}

export default function AtestadoTableButtons(props: AtestadoTableButtonsProps) {
  const { registro, cidadao, atestadoRecente, update, remove, atendimentoId, prontuarioId, readOnly } = props

  const { hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })
  const alert = useAlert()

  const hasPermissionImprimirAtestado = hasCboAuth(grupoCboAtestado.imprimir)
  const isAssinadoDigitalmente = registro.assinadoDigitalmente

  const [isModalVisualizacaoOpen, setModalVisualizacaoOpen] = useState(false)
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [isPrintLoading, setIsPrintLoading] = useState(false)

  const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(
    atestadoRecente,
    '',
    OBSERVACAO_DISABLE_EDIT_AND_REMOVE_ATESTADOS_TOOLTIP_TEXT
  )

  const { analytics } = useFirebase()

  const handleExcluir = () => {
    const isLicencaMaternidade = TipoAtestadoEnum.LICENCA_MATERNIDADE === atestadoRecente.tipo
    const titleConfirm = isLicencaMaternidade ? 'Deseja excluir a licença maternidade?' : 'Deseja excluir o atestado?'
    const alertSucess = isLicencaMaternidade
      ? 'Licença maternidade excluída com sucesso'
      : 'Atestado excluído com sucesso'
    return confirm({
      title: titleConfirm,
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        remove(registro.id)
        alert('success', alertSucess)
      },
    })()
  }

  const handlePrint = () => {
    const input: AtestadoImpressaoWrapperModel = {
      id: registro.id,
      atestadoModel: atestadoRecente,
      prontuarioId: prontuarioId,
      atendimentoId: atendimentoId,
      atendimentoProfissionalIniciadoEm: registro.atendimentoProfissional.iniciadoEm,
    }
    if (isAssinadoDigitalmente) {
      analytics.logEvent('download_atestado_digital')
    }
    setIsPrintLoading(true)
    downloadAtestado(input, registro.recente)
      .catch(() => {
        alert('danger', 'Erro ao imprimir atestado.')
      })
      .finally(() => {
        setIsPrintLoading(false)
      })
  }

  const onSubmitUpdate = (value: AtestadoModel) => {
    update(registro.id, value)
  }

  const EditModal = () => {
    if (atestadoRecente.tipo === TipoAtestadoEnum.EM_BRANCO) {
      return (
        <AtestadoEmBrancoFormModal
          onSubmit={onSubmitUpdate}
          handleModalClose={() => setEditModalOpen(false)}
          cidadaoId={cidadao.id}
          initialValues={atestadoRecente}
          atendimentoId={atendimentoId}
          prontuarioId={prontuarioId}
          dataAtendimento={registro.atendimentoProfissional.iniciadoEm}
        />
      )
    }

    if (atestadoRecente.tipo === TipoAtestadoEnum.LICENCA_MATERNIDADE) {
      return (
        <LicencaMaternidadeFormModal
          onSubmit={onSubmitUpdate}
          handleModalClose={() => setEditModalOpen(false)}
          cidadao={cidadao}
          initialValues={atestadoRecente}
          atendimentoId={atendimentoId}
          prontuarioId={prontuarioId}
          dataAtendimento={registro.atendimentoProfissional.iniciadoEm}
        />
      )
    }

    if (atestadoRecente.tipo === TipoAtestadoEnum.PADRAO) {
      return (
        <AtestadoPadraoFormModal
          onSubmit={onSubmitUpdate}
          handleModalClose={() => setEditModalOpen(false)}
          cidadao={cidadao}
          initialValues={atestadoRecente}
          atendimentoId={atendimentoId}
          prontuarioId={prontuarioId}
          dataAtendimento={registro.atendimentoProfissional.iniciadoEm}
        />
      )
    }
  }

  return (
    <>
      <HFlow justifyContent='flex-end' hSpacing={0}>
        <Tooltip text='Visualizar'>
          <Button size='small' skin='ghost' onClick={() => setModalVisualizacaoOpen(true)}>
            <Icon icon='zoomOutline' />
          </Button>
        </Tooltip>
        {hasPermissionImprimirAtestado && (
          <Tooltip text='Imprimir'>
            <Button size='small' skin='ghost' onClick={handlePrint} loading={isPrintLoading}>
              <Icon icon='printerOutline' />
            </Button>
          </Tooltip>
        )}

        {!readOnly && (
          <Tooltip
            text={
              isAssinadoDigitalmente && registro.recente
                ? 'Não é possível editar ou excluir atestados que foram gerados digitalmente e enviados ao cidadão.'
                : tooltipText
            }
          >
            <DropdownButton disabled={!registro.recente || isEditable || isAssinadoDigitalmente}>
              <DropdownItem
                onClick={() => {
                  setEditModalOpen(true)
                }}
              >
                Editar
              </DropdownItem>
              <DropdownItem onClick={handleExcluir} type='danger'>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Icon icon='trashOutline' />
                  Excluir
                </HFlow>
              </DropdownItem>
            </DropdownButton>
          </Tooltip>
        )}
      </HFlow>

      {isModalVisualizacaoOpen && (
        <AtestadosVisualizacaoModal
          registro={registro}
          descricaoAtestadoRecente={atestadoRecente.descricao}
          tipoAtestadoRecente={atestadoRecente.tipo}
          handlePrint={handlePrint}
          handleModalClose={() => setModalVisualizacaoOpen(false)}
          nomeCidadao={cidadao.nome}
          nomeSocialCidadao={cidadao.nomeSocial}
        />
      )}

      {isEditModalOpen && <EditModal />}
    </>
  )
}
