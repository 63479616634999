import { LoadingIndicator } from 'components/loading'
import { useConfiguracoesQuery } from 'graphql/hooks.generated'
import { ConfiguracoesQuery } from 'graphql/types.generated'
import React, { useCallback, useContext, useMemo } from 'react'

export type ConfiguracoesModel = Pick<
  ConfiguracoesQuery['info'],
  | 'smtpConfigurado'
  | 'internetHabilitada'
  | 'linkInstalacaoConfigurado'
  | 'govBREnabled'
  | 'versao'
  | 'buscaCidadaoPorPropriedadesEnabled'
  | 'oldPecConnected'
  | 'firebaseEnabled'
  | 'ativado'
  | 'treinamento'
  | 'isPrescricaoDigitalEnabled'
  | 'rocksdbEnabled'
  | 'tipoInstalacao'
  | 'cadastroDomiciliarViaCdsEnabled'
>

interface ConfiguracoesContextModel {
  configuracoes: ConfiguracoesModel
  update(): Promise<ConfiguracoesModel>
}

interface ConfiguracoesContextProps {
  children: React.ReactNode
}

const configuracoesFallback: ConfiguracoesModel = {
  smtpConfigurado: false,
  govBREnabled: false,
  internetHabilitada: false,
  linkInstalacaoConfigurado: false,
  versao: null,
  buscaCidadaoPorPropriedadesEnabled: false,
  cadastroDomiciliarViaCdsEnabled: false,
  oldPecConnected: false,
  firebaseEnabled: false,
  ativado: false,
  treinamento: false,
  isPrescricaoDigitalEnabled: false,
  rocksdbEnabled: false,
  tipoInstalacao: null,
}

const ConfiguracoesContext = React.createContext<ConfiguracoesContextModel>({
  configuracoes: configuracoesFallback,
  update: () => Promise.resolve(configuracoesFallback),
})

export function ConfiguracoesProvider(props: ConfiguracoesContextProps) {
  const { children } = props
  const { data, loading, refetch } = useConfiguracoesQuery({
    fetchPolicy: 'cache-first',
  })

  const update = useCallback(() => refetch().then((result) => result?.data?.info), [refetch])

  const configuracoes = data?.info
  const value = useMemo(
    () => ({
      configuracoes,
      update,
    }),
    [configuracoes, update]
  )

  return (
    <ConfiguracoesContext.Provider value={value}>
      {loading || !configuracoes ? <LoadingIndicator /> : children}
    </ConfiguracoesContext.Provider>
  )
}

export const useConfiguracoes = () => useContext(ConfiguracoesContext)
