import { orange } from 'bold-ui/lib/styles/colors'

import {
  getEmptyReferenceArea,
  getExpectedReferenceArea,
  getNotExpectedReferenceArea,
  getUnderExpectedReferenceArea,
} from '../converter-graficoGanhoPeso'

const OBESIDADE_P10: ReadonlyArray<number> = [
  -5.23,
  -5.27,
  -5.29,
  -5.3,
  -5.28,
  -5.24,
  -5.17,
  -5.08,
  -4.97,
  -4.83,
  -4.67,
  -4.49,
  -4.28,
  -4.06,
  -3.83,
  -3.58,
  -3.32,
  -3.06,
  -2.78,
  -2.51,
  -2.23,
  -1.95,
  -1.68,
  -1.39,
  -1.12,
  -0.83,
  -0.55,
  -0.27,
  0.01,
  0.3,
  0.58,
]

const OBESIDADE_P27: ReadonlyArray<number> = [
  -1.77,
  -1.74,
  -1.7,
  -1.65,
  -1.59,
  -1.51,
  -1.42,
  -1.3,
  -1.16,
  -1,
  -0.81,
  -0.6,
  -0.37,
  -0.11,
  0.16,
  0.45,
  0.75,
  1.06,
  1.38,
  1.7,
  2.02,
  2.34,
  2.67,
  2.99,
  3.31,
  3.64,
  3.96,
  4.29,
  4.61,
  4.94,
  5.27,
]

const OBESIDADE_P38: ReadonlyArray<number> = [
  -0.75,
  -0.66,
  -0.56,
  -0.46,
  -0.35,
  -0.23,
  -0.09,
  0.07,
  0.25,
  0.45,
  0.66,
  0.9,
  1.16,
  1.44,
  1.73,
  2.04,
  2.36,
  2.69,
  3.02,
  3.35,
  3.69,
  4.03,
  4.36,
  4.7,
  5.05,
  5.39,
  5.74,
  6.09,
  6.44,
  6.79,
  7.15,
]

const OBESIDADE_P50: ReadonlyArray<number> = [
  0.01,
  0.17,
  0.34,
  0.51,
  0.68,
  0.87,
  1.07,
  1.28,
  1.51,
  1.75,
  2.01,
  2.29,
  2.58,
  2.89,
  3.2,
  3.53,
  3.86,
  4.2,
  4.54,
  4.88,
  5.23,
  5.58,
  5.93,
  6.28,
  6.64,
  7.0,
  7.37,
  7.75,
  8.13,
  8.52,
  8.91,
]

const OBESIDADE_P90: ReadonlyArray<number> = [
  5.1,
  5.44,
  5.77,
  6.09,
  6.42,
  6.75,
  7.09,
  7.44,
  7.79,
  8.17,
  8.56,
  8.96,
  9.38,
  9.8,
  10.24,
  10.68,
  11.13,
  11.58,
  12.03,
  12.48,
  12.94,
  13.4,
  13.87,
  14.34,
  14.83,
  15.33,
  15.84,
  16.36,
  16.9,
  17.44,
  17.99,
]

const REFERENCE_AREA_P90_OBESIDADE = getNotExpectedReferenceArea(OBESIDADE_P90, 'P90', orange)
const REFERENCE_AREA_P50_OBESIDADE = getNotExpectedReferenceArea(OBESIDADE_P50, 'P50', orange)

const REFERENCE_AREA_P38_OBESIDADE = getExpectedReferenceArea(OBESIDADE_P38, 'P38', 'P27', orange)
const REFERENCE_AREA_ABAIXO_P27_OBESIDADE = getUnderExpectedReferenceArea(OBESIDADE_P27, orange)

const REFERENCE_AREA_P10_OBESIDADE = getNotExpectedReferenceArea(OBESIDADE_P10, 'P10', orange)
const REFERENCE_AREA_ABAIXO_P10_OBESIDADE = getEmptyReferenceArea(OBESIDADE_P10)

export const REFERENCE_AREAS_OBESIDADE = [
  REFERENCE_AREA_ABAIXO_P10_OBESIDADE,
  REFERENCE_AREA_P10_OBESIDADE,
  REFERENCE_AREA_ABAIXO_P27_OBESIDADE,
  REFERENCE_AREA_P38_OBESIDADE,
  REFERENCE_AREA_P50_OBESIDADE,
  REFERENCE_AREA_P90_OBESIDADE,
]
