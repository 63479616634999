import useSession, { UseSessionOptions } from 'components/auth/useSession'
import { AcessoCbo, LotacaoOuEstagio, SessaoDataFragment, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { Permission } from 'types/Permissions'

import { isLotacaoOrEstagio } from './useSessionUtils'

type Profissional = SessaoDataFragment['profissional']

interface AcessoLotacaoOrEstagioResult
  extends Pick<ReturnType<typeof useSession>, 'hasAuthorization' | 'refresh' | 'tipoEstabelecimento'> {
  acesso: LotacaoOuEstagio
  profissional: Profissional
  tipoEstabelecimento: TipoEstabelecimentoEnum
  hasAuthorization: (permission: Permission) => boolean
  hasSupervisorAuthorization: (permission: Permission) => boolean
  hasCboAuth: (acessos: AcessoCbo[]) => boolean
}

export const useAcessoLotacaoOrEstagio = (options: UseSessionOptions = {}): AcessoLotacaoOrEstagioResult => {
  const {
    data: { acesso, profissional },
    hasAuthorization,
    hasSupervisorAuthorization,
    refresh,
    hasCboAuth,
  } = useSession(options)

  return acesso && isLotacaoOrEstagio(acesso)
    ? {
        acesso,
        profissional,
        hasAuthorization,
        hasSupervisorAuthorization,
        hasCboAuth,
        tipoEstabelecimento: acesso.unidadeSaude.tipoEstabelecimento,
        refresh,
      }
    : null
}
