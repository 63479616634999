/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, PagedTable, PagedTableProps, Text, VFlow } from 'bold-ui'
import { Cns, Cpf, Telefone } from 'components/label'
import { VisualizarPaginaButton } from 'components/VisualizarPaginaButton'
import { differenceInDays } from 'date-fns'
import { StatusVacinacaoEnum, TipoVisualizacaoBuscaAtivaVacinacaoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { humanizeDate } from 'util/date/humanize-date'

import { BuscaAtivaVacinacao } from '../model-buscaAtivaVacinacao'
import { vacinasMap } from '../utils-buscaAtivaVacinacao'
import { items } from './StatusDoseImunoSelectField'

const colunasCidadaosEncontrados = (
  statusVacinacao: StatusVacinacaoEnum,
  tipoVisualizacao: TipoVisualizacaoBuscaAtivaVacinacaoEnum,
  now: Date
) => [
  {
    name: 'cidadao',
    header: 'Cidadão',
    render: (row: BuscaAtivaVacinacao) => (
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'>{row.nomeSocialOuNomeCidadao.titleCase()}</Text>
        {row.cpfCidadao ? (
          <HFlow hSpacing={0.25} alignItems='center'>
            <Text fontWeight='bold'>CPF</Text>
            <Cpf value={row.cpfCidadao} />
          </HFlow>
        ) : row.cnsCidadao ? (
          <HFlow hSpacing={0.25} alignItems='center'>
            <Text fontWeight='bold'>CNS</Text>
            <Cns value={row.cnsCidadao} />
          </HFlow>
        ) : (
          <Text>Cidadão sem CNS e CPF</Text>
        )}
        {row.telefoneResidencial && <Telefone value={row.telefoneResidencial} />}
        {row.telefoneCelular && <Telefone value={row.telefoneCelular} />}
        {row.telefoneContato && <Telefone value={row.telefoneContato} />}
      </VFlow>
    ),
    style: css`
      width: 20%;
      padding: 0.5rem 1rem;
    `,
    sortable: true,
  },
  {
    name: 'idade',
    header: 'Idade',
    render: (row: BuscaAtivaVacinacao) => humanizeDate(row.dataNascimentoCidadao),
    style: css`
      width: 15%;
      padding: 0.5rem 1rem;
    `,
    sortable: true,
  },
  {
    name: 'endereco',
    header: 'Endereço',
    render: (row: BuscaAtivaVacinacao) => row.endereco.titleCase(),
    style: css`
      padding: 0.5rem 1rem;
      width: 25%;
    `,
  },
  {
    name: 'status',
    header: `${items[statusVacinacao]?.descricao ?? ''} (dose)`,
    render: (row: BuscaAtivaVacinacao) => {
      const vacinas = vacinasMap(row)[statusVacinacao] ?? null
      const isTipoCovid = tipoVisualizacao === TipoVisualizacaoBuscaAtivaVacinacaoEnum.COVID_19

      return (
        <div css={isTipoCovid ? styles.breaklineSeparator : styles.commaSeparator}>
          {vacinas?.map((vacina, index) => {
            const isLastVacina = index === vacinas.length - 1
            const diasAplicacao = differenceInDays(now, new Date(vacina.dataAplicacaoVacina))
            return (
              <Text key={`${vacina.nome}-${vacina.siglaDose}`}>
                <Text fontWeight='bold'>{vacina.nome}</Text> ({vacina.siglaDose})
                {isLastVacina && isTipoCovid && ` aplicada há ${`dia`.pluralizeAndConcatValue(diasAplicacao)}`}
              </Text>
            )
          })}
        </div>
      )
    },
    style: css`
      width: 40%;
      padding: 0.5rem 1rem;
    `,
  },
  {
    name: 'visualizarCidadao',
    header: '',
    render: (row: BuscaAtivaVacinacao) => (
      <VisualizarPaginaButton path={`/cidadao/${row.cidadaoId}/informacoes`} tooltipText='Visualizar cidadão' />
    ),
    style: css`
      width: 4rem;
    `,
  },
]

interface BuscaAtivaVacinacaoCidadaosEncontradosTableProps
  extends Omit<PagedTableProps<BuscaAtivaVacinacao>, 'columns'> {
  statusVacinacao: StatusVacinacaoEnum
  tipoVisualizacao: TipoVisualizacaoBuscaAtivaVacinacaoEnum
}

export default function BuscaAtivaVacinacaoCidadaosEncontradosTable({
  statusVacinacao,
  tipoVisualizacao,
  ...rest
}: BuscaAtivaVacinacaoCidadaosEncontradosTableProps) {
  const { getServerTimeNow } = useServerTime()

  return (
    <PagedTable<BuscaAtivaVacinacao>
      columns={colunasCidadaosEncontrados(statusVacinacao, tipoVisualizacao, getServerTimeNow())}
      {...rest}
    />
  )
}
const styles = {
  commaSeparator: css`
    *:not(:last-child):after {
      content: ', ';
    }
  `,
  breaklineSeparator: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  `,
}
