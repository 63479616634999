import { HFlow, Text } from 'bold-ui'
import { SelectField } from 'components/form/final-form'
import { InepSelectFieldDocument } from 'graphql/hooks.generated'
import { InepSelectFieldQuery, InepSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type InepSelectFieldModel = InepSelectFieldQuery['ineps']['content'][0]

export interface InepSelectFieldProps extends AsyncSelectFieldProps<InepSelectFieldModel> {}

export function InepSelectField(props: InepSelectFieldProps) {
  const { ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    InepSelectFieldModel,
    InepSelectFieldQuery,
    InepSelectFieldQueryVariables
  >({
    query: InepSelectFieldDocument,
    extractItems: (data) => data?.ineps && data?.ineps?.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nomeEstabelecimento'],
        },
      },
    }),
  })

  const itemToString = (option: InepSelectFieldModel) => option && option.nomeEstabelecimento

  const renderItem = (item: InepSelectFieldModel) => (
    <>
      <Text fontWeight='bold'>{item.nomeEstabelecimento}</Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>INEP:</Text>
        {item.dsInep}
      </HFlow>
    </>
  )

  return (
    <SelectField<InepSelectFieldModel> itemToString={itemToString} renderItem={renderItem} {...selectProps} {...rest} />
  )
}
