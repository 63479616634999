import { ParteBucalEnum } from 'graphql/types.generated'
import { compact } from 'lodash'
import { isObjectDeepFalsy } from 'util/object'
import { createValidator, maxLength, msg, range, required } from 'util/validation'

import {
  getDentesFromPeriogramaCompleto,
  getPeriogramaCompletoCondicaoConfig,
  PeriogramaCompletoCondicao,
  PeriogramaCompletoModel,
  PeriogramaCompletoTableModel,
  possuiPeriogramaLesaoDeFurca,
} from '../model'

const rules = {
  [PeriogramaCompletoCondicao.PROF_SONDAGEM]: [range(0, 15)],
  [PeriogramaCompletoCondicao.RECESSAO]: [range(-10, 15)],
  [PeriogramaCompletoCondicao.MOBILIDADE]: [range(0, 3)],
  [PeriogramaCompletoCondicao.LESAO_FURCA]: [range(0, 3)],
}

const dentes = getDentesFromPeriogramaCompleto()

const periogramaCompletoDadosValidator = createValidator<PeriogramaCompletoTableModel>({}, (values) => {
  if (!isObjectDeepFalsy(values)) {
    return Object.fromEntries(
      dentes.map(([dente, faces]) => [
        dente,
        {
          values: Object.fromEntries(
            faces.map(([face, condicoes]) => [
              face,
              Object.fromEntries(
                compact(
                  condicoes.map((condicao) => {
                    const inputValue = values?.[dente]?.values?.[face]?.[condicao] as string | undefined

                    return !values?.[dente]?.ausente && [condicao, validate(inputValue, condicao, dente)]
                  })
                )
              ),
            ])
          ),
        },
      ])
    )
  }
})

const validate = (value: string, condicao: PeriogramaCompletoCondicao, dente?: ParteBucalEnum) => {
  if (
    (condicao !== PeriogramaCompletoCondicao.LESAO_FURCA ||
      (condicao === PeriogramaCompletoCondicao.LESAO_FURCA && possuiPeriogramaLesaoDeFurca(dente))) &&
    rules[condicao]
  ) {
    const { mask } = getPeriogramaCompletoCondicaoConfig(condicao, dente)

    return (
      required(value) ||
      validateMask(value, mask) ||
      rules[condicao].map(
        (validateRule: (value: any) => string) => compact(value?.split('/')?.map((part) => validateRule(part)))[0]
      )
    )
  }
}

const validateMask = (value: string, mask?: RegExp) => {
  if (mask) {
    return !value.match(mask) && msg('invalid', value)
  }
}

export const periogramaCompletoValidator = createValidator<PeriogramaCompletoModel>({
  dados: periogramaCompletoDadosValidator,
  observacao: [maxLength(400)],
})
