import { modal, Text } from 'bold-ui'
import { confirm } from 'components/modals/confirm'
import { ModalBody } from 'components/modals/ModalBody'
import { FormApi } from 'final-form'
import {
  useEsquemaCalendarioVacinalQuery,
  useFaixaEtariaVacinacaoQuery,
  useLoteHasCadastroQuery,
  useRegistrosAplicadosVacinacaoQuery,
  useRegistrosDoseVacinacaoLazyQuery,
} from 'graphql/hooks.generated'
import { GrupoAlvoVacinacaoEnum, RegistroAplicado, SexoEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { groupBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import {
  convertEsquemaCalendarioVacinalQueryToProps,
  createListWithSaveRegistry,
  createListWithUpdatedRegistries,
  filterDeleteRegistriesList,
  updateDosesCalendarioVacinalProps,
  updateDosesOutrosImunosProps,
} from 'view/atendimentos/atendimento-vacinacao/condicoes/converter'
import { DosesAnterioresVacinaModal } from 'view/atendimentos/atendimento-vacinacao/DosesAnterioresVacinaModal'
import {
  AtendimentoVacinacaoState,
  CondicoesFormModel,
  ImunobiologicoModel,
  isBcg,
  meta,
  RegistroVacinacaoFormModel,
  StatusDoseEnum,
} from 'view/atendimentos/atendimento-vacinacao/model'
import { SubModalVacinacaoOptions, VacinacaoModal } from 'view/atendimentos/atendimento-vacinacao/VacinacaoModal'
import {
  checkErrors,
  checkUpdateAprazamentoAutomatico,
  submitImunobiologicoValidate,
  ValidateRegistroVacinaError,
} from 'view/atendimentos/atendimento-vacinacao/validator'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { VacinacaoPanel } from '../components/vacinacao/VacinacaoPanel'
import { EstadoBotaoCalendario } from './VacinacaoView'

interface VacinacaoCalendarioViewProps {
  cidadao: CidadaoAtendimento
  condicoesVacinacao: CondicoesFormModel
  dataAtendimento: Instant
  estadoBotaoCalendario: EstadoBotaoCalendario
  grupoAlvoCidadao: GrupoAlvoVacinacaoEnum
  prontuarioId: string
  registros: RegistroVacinacaoFormModel[]
  formVacinacao: FormApi<AtendimentoVacinacaoState>
  mostrarCalendarioCompleto: boolean
  setMostrarCalendarioCompleto: (value: boolean) => void
  isAcompanhamentoVacinal: boolean
  openDosesAnterioresModal: boolean
  closeDosesAnterioresModal: () => void
  sexoIdentidadeGenero: SexoEnum
}

export interface RegistrosAplicadosDto {
  imunobiologicoId: ID
  imunobiologicoSigla: string
  imunobiologicoNome: string
  doseId: ID
  doseNomeApresentacao: string
  doseNome: string
  doseSigla: string
  dataAprazamento?: LocalDate
  dataAplicacao?: LocalDate
  dataRegistroAprazamento?: Instant
  quantidadeAplicacoes: number
  estrategiaId?: ID
}

export interface InfoCardModalVacinacao {
  imunobiologico: ImunobiologicoModel
  doseId: ID
  doseNome: string
  idadeRecomendada: string
  registradoAtendimento: boolean
  status: StatusDoseEnum
  hasRegistro: boolean
}

export interface RegistroVacinacaoFormInput {
  formValues: RegistroVacinacaoFormModel
  onSuccess(): void
}

export function VacinacaoCalendarioView(props: VacinacaoCalendarioViewProps) {
  const {
    cidadao,
    dataAtendimento,
    condicoesVacinacao,
    estadoBotaoCalendario,
    grupoAlvoCidadao,
    prontuarioId,
    formVacinacao,
    mostrarCalendarioCompleto,
    setMostrarCalendarioCompleto,
    isAcompanhamentoVacinal,
    openDosesAnterioresModal,
    closeDosesAnterioresModal,
    sexoIdentidadeGenero,
  } = props

  const { isRegistroTardio } = useAtendimentoContext() || {}

  const [isVacinacaoModalOpen, setIsVacinacaoModalOpen] = useState(false)
  const [isPreviousRegistryModalOpen, setIsPreviousRegistryModalOpen] = useState(false)
  const [
    registrosDoseLazyQuery,
    { data: registrosDoseVacinacaoQueryResult, loading },
  ] = useRegistrosDoseVacinacaoLazyQuery()
  const [infoCardModal, setInfoCardModal] = useState<InfoCardModalVacinacao>(null)
  const [registroEmEdicao, setRegistroEmEdicao] = useState<RegistroVacinacaoFormModel>(null)
  const { refetch: refetchFaixaEtaria } = useFaixaEtariaVacinacaoQuery({ skip: true })
  const { refetch: refetchLoteHasCadastro } = useLoteHasCadastroQuery({ skip: true })

  const { data } = useEsquemaCalendarioVacinalQuery({
    variables: {
      input: {
        sexo: sexoIdentidadeGenero,
        grupoAlvo: grupoAlvoCidadao,
        gestante: condicoesVacinacao.gestante,
        dataNascimento: cidadao.dataNascimento,
        dataAtendimento: dataAtendimento,
      },
    },
    fetchPolicy: 'network-only',
  })

  const {
    data: { registrosAplicadosVacinacao },
  } = useRegistrosAplicadosVacinacaoQuery({
    variables: {
      input: {
        prontuarioId: prontuarioId,
        cidadaoId: cidadao.id,
        cpf: cidadao.cpf,
      },
    },
  })

  return useMemo(() => {
    const handleOnDoseClicked = (info: InfoCardModalVacinacao) => {
      setInfoCardModal(info)
      if (!info.hasRegistro && isAcompanhamentoVacinal) {
        setIsVacinacaoModalOpen(false)
        setIsPreviousRegistryModalOpen(true)
      } else {
        setIsVacinacaoModalOpen(true)
        setIsPreviousRegistryModalOpen(false)
      }
    }

    const handleVacinacaoModalClose = () => {
      setInfoCardModal(null)
      setRegistroEmEdicao(null)
      setIsVacinacaoModalOpen(false)
    }

    const handleOnSubmit = (formValues: RegistroVacinacaoFormModel) => {
      let registrosAtualizados: RegistroVacinacaoFormModel[] = null

      // Caso exista um id unico o registro esta sendo editado
      if (formValues.idUnicoRegistro) {
        registrosAtualizados = createListWithUpdatedRegistries(formValues, props.registros)
        setRegistroEmEdicao(null)

        // Criacao de registro
      } else {
        setInfoCardModal({ ...infoCardModal, registradoAtendimento: true })
        registrosAtualizados = createListWithSaveRegistry(formValues, props.registros)
      }

      // Atualiza de uma so vez os changes dentro da funcao
      formVacinacao.batch(() => {
        formVacinacao.change(meta.registroVacinacao.absolutePath(), registrosAtualizados)

        isBcg(formValues.imunobiologicoId) &&
          formVacinacao.change(
            meta.condicoesVacinacao.comunicanteHanseniase.absolutePath(),
            formValues.isComunicanteHanseniase
          )
      })
    }

    const validateModalOnSubmit = async (input: RegistroVacinacaoFormInput): Promise<void> => {
      const handleSubmit = () => {
        if (input.formValues.dataAprazamentoProxDose) {
          checkUpdateAprazamentoAutomatico(input.formValues, input.onSuccess, handleOnSubmit, props.registros)
        } else {
          handleOnSubmit(input.formValues)
          input.onSuccess()
        }
      }

      const error = await submitImunobiologicoValidate({
        ...input.formValues,
        cidadao: cidadao,
        imunobiologicoId: input.formValues.imunobiologicoId || input.formValues.outrosImunosImunobiologico.id,
        dataAtendimento,
        refetchFaixaEtaria,
        loteDataValidade: input.formValues.loteImunobiologico?.validade || input.formValues.loteDataValidade,
        doseId: input.formValues.doseId || input.formValues.outrosImunosDose.id,
        registrosAplicados: registrosAplicadosVacinacao as RegistroAplicado[],
        registrosAplicadosAtendimento: props.registros,
        fabricanteNome: input.formValues.loteFabricante?.nome,
        handleOnSubmit: handleSubmit,
        refetchLoteHasCadastro,
        status: infoCardModal?.status,
      })

      if (error.impeditivos.length > 0 || error.naoImpeditivos.length > 0) {
        checkErrors(error)
      } else {
        handleSubmit()
      }
    }

    const handleOnDelete = (
      idUnicoRegistro: ID,
      tipoModal: SubModalVacinacaoOptions,
      idUnicoRegistroAprazamentoProxDose?: ID,
      idUnicoRegistroAplicacaoProxDose?: ID
    ) => {
      const labelTipoRegistro =
        tipoModal === 'APLICACAO'
          ? 'a aplicação'
          : tipoModal === 'ANTERIOR'
          ? 'a transcrição de caderneta'
          : 'o aprazamento'
      return confirm({
        title: `Deseja excluir ${labelTipoRegistro}?`,
        type: 'danger',
        confirmLabel: 'Excluir',
        onConfirm: () => {
          let registrosAtualizados: RegistroVacinacaoFormModel[] = []

          // Deletar tambem o aprazamento da proxima dose caso exista
          if (idUnicoRegistroAprazamentoProxDose) {
            registrosAtualizados = filterDeleteRegistriesList(
              props.registros,
              idUnicoRegistro,
              idUnicoRegistroAprazamentoProxDose
            )

            // Atualizar a aplicacao caso exista um aprazamento prox dose vinculado
          } else if (idUnicoRegistroAplicacaoProxDose) {
            const aplicacaoPai = {
              ...props.registros.filter((item) => item.idUnicoRegistro === idUnicoRegistroAplicacaoProxDose)[0],
            }

            aplicacaoPai.aprazamentoProxDoseLink = undefined
            aplicacaoPai.dataAprazamentoProxDose = undefined

            registrosAtualizados = [
              ...filterDeleteRegistriesList(props.registros, idUnicoRegistro, idUnicoRegistroAplicacaoProxDose),
              aplicacaoPai,
            ]
          } else if (tipoModal === 'APRAZAMENTO') {
            // Exceção para deleção de um aprazamento manual que não tem idUnicoRegistroAplicacaoProxDose (obtem a aplicacaoPai sem um link)
            const aplicacaoAtual = props.registros.find((item) => item.idUnicoRegistro === idUnicoRegistro)
            const aplicacaoPai = props.registros.find(
              (item) =>
                item.imunobiologicoId === aplicacaoAtual.imunobiologicoId &&
                !isUndefinedOrNull(item.proximaDoseId) &&
                item.proximaDoseId === aplicacaoAtual.doseId
            )
            // Remove o link não gerado pela aprazamento manual
            if (!isUndefinedOrNull(aplicacaoPai)) {
              aplicacaoPai.aprazamentoProxDoseLink = undefined
              aplicacaoPai.dataAprazamentoProxDose = undefined

              registrosAtualizados = [
                ...filterDeleteRegistriesList(props.registros, idUnicoRegistro, aplicacaoPai.idUnicoRegistro),
                aplicacaoPai,
              ]
            } else {
              // Deletar normalmente
              registrosAtualizados = filterDeleteRegistriesList(props.registros, idUnicoRegistro)
            }
          } else {
            // Deletar normalmente
            registrosAtualizados = filterDeleteRegistriesList(props.registros, idUnicoRegistro)
          }

          formVacinacao.change(meta.registroVacinacao.absolutePath(), registrosAtualizados)

          formVacinacao.change(
            meta.condicoesVacinacao.comunicanteHanseniase.absolutePath(),
            registrosAtualizados.some((registro) => registro.isComunicanteHanseniase)
          )

          handleVacinacaoModalClose()
        },
        depthLevel: 5,
      })()
    }

    const calendario = groupBy(data.esquemaCalendarioVacinal, 'imunobiologico.id')

    const calendarioVacinalProps = convertEsquemaCalendarioVacinalQueryToProps(
      data,
      cidadao,
      dataAtendimento,
      prontuarioId,
      calendario,
      registrosAplicadosVacinacao
    )

    const clonedDoses = JSON.parse(JSON.stringify(calendarioVacinalProps))

    const calendarioDosesProps = updateDosesCalendarioVacinalProps(clonedDoses.abaCalendario, props.registros)
    const outrasDosesProps = updateDosesOutrosImunosProps(
      clonedDoses.abaOutros,
      props.registros,
      cidadao,
      prontuarioId,
      calendario
    )

    return (
      <>
        {
          <VacinacaoModal
            handleModalClose={handleVacinacaoModalClose}
            registrosDoseQueryResult={registrosDoseVacinacaoQueryResult}
            modalOpen={isVacinacaoModalOpen}
            handleOnSubmit={validateModalOnSubmit}
            setIsVacinacaoModalOpen={setIsVacinacaoModalOpen}
            loading={loading}
            infoCardModal={infoCardModal}
            registrosModificados={props.registros}
            handleOnDelete={handleOnDelete}
            setRegistroEmEdicao={setRegistroEmEdicao}
            registroEmEdicao={registroEmEdicao}
            condicoesVacinacao={condicoesVacinacao}
            dataAtendimento={dataAtendimento}
            dataNascimento={cidadao.dataNascimento}
            registrosAplicados={registrosAplicadosVacinacao as RegistroAplicado[]}
            isAcompanhamentoVacinal={isAcompanhamentoVacinal}
            setIsPreviousRegistryModalOpen={setIsPreviousRegistryModalOpen}
            isPreviousRegistryModalOpen={isPreviousRegistryModalOpen}
          />
        }

        {openDosesAnterioresModal && (
          <DosesAnterioresVacinaModal
            dataAtendimento={dataAtendimento}
            dataNascimento={cidadao.dataNascimento}
            handleModalClose={closeDosesAnterioresModal}
            handleOnSubmit={validateModalOnSubmit}
            modalOpen={openDosesAnterioresModal}
            outrosImunobiologicos
            initialValues={
              {
                outrosImunosImunobiologico: null,
                outrosImunosDose: null,
                dataAplicacao: null,
                loteNome: null,
                loteFabricante: null,
                observacoes: null,
              } as RegistroVacinacaoFormModel
            }
          />
        )}

        {estadoBotaoCalendario === 'CALENDARIO_VACINAL' ? (
          <VacinacaoPanel
            imunobiologicos={calendarioDosesProps}
            handleOnDoseClicked={handleOnDoseClicked}
            registrosDoseLazyQuery={registrosDoseLazyQuery}
            mostrarCalendarioCompleto={mostrarCalendarioCompleto}
            setMostrarCalendarioCompleto={setMostrarCalendarioCompleto}
            isAcompanhamentoVacinal={isAcompanhamentoVacinal}
            readOnly={isRegistroTardio}
          />
        ) : (
          <VacinacaoPanel
            imunobiologicos={outrasDosesProps}
            outrosImunobiologicos
            handleOnDoseClicked={handleOnDoseClicked}
            registrosDoseLazyQuery={registrosDoseLazyQuery}
            handleOnSubmit={validateModalOnSubmit}
            dataAtendimento={dataAtendimento}
            dataNascimento={cidadao.dataNascimento}
            sexo={cidadao.sexo}
            gestante={condicoesVacinacao.gestante}
            isAcompanhamentoVacinal={isAcompanhamentoVacinal}
            readOnly={isRegistroTardio}
          />
        )}
      </>
    )
  }, [
    data,
    cidadao,
    dataAtendimento,
    prontuarioId,
    registrosAplicadosVacinacao,
    props.registros,
    registrosDoseVacinacaoQueryResult,
    isVacinacaoModalOpen,
    loading,
    infoCardModal,
    registroEmEdicao,
    condicoesVacinacao,
    isRegistroTardio,
    isAcompanhamentoVacinal,
    closeDosesAnterioresModal,
    openDosesAnterioresModal,
    estadoBotaoCalendario,
    registrosDoseLazyQuery,
    mostrarCalendarioCompleto,
    setMostrarCalendarioCompleto,
    formVacinacao,
    refetchFaixaEtaria,
    refetchLoteHasCadastro,
    isPreviousRegistryModalOpen,
  ])
}

export const createVacinacaoErrorModal = (error: ValidateRegistroVacinaError, submitModal?: () => void) => {
  modal({
    size: 'small',
    render: () => (
      <ModalBody title={error.title} icon={error.icon} type={error.type}>
        <Text dangerouslySetInnerHTML={{ __html: error.subTitle }} />
      </ModalBody>
    ),
    actions: submitModal
      ? [{ label: 'Não' }, { label: 'Sim', kind: 'primary', onClick: () => submitModal() }]
      : error.actions,
    depthLevel: 5,
    closeOnBackdropClick: false,
  })()
}
