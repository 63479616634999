/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { DateField, ErrorField, HorarioAgendaSelectField, TextAreaField } from 'components/form'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { addMonths, parseISO } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { MetaPath } from 'util/metaPath'
import {
  CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER,
  CuidadoCompartilhadoModel,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoSolicitanteFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { getLotacoesConsultaCompartilhadaCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import { EnviarGarantiaAcessoCuidadoCompartilhadoField } from './EnviarGarantiaAcessoCuidadoCompartilhadoField'

interface AgendarCunsultaPresencialCuidadoCompartilhadoPanelProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel>
  cuidadoCompartilhado: CuidadoCompartilhadoModel
}

export const AgendarCunsultaPresencialCuidadoCompartilhadoPanel = (
  props: AgendarCunsultaPresencialCuidadoCompartilhadoPanelProps
) => {
  const { path, cuidadoCompartilhado } = props

  const { getServerTimeNow } = useServerTime()
  const { acesso: lotacao } = useAcessoLotacaoOrEstagio()
  const serverTime = getServerTimeNow()

  const {
    input: { value: agendarCuidadoValue },
  } = usePecField({ name: path.agendamento, subscription: { value: true } })

  const { lotacaoResponsavel } = getLotacoesConsultaCompartilhadaCuidadoCompartilhado(lotacao.id, cuidadoCompartilhado)

  const isProfissionalExecutante = cuidadoCompartilhado.lotacaoExecutanteAtual.id === lotacaoResponsavel.id

  return (
    <VFlow>
      <ErrorField name={FORM_ERROR} inline={false} type='alert' />
      <ErrorField name={path.agendamento.consultaComCidadao} inline={false} type='alert' ignoreObjectError />
      <Grid>
        <Cell size={6} lg={3}>
          <DateField
            name={path.agendamento.consultaComCidadao.data}
            label='Data do agendamento'
            minDate={serverTime}
            maxDate={addMonths(serverTime, 12)}
            clearable
            required
          />
        </Cell>
        <Cell size={6} lg={3}>
          <HorarioAgendaSelectField
            name={path.agendamento.consultaComCidadao.horario}
            lotacaoId={lotacao.id}
            dia={
              agendarCuidadoValue.consultaComCidadao?.data
                ? parseISO(agendarCuidadoValue.consultaComCidadao?.data)
                : undefined
            }
            disabled={!agendarCuidadoValue.consultaComCidadao?.data}
            labels={{ inicial: 'Horário do agendamento' }}
            required
          />
        </Cell>

        {isProfissionalExecutante && (
          <Cell size={6} lg={3}>
            <EnviarGarantiaAcessoCuidadoCompartilhadoField
              name={path.garantiaAcesso}
              pathCondutaGroup={path.condutaGroup}
              tipoAgendamento={CondutaCuidadoCompartilhadoEnum.AGENDADO_CONSULTA_CIDADAO_PRESENCIAL}
              cidadaoAceitaAtendTic={cuidadoCompartilhado.cidadaoAceitaAtendTic}
            />
          </Cell>
        )}

        <Cell size={12}>
          <TextAreaField
            name={path.agendamento.consultaComCidadao.observacoes}
            label='Observações'
            placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
            maxLength={200}
            style={styles.textArea}
          />
        </Cell>
      </Grid>
    </VFlow>
  )
}

const styles = {
  checkbox: css`
    margin-left: -0.25rem;
  `,
  textArea: css`
    resize: vertical;
  `,
  textMotivo: css`
    word-break: break-all;
  `,
}
