import { Cell, Grid, HeadingSection, InfoLabel } from 'bold-ui'
import { DateTime } from 'components/date'
import { Cns, Cpf } from 'components/label'
import { NacionalidadeEnum, RacaCorDbEnum } from 'graphql/types.generated'
import React from 'react'
import { nacionalidade, sexo } from 'types/enums'

import { CidadaoTabSectionModel } from '../types/model-cidadaoTabSection'

interface CidadaoFciDadosPessoaisSectionProps {
  cidadao: CidadaoTabSectionModel
}

export const CidadaoFciDadosPessoaisSection = (props: CidadaoFciDadosPessoaisSectionProps) => {
  const { cidadao } = props

  return (
    <HeadingSection level={2} title='Dados pessoais'>
      <Grid wrap>
        <Cell size={4}>
          <InfoLabel placeholder='-' title='CPF'>
            <Cpf value={cidadao?.cpf} />
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel placeholder='-' title='CNS'>
            <Cns value={cidadao?.cns} />
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel placeholder='-' title='Data de nascimento'>
            <DateTime format='DD/MM/YYYY' value={cidadao?.dataNascimento} />
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel placeholder='-' title='Sexo'>
            {sexo[cidadao?.sexo]}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel placeholder='-' title='Raça/cor'>
            {cidadao?.racaCor?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        {cidadao?.racaCor?.racaCorDbEnum === RacaCorDbEnum.INDIGENA ? (
          <Cell size={4}>
            <InfoLabel placeholder='-' title='Etnia'>
              {cidadao?.etnia?.nome?.titleCase()}
            </InfoLabel>
          </Cell>
        ) : (
          <Cell size={4} />
        )}
        <Cell size={4}>
          <InfoLabel placeholder='-' title='Nome da mãe'>
            {cidadao?.nomeMae?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={8}>
          <InfoLabel placeholder='-' title='Nome do pai'>
            {cidadao?.nomePai?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel placeholder='-' title='Nacionalidade'>
            {nacionalidade[cidadao?.nacionalidade?.nacionalidadeDbEnum]}
          </InfoLabel>
        </Cell>
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.BRASILEIRA && (
          <Cell size={8}>
            <InfoLabel placeholder='-' title='Município de nascimento'>
              {[cidadao?.localidadeNascimento?.nome?.titleCase(), cidadao?.localidadeNascimento?.uf?.sigla]
                .filter(Boolean)
                .join(' - ')}
            </InfoLabel>
          </Cell>
        )}
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.NATURALIZADA && (
          <>
            <Cell size={4}>
              <InfoLabel placeholder='-' title='Portaria de naturalização'>
                {cidadao?.portariaNaturalizacao?.titleCase()}
              </InfoLabel>
            </Cell>

            <Cell size={4}>
              <InfoLabel placeholder='-' title='Data de naturalização'>
                <DateTime format='DD/MM/YYYY' value={cidadao?.dataNaturalizacao} />
              </InfoLabel>
            </Cell>
          </>
        )}
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.ESTRANGEIRA && (
          <>
            <Cell size={4}>
              <InfoLabel placeholder='-' title='País de nascimento'>
                {cidadao?.paisNascimento?.nome?.titleCase()}
              </InfoLabel>
            </Cell>

            <Cell size={4}>
              <InfoLabel placeholder='-' title='Data de entrada no Brasil'>
                <DateTime format='DD/MM/YYYY' value={cidadao?.dataEntradaBrasil} />
              </InfoLabel>
            </Cell>
          </>
        )}
      </Grid>
    </HeadingSection>
  )
}
