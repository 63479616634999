import { CidadaoSelectFieldModel, EnderecoFieldGroupModel } from 'components/form'
import { EnderecoIndigenaFieldGroupModel } from 'components/form/field/enderecoindigena/model-enderecoIndigena'
import { SimNaoEnum, simNaoEnumToBoolean } from 'components/form/field/SimNaoRadioGroupField'
import { format } from 'date-fns'
import {
  AnimaisDomicilioEnum,
  AnimaisImovelInput,
  CidadaoTerritorio,
  CidadaoTerritorioBasico,
  CondicoesMoradiaInput,
  EnderecoIndigenaTerritorioInput,
  EnderecoTerritorioInput,
  FamiliaInput,
  ImovelCadastroQuery,
  ImovelInput,
  ReferenceMonth,
  TipoEnderecoEnum,
} from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { toDate } from 'util/date/formatDate'
import { isObjectDeepEmpty } from 'util/object'

import {
  AnimaisImovelFormModel,
  CidadaoCardModel,
  CidadaoTerritorioFormModel,
  CidadaoTerritorioModel,
  CondicoesMoradiaFormModel,
  FamiliaFormModel,
  ImovelFormModel,
  tipoEnderecoIndigena,
} from './model-cadastroImovel'
import { booleanToSimNaoEnum, buildAnimais, evaluatePossuiAnimal, isTipoImovelDomicilio } from './utils-cadastroImovel'

export const convertInitialValuesToEnderecoFieldGroupModel = (
  imovel: ImovelCadastroQuery['imovel']
): EnderecoFieldGroupModel => {
  return (
    imovel && {
      ...imovel,
      bairro: imovel.bairro && { nome: imovel.bairro },
      logradouro: imovel.logradouro && { nome: imovel.logradouro },
      municipio: imovel.municipio && { ...imovel.municipio, uf: { ...imovel.uf } },
      semNumero: imovel.numero === 'S/N',
    }
  )
}

export const convertInitialValuesToEnderecoIndigenaFieldGroupModel = (
  imovel: ImovelCadastroQuery['imovel']
): EnderecoIndigenaFieldGroupModel => {
  return (
    imovel && {
      ...imovel,
      municipio: imovel.municipioAldeia,
      uf: imovel.ufAldeia,
      numero: imovel.numeroResidenciaIndigena,
    }
  )
}

export const convertInitialValuesToCondicaoMoradiaFieldGroupModel = (
  condicoesMoradia: ImovelCadastroQuery['imovel']['condicoesMoradia']
): CondicoesMoradiaFormModel => {
  return (
    condicoesMoradia && {
      tipoAbastecimentoAgua: condicoesMoradia.tipoAbastecimentoAgua,
      tipoDomicilio: condicoesMoradia.tipoDomicilio,
      tipoEscoamentoSanitario: condicoesMoradia.tipoEscoamentoSanitario,
      tipoMaterialParede: condicoesMoradia.tipoMaterialParede,
      tipoPosseTerra: condicoesMoradia.tipoPosseTerra,
      tipoSituacaoMoradia: condicoesMoradia.tipoSituacaoMoradia,
      tipoTratamentoAgua: condicoesMoradia.tipoTratamentoAgua,
      isEnergiaEletricaDisponivel: booleanToSimNaoEnum(condicoesMoradia.isEnergiaEletricaDisponivel),
      tipoOrigemEnergiaEletrica: condicoesMoradia.tipoOrigemEnergiaEletrica,
      numeroComodos: condicoesMoradia.numeroComodos,
      tipoAcessoDomicilio: condicoesMoradia.tipoAcessoDomicilio,
      tipoDestinoLixo: condicoesMoradia.tipoDestinoLixo,
      tipoLocalizacao: condicoesMoradia.tipoLocalizacao,
    }
  )
}

export const convertInitialValuesToAnimaisImovelFieldGroupModel = (
  animaisImovel: ImovelCadastroQuery['imovel']['animaisImovel']
): AnimaisImovelFormModel => {
  return (
    animaisImovel && {
      possuiAnimais: booleanToSimNaoEnum(animaisImovel.possuiAnimais),
      possuiCachorro: animaisImovel.possuiCachorro,
      possuiGato: animaisImovel.possuiGato,
      possuiPassaro: animaisImovel.possuiPassaro,
      possuiMacaco: animaisImovel.possuiMacaco,
      possuiGalinha: animaisImovel.possuiGalinha,
      possuiPorco: animaisImovel.possuiPorco,
      possuiRepteis: animaisImovel.possuiRepteis,
      possuiOutros: animaisImovel.possuiOutros,
      quantidadeAnimais: animaisImovel.quantidadeAnimais,
      animais: buildAnimais(animaisImovel),
    }
  )
}

export const convertImovelFormModelToImovelInput = (input: ImovelFormModel): ImovelInput => {
  const {
    responsabilidade: { lotacao },
    tipoEndereco,
    endereco,
    enderecoIndigena,
    condicoesMoradia,
    animaisNoDomicilio,
    numeroMoradores,
    instituicaoPermanencia,
    tipoImovel,
    id,
    dataColeta,
    telefoneContato,
    telefoneResidencial,
    statusForaArea,
    stMicroareaPoloBase,
    microarea,
    familias,
    statusRecusa,
  } = input

  const animaisImovelInput: AnimaisImovelInput = {
    possuiAnimais: simNaoEnumToBoolean[animaisNoDomicilio?.possuiAnimais],
  }

  if (animaisNoDomicilio?.possuiAnimais === SimNaoEnum.SIM && animaisNoDomicilio?.animais?.length > 0) {
    animaisImovelInput.possuiCachorro = evaluatePossuiAnimal(animaisNoDomicilio, AnimaisDomicilioEnum.CACHORRO)
    animaisImovelInput.possuiGato = evaluatePossuiAnimal(animaisNoDomicilio, AnimaisDomicilioEnum.GATO)
    animaisImovelInput.possuiPassaro = evaluatePossuiAnimal(animaisNoDomicilio, AnimaisDomicilioEnum.PASSARO)
    animaisImovelInput.possuiMacaco = evaluatePossuiAnimal(animaisNoDomicilio, AnimaisDomicilioEnum.MACACO)
    animaisImovelInput.possuiGalinha = evaluatePossuiAnimal(animaisNoDomicilio, AnimaisDomicilioEnum.GALINHA)
    animaisImovelInput.possuiPorco = evaluatePossuiAnimal(animaisNoDomicilio, AnimaisDomicilioEnum.PORCO)
    animaisImovelInput.possuiRepteis = evaluatePossuiAnimal(animaisNoDomicilio, AnimaisDomicilioEnum.REPTEIS)
    animaisImovelInput.possuiOutros = evaluatePossuiAnimal(animaisNoDomicilio, AnimaisDomicilioEnum.OUTRO)
    animaisImovelInput.quantidadeAnimais = animaisNoDomicilio?.quantidadeAnimais
  } else if (animaisNoDomicilio?.possuiAnimais === SimNaoEnum.NAO) {
    animaisImovelInput.possuiCachorro = false
    animaisImovelInput.possuiGato = false
    animaisImovelInput.possuiPassaro = false
    animaisImovelInput.possuiMacaco = false
    animaisImovelInput.possuiGalinha = false
    animaisImovelInput.possuiPorco = false
    animaisImovelInput.possuiRepteis = false
    animaisImovelInput.possuiOutros = false
    animaisImovelInput.quantidadeAnimais = null
  }

  return (
    input && {
      id,
      cnes: lotacao.unidadeSaude.cnes,
      cbo2002: lotacao.cbo.cbo2002,
      ine: lotacao.equipe?.ine,
      dataColeta: dataColeta,
      cns: lotacao.profissional.cns,
      statusForaArea,
      microareaPoloBase: stMicroareaPoloBase,
      microarea,
      statusRecusa: statusRecusa,
      tipoEndereco,
      endereco: convertToEnderecoLogradouro(endereco, tipoEndereco),
      enderecoIndigena: convertToEnderecoIndigena(enderecoIndigena, tipoEndereco),
      animaisImovelInput:
        isTipoImovelDomicilio(tipoImovel) && !isUndefinedOrNull(animaisImovelInput.possuiAnimais)
          ? animaisImovelInput
          : null,
      tipoImovel: tipoImovel,
      condicoesMoradiaInput: condicoesMoradia && converterCondicoesMoradiaFormModel(condicoesMoradia),
      telefoneContato,
      telefoneResidencial,
      numeroMoradores: numeroMoradores,
      instituicaoPermanenciaInput: isUndefinedOrNull(instituicaoPermanencia)
        ? null
        : {
            ...instituicaoPermanencia,
            possuiOutrosProfissionaisVinculados:
              simNaoEnumToBoolean[instituicaoPermanencia?.possuiOutrosProfissionaisVinculados],
          },
      familias: familias && familias?.map((familia) => convertFamiliaFormModelToFamiliaInput(familia)),
    }
  )
}

export const convertCidadaoTerritorioToCidadaoTerritorioFormModel = (
  cidadao: CidadaoTerritorioBasico
): CidadaoTerritorioFormModel => {
  return {
    _id: cidadao.id,
    id: cidadao.id,
    mudouSe: false,
    registroObito: null,
    isNovoCadastroNaFamilia: false,
  }
}

const convertFamiliaFormModelToFamiliaInput = (familia: FamiliaFormModel): FamiliaInput => {
  return {
    numeroProntuarioFamiliar: familia?.numeroProntuarioFamiliar,
    rendaFamiliar: familia?.rendaFamiliar,
    resideDesde: familia?.resideDesde,
    numeroMembros: familia?.numeroMembros,
    responsavelId: familia.responsavelId,
    mudouSe: familia.isMudouSe,
    cidadaos: familia?.cidadaos?.map((cidadao) => {
      return {
        id: cidadao.id,
        mudouSe: cidadao.mudouSe,
        registroObito: cidadao?.registroObito,
      }
    }),
  }
}

const convertToEnderecoLogradouro = (
  endereco: EnderecoFieldGroupModel,
  tipoEndereco: TipoEnderecoEnum
): EnderecoTerritorioInput => {
  if (!tipoEnderecoIndigena.includes(tipoEndereco))
    return {
      bairro: endereco?.bairro.nome,
      cep: endereco?.cep,
      complemento: endereco?.complemento,
      pontoReferencia: endereco?.pontoReferencia,
      logradouro: endereco?.logradouro.nome,
      municipioId: endereco?.municipio.id,
      numero: endereco?.numero,
      semNumero: endereco?.semNumero,
      tipoLogradouroId: endereco?.tipoLogradouro.id,
      ufId: endereco?.uf.id,
    }
  return null
}

const convertToEnderecoIndigena = (
  enderecoIndigena: EnderecoIndigenaFieldGroupModel,
  tipoEndereco: TipoEnderecoEnum
): EnderecoIndigenaTerritorioInput => {
  if (tipoEnderecoIndigena.includes(tipoEndereco))
    return {
      dseiId: enderecoIndigena?.dsei.id,
      poloBaseId: enderecoIndigena?.poloBase.id,
      aldeiaId: enderecoIndigena?.aldeia.id,
      municipioId: enderecoIndigena?.municipio.id,
      ufId: enderecoIndigena?.uf.id,
      numero: enderecoIndigena?.numero,
    }
  return null
}

const converterCondicoesMoradiaFormModel = (condicoesMoradia: CondicoesMoradiaFormModel): CondicoesMoradiaInput => {
  const input = {
    ...condicoesMoradia,
    isEnergiaEletricaDisponivel: simNaoEnumToBoolean[condicoesMoradia.isEnergiaEletricaDisponivel],
    tipoOrigemEnergiaEletrica: condicoesMoradia.tipoOrigemEnergiaEletrica,
    tipoAbastecimentoAgua: condicoesMoradia.tipoAbastecimentoAgua,
    tipoDomicilio: condicoesMoradia.tipoDomicilio,
    tipoEscoamentoSanitario: condicoesMoradia.tipoEscoamentoSanitario,
    tipoMaterialParede: condicoesMoradia.tipoMaterialParede,
    tipoSituacaoMoradia: condicoesMoradia.tipoSituacaoMoradia,
    tipoTratamentoAgua: condicoesMoradia.tipoTratamentoAgua,
    tipoPosseTerra: condicoesMoradia.tipoPosseTerra,
    tipoLocalizacao: condicoesMoradia.tipoLocalizacao,
  }

  return !isObjectDeepEmpty(input) ? input : null
}

export const convertToCidadaoCardModel = (
  cidadaosTerritorioFormModel: CidadaoTerritorioFormModel,
  cidadaosTerritorioModel: CidadaoTerritorioModel
): CidadaoCardModel => {
  return {
    ...cidadaosTerritorioModel,
    ...cidadaosTerritorioFormModel,
  }
}

export const convertResideDesdeToResideDesdeMonthYear = (resideDesde: LocalDate) => {
  if (resideDesde) {
    const date = toDate(resideDesde)
    return {
      month: date.getMonth(),
      year: date.getFullYear(),
    }
  }
  return null
}

export const convertResideDesdeMonthYearToResideDesde = (resideDesdeMonthYear: ReferenceMonth) => {
  const { year, month } = resideDesdeMonthYear || {}

  return resideDesdeMonthYear ? format(new Date(year, month, 1), 'yyyy-MM-dd') : null
}

export const convertCidadaoTerritorioToCidadao = (
  cidadaoTerritorio: CidadaoTerritorio[]
): CidadaoSelectFieldModel[] => {
  return cidadaoTerritorio?.map((cidadaoImovel) => ({
    id: cidadaoImovel.id,
    cns: cidadaoImovel.cns,
    cpf: cidadaoImovel.cpf,
    dataNascimento: cidadaoImovel.dataNascimento,
    telefoneCelular: cidadaoImovel.telefoneCelular,
    racaCor: {
      racaCorDbEnum: cidadaoImovel.racaCorDbEnum,
    },
    etnia: {
      id: cidadaoImovel?.etniaId,
    },
    nomeMae: cidadaoImovel.nomeMae,
    nomePai: cidadaoImovel.nomePai,
    desconheceNomeMae: cidadaoImovel.desconheceNomeMae,
    desconheceNomePai: cidadaoImovel.desconheceNomePai,
    nacionalidade: {
      nacionalidadeDbEnum: cidadaoImovel.nacionalidadeDbEnum,
    },
    paisNascimento: {
      id: cidadaoImovel.paisNascimentoId,
    },
    localidadeNascimento: {
      id: cidadaoImovel.localidadeNascimentoId,
      uf: {
        id: cidadaoImovel.ufNascimentoId,
      },
    },
    dataNaturalizacao: cidadaoImovel.dataNaturalizacao,
    portariaNaturalizacao: cidadaoImovel.portariaNaturalizacao,
    dataEntradaBrasil: cidadaoImovel.dataEntradaBrasil,
    nome: cidadaoImovel.nome,
    nomeSocial: cidadaoImovel.nomeSocial,
    sexo: cidadaoImovel.sexo,
    cidadaoAldeado: {
      id: cidadaoImovel.cidadaoAldeadoId,
    },
  }))
}
