/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, colors, Grid, Heading, HFlow } from 'bold-ui'
import { CnsField, TelefoneField, TextField } from 'components/form'
import { SimNaoRadioGroupField } from 'components/form/field/SimNaoRadioGroupField'
import { Fragment } from 'react'
import { MetaPath } from 'util/metaPath'

import { InstituicaoPermanenciaFormModel } from '../model-cadastroImovel'

interface InstituicaoPermanenciaSectionProps {
  meta: MetaPath<InstituicaoPermanenciaFormModel>
  isRecusaCadastro: boolean
}

export const InstituicaoPermanenciaSection = ({ meta, isRecusaCadastro }: InstituicaoPermanenciaSectionProps) => {
  return (
    <Fragment>
      <Cell size={4}>
        <Heading level={1}>Instituição de permanência</Heading>
      </Cell>
      <Cell size={8} />
      <Cell size={6}>
        <TextField
          name={meta.nomeInstituicaoPermanencia}
          label='Nome da instituição de permanência'
          disabled={isRecusaCadastro}
        />
      </Cell>
      <Cell size={6} />
      <Cell size={8}>
        <HFlow
          style={css`
            margin: 0.5rem 0;
          `}
        >
          <SimNaoRadioGroupField
            name={meta.possuiOutrosProfissionaisVinculados}
            label='Existem outros profissionais de saúde vinculados à instituição (não inclui profissionais da rede pública de saúde)'
            disabled={isRecusaCadastro}
          />
        </HFlow>
      </Cell>
      <Cell size={4} />
      <Cell
        size={12}
        style={css`
          border: 1px solid ${colors.gray.c60};
          padding: 1rem;
          margin-left: 0.9rem;
        `}
      >
        <Grid>
          <Cell size={6}>
            <Heading level={3}> Identificação do responsável técnico da instituição de permanência</Heading>
          </Cell>
          <Cell size={6} />
          <Cell size={8}>
            <TextField
              name={meta.responsavelTecnico.nome}
              label='Nome do responsável'
              required={!isRecusaCadastro}
              disabled={isRecusaCadastro}
            />
          </Cell>
          <Cell size={4}>
            <CnsField name={meta.responsavelTecnico.cns} label='CNS do responsável' disabled={isRecusaCadastro} />
          </Cell>
          <Cell size={8}>
            <TextField name={meta.responsavelTecnico.cargo} label='Cargo na instituição' disabled={isRecusaCadastro} />
          </Cell>
          <Cell size={4}>
            <TelefoneField
              name={meta.responsavelTecnico.contato}
              label='Telefone para contato'
              disabled={isRecusaCadastro}
            />
          </Cell>
        </Grid>
      </Cell>
    </Fragment>
  )
}
