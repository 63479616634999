/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Tooltip } from 'bold-ui'
import { FormProps } from 'components/form/final-form'
import { ButtonLink, PrivateRoute } from 'components/route'
import { FormApi } from 'final-form'
import { useHistoricoResultadosExamesQuery } from 'graphql/hooks.generated'
import { IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { isEmpty, pickBy, transform } from 'lodash'
import { Fragment, useMemo } from 'react'
import { Switch, useHistory, useRouteMatch } from 'react-router'
import { isObjectDeepFalsy } from 'util/object'
import { historicoResultadoExamesUrl } from 'view/atendimentos/detail/components/modals/resultados-exames/HistoricoResultadosExamesModal'

import { grupoCboResultadosExames } from '../../acessos'
import {
  ResultadoExameQueryModel,
  ResultadosExamesModel,
  ResultadosExamesNaoSolicitadosModel,
  ResultadosExamesSolicitadosModel,
  SolicitacaoExameQueryModel,
} from '../model-resultadosExames'
import { getResultadoExameEspecificoFormatOnSave, isExameEspecifico, removePrefix } from '../util-resultadosExames'
import { AdicionarResultadosExamesModalForm } from './AdicionarResultadosExamesModalForm'

interface AdicionarResultadosExamesModalViewProps
  extends Pick<FormProps<ResultadosExamesModel>, 'initialValues' | 'onSubmit'> {
  editing: boolean
  solicitacoes: SolicitacaoExameQueryModel[]
  resultadosSolicitados: Record<ID, ResultadoExameQueryModel>
  cidadaoSexo: SexoEnum
  cidadaoIdentidadeGeneroDbEnum: IdentidadeGeneroEnum
  cidadaoDataNascimento: LocalDate
  numberOfResultadosNaoAvaliados: number
  prontuarioId: ID
}

export const AdicionarResultadosExamesModalView = (props: AdicionarResultadosExamesModalViewProps) => {
  const {
    editing,
    solicitacoes,
    resultadosSolicitados,
    cidadaoSexo,
    cidadaoIdentidadeGeneroDbEnum,
    cidadaoDataNascimento,
    initialValues,
    numberOfResultadosNaoAvaliados,
    prontuarioId,
    onSubmit,
  } = props

  const { analytics } = useFirebase()

  const history = useHistory()
  const match = useRouteMatch()

  const handleSubmit = (values: ResultadosExamesModel, form: FormApi<ResultadosExamesModel>) => {
    if (!isEmpty(values?.resultadosSemSolicitacao)) {
      analytics.logEvent('salvar_resultados_exm_sem_solicitacao')
    }

    if (!isEmpty(values?.resultadosComSolicitacao)) {
      analytics.logEvent('salvar_resultados_exm_com_solicitacao')
    }

    onSubmit(formatValues(values, resultadosSolicitados), form)
  }

  const goBack = () => history.push(match.url.replace('/resultados-exames/adicionar', ''))

  const handleClick = () => analytics.logEvent('historico_registro_exm_soap')

  const {
    data: { resultadosExame },
    loading,
  } = useHistoricoResultadosExamesQuery({
    variables: { input: { prontuarioId, pageParams: { fetchPageInfo: false, size: 2000 } } },
    fetchPolicy: 'cache-and-network',
  })

  const historicoResultadosExamesConferidos = useMemo(
    () => resultadosExame?.content?.filter((item) => !!item.conferido) ?? [],
    [resultadosExame]
  )

  const hasResultadosExame = !loading && !isEmpty(historicoResultadosExamesConferidos)

  const pluralText: boolean = numberOfResultadosNaoAvaliados > 1

  return (
    <Fragment>
      <HFlow hSpacing={0.5}>
        <Tooltip
          text={
            numberOfResultadosNaoAvaliados > 0 &&
            `${pluralText ? 'Existem' : 'Existe'} ${'exame'.pluralizeAndConcatValue(
              numberOfResultadosNaoAvaliados
            )} sem resultado informado`
          }
        >
          <ButtonLink to={`${match.url}/resultados-exames/adicionar`} type='button' kind='primary' size='small'>
            <Icon icon={editing ? 'penOutline' : 'plus'} style={styles.icon} />
            {`${editing ? 'Editar' : 'Adicionar'} resultados de exames${
              numberOfResultadosNaoAvaliados > 0 ? ` (${numberOfResultadosNaoAvaliados})` : ''
            }`}
          </ButtonLink>
        </Tooltip>

        <Tooltip text={!hasResultadosExame && 'Nenhum resultado registrado'}>
          <ButtonLink
            to={`${match.url}${historicoResultadoExamesUrl}`}
            kind='primary'
            size='small'
            skin='outline'
            disabled={!hasResultadosExame}
            onClick={handleClick}
          >
            <Icon icon='zoomOutline' style={styles.icon} />
            Histórico de resultados de exames
          </ButtonLink>
        </Tooltip>
      </HFlow>

      <Switch>
        <PrivateRoute path={`${match.url}/resultados-exames/adicionar`} permission={grupoCboResultadosExames.adicionar}>
          <AdicionarResultadosExamesModalForm
            editing={editing}
            cidadaoDataNascimento={cidadaoDataNascimento}
            cidadaoIdentidadeGeneroDbEnum={cidadaoIdentidadeGeneroDbEnum}
            cidadaoSexo={cidadaoSexo}
            resultadosSolicitados={resultadosSolicitados}
            solicitacoes={solicitacoes}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            goBack={goBack}
          />
        </PrivateRoute>
      </Switch>
    </Fragment>
  )
}

const formatValues = (
  values: ResultadosExamesModel,
  resultadosSolicitados: Record<ID, ResultadoExameQueryModel>
): ResultadosExamesModel => {
  const { replicateComSolicitacao, resultadosComSolicitacao, resultadosSemSolicitacao } = values

  return {
    replicateComSolicitacao,
    resultadosSemSolicitacao: formatValueSemSolicitacao(resultadosSemSolicitacao),
    resultadosComSolicitacao: formatValueComSolicitacao(resultadosComSolicitacao, resultadosSolicitados),
  }
}

const formatValueSemSolicitacao = (
  values: ResultadosExamesNaoSolicitadosModel
): ResultadosExamesNaoSolicitadosModel => {
  return Object.fromEntries(
    Object.entries(values ?? {}).map(([id, value]) => {
      const { exame } = value
      const formatOnSave = getResultadoExameEspecificoFormatOnSave(exame)

      return [id, formatOnSave ? formatOnSave(value, exame) : value]
    })
  ) as ResultadosExamesNaoSolicitadosModel
}

const formatValueComSolicitacao = (
  values: ResultadosExamesSolicitadosModel,
  resultadosSolicitados: Record<ID, ResultadoExameQueryModel>
) => {
  const filteredValuesWithResultado = pickBy(values, (value, key) => {
    const isEspecifico = isExameEspecifico(resultadosSolicitados[removePrefix(key)].exame)

    return !!(isEspecifico ? !isObjectDeepFalsy(value.resultado) : trimRemoveBreakLines(value.resultado))
  })

  return transform(
    filteredValuesWithResultado,
    (result, value, key) => {
      const exame = resultadosSolicitados[removePrefix(key)].exame
      const isEspecifico = isExameEspecifico(exame)
      const formatOnSave = getResultadoExameEspecificoFormatOnSave(exame)

      const newValue = formatOnSave ? formatOnSave({ ...value, exame }, exame) : { ...value, exame }

      return (result[key] = {
        ...newValue,
        resultado: isEspecifico ? newValue.resultado : newValue.resultado.trim(),
      })
    },
    {}
  )
}

const trimRemoveBreakLines = (value: string) => value?.replaceAll('\n', '').trim()

const styles = {
  icon: css`
    margin-right: 0.5rem;
  `,
}
