import moment from 'moment'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { MedicaoAntropometricaPreNatal } from './pre-natal/model-medicoesPreNatal'

export function getMedicoesAntropometricasPreNatal(medicoes: HistoricoMedicaoModel[]): MedicaoAntropometricaPreNatal[] {
  return medicoes
    .filter((medicao) => !!medicao.valorPeso || !!medicao.valorAltura)
    .map((medicao) => ({
      dataMedicao: medicao.dataMedicao,
      peso: medicao.valorPeso,
      altura: medicao.valorAltura,
      tipoMedicao: medicao.tipoMedicao,
    }))
}

const format = (data) => moment(data).format('DD/MM/YY')

export function reduceMedicoesIguais<T extends Pick<HistoricoMedicaoModel, 'dataMedicao'>>(
  medicoes: ReadonlyArray<T>,
  key: keyof T
): T[] {
  const mapMedicoesUnicas = medicoes?.reduce(
    (acc, curr) => (curr[key] ? acc.set(`${format(curr.dataMedicao)}|${curr[key]}`, curr) : acc),
    new Map<string, T>()
  )

  return Array.from(mapMedicoesUnicas.values())
}
