/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import { confirm } from 'components/modals/confirm'
import { RegistroObitoInput } from 'graphql/types.generated'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { stringGrouper } from 'util/string/string-grouper'

import { useGetCallbackUrlToCadastroImovel } from '../hooks/useGetCallbackUrlToCadastroImovel'
import { CidadaoCardModel } from '../model-cadastroImovel'
import { CidadaoFamiliaCard } from './CidadaoFamiliaCard'
import { CidadaoFamiliaDropdownButton } from './CidadaoFamiliaDropdownButton'
import { RegistroObitoCidadaoModal } from './modal/RegistroObitoCidadaoModal'

interface CidadaoCardWrapperProps {
  cidadao: CidadaoCardModel
  isResponsavel?: boolean
  isFamiliaMudouSe: boolean
  imovelId?: ID
  hasPermission: boolean
  camposObrigatoriosNulos: string[]
  isAtualizacaoCidadao: boolean
  dataColeta: LocalDate
  onAtualizarCidadaoCache: (updateCidado: boolean) => void
  onDefinirResponsavelFamiliar: (cidadaoId: ID) => void
  onRemoverCidadao: () => void
  onCidadaoMudouSe(mudouSe: boolean): void
  onCidadaoObito(registroObito: RegistroObitoInput): void
  onScrollAfterRedirect(): void
}

export const CidadaoCardWrapper = (props: CidadaoCardWrapperProps) => {
  const {
    cidadao,
    imovelId,
    isResponsavel = false,
    isFamiliaMudouSe,
    hasPermission,
    isAtualizacaoCidadao,
    camposObrigatoriosNulos,
    dataColeta,
    onAtualizarCidadaoCache,
    onDefinirResponsavelFamiliar,
    onCidadaoMudouSe,
    onCidadaoObito,
    onRemoverCidadao,
    onScrollAfterRedirect,
  } = props

  const {
    id,
    dataNascimento,
    dataEntradaBrasil,
    dataNaturalizacao,
    nome,
    nomeSocial,
    isNovoCadastroNaFamilia,
    mudouSe,
    registroObito,
  } = cidadao

  const [isModalObitoOpen, setIsModalObitoOpen] = useState<boolean>(false)

  const history = useHistory()

  const alert = useAlert()

  const isAtualizacaoCidadaoRef = useRef(isAtualizacaoCidadao)

  useEffect(() => {
    if (isAtualizacaoCidadaoRef.current && isAtualizacaoCidadao) {
      onScrollAfterRedirect()
      onAtualizarCidadaoCache(false)
    }
  }, [isAtualizacaoCidadao, isAtualizacaoCidadaoRef, onAtualizarCidadaoCache, onScrollAfterRedirect])

  const handleDefinirNovoResponsavel = confirm({
    title: `Definir ${(nomeSocial ?? nome).titleCase()} como responsável familiar?`,
    onConfirm: () => {
      onDefinirResponsavelFamiliar(id)
      alert('success', 'Responsável familiar definido com sucesso.')
    },
  })

  const handleClickMudouSe = confirm({
    title: `Confirmar mudança do cidadão.`,
    body: <Text>Ao confirmar essa ação o cidadão será removido do domicílio.</Text>,
    onConfirm: () => onCidadaoMudouSe(true),
  })

  const handleClickCidadaoObito = () => {
    if (!registroObito) setIsModalObitoOpen(true)
    else onCidadaoObito(null)
  }

  const handleDesfazerMudouSe = () => onCidadaoMudouSe(false)

  const cadastroImovelPath = useGetCallbackUrlToCadastroImovel(imovelId)

  const handleAtualizarCidadao = () => {
    onAtualizarCidadaoCache(true)
    setTimeout(() => history.push(`/cidadao/${id}/edit?callbackUrl=${cadastroImovelPath}`))
  }

  const cidadaoCamposObrigatoriosErrorMessage = useMemo(
    () =>
      `Cidadão não pode ser adicionado na família pois não possui ${stringGrouper(
        ...camposObrigatoriosNulos
      )} em seu cadastro.`,
    [camposObrigatoriosNulos]
  )

  return (
    <Fragment>
      <CidadaoFamiliaCard
        cidadao={cidadao}
        isResponsavel={isResponsavel}
        possuiFichaNaoProcessada={false}
        errorMessage={camposObrigatoriosNulos.length > 0 ? cidadaoCamposObrigatoriosErrorMessage : null}
        buttonComponent={
          <CidadaoFamiliaDropdownButton
            isResponsavel={isResponsavel}
            isFamiliaMudouSe={isFamiliaMudouSe}
            isMudouSe={mudouSe}
            isNovoCadastroNaFamilia={isNovoCadastroNaFamilia}
            hasPermission={hasPermission}
            registroObito={registroObito}
            onDefinirNovoResponsavel={handleDefinirNovoResponsavel}
            onClickMudouSe={handleClickMudouSe}
            onDesfazerMudouSe={handleDesfazerMudouSe}
            onAtualizarCidadao={handleAtualizarCidadao}
            onClickCidadaoObito={handleClickCidadaoObito}
            onRemoverCidadao={onRemoverCidadao}
          />
        }
      />
      {isModalObitoOpen && (
        <RegistroObitoCidadaoModal
          open
          isResponsavel
          dataNascimento={dataNascimento}
          dataEntradaBrasil={dataEntradaBrasil}
          dataNaturalizacao={dataNaturalizacao}
          dataColeta={dataColeta}
          onSubmit={onCidadaoObito}
          onClose={() => setIsModalObitoOpen(false)}
        />
      )}
    </Fragment>
  )
}
