import { useOnWindowFocusOut } from 'hooks/useOnWindowFocusOut'
import { useLocalStorageState } from 'hooks/useStorage'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'

import { VisualizacaoTerritorialCacheModel } from '../model'

interface VisualizacaoQueryParams {
  redirect?: string
}

export const useVisualizacaoCache = (acessoId: ID) => {
  const { redirect } = useUrlQueryParams<VisualizacaoQueryParams>()

  const [visualizacaoCacheState, setVisualizacaoCacheState, deleteVisualizacaoCacheState] = useLocalStorageState<
    VisualizacaoTerritorialCacheModel
  >(`${acessoId}/acompanhamentoTerritorio/`)

  const shouldUseCache = !!visualizacaoCacheState

  /* TODO (NP) #23449: Essa hook deleta a cache sempre que o componente é descarregado. Quando eu atualizo a página do Cadastro do imóvel, essa função é ativada e limpa a cache
    causando o bug do 'cnes' undefined. Não encontrei problemas ao comentar isso, dá para avaliar melhor a necessidade dela. */
  // useOnBeforeUnload(() => {
  //   deleteVisualizacaoCacheState()
  // })

  useOnWindowFocusOut(() => {
    if (shouldUseCache && !redirect) deleteVisualizacaoCacheState()
  })

  return {
    visualizacaoCacheState,
    setVisualizacaoCacheState,
    deleteVisualizacaoCacheState,
    shouldUseCache,
  }
}
