/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Cell,
  DataTable,
  Grid,
  HFlow,
  Icon,
  Modal,
  ModalBody,
  Text,
  Theme,
  Tooltip,
  useTheme,
  VFlow,
} from 'bold-ui'
import { TitleGroup } from 'components/TitleGroup'
import { useUltimosCincoArquivosTemporariosQuery } from 'graphql/hooks.generated'
import { ArquivoTemporario, ArquivoTemporarioStatusEnum } from 'graphql/types.generated'
import { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'

import { downloadArquivo } from './downloadsArquivo'
import { ArquivoTemporarioModel, MensagemErroArquivoTemporarioMaisRecente } from './model-arquivo'
import { RELATORIOS_RECENTES_URL, RelatoriosRecentesProps } from './RelatoriosRecentes'
import StatusArquivoLabel from './StatusArquivoTemporarioLabel'

interface RelatoriosRecentesModalProps extends Omit<RelatoriosRecentesProps, 'setStatusRelatorio' | 'shouldRefetch'> {
  fileName: string
  mensagemErroArquivoMaisRecente?: MensagemErroArquivoTemporarioMaisRecente
}

export const RelatoriosRecentesModal = (props: RelatoriosRecentesModalProps) => {
  const { fileName, mensagemErroArquivoMaisRecente, identificadorModuloArquivo, setShouldRefetch } = props

  const { data, loading } = useUltimosCincoArquivosTemporariosQuery({
    variables: {
      input: identificadorModuloArquivo,
    },
    onCompleted: () => setShouldRefetch(false),
  })

  const history = useHistory()
  const match = useRouteMatch()

  const [mostrarMais, setMostrarMais] = useState<boolean>(false)

  const theme = useTheme()
  const styles = createStyles(theme)

  const handleFechar = () => {
    history.push(match.url.replace(RELATORIOS_RECENTES_URL, ''))
  }

  const handleDownload = (item: ArquivoTemporario) => {
    if (item.status === ArquivoTemporarioStatusEnum.PRONTO)
      downloadArquivo(item.id, fileName, item.formato.toLocaleLowerCase())
  }
  const ultimosCincoArquivos = !loading ? data?.ultimosCincoArquivosTemporarios : []
  const first = ultimosCincoArquivos.isNotEmpty() ? [ultimosCincoArquivos[0]] : []

  return (
    <Modal open size='auto' onClose={handleFechar} closeOnBackdropClick>
      <ModalBody>
        <TitleGroup title='Relatórios em processamento/processados (7 dias)' />
        <Grid>
          <Cell size={12} style={styles.cell} alignSelf='flex-start'>
            <VFlow vSpacing={0}>
              <DataTable<ArquivoTemporarioModel>
                rows={mostrarMais ? ultimosCincoArquivos : first}
                columns={[
                  {
                    name: 'dataRequisicao',
                    header: 'Data da requisição',
                    render: (item) => formatDateAndHoursMinutes(item.dataInicioGerado),
                  },
                  {
                    name: 'tipo',
                    header: 'Tipo',
                    render: (item) => item.formato,
                  },
                  {
                    name: 'statusProcessamento',
                    header: 'Status de processamento',
                    render: (item) => (
                      <StatusArquivoLabel arquivoTemporarioStatus={item.status} isApenasUltimoRelatorio={false} />
                    ),
                  },
                  {
                    name: 'download',
                    header: '',
                    render: (item) => {
                      const hasMensagemErroArquivoMaisRecente =
                        item.status === ArquivoTemporarioStatusEnum.ERRO &&
                        Number(item.id) === mensagemErroArquivoMaisRecente?.arquivoId
                      return (
                        <Tooltip
                          text={
                            item.status === ArquivoTemporarioStatusEnum.PRONTO
                              ? 'Download'
                              : hasMensagemErroArquivoMaisRecente
                              ? mensagemErroArquivoMaisRecente?.mensagemErro
                              : 'Disponível apenas para relatórios gerados'
                          }
                        >
                          <Button
                            type='button'
                            skin='ghost'
                            size='small'
                            disabled={item.status !== ArquivoTemporarioStatusEnum.PRONTO}
                            onClick={() => handleDownload(item)}
                          >
                            {hasMensagemErroArquivoMaisRecente ? (
                              <Icon icon='exclamationTriangleOutline' />
                            ) : (
                              <Icon icon='download' />
                            )}
                          </Button>
                        </Tooltip>
                      )
                    },
                  },
                ]}
              />

              <HFlow style={styles.footer}>
                <Button
                  type='button'
                  skin='ghost'
                  size='small'
                  onClick={() => {
                    setMostrarMais(!mostrarMais)
                  }}
                  style={styles.mostrarMais}
                >
                  <Icon icon={mostrarMais ? 'angleUp' : 'angleDown'} />
                  <Text>{mostrarMais ? 'Mostrar menos' : 'Mostrar mais'}</Text>
                </Button>
              </HFlow>
            </VFlow>
          </Cell>
        </Grid>
      </ModalBody>
    </Modal>
  )
}

const createStyles = (theme: Theme) => ({
  cell: css`
    padding-top: 2rem;
    padding-left: 0rem;
  `,
  dataTable: css`
    padding-bottom: 0rem;
  `,
  footer: css`
    display: flex;
    align-items: stretch;
    border-left: 1px solid ${theme.pallete.divider};
    border-right: 1px solid ${theme.pallete.divider};
    border-bottom: 1px solid ${theme.pallete.divider};
    padding-top: 0rem;
    padding-left: 1rem;
    height: 2.25rem;
  `,
  mostrarMais: css`
    padding-top: 0.35rem;
  `,
})
