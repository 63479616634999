/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import AccordionList from 'components/accordion/AccordionList'
import { Cep } from 'components/label'
import { useAtualizarEnderecoLogradouroMutation } from 'graphql/hooks.generated'
import { PageParams, TipoEnderecoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { Fragment, useState } from 'react'
import { formatDateAsMonth } from 'util/date/formatDate'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'
import { AtualizacaoImoveisProgressBar } from 'view/reterritorializacao/AtualizacaoImoveisProgressBar'
import { convertLogradouroDtoToLogradouroEditFormModel } from 'view/visualizacaoTerritorial/converter'
import {
  AccordionItemHeaderProps,
  LogradouroEditFormModel,
  LogradouroTerritorio,
  TerritorioMicroareaItemModel,
  TerritorioMicroareaQueryResult,
  TerritorioModel,
  VisualizacaoMicroareasFilterFormModel,
  VisualizacaoMicroareasFilterModel,
} from 'view/visualizacaoTerritorial/model'

import { MicroareasFilter } from '../filter/MicroareasFilter'
import { LogradouroEditModal } from '../modal/LogradouroEditModal'
import { IndiceItem } from './IndiceItem'
import MicroareaLogradouroListItemBody from './MicroareaLogradouroListItemBody'

interface MicroareaLogradouroListProps {
  logradouros: TerritorioMicroareaQueryResult
  territorio: TerritorioModel
  lotacaoId: ID
  municipioId: ID
  profissionalId: ID
  microareasFilter?: VisualizacaoMicroareasFilterModel
  progressToken: ID
  tipoEndereco: TipoEnderecoEnum
  canCboWriteFicha: boolean
  onAccordionItemClick(id: string): void
  isExpanded(id: string): boolean
  resetExpandedItems(): void
  onFiltrarMicroareas(formValues: VisualizacaoMicroareasFilterFormModel): void
  saveVisualizacaoCacheState(): void
  onChangePageParams(value: { pageParams: PageParams }): void
  deleteVisualizacaoCacheState(): void
  onRefreshMicroarea(): void
}

export function MicroareaLogradouroPagedList(props: MicroareaLogradouroListProps) {
  const {
    logradouros,
    territorio,
    lotacaoId,
    municipioId,
    profissionalId,
    microareasFilter,
    progressToken,
    tipoEndereco,
    canCboWriteFicha,
    onAccordionItemClick,
    isExpanded,
    saveVisualizacaoCacheState,
    onFiltrarMicroareas,
    resetExpandedItems,
    onChangePageParams,
    deleteVisualizacaoCacheState,
    onRefreshMicroarea,
  } = props

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [currentLogradouro, setCurrentLogradouro] = useState<LogradouroEditFormModel>()
  const [currentProcessingLogradouroId, setCurrentProcessingLogradouroId] = useState<ID>()
  const [atualizarLogradouro] = useAtualizarEnderecoLogradouroMutation()
  const hasLogradouros = !!logradouros?.logradouros.content?.length
  const serverTime = useServerTime()

  const handleChangePage = (value: { pageParams: PageParams }) => {
    onChangePageParams(value)
    resetExpandedItems()
  }

  const handleCompleteProcessing = () => {
    setCurrentProcessingLogradouroId(null)
    onRefreshMicroarea()
  }

  const handleLogradouroEdit = (input: LogradouroEditFormModel) => {
    atualizarLogradouro({
      variables: {
        input: {
          cep: input.cep,
          bairro: input.bairro.nome,
          tipoLogradouroNuDne: input.tipoLogradouro.numeroDne,
          logradouro: input.logradouro,
          filter: {
            ...territorio,
            tipoEndereco: tipoEndereco,
            bairro: currentLogradouro.bairro.nome,
            tipoLogradouroId: currentLogradouro.tipoLogradouro?.id,
            logradouro: currentLogradouro.logradouro,
            cep: currentLogradouro.cep,
          },
          progressToken,
        },
      },
    })
    setIsModalOpen(false)
    setCurrentProcessingLogradouroId(input.id)
    setCurrentLogradouro(null)
  }

  return (
    <VFlow vSpacing={0.5}>
      <MicroareasFilter
        microareasFilter={microareasFilter}
        tipoEndereco={tipoEndereco}
        onSubmit={onFiltrarMicroareas}
      />
      <VFlow
        style={css`
          margin-bottom: 1rem;
        `}
      >
        {hasLogradouros && (
          <HFlow
            hSpacing={2}
            style={css`
              margin-bottom: 1rem;
            `}
            alignItems='center'
          >
            <IndiceItem
              descricaoIndice='Imóveis com cadastro completo:'
              textoToolTip='Cadastros completos são aqueles nos quais todos os campos foram preenchidos e não apenas os obrigatórios, exceto para imóveis com cadastro recusado.'
              quantidade={logradouros.indices.quantidadeImoveisCadastroCompleto}
              quantidadeTotal={logradouros.indices.totalImoveisNaoRecusados}
            />
            <IndiceItem
              descricaoIndice='Imóveis com cadastro atualizado:'
              textoToolTip='Cadastros atualizados são aqueles que tiveram uma atualização há menos de 1 ano.'
              quantidade={logradouros.indices.quantidadeImoveisAtualizados}
              quantidadeTotal={logradouros.indices.totalImoveisAtualizados}
            />
            {!tipoEnderecoIndigena.includes(tipoEndereco) && (
              <IndiceItem
                descricaoIndice={`Domicílios visitados em ${formatDateAsMonth(serverTime.getServerTimeNow())}:`}
                textoToolTip='Este índice é calculado pelas visitas mais recentes informadas pelo aplicativo e-SUS Território neste domicílio ou pelas
                          Fichas de Visita registradas com o CPF ou CNS de algum dos moradores do mesmo.'
                quantidade={logradouros.indices.quantidadeDomiciliosVisitados}
                quantidadeTotal={logradouros.indices.quantidadeDomiciliosAvaliados}
              />
            )}
          </HFlow>
        )}
      </VFlow>
      <AccordionList<TerritorioMicroareaItemModel>
        data={logradouros?.logradouros}
        accordionItemHeader={(item: LogradouroTerritorio) => (
          <AccordionItemHeader
            processingLogradouroId={currentProcessingLogradouroId}
            canCboWriteFicha={canCboWriteFicha}
            onCompleteProcessing={handleCompleteProcessing}
            item={item}
            onEdit={() => {
              setIsModalOpen(true)
              setCurrentLogradouro(convertLogradouroDtoToLogradouroEditFormModel(item))
            }}
            progressToken={progressToken}
          />
        )}
        accordionItemBody={(item: LogradouroTerritorio) => (
          <MicroareaLogradouroListItemBody
            lotacaoId={lotacaoId}
            profissionalId={profissionalId}
            rowItem={item}
            territorio={territorio}
            microareaFilters={microareasFilter}
            isExpanded={isExpanded(item.id)}
            saveVisualizacaoCacheState={saveVisualizacaoCacheState}
            deleteVisualizacaoCacheState={deleteVisualizacaoCacheState}
          />
        )}
        onChange={handleChangePage}
        controls={{ handleAccordionItemClick: onAccordionItemClick, isExpanded }}
        noResultPlaceholder='Nenhum imóvel encontrado. Tente novamente alterando os filtros ou parâmetros de busca.'
      />

      {isModalOpen && (
        <LogradouroEditModal
          isModalOpen={isModalOpen}
          municipioId={municipioId}
          onClose={() => {
            setIsModalOpen(false)
            setCurrentLogradouro(undefined)
          }}
          onSubmit={handleLogradouroEdit}
          initialValues={currentLogradouro}
          territorio={territorio}
          currentLogradouro={currentLogradouro}
        />
      )}
    </VFlow>
  )
}

const AccordionItemHeader = (props: AccordionItemHeaderProps) => {
  const { item, processingLogradouroId, progressToken, canCboWriteFicha, onEdit, onCompleteProcessing } = props
  const isProcessing = processingLogradouroId === item.id
  const isTipoEnderecoIndigena = tipoEnderecoIndigena.includes(item.tipoEndereco)

  return (
    <Fragment>
      <Grid
        style={css`
          width: 100%;
        `}
      >
        <Cell size={12}>
          <HFlow
            hSpacing={0.5}
            key={item.id}
            alignItems='center'
            style={css`
              display: flex;
              width: 100%;
            `}
          >
            {isTipoEnderecoIndigena ? (
              <Text fontWeight='bold' fontSize={1}>
                {item.aldeia.nome.titleCase()}
              </Text>
            ) : (
              <Text fontWeight='bold' fontSize={1}>
                {`${item.tipoLogradouroNome ? `${item.tipoLogradouroNome} ` : ''}${item.logradouro}, ${
                  item.bairro
                }`.titleCase()}
                {item.cep && ' - CEP: '}
                {item.cep && <Cep value={item.cep} />}
              </Text>
            )}
            <Text fontSize={1}>{`${item.numeroImoveis ?? 0} imóve${item.numeroImoveis === 1 ? 'l' : 'is'}`}</Text>
            {!isTipoEnderecoIndigena && canCboWriteFicha && (
              <Tooltip text='Editar' key='tooltip'>
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    onEdit()
                  }}
                  disabled={isProcessing || (processingLogradouroId && !isProcessing)}
                  size='small'
                  skin='ghost'
                  style={css`
                    margin-left: auto;
                  `}
                >
                  <Icon icon='penFilled' />
                </Button>
              </Tooltip>
            )}
          </HFlow>
        </Cell>
        {isProcessing && progressToken && (
          <Cell size={12}>
            <AtualizacaoImoveisProgressBar
              topic={`atualizacaoEnderecoImoveis/${progressToken}`}
              progressToken={progressToken}
              onCompleteCallBack={onCompleteProcessing}
              renderProgressBar={isProcessing}
            />
          </Cell>
        )}
      </Grid>
    </Fragment>
  )
}
