import { isApolloError } from 'apollo-client'
import { GraphQLError } from 'graphql'
import { ErrorObject } from 'util/validation'

export const isValidationError = (error: any) => {
  return !!error?.extensions?.validationError
}

export const getValidationError = (graphQLError: any): ErrorObject<any> | string | null => {
  return graphQLError.extensions.validationError
}

/**
 * itera sobre o validationError e, caso haja mais de um erro, retorna msg default
 * (Existem campos preenchidos de forma incorreta.).
 * Se houver apenas um erro retorna a mensagem recebida do validator
 */
export const getValidationErrorMessage = (
  graphQLError: any,
  supressFieldValidationNotificationError: boolean
): string | object | null => {
  let validationError = getValidationError(graphQLError)

  while (typeof validationError !== 'string') {
    if (!supressFieldValidationNotificationError && Object.keys(validationError).length === 1) {
      validationError = Object.values(validationError)[0]
    } else {
      return graphQLError.message
    }
  }

  return validationError
}

export const getFirstGraphqlError = (error: any): GraphQLError =>
  error instanceof Error && isApolloError(error) ? error.graphQLErrors[0] : undefined
