/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core'
import { Alert } from 'bold-ui'
import { IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'
import moment from 'moment'
import { Fragment, useMemo } from 'react'
import { estadoNutricional } from 'util/atendimento'
import { parseNumber } from 'util/number'
import { PeriodoGestacaoModel } from 'view/atendimentos/detail/components/modals/types/PeriodoGestacaoModel'

interface AlertImcProps {
  imc: string
  dataMedicao: number
  dataNascimento: string
  sexo: SexoEnum
  identidadeGenero?: IdentidadeGeneroEnum
  gestacoes?: ReadonlyArray<PeriodoGestacaoModel>
  serializedStyles?: { alertWrapper: SerializedStyles }
}

export const AlertImc = (props: AlertImcProps) => {
  const { imc, dataMedicao, dataNascimento, sexo, identidadeGenero, gestacoes, serializedStyles } = props

  const idadeDuranteMedicao = moment.duration(moment(dataMedicao).diff(dataNascimento, 'days'), 'days').asYears()

  const msgEstadoNutricional = useMemo(
    () => imc && estadoNutricional(parseNumber(imc), sexo, idadeDuranteMedicao, gestacoes, identidadeGenero),
    [imc, sexo, identidadeGenero, idadeDuranteMedicao, gestacoes]
  )

  return (
    <Fragment>
      {msgEstadoNutricional && (
        <Alert inline type={msgEstadoNutricional.alertType} styles={{ wrapper: serializedStyles?.alertWrapper }}>
          {msgEstadoNutricional.message}
        </Alert>
      )}
    </Fragment>
  )
}
