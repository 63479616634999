import { getFirstGraphqlError } from 'components/error'

export interface UnificacaoCidadaoInfo {
  nomeCompleto: string
  cpf: string
  cns: string
}

export interface UnificacaoCadastroErrorModel {
  message: string
  cidadaoInfoList: UnificacaoCidadaoInfo[] | null
}

export const handleUnificacaoError = (error: any) => {
  const firstError = getFirstGraphqlError(error)
  if (firstError) {
    if (isUnificacaoCadastroError(firstError)) {
      const unificacaoError: UnificacaoCadastroErrorModel = {
        message: firstError.message,
        cidadaoInfoList: getUnificacaoCadastroErrorInfoList(firstError),
      }
      return unificacaoError
    }
  }
  return {
    message: 'Erro inesperado. Tente novamente mais tarde ou entre em contato com o suporte.',
  } as UnificacaoCadastroErrorModel
}

export const isUnificacaoCadastroError = (error: any) => {
  return error.extensions.classification === 'UnificacaoCadastroDbValidationException'
}

export const getUnificacaoCadastroErrorInfoList = (graphQLError: any): UnificacaoCidadaoInfo[] | null => {
  return graphQLError.extensions?.unificacaoCadastroError?.map((i: any) => {
    const info: UnificacaoCidadaoInfo = {
      nomeCompleto: i.nomeCompleto,
      cpf: i.cpf,
      cns: i.cns,
    }
    return info
  })
}
