import { SerializedStyles } from '@emotion/core'
import { Table, TableBody, TableCell, TableRow } from 'bold-ui'
import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

interface ArcaboucoLegalSectionProps {
  tableCellStyle: SerializedStyles
  ivcfEnabled: boolean
}

export const ArcaboucoLegalSection = (props: ArcaboucoLegalSectionProps) => {
  const { tableCellStyle, ivcfEnabled } = props

  return (
    <TermosDeUsoHeadingSection title='3. Arcabouço Legal' id='arcabouco_legal'>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Lei nº 8.080, de 19 de setembro de 1990</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Dispõe sobre as condições para a promoção, proteção e recuperação da saúde, a organização e o
                  funcionamento dos serviços correspondentes e dá outras providências.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Lei nº 12.737, de 30 de novembro de 2012</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>Dispõe sobre a tipificação criminal de delitos informáticos.</i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Lei nº 12.965, de 23 de abril de 2014 - Marco Civil da Internet</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>Estabelece princípios, garantias, direitos e deveres para o uso da Internet no Brasil.</i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Lei nº 12.527, de 18 de novembro de 2011 - Lei de Acesso à Informação</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>Regula o acesso a informações previsto na Constituição Federal.</i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Lei nº 13.460, de 26 de junho de 2017</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Dispõe sobre participação, proteção e defesa dos direitos do usuário dos serviços públicos da
                  administração pública.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Lei nº 13.709, de 14 de agosto de 2018</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Dispõe sobre o tratamento de dados pessoais, inclusive nos meios digitais, por pessoa natural ou por
                  pessoa jurídica de direito público ou privado, com o objetivo de proteger os direitos fundamentais de
                  liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Lei nº 13.444, de 11 de maio de 2017</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>Dispõe sobre a Identificação Civil Nacional (ICN).</i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Lei nº 14.510, de 27 de dezembro de 2022</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Altera a Lei nº 8.080, de 19 de setembro de 1990, para autorizar e disciplinar a prática da telessaúde
                  em todo o território nacional, e a Lei nº 13.146, de 6 de julho de 2015; e revoga a Lei nº 13.989, de
                  15 de abril de 2020.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Decreto nº 8.777, de 11 de maio de 2016</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>Institui a Política de Dados Abertos do Poder Executivo federal.</i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Decreto nº 7.724, de 16 de maio de 2012</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Regulamenta a Lei no 12.527, de 18 de novembro de 2011 (Lei de Acesso à Informação), que dispõe sobre
                  o acesso a informações previsto na Constituição.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Decreto nº 7.845, de 14 de novembro de 2012</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Regulamenta procedimentos para credenciamento de segurança e tratamento de informação classificada em
                  qualquer grau de sigilo, e dispõe sobre o Núcleo de Segurança e Credenciamento.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Decreto nº 10.046, de 09 de outubro de 2019</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Dispõe sobre a governança no compartilhamento de dados no âmbito da administração pública federal e
                  institui o Cadastro Base do Cidadão e o Comitê Central de Governança de Dados.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Normas complementares do Gabinete de Segurança da Informação da Presidência (GSI/PR)</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Disciplinam a Gestão de Segurança da Informação e Comunicações na Administração Pública Federal,
                  direta e indireta, e dá outras providências.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Decreto nº 9.637, de 26 de dezembro de 2018</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Institui a Política Nacional de Segurança da Informação, dispõe sobre a governança da segurança da
                  informação, e altera o Decreto nº 2.295, de 4 de agosto de 1997, que regulamenta o disposto no art.
                  24, caput, inciso IX, da Lei nº 8.666, de 21 de junho de 1993, e dispõe sobre a dispensa de licitação
                  nos casos que possam comprometer a segurança nacional.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Portaria de Consolidação MS/GM nº 1, de 28 de setembro de 2017</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Consolidação das normas sobre os direitos e deveres dos usuários da saúde, a organização e o
                  funcionamento do Sistema Único de Saúde.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>
                    Portaria de Consolidação MS/GM nº 1, de 28 de setembro de 2017, arts. 230 a 240 (Origem: PRT MS/GM
                    2073/2011)
                  </i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Regulamenta o uso de padrões de interoperabilidade e informação em saúde para sistemas de informação
                  em saúde no âmbito do Sistema Único de Saúde, nos níveis Municipal, Distrital, Estadual e Federal, e
                  para os sistemas privados e do setor de saúde suplementar.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Portaria nº 1.434, de 28 de maio de 2020</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Institui o Programa Conecte SUS e altera a Portaria de Consolidação nº 1/GM/MS, de 28 de setembro de
                  2017, para instituir a Rede Nacional de Dados em Saúde e dispor sobre a adoção de padrões de
                  interoperabilidade em saúde.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>
                    Portaria de Consolidação MS/GM nº 1, de 28 de setembro de 2017, arts. 305 a 312 (Origem: PRT MS/GM
                    1412/2013)
                  </i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>Institui o Sistema de Informação em Saúde para a Atenção Básica (SISAB).</i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>
                    Portaria de Consolidação MS/GM nº 1, de 28 de setembro de 2017, art. 308, §3º (Origem: PRT MS/GM
                    1113/2015)
                  </i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Altera o § 3º do art. 3º da Portaria nº 1.412/GM/MS, de 10 de julho de 2013, que institui o Sistema de
                  Informação em Saúde para a Atenção Básica (SISAB).
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Portaria nº 2.499, de 23 de setembro de 2019</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>Altera a Portaria de Consolidação nº 1, de 28 de setembro de 2017.</i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Portaria GM/MS nº 3.632, de 21 de dezembro de 2020</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Altera a Portaria de Consolidação GM/MS nº 1, de 28 de setembro de 2017, para instituir a Estratégia
                  de Saúde Digital para o Brasil 2020-2028 (ESD28)
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Portaria de consolidação nº 2, de 28 de setembro de 2017</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>Consolidação das normas sobre as políticas nacionais de saúde do Sistema Único de Saúde.</i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>
                    Portaria de consolidação nº 2, de 28 de setembro de 2017, ANEXO XLII - Política Nacional de
                    Informação e Informática em Saúde (PNIIS) (Redação dada pela PRT GM/MS nº 1.768 de 30.07.2021)
                  </i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Altera o Anexo XLII da Portaria de Consolidação GM/MS nº 2, de 28 de setembro de 2017, para dispor
                  sobre a Política Nacional de Informação e Informática em Saúde (PNIIS).
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Lei nº 14.601, de 19 de Junho de 2023</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Institui o Programa Bolsa Família; altera a Lei nº 8.742, de 7 de dezembro de 1993 (Lei Orgânica da
                  Assistência Social), a Lei nº 10.820, de 17 de dezembro de 2003, que dispõe sobre a autorização para
                  desconto em folha de pagamento, e a Lei nº 10.779, de 25 de novembro de 2003; e revoga dispositivos
                  das Leis nºs 14.284, de 29 de dezembro de 2021, e 14.342, de 18 de maio de 2022, e a Medida Provisória
                  nº 1.155, de 1º de janeiro de 2023.
                </i>
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Portaria GM/MS nº 635, de 22 de maio de 2023</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Institui, define e cria incentivo financeiro federal de implantação, custeio e desempenho para as
                  modalidades de equipes Multiprofissionais na Atenção Primária à Saúde.
                </i>
              </p>
            </TableCell>
          </TableRow>
          {ivcfEnabled && (
            <TableRow>
              <TableCell style={tableCellStyle}>
                <p>
                  <b>
                    <i>
                      Recomendação do Conselho Nacional de Secretários de Saúde, em Manual de avaliação multidimensional
                      da idosa para a Atenção Primária à Saúde.
                    </i>
                  </b>
                </p>
              </TableCell>
              <TableCell style={tableCellStyle}>
                <p>
                  <i>Aplicações do IVCF-20 e do ICOPE - Linha de Cuidado: Saúde da Pessoa Idosa, 2023.</i>
                </p>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell style={tableCellStyle}>
              <p>
                <b>
                  <i>Portaria GM/MS Nº 3.232, de 1º de março de 2024</i>
                </b>
              </p>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <p>
                <i>
                  Altera a Portaria de Consolidação GM/MS nº 5, de 28 de setembro de 2017, para instituir o Programa SUS
                  Digital.
                </i>
              </p>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TermosDeUsoHeadingSection>
  )
}
