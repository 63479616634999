import {
  CategoriaImcPreGestacionalEnum,
  IMC_MAX_EUTROFIA,
  IMC_MAX_SOBREPESO,
  IMC_MIN_EUTROFIA,
  IMC_MIN_OBESIDADE,
  IMC_MIN_SOBREPRESO,
} from './model-graficoGanhoPeso'

export function getCategoriaImcPreGestacional(imcPreGestacional: number): CategoriaImcPreGestacionalEnum {
  if (imcPreGestacional >= IMC_MIN_OBESIDADE) return CategoriaImcPreGestacionalEnum.OBESIDADE
  else if (imcPreGestacional >= IMC_MIN_SOBREPRESO && imcPreGestacional < IMC_MAX_SOBREPESO)
    return CategoriaImcPreGestacionalEnum.SOBREPESO
  else if (imcPreGestacional >= IMC_MIN_EUTROFIA && imcPreGestacional < IMC_MAX_EUTROFIA)
    return CategoriaImcPreGestacionalEnum.EUTROFIA
  else return CategoriaImcPreGestacionalEnum.BAIXO_PESO
}
