import { UnidadeSaudeSelectModel } from 'components/form'
import { AldeiaSelectFieldModel } from 'components/form/field/select/AldeiaSelectField'
import { DseiSelectFieldModel } from 'components/form/field/select/DseiSelectField'
import { PoloBaseSelectFieldModel } from 'components/form/field/select/PoloBaseSelectField'
import createDecorator, { Calculation } from 'final-form-calculate'
import { TipoEnderecoEnum } from 'graphql/types.generated'
import { MetaRoot } from 'util/metaPath'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'

import { VisualizacaoTerritorialBuscaFormModel } from './model'

const createEnderecoIndigenaCalculator = (meta: MetaRoot<VisualizacaoTerritorialBuscaFormModel>): Calculation[] => [
  {
    field: meta.dsei.absolutePath(),
    updates: {
      [meta.poloBase.absolutePath()]: (dsei: DseiSelectFieldModel, allValues: VisualizacaoTerritorialBuscaFormModel) =>
        dsei?.id !== allValues?.poloBase?.dsei.id ? null : allValues?.poloBase,
    },
  },
  {
    field: meta.poloBase.absolutePath(),
    updates: {
      [meta.dsei.absolutePath()]: (
        poloBase: PoloBaseSelectFieldModel,
        allValues: VisualizacaoTerritorialBuscaFormModel
      ) => (!!poloBase?.dsei ? poloBase.dsei : allValues?.dsei),
      [meta.aldeia.absolutePath()]: (
        poloBase: PoloBaseSelectFieldModel,
        allValues: VisualizacaoTerritorialBuscaFormModel
      ) => (poloBase?.id !== allValues?.aldeia?.poloBase?.id ? null : allValues?.aldeia),
    },
  },
  {
    field: meta.aldeia.absolutePath(),
    updates: {
      [meta.poloBase.absolutePath()]: (
        aldeia: AldeiaSelectFieldModel,
        allValues: VisualizacaoTerritorialBuscaFormModel
      ) => (!!aldeia?.poloBase ? aldeia.poloBase : allValues?.poloBase),
      [meta.dsei.absolutePath()]: (aldeia: AldeiaSelectFieldModel, allValues: VisualizacaoTerritorialBuscaFormModel) =>
        !!aldeia?.poloBase?.dsei ? aldeia.poloBase.dsei : allValues?.dsei,
    },
  },
]

const clearEnderecoCalculator = (meta: MetaRoot<VisualizacaoTerritorialBuscaFormModel>): Calculation[] => [
  {
    field: meta.tipoEndereco.absolutePath(),
    updates: {
      [meta.bairro.absolutePath()]: (
        tipoEndereco: TipoEnderecoEnum,
        allValues: VisualizacaoTerritorialBuscaFormModel
      ) => (tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : allValues.bairro),
      [meta.cep.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, allValues: VisualizacaoTerritorialBuscaFormModel) =>
        tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : allValues.cep,
      [meta.tipoLogradouro.absolutePath()]: (
        tipoEndereco: TipoEnderecoEnum,
        allValues: VisualizacaoTerritorialBuscaFormModel
      ) => (tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : allValues.tipoLogradouro),
      [meta.logradouro.absolutePath()]: (
        tipoEndereco: TipoEnderecoEnum,
        allValues: VisualizacaoTerritorialBuscaFormModel
      ) => (tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : allValues.logradouro),
      [meta.dsei.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, allValues: VisualizacaoTerritorialBuscaFormModel) =>
        !tipoEnderecoIndigena.includes(tipoEndereco) ? null : allValues.dsei,
      [meta.poloBase.absolutePath()]: (
        tipoEndereco: TipoEnderecoEnum,
        allValues: VisualizacaoTerritorialBuscaFormModel
      ) => (!tipoEnderecoIndigena.includes(tipoEndereco) ? null : allValues.poloBase),
      [meta.aldeia.absolutePath()]: (
        tipoEndereco: TipoEnderecoEnum,
        allValues: VisualizacaoTerritorialBuscaFormModel
      ) => (!tipoEnderecoIndigena.includes(tipoEndereco) ? null : allValues.aldeia),
    },
  },
  {
    field: meta.unidadeSaude.absolutePath(),
    updates: {
      [meta.equipe.absolutePath()]: (
        unidadeSaude: UnidadeSaudeSelectModel,
        allValues: VisualizacaoTerritorialBuscaFormModel,
        prevValues: VisualizacaoTerritorialBuscaFormModel
      ) =>
        !unidadeSaude || (!!prevValues.unidadeSaude && prevValues.unidadeSaude !== unidadeSaude)
          ? null
          : allValues.equipe,
    },
  },
]

export const createVisualizacaoTerritorialCalculator = (meta: MetaRoot<VisualizacaoTerritorialBuscaFormModel>) =>
  createDecorator(...createEnderecoIndigenaCalculator(meta), ...clearEnderecoCalculator(meta))
