import { SerializedStyles } from '@emotion/core'
import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

interface CompartilhamentoDeDadosSectionProps {
  listStyle: SerializedStyles
}

export const CompartilhamentoDeDadosSection = (props: CompartilhamentoDeDadosSectionProps) => {
  const { listStyle } = props

  return (
    <TermosDeUsoHeadingSection title='9. Compartilhamento de Dados' id='compartilhamento_de_dados'>
      <p>O Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS sistema faz os seguintes compartilhamentos:</p>
      <br />
      <ol type='I' css={listStyle}>
        <li>Dos dados do e-SUS APS com o Centralizador Nacional, do Datasus/Ministério da Saúde;</li>
        <li>
          Dos dados de atendimento individual com a RNDS;
          <ol type='a' css={listStyle}>
            <br />
            <li>
              Dados do cidadão: CPF, CNS, Subjetivo (descrição em texto livre), Observação (descrição em texto livre),
              Avaliação (descrição em texto livre), problema e condição identificada, diagnósticos CID e/ou CIAP2,
              observações dos CID e/ou CIAP2, altura (cm), circunferência abdominal (cm), DUM, idade gestacional,
              perímetro cefálico (cm), peso (kg), pressão arterial (mmHg), abortos, gestas prévias, tipo de aleitamento,
              grau de certeza das alergias/reações adversas, categoria do agenda causador, criticidade, agente ou
              substância específica, manifestações, data, observações, recomendações da prescrição eletrônica, dose,
              intervalo, turno, frequência da dose, via de administração, forma farmacêutica, quantidade, início do
              tratamento, conclusão do tratamento, unidade de fornecimento.{' '}
            </li>
            <li>
              Também são enviados dados referentes à vacinação: Estratégia, Data da administração do imunobiológico,
              Imunobiológico, Fabricante, Lote, Dose, Via de administração, Local de aplicação, Estabelecimento de saúde
              (código CNES), Grupo de atendimento, Condição maternal, data de validade da vacina (transcrição de
              caderneta);
            </li>
            <li>Dados do profissional: CNS, CNES da lotação, CBO da lotação do profissional, Equipe da lotação. </li>
            <br />
          </ol>
        </li>
        <li>
          Dos seguintes dados com o paciente, via e-mail cadastrado, caso haja prescrição eletrônica: Informações do
          estabelecimento de saúde onde foi realizado o atendimento: Estado, Município, Endereço, Telefone 1, Telefone
          2; Informações do prescritor: Nome, Conselho de classe, Estado emissor, Número de registro no conselho, CBO;
          Identificação do cidadão: Nome do cidadão, Nome social, CNS do cidadão, Endereço do cidadão; Medicamentos
          incluídos na prescrição, com as informações: Aplicação / Uso, Nome do medicamento, Recomendações, Posologia,
          Unidade, Quantidade; Data do atendimento, Data e hora da impressão do documento, Nome do profissional
          responsável pela impressão;
        </li>
        <li>
          Dos dados específicos e pertinentes os quais são definidos pelos profissionais de saúde usuários do Prontuário
          Eletrônico do Cidadão (PEC) do Sistema e-SUS APS, com outro profissional da saúde, no caso de videochamada.
        </li>
        <li>
          Dos seguintes dados com o paciente, via e-mail cadastrado, caso haja atestado digital: Informações do
          estabelecimento de saúde onde foi realizado o atendimento: Estado, Município, Endereço, Telefone 1, Telefone
          2; Informações do emissor: Nome, Conselho de classe, Estado emissor, Número de registro no conselho, CBO;
          Identificação do cidadão: Nome do cidadão, Nome social, CPF do cidadão, CNS do cidadão; Conteúdo do atestado,
          Data do atendimento, Data e hora da assinatura do documento, Nome do profissional responsável pela emissão.
        </li>
      </ol>
      <br />
      <p>
        Demais compartilhamentos de dados, que são feitos de outras formas, utilizando dados do Prontuário Eletrônico do
        Cidadão (PEC) do Sistema e-SUS APS - mas que não são feitos pelo próprio Sistema como supramencionado - e
        principalmente quando os estados e municípios operarem qualquer tipo de compartilhamento de dados com outras
        entidades, organizações ou instituições públicas ou privadas devem seguir as diretrizes da ANPD.
      </p>
    </TermosDeUsoHeadingSection>
  )
}
