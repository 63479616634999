/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { ButtonLink } from 'components/route'
import { AtividadeColetivaQueryInput } from 'graphql/types.generated'
import { Fragment, useState } from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import { AtividadeColetivaTable } from '../componentes/AtividadeColetivaTable'

export function AtividadeColetivaReuniaoListaView() {
  const match = useRouteMatch()
  const [filter, setFilter] = useState<AtividadeColetivaQueryInput>({
    pageParams: {
      page: 0,
      size: 10,
    },
  })

  const RegistrarReuniaoButton = () => {
    return (
      <VFlow
        style={css`
          padding-bottom: 2rem;
        `}
      >
        <CheckPermission permission={Permissions.cds.fichaDeAtividadeColetiva.cadastrarEditarEExcluir}>
          <ButtonLink to={`${match.url}/registrar`} kind='primary' size='small'>
            Registrar atividade em reunião
          </ButtonLink>
        </CheckPermission>
      </VFlow>
    )
  }

  return (
    <Fragment>
      <PageHeaderSection title='Atividade em reunião' children={<RegistrarReuniaoButton />} style={styles.header} />

      <PageContent type='transparent'>
        <AtividadeColetivaTable filter={filter} onChangeFilter={setFilter} />
      </PageContent>
    </Fragment>
  )
}

const styles = {
  header: css`
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  `,
}
