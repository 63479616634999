import { Alert, Button, HFlow, Icon, Text, Tooltip, useStyles, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { InfoIcon } from 'components/InfoIcon'
import { useFlags } from 'config/useFlagsContext'
import { CidadaoListingQuery } from 'graphql/types.generated'
import { useRouter } from 'hooks/useRouter'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import Permissions from 'types/Permissions'

import { ModalInformarLoginGovBr } from '../create/ModalInformarLoginGovBr'
import { CidadaoCadsusItemType } from '../types/CadsusModel'
import { CidadaoUrlParams } from '../types/CidadaoUrlParams'
import { useNeedLoginGovBrToCadsus } from '../useNeedLoginGovBrToCadsus'
import { CidadaoCadsusListingItem } from './CidadaoCadsusListItem'
import { CidadaoListingItem } from './CidadaoListingItem'

interface CidadaoListingViewProps {
  result: CidadaoListingQuery
  resultCadsus: CidadaoCadsusItemType[]
  loadingCadsus: boolean
  hasCadsusCalled: boolean
  onBuscarClicked(): void
  refetchLocal(): void
  hasInternetConnection: boolean
  hasCadsusConnection: boolean
  hasCertificadoCadsus: boolean
  cidadaoUrlParams: CidadaoUrlParams
}

export function CidadaoListItemView(props: CidadaoListingViewProps) {
  const { result, resultCadsus, hasInternetConnection, hasCadsusConnection, hasCertificadoCadsus } = props
  const { match } = useRouter()
  const history = useHistory()
  const totalElements = props.result?.cidadaos?.content.length
  const totalElementsCadsus = props.resultCadsus?.length
  const hasMoreResults = props.result?.cidadaos?.content.length > 50
  const isOnline = props.hasInternetConnection && props.hasCadsusConnection
  const { theme } = useStyles()
  const { CIDADAO_FCI_ENABLED } = useFlags()

  const { needLoginGovBr: deveAparecerModalGovBr } = useNeedLoginGovBrToCadsus()

  const [modalInformarLoginGovBrOpen, setModalInformarLoginGovBrOpen] = useState(false)

  const continueAdicionarCidadao = () => {
    history.push(`${match.url}/create${history.location.search}`)
  }

  const onClickAdicionarCidadao = () => {
    if (deveAparecerModalGovBr) {
      setModalInformarLoginGovBrOpen(true)
    } else {
      continueAdicionarCidadao()
    }
  }

  const handleClickAdicionarCidadaoFci = () => {
    history.push(`${match.url}/createfci`)
  }

  return (
    <>
      {result != null ? (
        <VFlow vSpacing={0.5}>
          <HFlow alignItems='baseline'>
            <Text fontWeight='bold' fontSize={1.5}>
              Cadastros na base local
            </Text>
            {totalElements > 0 && !hasMoreResults ? (
              <Text fontSize={1}>
                {totalElements === 1 ? totalElements + ' cidadão encontrado' : totalElements + ' cidadãos encontrados'}
              </Text>
            ) : null}
          </HFlow>
          {totalElements > 0 &&
            !hasMoreResults &&
            result.cidadaos?.content?.map((result) => (
              <CidadaoListingItem
                data={result}
                key={result.id}
                refetchLocal={props.refetchLocal}
                cidadaoUrlParams={props.cidadaoUrlParams}
              />
            ))}
          {!totalElements && !hasMoreResults && <Text>Nenhum resultado encontrado.</Text>}
          {hasMoreResults && <Text>Refine a busca incluindo mais informações.</Text>}

          <HFlow alignItems='baseline' style={{ marginTop: '1rem' }}>
            <HFlow hSpacing={0.5} alignItems='center'>
              <Text fontWeight='bold' fontSize={1.5}>
                Cadastros na base nacional
              </Text>
              {
                <InfoIcon
                  icon='infoCircleFilled'
                  text={
                    hasCertificadoCadsus
                      ? 'Para pesquisar na base nacional, é necessário configurar o certificado e estar logado através do gov.br.'
                      : 'Para pesquisar na base nacional, é necessário configurar o certificado e estar logado através do gov.br. Entre em contato com o administrador municipal.'
                  }
                />
              }
            </HFlow>

            {totalElementsCadsus > 0 ? (
              <Text fontSize={1}>
                {totalElementsCadsus === 1
                  ? totalElementsCadsus + ' cidadão encontrado'
                  : totalElementsCadsus + ' cidadãos encontrados'}
              </Text>
            ) : null}
          </HFlow>

          {!hasInternetConnection ? (
            <Alert inline type='warning'>
              Não foi possível buscar na base nacional, pois a instalação está offline. Entre em contato com o
              responsável pela instalação para mais informações.
            </Alert>
          ) : (
            !hasCadsusConnection && (
              <Alert inline type='warning'>
                Não foi possível buscar na base nacional, pois a conexão com o serviço está desabilitada. Entre em
                contato com o responsável pela instalação para mais informações.
              </Alert>
            )
          )}

          {isOnline && props.result && !props.resultCadsus && (
            <Tooltip
              text={
                hasCertificadoCadsus
                  ? 'Para pesquisar na base nacional, é necessário configurar o certificado e estar logado através do gov.br.'
                  : 'Para pesquisar na base nacional, é necessário configurar o certificado e estar logado através do gov.br. Entre em contato com o administrador municipal.'
              }
            >
              <Button
                size='large'
                block
                loading={props.loadingCadsus}
                onClick={props.onBuscarClicked}
                disabled={!hasCertificadoCadsus}
              >
                <Icon icon='zoomOutline' style={{ marginRight: '0.5rem' }} />
                Pesquisar na base nacional
              </Button>
            </Tooltip>
          )}
          {props.resultCadsus?.map((result) => (
            <CidadaoCadsusListingItem data={result} key={result.cns} />
          ))}
          {resultCadsus != null && !resultCadsus?.length && <Text>Nenhum resultado encontrado.</Text>}
          <CheckPermission permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar}>
            {props.result != null && (
              <>
                <div
                  style={{ borderTop: `1px solid ${theme.pallete.divider}`, marginTop: '1.6rem', marginBottom: '1rem' }}
                />
                <HFlow justifyContent='flex-end'>
                  {CIDADAO_FCI_ENABLED && (
                    <Button kind='primary' skin='default' size='medium' onClick={handleClickAdicionarCidadaoFci}>
                      Adicionar cidadão FCI
                    </Button>
                  )}
                  <Button kind='primary' skin='default' size='medium' onClick={onClickAdicionarCidadao}>
                    Adicionar cidadão
                  </Button>
                </HFlow>
              </>
            )}
          </CheckPermission>
          <ModalInformarLoginGovBr
            open={modalInformarLoginGovBrOpen}
            onClose={() => setModalInformarLoginGovBrOpen(false)}
            onConfirm={continueAdicionarCidadao}
          />
        </VFlow>
      ) : (
        <VFlow style={{ marginTop: '1rem' }}>
          <Text fontWeight='bold' fontSize={1}>
            Busque um cidadão pelo seu nome, CPF, CNS ou demais informações.
          </Text>
        </VFlow>
      )}
    </>
  )
}
