/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, HFlow, Theme, useTheme } from 'bold-ui'
import { useOrientationDetection } from 'hooks/useOrientationDetection'
import { useViewportType } from 'hooks/useViewportType'
import { noop } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { ObjectFitEnum, VideocallStream, VideoOrientationEnum } from '../model-videochamada'
import { StreamPlayerVideochamada, StreamPlayerVideochamadaProps } from './StreamPlayerVideochamada'

interface StreamPlayersVideochamadaLayoutProps {
  streams: VideocallStream[]
  pipActive?: boolean
  setPipActive?(newVal: boolean): void
}

export function StreamPlayersVideochamadaLayout(props: StreamPlayersVideochamadaLayoutProps) {
  const { streams } = props

  const anyPresenting = streams.some((stream) => stream.presenting)

  return anyPresenting || streams.length > 2 ? (
    <StreamPlayersVideochamadaPresentationLayout {...props} />
  ) : (
    <StreamPlayersVideochamadaVideocallLayout {...props} />
  )
}

function StreamPlayersVideochamadaPresentationLayout(props: StreamPlayersVideochamadaLayoutProps) {
  const { streams, pipActive = false, setPipActive } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const [participants, presentations] = streams.reduce(
    (partition, stream) => {
      partition[+stream.presenting].push(stream)
      return partition
    },
    [[], []] as [VideocallStream[], VideocallStream[]]
  )

  const remotePresentation = presentations.find((presentation) => presentation.remote)
  const pipStreamId = (remotePresentation ?? participants.find((participant) => participant.remote))?.id

  return (
    <div css={styles.presentationLayoutContainer}>
      {presentations.length > 0 && (
        <div css={styles.presentationsContainer}>
          {presentations.map((stream) => (
            <StreamPlayerVideochamada
              key={stream.id}
              nomeParticipante={stream.label}
              stream={stream.stream}
              corParticipante={stream.color}
              fit='contain'
              showMicrophoneFeedback={false}
              style={css(styles.presentation, pipActive && stream.id === pipStreamId ? styles.presentationOnPip : null)}
              muted
              pipActive={pipActive && stream.id === pipStreamId}
              setPipActive={pipActive && stream.id === pipStreamId ? setPipActive : noop}
              disablePictureInPicture={!stream.remote}
            />
          ))}
        </div>
      )}
      <HFlow justifyContent='center' style={styles.participantsContainer}>
        {participants.sort(remoteFirst).map((stream) => (
          <ResponsiveStreamPlayerVideochamada
            key={stream.id}
            stream={stream}
            pipActive={stream.id === pipStreamId && pipActive}
            setPipActive={stream.id === pipStreamId && pipActive ? setPipActive : noop}
            multipleStreams={streams.length > 1}
            presentations={presentations?.length > 0}
            muted={!stream.remote}
            style={styles.presentationParticipant}
            localStream={!stream.remote}
          />
        ))}
      </HFlow>
    </div>
  )
}

function StreamPlayersVideochamadaVideocallLayout(props: StreamPlayersVideochamadaLayoutProps) {
  const { streams, pipActive = false, setPipActive } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const pipStreamId = streams.find((stream) => stream.remote)?.id

  return (
    <div css={styles.playersContainer}>
      {streams.map((stream) => (
        <ResponsiveStreamPlayerVideochamada
          key={stream.id}
          stream={stream}
          pipActive={pipActive}
          setPipActive={setPipActive}
          pipStreamId={pipStreamId}
          multipleStreams={streams.length > 1}
          localStream={!stream.remote}
        />
      ))}
    </div>
  )
}

interface ResponsiveStreamPlayerVideochamadaProps extends Partial<Omit<StreamPlayerVideochamadaProps, 'stream'>> {
  stream: VideocallStream
  pipStreamId?: ID
  multipleStreams: boolean
  presentations?: boolean
  localStream?: boolean
  pipActive?: boolean
  setPipActive?(newVal: boolean): void
  style?: ExternalStyles
}

function ResponsiveStreamPlayerVideochamada(props: ResponsiveStreamPlayerVideochamadaProps) {
  const {
    stream,
    pipStreamId,
    multipleStreams,
    presentations,
    style: externalStyle,
    localStream = false,
    pipActive = false,
    setPipActive,
    ...rest
  } = props

  const [videoOrientation, setVideoOrientation] = useState<VideoOrientationEnum>()
  const [videoWidth, setVideoWidth] = useState(0)
  const [videoHeight, setVideoHeight] = useState(0)

  const [isPortraitVideo, setIsPortraitVideo] = useState(false)
  const [isPortraitDevice, setIsPortraitDevice] = useState(false)

  const { isMobile, isTablet } = useViewportType()
  const orientationDevice = useOrientationDetection()

  const theme = useTheme()
  const styles = createStyles(theme, videoHeight)

  const handleVideoResize = useCallback(
    (videoWidth: number, videoHeight: number) => {
      const videoStreamAtiva = stream?.stream?.getVideoTracks().some((track) => track.enabled)
      if (videoStreamAtiva) {
        const newOrientation = videoHeight > videoWidth ? VideoOrientationEnum.PORTRAIT : VideoOrientationEnum.LANDSCAPE
        newOrientation !== videoOrientation && setVideoOrientation(newOrientation)
      }
    },
    [videoOrientation, stream]
  )

  useEffect(() => {
    setIsPortraitDevice(orientationDevice === VideoOrientationEnum.PORTRAIT)
    setIsPortraitVideo(isPortrait(videoOrientation))
    const height = (videoWidth * 16) / 9
    height && setVideoHeight(height)
  }, [orientationDevice, videoOrientation, videoWidth])

  const videoFit = () => {
    let objectFitValue = ObjectFitEnum.CONTAIN

    if (presentations) {
      objectFitValue = localStream || isPortraitVideo ? ObjectFitEnum.CONTAIN : ObjectFitEnum.COVER
    } else {
      if ((isMobile || isTablet) && isPortraitDevice) {
        objectFitValue = isPortraitVideo ? ObjectFitEnum.COVER : ObjectFitEnum.CONTAIN
      } else {
        objectFitValue =
          localStream && multipleStreams
            ? ObjectFitEnum.CONTAIN
            : isPortraitVideo
            ? ObjectFitEnum.CONTAIN
            : ObjectFitEnum.COVER
      }
    }

    return objectFitValue
  }

  const playerStyles = [
    styles.video,
    localStream &&
      multipleStreams && [
        !presentations && styles.camMinimizada,
        !presentations &&
          (isMobile
            ? isPortraitDevice && styles.camPortrait
            : isPortraitVideo && isPortraitDevice && styles.camPortrait),
      ],
    externalStyle,
  ].filter(Boolean)

  return (
    <StreamPlayerVideochamada
      key={stream.id}
      nomeParticipante={stream.label}
      stream={stream.stream}
      corParticipante={stream.color}
      muted={localStream}
      fit={videoFit()}
      style={playerStyles}
      pipActive={stream.id === pipStreamId && pipActive}
      setPipActive={stream.id === pipStreamId && pipActive ? setPipActive : noop}
      disablePictureInPicture={localStream}
      onVideoResize={handleVideoResize}
      setVideoWidth={setVideoWidth}
      {...rest}
    />
  )
}

const isPortrait = (orientation: VideoOrientationEnum) => orientation === VideoOrientationEnum.PORTRAIT

const remoteFirst = (a: VideocallStream, b: VideocallStream): number => Number(b.remote) - Number(a.remote)

const createStyles = (theme: Theme, videoHeight?: number) => ({
  playersContainer: css`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: 0.125rem;
    overflow: hidden;
    background: ${theme.pallete.gray.c20};
  `,
  video: css`
    transition: width 0.3s linear;
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: relative;
    align-self: center;
    ${theme.breakpoints.down('sm')} {
      min-height: 0;
    }
  `,
  camMinimizada: css`
    background: ${theme.pallete.gray.c30};
    z-index: 3;
    position: absolute;
    bottom: 1rem;
    border-radius: 0.125rem;
    overflow: hidden;
    right: 1rem;
    min-height: 0;
    width: 17.5rem;
    height: 10rem;

    ${theme.breakpoints.down('sm')} {
      right: 0.5rem;
    }
  `,

  camPortrait: css`
    ${theme.breakpoints.down('md')} {
      width: 25%;
      height: ${videoHeight}px;
    }
  `,
  presentationLayoutContainer: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: stretch;
    justify-content: flex-start;
  `,
  presentationsContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-direction: column;
    padding-bottom: 1rem;
    overflow: hidden;
    height: 0;
  `,
  participantsContainer: css`
    display: flex;
    height: 20%;
    min-height: 7rem;

    ${theme.breakpoints.up('sm')} {
      height: auto;
      min-height: auto;
    }

    ${theme.breakpoints.up('md')} {
      display: grid;
    }
  `,
  presentationParticipant: css`
    border-radius: 0.125rem;
    height: 100%;
    max-width: 50%;

    ${theme.breakpoints.up('sm')} {
      min-height: 10rem;
      height: 10rem;
      width: 16rem;
      max-width: 100%;
    }
  `,
  presentation: css`
    height: 100%;
    border-radius: 0.125rem;

    &:first-of-type:nth-last-child(n + 2) {
      margin-bottom: 0.5rem;
    }
  `,
  presentationOnPip: css`
    flex-grow: 1;
  `,
})
