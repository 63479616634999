/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HFlow, Radio, Theme, useTheme, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { css } from 'emotion'
import { ImovelDto } from 'graphql/types.generated'
import { formatDate } from 'util/date/formatDate'
import { StatusCadastroImovelTag } from 'view/visualizacaoTerritorial/components/list/StatusCadastroImovelTag'

interface ImovelDuplicadoCardProps {
  imovel: ImovelDto
  isSelected: boolean
  onSelectImovel(): void
}

export const ImovelDuplicadoCard = (props: ImovelDuplicadoCardProps) => {
  let { imovel, isSelected, onSelectImovel } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <HFlow style={styles.container} onClick={onSelectImovel}>
      <HFlow>
        <Radio checked={isSelected} readOnly={true} label='' />
        <VFlow vSpacing={0}>
          <HLabel placeholder='Não informado' title='Unidade de Saúde'>
            {imovel.unidadeSaude?.nome}
          </HLabel>
          <HLabel placeholder='Não informado' title='Equipe'>
            {[imovel.equipe?.nome, imovel.equipe?.ine].filter(Boolean).join(' - ')}
          </HLabel>
          <HLabel placeholder='Não informado' title='Microárea'>
            {imovel.microarea}
          </HLabel>
          <HLabel placeholder='Não informado' title='Nº de famílias'>
            {imovel.familias?.length}
          </HLabel>
          <HLabel placeholder='Não informado' title='Atualizado em'>
            {imovel.dataUltimaAtualizacao && formatDate(imovel.dataUltimaAtualizacao)}
          </HLabel>
        </VFlow>
      </HFlow>
      <HFlow>
        <StatusCadastroImovelTag isCadastroCompleto={imovel.isCadastroCompleto} isStatusRecusaCadastro={false} />
      </HFlow>
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    border: ${`1px solid ${theme.pallete.gray.c70}`};
    grid-template-columns: 1fr auto;
    padding: 1rem;
    border-radius: 2px;
  `,
})
