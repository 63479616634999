/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, Heading, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'

interface RecusaCadastroModalProps {
  open: boolean
  onClose(): void
}

export function RecusaCadastroModal(props: RecusaCadastroModalProps) {
  const { open, onClose } = props

  return (
    <Modal open={open} onClose={onClose} size='small' closeOnBackdropClick={false}>
      <ModalBody>
        <VFlow vSpacing={1}>
          <Heading level={1}>Recusa de cadastro</Heading>
          <Text component='p'>
            A recusa do cidadão ao cadastro não implicará no não atendimento deste na unidade de saúde, bem como
            quaisquer outras formas de discriminação.
          </Text>
          <Text component='p'>
            Solicite ao entrevistado que assine o termo de recusa para assegurar que ele está ciente.
          </Text>
          <Text component='p'>
            Em situações em que o cidadão se recuse também a assinar o termo, a validação dessa informação deve ser
            discutida com a supervisão e/ou coordenação desta equipe.
          </Text>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button kind='primary' size='medium' onClick={onClose}>
            Entendi
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
