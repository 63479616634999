/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, FormControl, Grid, HFlow, Text, Tooltip, useTheme, VFlow } from 'bold-ui'
import { SwitchField, TextAreaField } from 'components/form'
import { CiapSelectField, CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import { ProblemaCondicaoSelectField } from 'components/form/field/select/ProblemaCondicaoSelectField/ProblemaCondicaoSelectField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { anyFieldIsTouched } from 'components/form/final-form/util'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { CiapCapituloEnum, SexoEnum, SituacaoProblema } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { max } from 'lodash'
import { Fragment, SyntheticEvent, useMemo, useRef } from 'react'
import { useField } from 'react-final-form'
import { emptyArray } from 'util/array'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsYyyyMmDd, toDate } from 'util/date/formatDate'
import { isEmpty } from 'util/validation/Util'
import { OBSERVACAO_DISABLE_SWITCH_INSERIR_NA_LPC } from 'view/atendimentos/atendimento-individual/atendimento-observacao/model-atendObservacao'
import { CiapCidPreNatal, meta } from 'view/atendimentos/atendimento-individual/model'
import {
  EditableListForm,
  EditableListFormProps,
  EditableListFormRenderProps,
} from 'view/atendimentos/detail/components/EditableListForm'

import { Problema } from '../../../aside/types/ProblemaModel'
import { DataIdadeField, DataIdadeFieldProps } from '../../../components/DataIdadeField'
import { FormAtivoObjetivoEnum } from '../../../objetivo/components/SwitchButtonObjetivoForm'
import { IDADE_GESTACIONAL_MAXIMA_EM_DIAS, TipoPreNatal } from '../../../pre-natal/model-preNatal'
import {
  informationPreNatalQuandoExisteW78ResolvidoNaAvaliacao,
  informationPreNatalSuperiorA336Dias,
  informationW78ResolvidoNaAvaliacaoQuandoExistePreNatal,
  isCiapOuCidDeGestacao,
  isDumForaDoLimitePermitido,
  isInicioOuContinuacaoPreNatal,
} from '../../../pre-natal/util-preNatal'
import { createProblemaCondicoesCalculator } from './calculator-problemasCondicoes'
import { AlertaEncerrarGestacao } from './components/AlertaEncerrarGestacao'
import { ProblemasCondicoesFormFooterButtons } from './components/ProblemasCondicoesFormFooterButtons'
import { ProblemasCondicoesFormModalButtons } from './components/ProblemasCondicoesFormModalButtons'
import { SituacaoProblemaField } from './components/SituacaoProblemaField'
import { ProblemaCondicaoModel, TodosProblemasCondicoesModel } from './model-problemasCondicoes'
import {
  informationDesfechoQuandoExistePreNatal,
  informationDesfechoQuandoExistePuericultura,
  informationDumForaDoLimitePermitido,
  informationPreNatalQuandoExisteDesfecho,
  informationPreNatalQuandoExisteIvcf,
  informationPreNatalQuandoExistePuericultura,
} from './utils/messages'
import {
  hasProblemaComCiapW78Ativo,
  isEvolucao,
  isProblemaCondicaoComCiapW78,
  isProblemaCondicaoComCiapW78Resolvido,
  isProblemaCondicaoDeGestacao,
  isProblemaCondicaoDePreNatal,
  isProblemaCondicaoGravidezAltoRisco,
  isProblemaNaoEvolucaoEncerraGestacao,
} from './utils/verifications-problemasCondicoes'
import { problemasCondicoesValidator } from './validator-problemasCondicoes'

export interface ProblemasCondicoesFormProps
  extends Pick<EditableListFormProps<ProblemaCondicaoModel>, 'initialValues' | 'onSubmit' | 'initialValuesEqual'>,
    TodosProblemasCondicoesModel {
  edicao?: boolean
  prontuarioId: ID
  onCancel?(): void
  dataAtendimento?: Instant
  problemasAtivosLatentes: Problema[]
  ciapCidPreNatal?: CiapCidPreNatal
  tipoPreNatal?: TipoPreNatal

  isLPC?: boolean
  isAntecedentes?: boolean
  hasIvcfPreenchidoEmAtendObservacaoAnterior?: boolean
  style?: {
    dataIdadeField?: DataIdadeFieldProps['style']
  }
}

export function ProblemasCondicoesForm(props: ProblemasCondicoesFormProps) {
  const {
    edicao = false,
    problemasCondicoesAvaliados = emptyArray,
    problemasCondicoesAnteriores = emptyArray,
    onCancel,
    prontuarioId,
    dataAtendimento,
    initialValues,
    problemasAtivosLatentes,
    ciapCidPreNatal,
    tipoPreNatal,
    isLPC = false,
    isAntecedentes,
    style,
    onSubmit,
    hasIvcfPreenchidoEmAtendObservacaoAnterior,
    ...rest
  } = props

  const theme = useTheme()

  const { analytics } = useFirebase()

  const todosProblemas = [...problemasCondicoesAvaliados, ...problemasCondicoesAnteriores]
  const problemasToDisabled = [
    ...problemasCondicoesAvaliados,
    ...problemasCondicoesAnteriores.filter((problema) => problema.situacaoProblema !== SituacaoProblema.RESOLVIDO),
  ]

  const rootRef = useRef<HTMLDivElement>(null)

  const {
    input: { value: tipoAtendimento, onChange: onUpdateFormAtivo },
  } = useField<FormAtivoObjetivoEnum>(meta.objetivo.atendimentosEspecificos.formAtivo.absolutePath(), {
    subscription: { value: true },
  })

  const {
    input: { value: ivcfValue },
    tools: { resetToUndefined: resetIvcf },
  } = usePecField({ name: meta.objetivo.atendimentosEspecificos.ivcf.absolutePath(), subscription: { value: true } })

  const {
    input: { value: ivcfCacheValue },
    tools: { resetToUndefined: resetIvcfCache },
  } = usePecField({
    name: meta.objetivo.atendimentosEspecificos.ivcfCache.absolutePath(),
    subscription: { value: true },
  })

  const {
    input: { value: dum },
  } = useField<string>(meta.objetivo.dum.absolutePath(), {
    subscription: { value: true },
  })

  const {
    cidadao: {
      idadeEmAnos,
      isGestante,
      dataInicioGestacao,
      idadeGestacional,
      dataNascimento,
      identidadeGeneroDbEnum,
      sexo,
    },
    atendimentoProfissional: { iniciadoEm },
    permissoes: { hasPermissionPreNatal, somenteCiap },
  } = useAtendimentoContext()

  const prefix = edicao ? initialValues._id ?? 'edit' : 'new'
  const dataNascimentoAsDate = toDate(dataNascimento)
  const isMasculinoAndNotHaveIdentidadeGenero = sexo === SexoEnum.MASCULINO && identidadeGeneroDbEnum === null
  const hasProblemaComCiapW78AtivoPersistido = hasProblemaComCiapW78Ativo(problemasAtivosLatentes)

  const decorators = useMemo(
    () =>
      createProblemaCondicoesCalculator(
        problemasAtivosLatentes,
        dataNascimento,
        ciapCidPreNatal,
        somenteCiap,
        prefix,
        dateAsYyyyMmDd(toDate(dataAtendimento)),
        !edicao && isLPC,
        isAntecedentes
      ),
    [
      problemasAtivosLatentes,
      dataNascimento,
      ciapCidPreNatal,
      somenteCiap,
      prefix,
      dataAtendimento,
      edicao,
      isLPC,
      isAntecedentes,
    ]
  )

  const ciapIsEqual = (item: CiapSelectFieldModel) => {
    return (problemasToDisabled || []).some((o2) => item.id === o2.ciap.id)
  }

  const problemaIsEqual = (item: Problema) => {
    return (problemasToDisabled || []).some((o2) => {
      return item.id === o2.problemaId || (item.ciap?.id === o2.ciap?.id && item.cid10?.id === o2.cid?.id)
    })
  }

  const handleSubmit = (values: ProblemaCondicaoModel, formApi: FormApi<ProblemaCondicaoModel>) => {
    onSubmit({ ...values, observacao: values.observacao?.trim(), editingId: initialValues?.editingId }, formApi)
  }

  const renderForm = ({
    form,
    values,
    error,
    handleSubmit: renderFormHandleSubmit,
    name,
  }: EditableListFormRenderProps<ProblemaCondicaoModel>) => {
    const reset = () => {
      form.reset()
      form.getRegisteredFields().forEach((field) => form.resetFieldState(field))
    }
    const onSubmit = async (
      event?: Partial<Pick<SyntheticEvent<Element, Event>, 'preventDefault' | 'stopPropagation'>>
    ) => {
      event.preventDefault()
      if (!isLPC) {
        const isProblemaNaoEvolucaoEncerraGravidez = isProblemaNaoEvolucaoEncerraGestacao(values)
        const isAtendimentoPuericultura = tipoAtendimento === FormAtivoObjetivoEnum.PUERICULTURA
        const isAtendimentoIvcf = tipoAtendimento === FormAtivoObjetivoEnum.IVCF
        if (isInicioOuContinuacaoPreNatal(tipoPreNatal)) {
          if (isProblemaNaoEvolucaoEncerraGravidez) {
            informationDesfechoQuandoExistePreNatal()
            return null
          } else if (isProblemaCondicaoComCiapW78Resolvido(values)) {
            informationW78ResolvidoNaAvaliacaoQuandoExistePreNatal()
            return null
          }
        }
        if (isAtendimentoIvcf && isCiapOuCidDeGestacao(values?.ciap?.codigo, values?.cid?.codigo)) {
          if (hasIvcfPreenchidoEmAtendObservacaoAnterior) {
            informationPreNatalQuandoExisteIvcf()
            return null
          } else {
            const isDescartarIvcf =
              !isEmpty(ivcfCacheValue) || !isEmpty(ivcfValue)
                ? await confirm({
                    title: 'Deseja descartar os dados do IVCF-20?',
                    body:
                      'Ao adicionar essa condição, os campos de Pré-natal serão habilitados e os dados contidos nos campos do IVCF-20 serão perdidos.',
                    cancelLabel: 'Não, manter dados',
                    confirmLabel: 'Sim, descartar',
                    onConfirm: () => {
                      resetIvcf()
                      resetIvcfCache()
                    },
                  })()
                : true

            if (isDescartarIvcf) {
              await onUpdateFormAtivo(null)
              rootRef?.current?.scrollIntoView()
            } else {
              return null
            }
          }
        }
        if (isProblemaNaoEvolucaoEncerraGravidez && isAtendimentoPuericultura) {
          informationDesfechoQuandoExistePuericultura()
          return null
        } else if (isProblemaCondicaoDePreNatal(values, hasProblemaComCiapW78AtivoPersistido)) {
          if (isDumForaDoLimitePermitido(dataAtendimento, dum)) {
            informationDumForaDoLimitePermitido(dum)
            return null
          }
          if (tipoPreNatal === TipoPreNatal.ENCERRAMENTO_GESTACAO) {
            informationPreNatalQuandoExisteDesfecho()
            return null
          } else if (tipoPreNatal === TipoPreNatal.W78_RESOLVIDO_NA_AVALIACAO) {
            informationPreNatalQuandoExisteW78ResolvidoNaAvaliacao()
            return null
          } else if (isAtendimentoPuericultura) {
            informationPreNatalQuandoExistePuericultura()
            return null
          } else if (isGestante && idadeGestacional > IDADE_GESTACIONAL_MAXIMA_EM_DIAS) {
            informationPreNatalSuperiorA336Dias(dataInicioGestacao)
            return null
          }
        }
      }

      return renderFormHandleSubmit()
    }

    const empty = !values?.ciap?.id && !values?.cid?.id
    const isProblemaDePreNatal = isProblemaCondicaoDeGestacao(values)
    const isProblemaAltoRisco = isProblemaCondicaoGravidezAltoRisco(values)
    const isCiapW78 = isProblemaCondicaoComCiapW78(values)
    const isInitialValueIncluidoNaListaAndEditing =
      !isUndefinedOrNull(values?.editingId) && !!initialValues?.incluirNaListaProblemas

    const isSituacaoProblemaResolvido = values?.situacaoProblema === SituacaoProblema.RESOLVIDO

    const isDataFimRequired = isSituacaoProblemaResolvido && isCiapW78

    const disabledIncluirListaProblemas =
      empty ||
      isProblemaDePreNatal ||
      isProblemaNaoEvolucaoEncerraGestacao(values) ||
      isInitialValueIncluidoNaListaAndEditing

    const isLpcOrEdicao = isLPC || edicao

    const deveExibirAlertaEncerrarGestacao = isGestante && hasPermissionPreNatal && !isLpcOrEdicao

    const disabledCiapCid =
      !disabledIncluirListaProblemas && !!values?.incluirNaListaProblemas && !values?.problemaCondicaoEvoluir

    const ciapDisabled =
      (!!values?.problemaCondicaoEvoluir && !!values?.problemaCondicaoEvoluir?.ciap) || disabledCiapCid

    const cidDisabled =
      (!!values?.problemaCondicaoEvoluir && !!values?.problemaCondicaoEvoluir?.cid10) || disabledCiapCid || isCiapW78

    const tooltipText = isInitialValueIncluidoNaListaAndEditing
      ? OBSERVACAO_DISABLE_SWITCH_INSERIR_NA_LPC
      : (empty && 'Necessário informar CIAP2 ou CID10.') ||
        (isCiapW78 && 'A CIAP2/CID10 de pré-natal é adicionada automaticamente na lista de problemas/condições.')

    return (
      <VFlow
        style={
          isLpcOrEdicao &&
          css`
            margin-left: -1rem;
            margin-right: -1rem;
          `
        }
      >
        {!isLpcOrEdicao && (
          <div
            ref={rootRef}
            css={css`
              background-color: ${theme.pallete.gray.c90};
              padding: 1rem;
              border-bottom: 1px ${theme.pallete.divider} solid;
            `}
          >
            <Grid>
              <Cell size={6}>
                <ProblemaCondicaoSelectField
                  name={name.problemaCondicaoEvoluir}
                  itemIsEqual={problemaIsEqual}
                  problemas={problemasAtivosLatentes}
                  onChange={() => analytics.logEvent('select_problema_condicao_pesquisa_AV')}
                  label='Pesquisar por problemas/condições ativos ou latentes do cidadão'
                />
              </Cell>
            </Grid>
          </div>
        )}
        <div
          css={css`
            padding: 0 1rem 1rem 1rem;
          `}
        >
          <Grid gap={1}>
            {anyFieldIsTouched(form) && error && (
              <Cell size={12}>
                <Text color='danger'>{error}</Text>
              </Cell>
            )}
            {deveExibirAlertaEncerrarGestacao && (
              <Cell size={12}>
                <AlertaEncerrarGestacao />
              </Cell>
            )}
            <Cell size={6}>
              {edicao ? (
                <FormControl label='CIAP 2'>
                  {values?.ciap ? `${values?.ciap.descricao} (${values?.ciap.codigo})` : '-'}
                </FormControl>
              ) : (
                <Tooltip
                  text={
                    ciapDisabled && 'CIAP2 de problema inserido na Lista de problemas/condições não pode ser alterada.'
                  }
                >
                  <CiapSelectField
                    name={name.ciap}
                    label='CIAP 2'
                    itemIsEqual={somenteCiap && !isLPC && ciapIsEqual}
                    sexo={isEmpty(identidadeGeneroDbEnum) ? sexo : undefined}
                    capitulosExcluidos={[CiapCapituloEnum.PROCEDIMENTOS]}
                    excluirCIAPsDAB
                    disabled={ciapDisabled}
                    menuMinWidth={620}
                    idadeCidadaoEmAnos={idadeEmAnos}
                    data-testid={`ProblemasCondicoesForm.ciap`}
                  />
                </Tooltip>
              )}
            </Cell>
            {!somenteCiap || values?.problemaCondicaoEvoluir ? (
              <Fragment>
                <Cell size={6}>
                  {somenteCiap || edicao ? (
                    <FormControl label='CID 10'>
                      {values?.cid ? `${values?.cid.nome} (${values?.cid.codigo})` : '-'}
                    </FormControl>
                  ) : (
                    <Tooltip
                      text={
                        cidDisabled &&
                        !isProblemaDePreNatal &&
                        'CID10 de problema inserido na Lista de problemas/condições não pode ser alterada.'
                      }
                    >
                      <CidSelectField
                        name={name.cid}
                        label='CID 10'
                        sexo={isEmpty(identidadeGeneroDbEnum) ? sexo : undefined}
                        ciapRelacionada={values?.ciap?.id}
                        disabled={cidDisabled}
                        menuMinWidth={620}
                        idadeCidadaoEmAnos={idadeEmAnos}
                      />
                    </Tooltip>
                  )}
                </Cell>
                {values?.problemaCondicaoEvoluir?.descricaoOutro && (
                  <Cell size={12}>
                    <FormControl label='Outro'>{values?.problemaCondicaoEvoluir?.descricaoOutro}</FormControl>
                  </Cell>
                )}
              </Fragment>
            ) : (
              values?.problemaCondicaoEvoluir?.descricaoOutro && (
                <Cell size={12}>
                  <FormControl label='Outro'>{values?.problemaCondicaoEvoluir?.descricaoOutro}</FormControl>
                </Cell>
              )
            )}
            {!isLPC && (
              <Cell size={12}>
                <HFlow
                  justifyContent='space-between'
                  style={css`
                    height: 2rem;
                  `}
                >
                  <Tooltip text={tooltipText}>
                    <SwitchField
                      name={name.incluirNaListaProblemas}
                      label='Incluir na lista de problemas/condições'
                      disabled={disabledIncluirListaProblemas}
                    />
                  </Tooltip>
                  {!values?.incluirNaListaProblemas && (
                    <ProblemasCondicoesFormFooterButtons edicao={edicao} onSubmit={onSubmit} onCancel={onCancel} />
                  )}
                </HFlow>
              </Cell>
            )}
          </Grid>
          {(values?.incluirNaListaProblemas || isLPC) && (
            <Grid gap={1}>
              <Cell
                size={12}
                style={css`
                  padding-top: 1rem;
                `}
              >
                <SituacaoProblemaField
                  name={name.situacaoProblema}
                  isAntecedentes={isAntecedentes}
                  isCiapW78={isCiapW78}
                  isProblemaAltoRisco={isProblemaAltoRisco}
                  isSituacaoProblemaResolvido={isSituacaoProblemaResolvido}
                  edicao={edicao}
                  isLPC={isLPC}
                  isEvolucao={isEvolucao(values)}
                  tipoPreNatal={tipoPreNatal}
                />
              </Cell>
              <Cell xs={isLPC ? 6 : 12}>
                <DataIdadeField
                  name={name.dataInicio}
                  label='Início'
                  dataNascimentoCidadao={dataNascimento}
                  style={style?.dataIdadeField}
                  minDate={dataNascimentoAsDate}
                  maxDate={iniciadoEm}
                />
              </Cell>
              <Cell xs={isLPC ? 6 : 12}>
                {values?.situacaoProblema && values?.situacaoProblema !== SituacaoProblema.ATIVO && (
                  <DataIdadeField
                    name={name.dataFim}
                    label='Fim'
                    dataNascimentoCidadao={dataNascimento}
                    style={style?.dataIdadeField}
                    required={isDataFimRequired}
                    minDate={max([dataNascimentoAsDate, toDate(values.dataInicio?.data)])}
                    maxDate={iniciadoEm}
                  />
                )}
              </Cell>
              <Cell size={12}>
                <TextAreaField name={name.observacao} label='Observações' maxLength={200} resize='vertical' />
              </Cell>
              <Cell size={12}>
                {!isLPC ? (
                  <ProblemasCondicoesFormFooterButtons edicao={edicao} onSubmit={onSubmit} onCancel={onCancel} />
                ) : (
                  <ProblemasCondicoesFormModalButtons
                    editing={edicao}
                    reset={reset}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                  />
                )}
              </Cell>
            </Grid>
          )}
        </div>
      </VFlow>
    )
  }

  return (
    <EditableListForm<ProblemaCondicaoModel>
      validate={problemasCondicoesValidator(
        somenteCiap,
        todosProblemas,
        !!edicao,
        props.dataAtendimento,
        dataNascimento,
        problemasAtivosLatentes,
        initialValues,
        isMasculinoAndNotHaveIdentidadeGenero,
        hasProblemaComCiapW78AtivoPersistido,
        tipoPreNatal,
        isLPC
      )}
      render={renderForm}
      onSubmit={handleSubmit}
      prefix={prefix}
      initialValues={
        isAntecedentes ? { ...initialValues, situacaoProblema: SituacaoProblema.RESOLVIDO } : initialValues
      }
      decorators={[decorators]}
      validateOnBlur={false}
      {...rest}
    />
  )
}
