/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Cell, Grid, Heading, InfoLabel, VFlow } from 'bold-ui'
import {
  tipoDomicilioEnumRecord,
  tipoImovelEnumRecord,
} from 'components/form/field/select/CondicoesMoradiaSelectFields/condicoes-moradia-model'
import { Telefone } from 'components/label'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import {
  LotacaoResponsavelCard,
  LotacaoResponsavelCardModel,
} from 'components/lotacao-responsavel/LotacaoResponsavelCard'
import { getLotacaoResponsavel } from 'components/lotacao-responsavel/utils/utils-lotacaoResponsavel'
import { useImovelInformacoesCadastraisQuery } from 'graphql/hooks.generated'
import { Fragment } from 'react'
import { useParams } from 'react-router'
import { formatDate } from 'util/date/formatDate'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'
import { isTipoImovelResidencialExcetoDomicilio } from 'view/cadastro-imovel/utils-cadastroImovel'
import { MENSAGEM_FICHA_NAO_PROCESSADA_IMOVEL } from 'view/visualizacaoTerritorial/visualizacao-territorial-constants'

import { CondicoesMoradiaSection } from './components/CondicoesMoradiaSection'
import { ResponsavelTecnicoSection } from './components/ResponsavelTecnicoSection'
import { ImovelUrlParams } from './model-visualizacao-imovel'

export function VisualizarInformacoesCadastraisView() {
  const { imovelId } = useParams<ImovelUrlParams>()
  const {
    data: { imovel },
    loading,
  } = useImovelInformacoesCadastraisQuery({
    variables: { input: imovelId },
    fetchPolicy: 'network-only',
  })

  const isTipoEnderecoIndigena = tipoEnderecoIndigena.includes(imovel?.tipoEndereco)
  const isResidencialExcetoDomicilio = isTipoImovelResidencialExcetoDomicilio(imovel?.tipoImovel)

  const isRecusaCadastro = imovel?.statusRecusa
  return loading ? (
    <LoadingIndicator />
  ) : (
    <PageContent fluid type='filled'>
      <Grid>
        {isRecusaCadastro && (
          <Fragment>
            <Cell size={12}>
              <Alert type='warning' style={{ padding: '0.5rem' }}>
                <b> Recusa de cadastro.</b> A recusa do cidadão ao cadastro não implicará no não atendimento deste na
                unidade de saúde, bem como quaisquer outras formas de discriminação.
                <br />
                Quando oportuno, tente sensibilizar o responsável pelo domicílio para que o cadastro possa ser feito de
                forma completa.
              </Alert>
            </Cell>
          </Fragment>
        )}
        {!imovel.ultimaFichaCadastrada?.isProcessado && (
          <Fragment>
            <Cell size={7}>
              <Alert type='info'>{MENSAGEM_FICHA_NAO_PROCESSADA_IMOVEL}</Alert>
            </Cell>
            <Cell size={5} />
          </Fragment>
        )}
        {!isRecusaCadastro && (
          <Fragment>
            <Cell size={6}>
              <Heading level={2}>Imóvel</Heading>
            </Cell>
            <Cell size={6}>
              <Heading level={2}>Contato</Heading>
            </Cell>
            <Cell size={3}>
              <InfoLabel placeholder='Não informado' title='Tipo de imóvel'>
                {tipoImovelEnumRecord[imovel.tipoImovel]}
              </InfoLabel>
            </Cell>
            <Cell size={2}>
              <InfoLabel placeholder='Não informado' title='Tipo de domicílio'>
                {tipoDomicilioEnumRecord[imovel.condicoesMoradia.tipoDomicilio]}
              </InfoLabel>
            </Cell>
            <Cell size={1} />
            <Cell size={2}>
              <InfoLabel placeholder='Não informado' title='Telefone de contato'>
                <Telefone value={imovel.telefoneContato} />
              </InfoLabel>
            </Cell>
            <Cell size={2}>
              <InfoLabel placeholder='Não informado' title='Telefone residencial'>
                <Telefone value={imovel.telefoneResidencial} />
              </InfoLabel>
            </Cell>
            {!isTipoEnderecoIndigena && (
              <Cell size={2}>
                <InfoLabel placeholder='Não informado' title='Última visita'>
                  {imovel.dataUltimaVisita && formatDate(imovel.dataUltimaVisita)}
                </InfoLabel>
              </Cell>
            )}
          </Fragment>
        )}
        <Cell
          size={8}
          style={css`
            margin-top: 1.5rem;
          `}
        >
          <VFlow>
            <Heading level={2}>Responsabilidade de acompanhamento</Heading>
            <LotacaoResponsavelCard
              responsavel={
                getLotacaoResponsavel(imovel.profissional, imovel.cbo, imovel.equipe, imovel.unidadeSaude)
                  .lotacao as LotacaoResponsavelCardModel
              }
            />
          </VFlow>
        </Cell>
        <Cell size={4} />
        <Cell size={12} />
        {!isRecusaCadastro && !isTipoEnderecoIndigena && (
          <Cell size={12}>
            <InfoLabel
              title={
                imovel.ultimaVisitaComAnotacao
                  ? `Última anotação de visita (${formatDate(imovel.ultimaVisitaComAnotacao?.dataVisita)})`
                  : 'Anotações última visita realizada'
              }
              placeholder='Não informado'
              titleStyles={css`
                font-size: 1.25rem;
                padding-bottom: 1rem;
              `}
            >
              {imovel.ultimaVisitaComAnotacao?.anotacoes}
            </InfoLabel>
          </Cell>
        )}
        <Cell size={12} />
        {!isRecusaCadastro && (
          <Cell size={12}>
            <CondicoesMoradiaSection condicoesMoradia={imovel.condicoesMoradia} animais={imovel.animaisImovel} />
          </Cell>
        )}
        <Cell size={12} />
        {isResidencialExcetoDomicilio && !isRecusaCadastro && (
          <Cell size={6}>
            <ResponsavelTecnicoSection
              nomeInstituicao={imovel.nomeInstituicaoPermanencia}
              responsavel={imovel.responsavelTecnico}
            />
          </Cell>
        )}
      </Grid>
    </PageContent>
  )
}
