import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageLoading } from 'components/loading/PageLoading'
import { TitleGroup } from 'components/TitleGroup'
import { css } from 'emotion'
import { useAtividadeColetivaEditarReuniaoQuery } from 'graphql/hooks.generated'
import React, { Fragment } from 'react'
import { Redirect, useParams } from 'react-router'

import AtividadeColetivaReuniaoForm from './AtividadeColetivaReuniaoForm'
import { convertAtividadeColetivaReuniaoToFormModel } from './convert-atividadeColetivaReuniao'

interface AtividadeColetivaEditarReuniaoViewProps {
  atividadeColetivaId: ID
}

export const AtividadeColetivaEditarReuniaoView = () => {
  const { atividadeColetivaId } = useParams<AtividadeColetivaEditarReuniaoViewProps>()

  const {
    data: { atividadeColetiva },
    loading,
  } = useAtividadeColetivaEditarReuniaoQuery({
    variables: { atividadeColetivaId },
  })

  if (loading) {
    return <PageLoading message='Carregando' />
  } else if (!atividadeColetiva) {
    return <Redirect to='/404' />
  }

  return (
    <Fragment>
      <Breadcrumb title='Edição de registro de reunião de equipe' />
      <PageContent type='filled' style={styles.pageContent}>
        <TitleGroup title='Registro de reunião' style={styles.header} />
        <AtividadeColetivaReuniaoForm initialValues={convertAtividadeColetivaReuniaoToFormModel(atividadeColetiva)} />
      </PageContent>
    </Fragment>
  )
}

const styles = {
  header: css`
    margin-top: 1rem;
    margin-bottom: 2rem;
  `,
  pageContent: css`
    padding-bottom: 3.5rem;
  `,
}
