import { Alert, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { PageContent } from 'components/layout/PageContent'
import { useFlags } from 'config/useFlagsContext'
import { CuidadoCompartilhadoQueryFilterEnum, CuidadosCompartilhadoQueryInput } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import React from 'react'

import {
  CuidadoCompartilhadoSort,
  CuidadoCompartilhadoSortEnum,
  ListaCuidadoCompartilhadoFilterModel,
} from '../model-cuidadoCompartilhado'
import { ListaCuidadoCompartilhadoHeader } from './ListaCuidadoCompartilhadoHeader'
import { ListaCuidadoCompartilhadoView } from './ListaCuidadoCompartilhadoView'

const filterToQueryInput = (
  value: ListaCuidadoCompartilhadoFilterModel,
  unidadeSaudeId?: ID
): CuidadosCompartilhadoQueryInput => {
  const isSomenteCuidadosSemExecutanteDefinido = value.switch
  return {
    query: value.query,
    cboId: value.cbo?.id,
    status: value.status,
    dataInicio: value.periodo?.startDate,
    dataFim: value.periodo?.endDate,
    executadosPorProfissionalId: value.profissionalExecutante?.id,
    solicitadosPorProfissionalId: value.profissionalSolicitante?.id,
    unidadeSaudeId: value.unidadeSaude?.id ?? unidadeSaudeId,
    equipesIds: isEmpty(value.equipe) ? null : value.equipe?.map((item) => item.id),
    executadosPelaMinhaEquipe: false,
    somenteSemExecutante: isSomenteCuidadosSemExecutanteDefinido,
    solicitadosPelaMinhaEquipe: false,
    somenteUnidadeExecutante: false,
    somenteUnidadesBasicas: false,
    pageParams: { ...value.pageParams, sort: CuidadoCompartilhadoSort[value.pageParams.sort] },
  }
}

const FILTER_DEFAULT: ListaCuidadoCompartilhadoFilterModel = {
  status: [],
  periodo: null,
  profissionalExecutante: null,
  profissionalSolicitante: null,
  unidadeSaude: null,
  equipe: [],
  cbo: null,
  switch: false,
}

export const ListaCuidadoCompartilhadoGerenteView = () => {
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { PILOTO_VINCULACAO_CUIDADO_ENABLED } = useFlags()

  const filterToQuery = (value: ListaCuidadoCompartilhadoFilterModel) =>
    filterToQueryInput(value, acesso.unidadeSaude.id)

  return (
    <>
      <ListaCuidadoCompartilhadoHeader isGerente={true} />
      <PageContent fluid type='transparent'>
        <VFlow vSpacing={0.5}>
          <Alert type='info' inline>
            A lista exibe todas as discussões desta unidade.
          </Alert>
          <ListaCuidadoCompartilhadoView
            filterDefault={FILTER_DEFAULT}
            initialSort={CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE}
            itemsToSort={itemsToSort}
            filterSwitchLabel={
              PILOTO_VINCULACAO_CUIDADO_ENABLED ? 'Ver somente cuidados sem profissionais definidos.' : null
            }
            filterToQueryInput={filterToQuery}
            role={CuidadoCompartilhadoQueryFilterEnum.GERENTE}
          />
        </VFlow>
      </PageContent>
    </>
  )
}

const itemsToSort = [
  CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE,
  CuidadoCompartilhadoSortEnum.STATUS_SOLICITANTE,
]
