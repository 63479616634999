import { SimNaoEnum } from 'components/form'
import createDecorator from 'final-form-calculate'
import { TipoLocalizacaoEnum } from 'graphql/types.generated'
import { MetaRoot } from 'util/metaPath'

import { VisualizacaoMicroareasFilterFormModel } from '../model'

export const createVisualizacaoMicroareaFilterCalculator = (meta: MetaRoot<VisualizacaoMicroareasFilterFormModel>) =>
  createDecorator(
    {
      field: meta.animaisImovelFilter.possuiAnimais.absolutePath(),
      updates: {
        [meta.animaisImovelFilter.animaisNoImovel.absolutePath()]: (
          possuiAnimais: SimNaoEnum,
          allValues: VisualizacaoMicroareasFilterFormModel
        ) => (possuiAnimais !== SimNaoEnum.SIM ? undefined : allValues?.animaisImovelFilter?.animaisNoImovel),
      },
    },
    {
      field: meta.condicoesMoradia.tipoLocalizacao.absolutePath(),
      updates: {
        [meta.condicoesMoradia.tipoPosseTerra.absolutePath()]: (
          tipoLocalizacao: TipoLocalizacaoEnum,
          allValues: VisualizacaoMicroareasFilterFormModel
        ) => (tipoLocalizacao !== TipoLocalizacaoEnum.RURAL ? undefined : allValues?.condicoesMoradia?.tipoPosseTerra),
      },
    }
  )
