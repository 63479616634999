/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HFlow, Icon, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import AccordionList from 'components/accordion/AccordionList'
import useSession from 'components/auth/useSession'
import { confirm } from 'components/modals/confirm'
import { ButtonLink } from 'components/route'
import { useHistoricoPrescricaoQuery } from 'graphql/hooks.generated'
import {
  AtendimentoProfissional,
  HistoricoPrescricaoQueryInput,
  ReceitaMedicamento,
  TipoReceitaEnum,
  UnidadeMedidaTempoEnum,
} from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { isEmpty, noop } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { tipoReceitaRecord } from 'types/enums'
import { formatDate } from 'util/date/formatDate'
import { getEditAtendProfId } from 'view/atendimentos/atendimento-individual/util-atendimentoIndividual'

import { grupoCboPrescricao } from '../../acessos'
import { periodoTratamento } from '../components/PeriodoTratamentoSelectField'
import { MedicamentoFormModel } from '../model-prescricao'
import { convertReceitaMedicamentoToMedicamento } from '../utils/converter-prescricao'
import HistoricoPrescricaoTableFilter from './HistoricoPrescricaoTableFilter'

export interface HistoricoPrescricaoTableProps {
  prontuarioId: ID
  atendimentoProfissionalId?: ID
  isAtendimentoProcedimento: boolean
  isAtendimentoObservacao?: boolean
  setMedicamentosToReplicate?(medicamentos: MedicamentoFormModel[]): void
  dataReferencia: Instant
  readOnly?: boolean
}

export default function HistoricoPrescricaoTable(props: HistoricoPrescricaoTableProps) {
  const {
    prontuarioId,
    isAtendimentoProcedimento,
    atendimentoProfissionalId,
    isAtendimentoObservacao = false,
    setMedicamentosToReplicate = noop,
    dataReferencia,
    readOnly,
  } = props
  const { analytics } = useFirebase()
  const history = useHistory()
  const match = useRouteMatch()
  const { hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })
  const dataInicioAtendimento = dataReferencia

  const podeReplicar = !isAtendimentoProcedimento && hasCboAuth(grupoCboPrescricao.adicionar)

  const editAtendProfId = getEditAtendProfId(isAtendimentoObservacao, atendimentoProfissionalId)

  const [currentFilter, setCurrentFilter] = useState<HistoricoPrescricaoQueryInput>({
    prontuarioId,
    editAtendProfId,
    pageParams: {
      size: 5,
    },
  })

  useEffect(() => {
    setCurrentFilter((prevCurrentFilter) => ({ ...prevCurrentFilter, editAtendProfId }))
  }, [editAtendProfId])

  const { data, loading } = useHistoricoPrescricaoQuery({
    variables: { input: currentFilter },
  })
  const historico = data?.historicoPrescricao

  const handleOnClickReplicarMedicamento = useCallback(
    (receitaMedicamento: ReceitaMedicamento, isVersaoAnterior: boolean) => {
      const medicamento: MedicamentoFormModel = convertReceitaMedicamentoToMedicamento(
        receitaMedicamento,
        isVersaoAnterior,
        true,
        dataInicioAtendimento
      )
      setMedicamentosToReplicate([medicamento])
    },
    [dataInicioAtendimento, setMedicamentosToReplicate]
  )

  const handleOnClickReplicar = useCallback(
    (receitaMedicamento: ReceitaMedicamento[], isVersaoAnterior: boolean) => {
      const medicamentos: MedicamentoFormModel[] = receitaMedicamento.map((item) =>
        convertReceitaMedicamentoToMedicamento(item, isVersaoAnterior, true, dataInicioAtendimento)
      )
      medicamentos.sort((a, b) => {
        return (a.registroManual ? a.principioAtivoText : a.principioAtivoCombo.principioAtivo.nome).localeCompare(
          b.registroManual ? b.principioAtivoText : b.principioAtivoCombo.principioAtivo.nome
        )
      })
      setMedicamentosToReplicate(medicamentos)
    },
    [dataInicioAtendimento, setMedicamentosToReplicate]
  )

  const iniciaReplicar = useCallback(
    (receitaMedicamento: ReceitaMedicamento[], isReplicarPrescricaoInteira: boolean) => {
      isReplicarPrescricaoInteira
        ? handleOnClickReplicar(receitaMedicamento, true)
        : handleOnClickReplicarMedicamento(receitaMedicamento[0], true)
    },
    [handleOnClickReplicar, handleOnClickReplicarMedicamento]
  )

  const handleOnConfirmReplicate = useCallback(
    (receitaMedicamento: ReceitaMedicamento[], isReplicarPrescricaoInteira: boolean) => {
      confirm({
        type: 'primary',
        title: `Deseja replicar ${isReplicarPrescricaoInteira ? 'esta prescrição' : 'este medicamento'}?`,
        body:
          'Este medicamento foi prescrito em versão anterior do sistema. Para concluir sua replicação será necessário completar informações. Deseja continuar?',
        onConfirm: () => iniciaReplicar(receitaMedicamento, isReplicarPrescricaoInteira),
        onCancel: () => history.push(match.url.replace('/prescricao-medicamentos/adicionar', '')),
        confirmLabel: 'Continuar',
        cancelLabel: 'Cancelar',
        depthLevel: 2,
        manageOverflow: true,
      })()
      analytics.logEvent(`click_button_replicar_${isReplicarPrescricaoInteira ? 'prescricao' : 'medicamento'}`)
    },
    [analytics, history, iniciaReplicar, match.url]
  )

  const medicamentoInativo = (receita: ReceitaMedicamento) =>
    !receita.medicamento.medicamentoCatmat?.ativo && !receita.registroManual

  const renderItemHeader = (item: AtendimentoProfissional) => {
    const prof = item.lotacao.profissional

    let subtitle: string = `${formatDate(item.finalizadoEm)} | ${item.lotacao.profissional.nome}`
    if (prof.conselhoClasse && prof.ufEmissoraConselhoClasse && prof.ufEmissoraConselhoClasse) {
      subtitle = subtitle.concat(
        ` - ${prof.conselhoClasse.sigla} ${prof.ufEmissoraConselhoClasse.sigla} ${prof.numeroConselhoClasse}`
      )
    }

    return (
      <HFlow
        style={css`
          width: 100%;
        `}
      >
        <VFlow vSpacing={0}>
          <Heading level={5}>
            {item.receitaMedicamento
              .map(
                (receitaMedicamento) =>
                  `${receitaMedicamento.medicamento.principioAtivo} ${
                    receitaMedicamento.medicamento.concentracao ?? ''
                  }`
              )
              .join(' | ')}
          </Heading>
          <Text>{subtitle}</Text>
        </VFlow>
      </HFlow>
    )
  }

  const renderItemBody = (item: AtendimentoProfissional) => {
    return item.receitaMedicamento?.map((receita) => (
      <Grid
        style={css`
          padding: 0.5rem 0.5rem 0.5rem 1rem;
          margin: auto;
          clip-path: margin-box;
        `}
      >
        <Cell size={11}>
          <Grid key={receita.id}>
            <Cell size={8}>
              <HFlow alignItems='center' hSpacing={0.5}>
                <Heading
                  level={5}
                  style={css`
                    word-break: break-word;
                  `}
                >
                  {`${receita.medicamento.principioAtivo} ${receita.medicamento.concentracao ?? ''}`}
                </Heading>
                {receita.tratamentoInterrompido && <Tag type='normal'>Tratamento interrompido</Tag>}
                {receita.usoContinuo && <Tag type='normal'>Uso contínuo</Tag>}
                {receita.tipoReceita !== TipoReceitaEnum.COMUM && (
                  <Tag type='info'>{tipoReceitaRecord[receita.tipoReceita]}</Tag>
                )}
              </HFlow>
              <Text>
                {`${receita.posologia} | ${receita.aplicacaoMedicamento.nome} ${
                  receita.medicamento.formaFarmaceutica?.nome ? `| ${receita.medicamento.formaFarmaceutica?.nome}` : ''
                }`.trim()}
              </Text>
              {receita.tratamentoInterrompido && (
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>Interrupção</Text>
                  {receita.dtInterrupcao && <Text>{`${formatDate(receita.dtInterrupcao)}`}</Text>}
                  {receita.justificativaInterrupcao && <Text>{receita.justificativaInterrupcao}</Text>}
                </VFlow>
              )}
            </Cell>
            <Cell size={4}>
              <Heading level={5}>
                {`Período: ${formatDate(receita.dtInicioTratamento)}
                 - ${
                   item?.versaoAnterior
                     ? ` ${periodoTratamento[receita.medidaTempoTratamento]}`
                     : receita.medidaTempoTratamento !== UnidadeMedidaTempoEnum.INDETERMINADO
                     ? formatDate(receita.dtFimTratamento)
                     : `${periodoTratamento[receita.medidaTempoTratamento]}`
                 }
            `}
              </Heading>
            </Cell>
            <Cell size={12}>
              <Heading level={5}>Recomendações</Heading>
              {receita.recomendacoes ? (
                <Text
                  style={css`
                    white-space: pre-wrap;
                    overflow-wrap: anywhere;
                  `}
                >
                  {receita.recomendacoes}
                </Text>
              ) : (
                <Text fontStyle='italic'>Não há recomendações para o medicamento</Text>
              )}
            </Cell>
          </Grid>
        </Cell>
        <Cell
          size={1}
          alignSelf='center'
          style={css`
            text-align: end;
          `}
        >
          {podeReplicar && !readOnly && (
            <Tooltip
              text={
                !medicamentoInativo(receita)
                  ? 'Replicar medicamento'
                  : 'Este medicamento não está mais presente na base de dados'
              }
            >
              <ButtonLink
                size='small'
                skin='ghost'
                kind='normal'
                disabled={medicamentoInativo(receita)}
                to={`${match.url}/prescricao-medicamentos/adicionar`}
                onClick={() => {
                  item.versaoAnterior
                    ? handleOnConfirmReplicate([receita], false)
                    : handleOnClickReplicarMedicamento(receita, item.versaoAnterior)
                }}
                data-cy='ReplicarMedicamentoButton'
              >
                <Icon icon='copyOutline' />
              </ButtonLink>
            </Tooltip>
          )}
        </Cell>
      </Grid>
    ))
  }

  const renderHeaderButtons = (item: AtendimentoProfissional) => {
    if (!podeReplicar) return null
    const disableReplicar = !item.receitaMedicamento.some((receita) => !medicamentoInativo(receita))

    return (
      <HFlow
        alignItems='center'
        style={css`
          left: 1;
        `}
      >
        {!readOnly && (
          <Tooltip
            text={
              item.receitaMedicamento.some((receita) => !medicamentoInativo(receita))
                ? 'Replicar prescrição'
                : 'Estes medicamentos não estão mais presentes na base de dados'
            }
          >
            <ButtonLink
              size='small'
              skin='ghost'
              kind='normal'
              disabled={disableReplicar}
              to={`${match.url}/prescricao-medicamentos/adicionar`}
              onClick={() => {
                item.versaoAnterior
                  ? handleOnConfirmReplicate(
                      item.receitaMedicamento.filter((receita) => !medicamentoInativo(receita)),
                      true
                    )
                  : handleOnClickReplicar(item.receitaMedicamento, item.versaoAnterior)
              }}
              data-cy='ReplicarPrescricaoButton'
            >
              <Icon icon='copyOutline' />
            </ButtonLink>
          </Tooltip>
        )}
      </HFlow>
    )
  }

  const noResultPlaceholder =
    !currentFilter?.query && (!historico || isEmpty(historico.content))
      ? 'Nenhuma prescrição realizada'
      : 'Nenhum registro encontrado'

  return (
    <AccordionList<AtendimentoProfissional>
      header={<HistoricoPrescricaoTableFilter initialValues={currentFilter} onChange={setCurrentFilter} />}
      loading={loading}
      data={historico}
      onChange={setCurrentFilter}
      accordionItemHeader={renderItemHeader}
      accordionItemBody={renderItemBody}
      accordionItemButtons={renderHeaderButtons}
      noResultPlaceholder={noResultPlaceholder}
    />
  )
}
