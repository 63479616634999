import ReactPDF, { Document, Page } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import { format, toDate } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'

import { PrinterType } from '../model-print'
import { PrintBody } from '../PrintBody'
import { PrintFooter } from '../PrintFooter'
import { PrintHeader } from '../PrintHeader'
import { usePrinterStyles } from '../usePrinterStyles'
import { convertDadosProfissionalPrint } from './converter-dadosProfissionalPrint'

export interface PecPrintPageLayoutProps {
  serverTime: Date
  documentTitle?: string
  pageProps?: ReactPDF.PageProps
  bodyStyles?: Style
  showSignature?: boolean
  session?: PrinterType
  children?: React.ReactNode
  nameFooter?: string
  signatureDate?: Date
}

export const PecPrintPageLayout = (props: PecPrintPageLayoutProps) => {
  const {
    serverTime,
    children,
    documentTitle,
    pageProps,
    bodyStyles,
    showSignature,
    session,
    nameFooter,
    signatureDate,
  } = props
  const { config } = usePrinterStyles()
  const printedOn = serverTime && toDate(serverTime)
  const signedOn = isUndefinedOrNull(signatureDate) ? printedOn : toDate(signatureDate)

  const {
    nomeProfissional,
    nomeCbo,
    nomeUnidadeSaude,
    nomeMunicipio,
    municipioUf,
    conselhoClasse,
  } = convertDadosProfissionalPrint(session)

  return (
    <Document title={documentTitle} author={nomeProfissional} creator='PEC eSUS-APS'>
      <Page style={{ ...config.page }} size='A4' {...pageProps}>
        <PrintHeader ufNome={municipioUf.nome} municipio={nomeMunicipio} unidadeSaude={nomeUnidadeSaude} />
        <PrintBody
          styles={bodyStyles}
          showSignature={showSignature ?? true}
          nomeProfissional={nomeProfissional}
          cboProfissional={nomeCbo}
          conselhoClasse={conselhoClasse}
          data={format(signedOn, "dd 'de' MMMM 'de' yyyy", { locale: ptBR }).toLowerCase()}
          municipio={nomeMunicipio}
          uf={municipioUf.sigla}
        >
          {children}
        </PrintBody>
        <PrintFooter
          data={format(printedOn, 'dd/MM/yyyy')}
          hora={format(printedOn, 'HH:mm')}
          nomeProfissional={nameFooter ?? nomeProfissional}
        />
      </Page>
    </Document>
  )
}
