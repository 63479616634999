/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { DateField, Form, FormRenderProps, SubmitButton } from 'components/form'
import { DeclaracaoObitoField } from 'components/form/field/DeclaracaoObitoField'
import { FormApi } from 'final-form'
import { RegistroObitoInput } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { metaPath } from 'util/metaPath'
import { RegistroObitoCidadaoFormModel } from 'view/cadastro-imovel/model-cadastroImovel'
import { createRegistroObitoCidadaoValidator } from 'view/cadastro-imovel/validator-cadastroImovel'

interface RegistroObitoCidadaoModalProps {
  open: boolean
  isResponsavel: boolean
  dataNascimento: LocalDate
  dataEntradaBrasil: LocalDate
  dataNaturalizacao: LocalDate
  dataColeta: LocalDate
  onSubmit(registroObito: RegistroObitoInput): void
  onClose(): void
}

const meta = metaPath<RegistroObitoCidadaoFormModel>()

export function RegistroObitoCidadaoModal(props: RegistroObitoCidadaoModalProps) {
  const { open, dataNascimento, dataEntradaBrasil, dataColeta, dataNaturalizacao, onClose, onSubmit } = props

  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()

  const handleCloseModal = (resetApi: () => void) => {
    setTimeout(resetApi)
    onClose()
  }

  const handleSubmit = (values: RegistroObitoCidadaoFormModel, formApi: FormApi) => {
    onSubmit(values)
    handleCloseModal(formApi.reset)
  }

  const renderForm = ({ handleSubmit, form }: FormRenderProps<RegistroObitoCidadaoFormModel>) => {
    return (
      <Modal open={open} onClose={() => handleCloseModal(form.reset)} size='auto' closeOnBackdropClick={false}>
        <ModalBody>
          <VFlow vSpacing={3}>
            <Heading level={1}>Registrar óbito do cidadão</Heading>
            <Grid
              style={css`
                width: 37rem;
                margin-bottom: 5rem;
              `}
            >
              <Cell size={6}>
                <DateField name={meta.dataObito} label='Data do óbito' required maxDate={serverTime} />
              </Cell>
              <Cell size={6}>
                <DeclaracaoObitoField name={meta.numeroDeclaracaoObito} label='Número da declaração de óbito' />
              </Cell>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={() => handleCloseModal(form.reset)} size='medium'>
              Cancelar
            </Button>
            <SubmitButton kind='primary' size='medium' handleSubmit={handleSubmit}>
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<RegistroObitoCidadaoFormModel>
      render={renderForm}
      onSubmit={handleSubmit}
      validate={createRegistroObitoCidadaoValidator(dataNascimento, dataEntradaBrasil, dataNaturalizacao, dataColeta)}
    />
  )
}
