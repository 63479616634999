import {
  ArquivoTemporarioFormatoEnum,
  ArquivoTemporarioIdentificadorEnum,
  ArquivoTemporarioStatusEnum,
} from 'graphql/types.generated'
import {
  ACOMPANHAMENTO_CIDADAOS_VINCULADOS_ATMOSPHERE_PATH,
  ACOMPANHAMENTO_CIDADAOS_VINCULADOS_FILENAME,
} from 'view/acompanhamento/acompanhamento-cidadaos-vinculados/model-acompanhamentoCidadaosVinculados'
import { ACOMPANHAMENTO_CONDICAO_SAUDE_ATMOSPHERE_PATH } from 'view/acompanhamento/acompanhamento-condicao-saude/model-acompanhamentoCondicaoSaude'
import { BUSCA_ATIVA_VACINACAO_PATH } from 'view/busca-ativa/vacinacao/model-buscaAtivaVacinacao'
import { IMPORTAR_CIDADAO_PATH } from 'view/importar-cidadao/model-importarCidadao'
import { EXPORTAR_CADASTROS_DUPLICADOS_PATH } from 'view/unificacaoCadastro/UnificacaoCadastroFormModel'
import {
  ACOMPANHAMENTO_TERRITORIO_MICROAREA_FILENAME,
  RELATORIO_ACOMPANHAMENTO_TERRITORIO_ATMOSPHERE_PATH,
} from 'view/visualizacaoTerritorial/model'

export interface ArquivoTemporarioModel {
  id: ID
  dataInicioGerado: Instant
  status: ArquivoTemporarioStatusEnum
  identificador: ArquivoTemporarioIdentificadorEnum
  formato: ArquivoTemporarioFormatoEnum
}

export interface RelatoriosRecentesRootModel {
  fileName: string
  atmospherePath: string
}

export const relatoriosRecentesRecord: Record<ArquivoTemporarioIdentificadorEnum, RelatoriosRecentesRootModel> = {
  [ArquivoTemporarioIdentificadorEnum.RELATORIO_ACOMPANHAMENTO_CIDADAOS_VINCULADOS]: {
    fileName: ACOMPANHAMENTO_CIDADAOS_VINCULADOS_FILENAME,
    atmospherePath: ACOMPANHAMENTO_CIDADAOS_VINCULADOS_ATMOSPHERE_PATH,
  },
  [ArquivoTemporarioIdentificadorEnum.RELATORIO_ACOMPANHAMENTO_CONDICAO_SAUDE]: {
    fileName: ACOMPANHAMENTO_CONDICAO_SAUDE_ATMOSPHERE_PATH,
    atmospherePath: ACOMPANHAMENTO_CONDICAO_SAUDE_ATMOSPHERE_PATH,
  },
  [ArquivoTemporarioIdentificadorEnum.RELATORIO_BUSCA_ATIVA_VACINACAO]: {
    fileName: BUSCA_ATIVA_VACINACAO_PATH,
    atmospherePath: BUSCA_ATIVA_VACINACAO_PATH,
  },
  [ArquivoTemporarioIdentificadorEnum.RELATORIO_CADASTROS_DUPLICADOS]: {
    fileName: EXPORTAR_CADASTROS_DUPLICADOS_PATH,
    atmospherePath: EXPORTAR_CADASTROS_DUPLICADOS_PATH,
  },
  [ArquivoTemporarioIdentificadorEnum.RELATORIO_IMPORTACAO_CIDADAOS]: {
    fileName: IMPORTAR_CIDADAO_PATH,
    atmospherePath: IMPORTAR_CIDADAO_PATH,
  },
  [ArquivoTemporarioIdentificadorEnum.RELATORIO_ACOMPANHAMENTO_TERRITORIO_MICROAREA]: {
    fileName: ACOMPANHAMENTO_TERRITORIO_MICROAREA_FILENAME,
    atmospherePath: RELATORIO_ACOMPANHAMENTO_TERRITORIO_ATMOSPHERE_PATH,
  },
}

export interface ArquivoTemporarioFormato {
  arquivo: number[]
  formato: string
  arquivoId: ID
  mensagem: string
}

export interface MensagemErroArquivoTemporarioMaisRecente {
  arquivoId: ID
  mensagemErro: string
}
