/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Alert,
  Button,
  Cell,
  Grid,
  Heading,
  HFlow,
  Icon,
  Modal,
  ModalBody,
  Text,
  TextInput,
  useTheme,
  VFlow,
} from 'bold-ui'
import {
  AlertVideochamadaType,
  compartilharChamadaAlertRecord,
  CompartilharVideochamadaAlertData,
} from 'components/videochamada'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'

import { VideochamadaUrlParams } from '../model-videochamada'
import { EnviarEmailVideochamadaForm } from './EnviarEmailVideochamadaForm'

//TODO (RNG): Verificar se é possível usar CompartilharVideochamadaForm (#14543)
interface CompartilharChamadaModalProps {
  open: boolean
  onClose(): void
}

export function CompartilharChamadaModal({ onClose, open }: CompartilharChamadaModalProps) {
  const { analytics } = useFirebase()

  const {
    params: { uuid: videochamadaUuid },
  } = useRouteMatch<VideochamadaUrlParams>()

  const theme = useTheme()

  const url = `${window.location.origin}/videochamada/${videochamadaUuid}`

  const [compartilharVideochamadaAlertData, setCompartilharVideochamadaAlertData] = useState<
    CompartilharVideochamadaAlertData
  >()

  useEffect(() => {
    !open && setCompartilharVideochamadaAlertData(null)
  }, [open])

  const handleCopiarLinkClick = () => {
    analytics.logEvent('TELE_videochamadas_copia_link')
    navigator.clipboard.writeText(url)
    setCompartilharVideochamadaAlertData(compartilharChamadaAlertRecord[AlertVideochamadaType.LINK_COMPARTILHADO])
  }

  const handleSubmitSucceeded = () => {
    setCompartilharVideochamadaAlertData(compartilharChamadaAlertRecord[AlertVideochamadaType.ENVIO_EMAIL_SUCESSO])
  }

  const handleSubmitFailed = () => {
    setCompartilharVideochamadaAlertData(compartilharChamadaAlertRecord[AlertVideochamadaType.ENVIO_EMAIL_ERRO])
  }

  return (
    <Modal open={open} onClose={onClose} size='small'>
      <ModalBody>
        <VFlow vSpacing={0.5}>
          <Heading level={2}>
            <HFlow alignItems='center'>
              <Icon icon='infoCircleOutline' size={3} fill='primary' />
              Compartilhar chamada
            </HFlow>
          </Heading>
          <VFlow
            style={css`
              height: 2.5rem;
            `}
          >
            {compartilharVideochamadaAlertData && (
              <Alert type={compartilharVideochamadaAlertData.type}>{compartilharVideochamadaAlertData.message}</Alert>
            )}
          </VFlow>
          <Text>
            Para compartilhar a chamada, é possível copiar o link e enviar para convidados de dentro ou de fora do e-SUS
            APS.
          </Text>
          <Grid alignItems='center' gap={0.5}>
            <Cell flexGrow={1}>
              <TextInput disabled value={url} aria-hidden />
            </Cell>
            <Cell>
              <Button onClick={handleCopiarLinkClick} kind='primary' size='small' aria-label='Copiar link'>
                Copiar
              </Button>
            </Cell>
          </Grid>
          <hr
            css={css`
              margin: 0.5rem 0;
              background: ${theme.pallete.divider};
            `}
          />
          <EnviarEmailVideochamadaForm
            videochamadaUuid={videochamadaUuid}
            handleSubmitSucceeded={handleSubmitSucceeded}
            handleSubmitFailed={handleSubmitFailed}
          />
        </VFlow>
      </ModalBody>
    </Modal>
  )
}
