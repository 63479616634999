/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Text, VFlow } from 'bold-ui'
import { AccordionAnimation } from 'components/accordion/AccordionAnimation'
import { AccordionButton } from 'components/accordion/AccordionButton'
import theme from 'config/theme'
import { useResumoEnviosRndsQuery } from 'graphql/hooks.generated'
import { TipoRegistroRndsEnum } from 'graphql/types.generated'
import { Accordion, AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'

import { getPorcentagem } from './util-rndsErros'

interface EnviosRndsAccordionProps {
  title: string
  tipoRegistro: TipoRegistroRndsEnum
}

export default function EnviosRndsAccordion(props: EnviosRndsAccordionProps) {
  const { title, tipoRegistro } = props
  const {
    data: { resumoEnviosRnds },
  } = useResumoEnviosRndsQuery({ variables: { input: tipoRegistro } })
  const { qtdDadosInvalidos, qtdEnviado, qtdErro, qtdFalhaConexao, qtdNaoEnviado } = resumoEnviosRnds || {}

  const totalNaoEnviados = qtdErro + qtdFalhaConexao + qtdDadosInvalidos + qtdNaoEnviado
  const totalRegistros = qtdEnviado + totalNaoEnviados

  return (
    <Accordion allowZeroExpanded={true} css={styles.accordion}>
      <AccordionItem>
        <AccordionAnimation>
          <AccordionButton>
            <HFlow alignItems='center' style={styles.heading}>
              <Icon icon='angleDown' />
              <VFlow vSpacing={0}>
                <Heading level={4}>{title}</Heading>
                <Text>{'registro'.pluralizeAndConcatValue(totalRegistros)}</Text>
              </VFlow>
            </HFlow>
          </AccordionButton>
          <AccordionItemPanel css={styles.content}>
            <HFlow hSpacing={0.5} style={styles.dividerTop}>
              <Heading level={4}>Total de enviados</Heading>
              <Text>
                {'registro'.pluralizeAndConcatValue(qtdEnviado ?? 0)} {getPorcentagem(qtdEnviado, totalRegistros)}
              </Text>
            </HFlow>
            <VFlow style={styles.dividerBottom}>
              <HFlow hSpacing={0.5}>
                <Heading level={4}>Total de não enviados</Heading>
                <Text>
                  {'registro'.pluralizeAndConcatValue(totalNaoEnviados)}{' '}
                  {getPorcentagem(totalNaoEnviados, totalRegistros)}
                </Text>
              </HFlow>
            </VFlow>
          </AccordionItemPanel>
        </AccordionAnimation>
      </AccordionItem>
    </Accordion>
  )
}

const styles = {
  accordion: css`
    background-color: ${theme.pallete.gray.c90};
    border: 1px solid ${theme.pallete.gray.c60};
    border-radius: 2px;
  `,
  heading: css`
    padding: 0.5rem 0.7rem;
  `,
  content: css`
    background-color: ${theme.pallete.primary.c100};
  `,
  dividerTop: css`
    border-bottom: 1px solid ${theme.pallete.gray.c80};
    padding: 1rem;
    margin-bottom: 0;
  `,
  dividerBottom: css`
    padding: 1rem 1rem 1.2rem 1rem;
    margin-top: 0;
  `,
}
