/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DropdownItem, HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { DropdownButton } from 'components/dropdown'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'
import { useAlertasSdc } from 'hooks/sdc/useAlertasSdc'

import { SdcAvisosCard } from './SdcAvisosCard'

interface SdcDetalhesModalSideMenuProps {
  alertas: AlertaSuporteDecisaoClinica[]
  alertaSelecionado: AlertaSuporteDecisaoClinica
  setAlertaSelecionado: (AlertaSuporteDecisaoClinica) => void
}

export const SdcDetalhesModalSideMenu = (props: SdcDetalhesModalSideMenuProps) => {
  const { alertas, setAlertaSelecionado, alertaSelecionado } = props
  const theme = useTheme()
  const styles = createStyles(theme)
  const { marcarTodosAlertasComoLidos } = useAlertasSdc()

  return (
    <div css={styles.wrapper}>
      <VFlow vSpacing={0.5}>
        <div css={styles.menuHeader}>
          <HFlow alignItems='center' justifyContent='space-between'>
            <Text fontWeight='bold'>Orientações disparadas</Text>
            <DropdownButton isInsideModal>
              <DropdownItem onClick={marcarTodosAlertasComoLidos}>Marcar todos como lidos</DropdownItem>
            </DropdownButton>
          </HFlow>
        </div>
        <VFlow style={styles.cardsWrapper} vSpacing={0.5}>
          {alertas.map((alerta) => (
            <SdcAvisosCard
              alerta={alerta}
              onClick={setAlertaSelecionado}
              selecionado={alerta.id === alertaSelecionado.id}
            />
          ))}
        </VFlow>
      </VFlow>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  wrapper: css`
    height: 100%;
    width: 20rem;
    background-color: ${theme.pallete.surface.background};
    padding: 1rem;
    padding-right: 0;
  `,
  cardsWrapper: css`
    overflow-y: scroll;
    height: 36rem;
    width: 19rem;
    ::-webkit-scrollbar {
      display: !expanded && none;
      width: 0.25rem;
    }
    ::-webkit-scrollbar-track {
      background-color: ${theme.pallete.gray.c90};
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: ${theme.pallete.gray.c70};
    }
  `,
  menuHeader: css`
    margin-top: 2rem;
    width: 18rem;
  `,
})
