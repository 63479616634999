import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { soapConfig, SoapFormModel } from './config/model'

interface SoapSectionsProps {
  tipoAtendProf: TipoAtendimentoProfissional
  isObservacaoAndAuxiliar: boolean
  renderAntecedentes: () => JSX.Element
  renderSubjetivo: () => JSX.Element
  renderObjetivo: () => JSX.Element
  renderAvaliacao: () => JSX.Element
  renderPlano: () => JSX.Element
  renderFinalizacaoAtendimento: () => JSX.Element
}

function SoapSections(props: SoapSectionsProps) {
  const {
    tipoAtendProf,
    isObservacaoAndAuxiliar,
    renderAntecedentes,
    renderSubjetivo,
    renderObjetivo,
    renderAvaliacao,
    renderPlano,
    renderFinalizacaoAtendimento,
  } = props

  const { isRetificacao } = useAtendimentoContext()
  const hiddenSections = soapConfig(tipoAtendProf, isObservacaoAndAuxiliar, isRetificacao)?.hiddenSections

  const sections: JSX.Element[] = [
    showSection(meta.antecedentes, hiddenSections) && renderAntecedentes(),
    showSection(meta.subjetivo, hiddenSections) && renderSubjetivo(),
    showSection(meta.objetivo, hiddenSections) && renderObjetivo(),
    showSection(meta.avaliacao, hiddenSections) && renderAvaliacao(),
    showSection(meta.plano, hiddenSections) && renderPlano(),
    showSection(meta.finalizacao, hiddenSections) && renderFinalizacaoAtendimento(),
  ]

  return <>{sections}</>
}

const showSection = (sectionMetaPath: MetaPath<SoapFormModel>, hiddenSections?: MetaPath<SoapFormModel>[]) =>
  !hiddenSections?.includes(sectionMetaPath)

export default React.memo(SoapSections)
