/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { IvcfPerguntaEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { humanizeAge } from 'util/date/humanize-age'

import { IvcfAplicacaoModel, IvcfGrupoEnum, IvcfSubGrupoEnum } from '../../model-ivcf'
import { IvcfGrupoHeader } from '../components/IvcfGrupoHeader'
import { IvcfPerguntaView } from './IvcfPerguntaView'
import { IvcfSubgrupoView } from './IvcfSubgrupoView'

interface IvcfAplicacaoViewProps {
  value: IvcfAplicacaoModel
  cidadaoDataNascimento: string
  dataReferencia: string
}

export function IvcfAplicacaoView(props: IvcfAplicacaoViewProps) {
  const { value, cidadaoDataNascimento, dataReferencia } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  const humanizedIdadeCidadao = humanizeAge(cidadaoDataNascimento, dataReferencia)

  return (
    <VFlow vSpacing={0}>
      <IvcfGrupoHeader grupo={IvcfGrupoEnum.IDADE} />
      <HFlow alignItems='center' style={styles.question}>
        <Text fontWeight='bold'>{humanizedIdadeCidadao}.</Text>
      </HFlow>

      <IvcfGrupoHeader grupo={IvcfGrupoEnum.PERCEPCAO_SAUDE} />
      <IvcfPerguntaView pergunta={IvcfPerguntaEnum.PERCEPCAO_SAUDE_PERGUNTA_1} value={value} />

      <IvcfGrupoHeader grupo={IvcfGrupoEnum.AVD} />
      <IvcfSubgrupoView subgrupo={IvcfSubGrupoEnum.AVD_INSTRUMENTAL} value={value} />
      <IvcfSubgrupoView subgrupo={IvcfSubGrupoEnum.AVD_BASICA} value={value} />

      <IvcfGrupoHeader grupo={IvcfGrupoEnum.COGNICAO} />
      <IvcfPerguntaView pergunta={IvcfPerguntaEnum.COGNICAO_PERGUNTA_1} value={value} />
      <IvcfPerguntaView pergunta={IvcfPerguntaEnum.COGNICAO_PERGUNTA_2} value={value} />
      <IvcfPerguntaView pergunta={IvcfPerguntaEnum.COGNICAO_PERGUNTA_3} value={value} />

      <IvcfGrupoHeader grupo={IvcfGrupoEnum.HUMOR} />
      <IvcfPerguntaView pergunta={IvcfPerguntaEnum.HUMOR_PERGUNTA_1} value={value} />
      <IvcfPerguntaView pergunta={IvcfPerguntaEnum.HUMOR_PERGUNTA_2} value={value} />

      <IvcfGrupoHeader grupo={IvcfGrupoEnum.MOBILIDADE} />
      <IvcfSubgrupoView subgrupo={IvcfSubGrupoEnum.ALCANCE_PREENSAO_PINCA} value={value} />
      <IvcfSubgrupoView subgrupo={IvcfSubGrupoEnum.CAPACIDADE} value={value} />
      <IvcfSubgrupoView subgrupo={IvcfSubGrupoEnum.MARCHA} value={value} />
      <IvcfSubgrupoView subgrupo={IvcfSubGrupoEnum.CONTINENCIA} value={value} />

      <IvcfGrupoHeader grupo={IvcfGrupoEnum.COMUNICACAO} />
      <IvcfSubgrupoView subgrupo={IvcfSubGrupoEnum.VISAO} value={value} />
      <IvcfSubgrupoView subgrupo={IvcfSubGrupoEnum.AUDICAO} value={value} />

      <IvcfGrupoHeader grupo={IvcfGrupoEnum.COMORBIDADE_MULTIPLA} />
      <IvcfPerguntaView pergunta={IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_1} value={value} />
      <IvcfPerguntaView pergunta={IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_2} value={value} />
      <IvcfPerguntaView pergunta={IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_3} value={value} hideBorderBottom />
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  question: css`
    padding: 0.5rem 1rem;
    border-bottom: 1px solid ${theme.pallete.gray.c80};
    background: ${theme.pallete.surface.main};
  `,
})
