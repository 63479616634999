/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, VFlow } from 'bold-ui'
import { green } from 'bold-ui/lib/styles/colors'
import { Box } from 'components/Box'

interface IvcfBoxProps {
  children: JSX.Element
  isIvcfPreenchido: boolean
}

export function IvcfBox(props: IvcfBoxProps) {
  const { children, isIvcfPreenchido } = props

  return (
    <Box style={styles.box}>
      <VFlow vSpacing={isIvcfPreenchido ? 1 : 0}>
        <Heading level={3} style={styles.heading}>
          <strong>Índice de Vulnerabilidade Clínico-Funcional</strong> (IVCF-20)
        </Heading>
        {children}
      </VFlow>
    </Box>
  )
}

const styles = {
  heading: css`
    font-weight: normal;
  `,
  box: css`
    border-color: ${green.c60};
  `,
}
