/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Theme, Tooltip, useTheme } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { useFixarAgendaMutation, useIsAgendaFixadaQuery, useRemoverAgendaFixadaMutation } from 'graphql/hooks.generated'
import { ReactComponent as PinFilled } from 'images/agenda/pin-filled.svg'
import { ReactComponent as PinOutlined } from 'images/agenda/pin-outlined.svg'
import { useCallback, useState } from 'react'

interface PinAgendaButtonProps {
  lotacaoId: string
  onClick(): void
}

export function PinAgendaButton({ lotacaoId, onClick }: PinAgendaButtonProps) {
  const handleException = useErrorHandler()
  const [fixarAgenda] = useFixarAgendaMutation()
  const [removerAgenda] = useRemoverAgendaFixadaMutation()
  const [isFixado, setIsFixado] = useState(false)
  const theme = useTheme()
  const styles = createStyles(theme)

  const { loading } = useIsAgendaFixadaQuery({
    skip: !lotacaoId,
    variables: { lotacaoId: lotacaoId },
    onCompleted: (data) => {
      setIsFixado(data?.isAgendaFixada ?? false)
    },
    fetchPolicy: 'network-only',
  })

  const handleFixarAgenda = useCallback(() => {
    fixarAgenda({
      variables: {
        lotacaoId,
      },
    })
      .then(() => {
        setIsFixado(true)
        onClick()
      })
      .catch(handleException)
  }, [handleException, fixarAgenda, lotacaoId, setIsFixado, onClick])

  const handleRemoverAgendaFixada = useCallback(() => {
    removerAgenda({
      variables: {
        lotacaoId,
      },
    })
      .then(() => {
        setIsFixado(false)
        onClick()
      })
      .catch(handleException)
  }, [handleException, removerAgenda, lotacaoId, setIsFixado, onClick])

  return (
    <Tooltip text={getTooltipText(lotacaoId, isFixado)}>
      <Button
        onClick={isFixado ? handleRemoverAgendaFixada : handleFixarAgenda}
        type='button'
        size='small'
        disabled={!lotacaoId || loading}
        skin='ghost'
      >
        {lotacaoId && isFixado ? <PinFilled css={styles.pinIcon} /> : <PinOutlined css={styles.pinIcon} />}
      </Button>
    </Tooltip>
  )
}

function getTooltipText(lotacaoId: string, isFixado: boolean): string {
  if (!lotacaoId) {
    return 'Selecione um profissional para fixá-lo na lista de acesso rápido'
  } else if (isFixado) {
    return 'Retirar profissional da lista de acesso rápido'
  } else {
    return 'Fixar profissional na lista de acesso rápido'
  }
}

const createStyles = (theme: Theme) => ({
  pinIcon: css`
    fill: ${theme.pallete.text.main};
  `,
})
