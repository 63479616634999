import { Button, HFlow, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormRenderProps, RichTextField, SubmitButton } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import React, { useState } from 'react'
import { metaPath } from 'util/metaPath'

import { AtestadoAssinaturaDigitalView } from '../assinatura-digital/AtestadoAssinaturaDigitalView'
import { AtestadoConfirmarAssinaturaDigitalModal } from '../assinatura-digital/AtestadoConfirmarAssinaturaDigitalModal'
import { MESSAGE_TOOLTIP_SUBMIT_ATESTADO_DISABLED } from '../assinatura-digital/model-atestadoAssinaturaDigital'
import { useAtestadoAssinaturaDigitalForm } from '../assinatura-digital/useAtestadoAssinaturaDigitalForm'
import AtestadoModel from '../components/model-atestado'
import { atestadoEmBrancoFormValidator } from './validator'

export interface AtestadoEmBrancoFormProps {
  initialValues?: AtestadoModel
  onSubmit(values: AtestadoModel): void
  isDirtForm?(): void
  cidadaoId: ID
  atendimentoId: ID
  prontuarioId: ID
  dataAtendimento: Instant
}

export const name = metaPath<AtestadoModel>()

export default function AtestadoEmBrancoForm(props: AtestadoEmBrancoFormProps) {
  const { initialValues, onSubmit, isDirtForm, cidadaoId, atendimentoId, prontuarioId, dataAtendimento } = props
  const alert = useAlert()

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [isEmailCidadaoDirty, setIsEmailCidadaoDirty] = useState(false)
  const [isEmailCidadaoCanceled, setIsEmailCidadaoCanceled] = useState(false)

  const tipoAtestado = TipoAtestadoEnum.EM_BRANCO

  const handleSubmit = (values: AtestadoModel, formApi: FormApi) => {
    onSubmit({ ...values, tipo: tipoAtestado })
    setTimeout(formApi.reset)
    formApi.getRegisteredFields().forEach((item) => formApi.resetFieldState(item))
    alert('success', 'Atestado salvo com sucesso.')
  }

  const {
    emailCidadao,
    handleSubmitWrapper,
    isAssinando,
    isModalOpen,
    onConfirm,
    onCancel,
    errorMessage,
    onAlertClose,
  } = useAtestadoAssinaturaDigitalForm({
    cidadaoId,
    atendimentoId,
    prontuarioId,
    tipoAtestado,
    dataAtendimento,
    onSubmit: handleSubmit,
  })

  const handleCancel = (formProps: FormRenderProps<AtestadoModel>) => {
    if (formProps.dirty || isEmailCidadaoDirty) {
      confirm({
        title: 'Deseja cancelar a inclusão?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar inclusão',
        confirmLabel: 'Sim, cancelar inclusão',
        onConfirm: () => {
          alert('success', 'Cadastro de atestado cancelado com sucesso.')
          setIsEmailCidadaoCanceled(true)
          formProps.form.reset()
        },
      })()
    } else {
      alert('success', 'Cadastro de atestado cancelado com sucesso.')
      setIsEmailCidadaoCanceled(true)
    }
    formProps.form.getRegisteredFields().forEach((item) => formProps.form.resetFieldState(item))
  }

  const renderForm = (formProps: FormRenderProps<AtestadoModel>) => {
    return (
      <>
        <VFlow>
          <AtestadoAssinaturaDigitalView
            name={name.assinadoDigitalmente}
            cidadaoId={cidadaoId}
            cidadaoEmail={emailCidadao}
            onEmailCidadaoChange={setIsSubmitDisabled}
            hasCid10={!!formProps.values.cid10}
            onDirtyChange={setIsEmailCidadaoDirty}
            emailCanceled={isEmailCidadaoCanceled}
            onEmailCancel={setIsEmailCidadaoCanceled}
          />
          <RichTextField
            name={name.descricao}
            maxLength={4000}
            placeholder='Insira conteúdo do atestado'
            height={7}
            label='Conteúdo'
            required
            onChange={isDirtForm}
          />

          <HFlow justifyContent='flex-end'>
            <Button size='small' onClick={() => handleCancel(formProps)}>
              Cancelar
            </Button>
            <Tooltip text={isSubmitDisabled && MESSAGE_TOOLTIP_SUBMIT_ATESTADO_DISABLED}>
              <SubmitButton
                type='button'
                size='small'
                kind='primary'
                handleSubmit={formProps.handleSubmit}
                throttleSubmit
                disabled={isSubmitDisabled}
              >
                Salvar atestado
              </SubmitButton>
            </Tooltip>
          </HFlow>
        </VFlow>

        {isModalOpen && (
          <AtestadoConfirmarAssinaturaDigitalModal
            emailCidadao={emailCidadao}
            onConfirm={onConfirm}
            onCancel={onCancel}
            isAssinando={isAssinando}
            errorMessage={errorMessage}
            onAlertClose={onAlertClose}
            tipoAtestado={tipoAtestado}
            descricao={formProps.values.descricao}
          />
        )}
      </>
    )
  }

  return (
    <Form<AtestadoModel>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleSubmitWrapper}
      validate={atestadoEmBrancoFormValidator}
    />
  )
}
