import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import { ListaAtendimentoRootView } from 'view/atendimentos/ListaAtendimentoRootView'
import { grupoCboAtendimento } from 'view/cidadao/acessos'

import { AtendimentoVacinacaoRootView } from './atendimento-vacinacao/AtendimentoVacinacaoRootView'
import { AtendimentoView } from './detail/AtendimentoView'
import { EscutaInicialView } from './escuta-inicial/EscutaInicialView'
import { EscutaInicialVisualizarRootView } from './escuta-inicial/visualizar/EscutaInicialVisualizarRootView'

export function AtendimentoRootView() {
  const match = useRouteMatch()
  const { RETIFICACAO_ATENDIMENTO_PROCEDIMENTOS_ENABLED } = useFlags()
  return (
    <>
      <Breadcrumb title='Lista de atendimentos' />

      <PecSwitch>
        <PrivateRoute
          path={`${match.url}/escuta-inicial/:atendimentoId`}
          component={EscutaInicialView}
          permission={Permissions.visualizarListaDeAtendimento.visualizarEscutaInicial.registrarEscutaInicial}
        />
        <PrivateRoute
          path={`${match.url}/visualizar-escuta/:atendimentoId`}
          component={EscutaInicialVisualizarRootView}
          permission={Permissions.visualizarListaDeAtendimento.visualizarEscutaInicial}
        />
        <PrivateRoute
          path={`${match.url}/atendimento-vacinacao/:atendimentoId`}
          component={AtendimentoVacinacaoRootView}
          permission={Permissions.visualizarListaDeAtendimento.registrarAtendimento}
        />
        {RETIFICACAO_ATENDIMENTO_PROCEDIMENTOS_ENABLED && (
          <PrivateRoute
            path={`${match.url}/atendimento/:atendimentoId/retificar`}
            render={() => <AtendimentoView isRetificacao />}
            permission={Permissions.visualizarListaDeAtendimento.registrarAtendimento}
            cbos={grupoCboAtendimento}
          />
        )}
        <PrivateRoute
          path={`${match.url}/atendimento/:atendimentoId`}
          component={AtendimentoView}
          permission={Permissions.visualizarListaDeAtendimento.registrarAtendimento}
          cbos={grupoCboAtendimento}
        />
        <PrivateRoute
          path={`${match.url}`}
          component={ListaAtendimentoRootView}
          permission={Permissions.visualizarListaDeAtendimento}
        />
      </PecSwitch>
    </>
  )
}
