import { useTheme } from 'bold-ui'
import React from 'react'
import { Rectangle } from 'recharts'

import { convertToLines } from './convertToLines'
import { ReferenceAreaWithPercents, TickProps } from './model'

const MAX_CHARS_PER_LINE = 8

export interface ReferenceTickProps extends TickProps {
  refTicks: Map<number, ReferenceAreaWithPercents<any>>
}

export function ReferenceAreaTick(props: ReferenceTickProps) {
  const { x, y, payload, refTicks, height } = props

  const ref = refTicks.get(+payload.value)
  const margin = 5

  const theme = useTheme()

  const nameLines = convertToLines(ref.name, MAX_CHARS_PER_LINE)
  const description = ref.description
  const align = description?.align

  const rectangleHeight = (ref.areaPercents.slice(-1)[0].percent / 100) * height - margin / 2

  return (
    <>
      <text
        x={x}
        y={y}
        dx={15}
        dy={15}
        textAnchor='start'
        fill={ref.tickColor ?? ref.color}
        style={{ fontWeight: 'bold' }}
      >
        {nameLines.map(
          (namePart, i) =>
            namePart && (
              <tspan key={namePart} dx={15} dy={(i + 1) * 15} x={x} y={y}>
                {namePart}
              </tspan>
            )
        )}
      </text>
      {description && (
        <text
          x={x + 15}
          y={y + 5 + nameLines.length * 15}
          dx={15}
          dy={15}
          textAnchor='start'
          fill={description.color ?? theme.pallete.text.main}
          style={description.style}
        >
          {convertToLines(description.text, MAX_CHARS_PER_LINE).map(
            (descriptionPart, i) =>
              descriptionPart && (
                <tspan
                  key={descriptionPart}
                  dx={15}
                  dy={(i + 1) * 15}
                  x={x}
                  y={y + (align === 'bottom' ? rectangleHeight : 5 + nameLines.length * 15)}
                >
                  {descriptionPart}
                </tspan>
              )
          )}
        </text>
      )}
      <Rectangle
        x={x}
        y={y}
        dx={15}
        dy={margin / 2}
        width={4}
        height={rectangleHeight}
        fill={ref.tickColor ?? ref.color}
      />
    </>
  )
}
