/** @jsx jsx */
import { jsx } from '@emotion/core'
import { RadioGroupField, SimNaoEnum } from 'components/form'
import { IvcfPerguntaEnum } from 'graphql/types.generated'
import { useForm } from 'react-final-form'
import { MetaRoot } from 'util/metaPath'

import { IVCF_PERGUNTAS, IvcfAplicacaoModel } from '../../model-ivcf'
import { IvcfPerguntaWrapper } from '../components/IvcfPerguntaWrapper'

interface IvcfPerguntaFieldProps {
  ivcfPath: MetaRoot<IvcfAplicacaoModel>
  pergunta: IvcfPerguntaEnum
  isEditing: boolean
  hideBorderBottom?: boolean
  readOnly?: boolean
}

const PERGUNTAS_COGNICAO_CONDICIONAIS = [IvcfPerguntaEnum.COGNICAO_PERGUNTA_2, IvcfPerguntaEnum.COGNICAO_PERGUNTA_3]

export function IvcfPerguntaField(props: IvcfPerguntaFieldProps) {
  const { ivcfPath, pergunta, hideBorderBottom, readOnly, isEditing } = props

  const { getState } = useForm()
  const name = ivcfPath[pergunta]
  const { values } = getState()
  const { options } = IVCF_PERGUNTAS[pergunta]

  const isPerguntaCognicao1Checked = values[IvcfPerguntaEnum.COGNICAO_PERGUNTA_1] === SimNaoEnum.SIM
  const isDisabled = !isPerguntaCognicao1Checked && PERGUNTAS_COGNICAO_CONDICIONAIS.includes(pergunta)

  return (
    <IvcfPerguntaWrapper pergunta={pergunta} hideBorderBottom={hideBorderBottom} isEditing={isEditing}>
      <RadioGroupField
        name={name}
        options={Object.entries(options).map(([value, label]) => ({
          value,
          label,
        }))}
        showErrorField={false}
        disabled={readOnly || isDisabled}
      />
    </IvcfPerguntaWrapper>
  )
}
