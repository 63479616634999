/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, InfoLabel, Spinner, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { isLotacaoOrEstagio } from 'components/auth/useSessionUtils'
import { Box } from 'components/Box'
import { CpfField, TextField } from 'components/form'
import { StatusVideochamadaEnum } from 'graphql/types.generated'
import { Fragment, useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import { SalaEsperaFormModel, StatusSolicitacaoEntradaVideochamadaEnum } from '../model-videochamada'
import { getVideochamadaAlert, isVideochamadaIniciada } from '../utils-videochamada'
import { VideochamadaAlert } from './VideochamadaAlert'

const meta = metaPath<SalaEsperaFormModel>()

interface SalaEsperaVideochamadaUsuariosExternosFormProps {
  nomeProfissional: string
  statusVideochamada: StatusVideochamadaEnum
  statusSolicitacao: StatusSolicitacaoEntradaVideochamadaEnum
  handleEntrarButtonClick(): void
  isMobile?: boolean
}

export function SalaEsperaVideochamadaUsuariosExternosForm(props: SalaEsperaVideochamadaUsuariosExternosFormProps) {
  const { nomeProfissional, statusVideochamada, statusSolicitacao, handleEntrarButtonClick, isMobile = false } = props

  const theme = useTheme()
  const styles = createStyles(theme, isMobile)

  const { data: session } = useSession()
  const cboProfissionalExterno = session ? isLotacaoOrEstagio(session.acesso) && session.acesso.cbo.nome : null

  const videochamadaLotada = statusVideochamada === StatusVideochamadaEnum.LOTADA
  const videochamadaSemDono = statusVideochamada === StatusVideochamadaEnum.ATIVA_SEM_DONO
  const videochamadaIniciada = isVideochamadaIniciada(statusVideochamada)
  const videochamadaAtiva = statusVideochamada === StatusVideochamadaEnum.ATIVA

  const videochamadaAlertModel = useMemo(
    () => getVideochamadaAlert(nomeProfissional, videochamadaIniciada, videochamadaLotada, videochamadaSemDono),
    [nomeProfissional, videochamadaIniciada, videochamadaLotada, videochamadaSemDono]
  )

  return (
    <Box style={isMobile && styles.boxMobile}>
      {statusSolicitacao === StatusSolicitacaoEntradaVideochamadaEnum.AGUARDANDO_RESPOSTA ? (
        <Grid>
          <Cell size={2} alignSelf='center' style={styles.spinnerContainer}>
            <Spinner size={2} borderWidth={3} style={styles.spinner} />
          </Cell>
          <Cell size={10}>
            <InfoLabel title='Aguardando permissão para ingressar na videochamada'>
              Você será direcionado para a tela da videochamada quando {nomeProfissional} permitir.
            </InfoLabel>
          </Cell>
        </Grid>
      ) : (
        <Fragment>
          <Grid gap={1} alignItems='center' justifyContent='space-between'>
            {session ? (
              <Fragment>
                <Cell xs={12} sm={8} md={8}>
                  <Text>Você está realizando essa chamada como </Text>
                  <Text fontWeight='bold'>
                    {session.profissional.nome}
                    {cboProfissionalExterno ? ` | ${cboProfissionalExterno.capitalize()}` : ''}
                  </Text>
                </Cell>
                <Cell xs={12} sm={4} md={4} style={styles.buttonContainer} onClick={handleEntrarButtonClick}>
                  <Tooltip text={videochamadaAlertModel?.tooltip}>
                    <Button type='submit' kind='primary' disabled={!videochamadaAtiva}>
                      Entrar na chamada
                    </Button>
                  </Tooltip>
                </Cell>
                {!!videochamadaAlertModel?.alert && (
                  <VideochamadaAlert>{videochamadaAlertModel.alert}</VideochamadaAlert>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Cell xs={12} md={6} alignSelf='flex-start'>
                  <TextField
                    label='Nome completo'
                    name={meta.nomeParticipante}
                    placeholder='Digite o nome do participante'
                    maxLength={70}
                    required
                  />
                </Cell>
                <Cell xs={12} md={6} alignSelf='flex-start'>
                  <CpfField label='CPF' name={meta.cpf} required />
                </Cell>
                <Cell size={12} style={styles.buttonContainer}>
                  <Tooltip text={videochamadaAlertModel?.tooltip}>
                    <Button
                      style={isMobile && styles.buttonMobile}
                      type='submit'
                      kind='primary'
                      disabled={!videochamadaAtiva}
                    >
                      Entrar na chamada
                    </Button>
                  </Tooltip>
                </Cell>
                {!!videochamadaAlertModel?.alert && (
                  <VideochamadaAlert>{videochamadaAlertModel.alert}</VideochamadaAlert>
                )}
              </Fragment>
            )}
          </Grid>
        </Fragment>
      )}
    </Box>
  )
}

const createStyles = (theme: Theme, isMobile: boolean) => ({
  buttonContainer: css`
    display: flex;
    justify-content: flex-end;
  `,
  spinnerContainer: css`
    display: flex;
    justify-content: center;
  `,
  spinner: css`
    color: ${theme.pallete.primary.main};
  `,
  boxMobile: css`
    border: none;
    padding: 0;
  `,
  buttonMobile: css`
    width: 100%;
  `,
})
