import { SimNaoEnum } from 'components/form'
import { IvcfEmAtendimentoObservacaoQuery } from 'graphql/types.generated'

import { IVCF_SUBGRUPOS, IvcfAplicacaoModel, IvcfFieldModel, IvcfViewModel } from './model-ivcf'

export const convertIvcfQueryToView = (
  value: IvcfEmAtendimentoObservacaoQuery['atendimentoIndividual']['ivcf']
): IvcfViewModel => {
  if (!value) return null
  return {
    resultado: value.resultado,
    riscoVulnerabilidade: value.riscoVulnerabilidade,
    dimensoesAlteradas: value.dimensoesAlteradas,
    aplicacao: Object.fromEntries(
      value.aplicacao.map((item) => [item.pergunta, item.resposta ? SimNaoEnum.SIM : SimNaoEnum.NAO])
    ) as IvcfAplicacaoModel,
  }
}

export const converIvcfFormToView = (value: IvcfFieldModel): IvcfViewModel => {
  if (!value) return null
  return {
    resultado: value.resultado,
    riscoVulnerabilidade: value.riscoVulnerabilidade,
    dimensoesAlteradas: value.dimensoesAlteradas
      .map((dimensaoAlterada) => IVCF_SUBGRUPOS[dimensaoAlterada].label)
      .join('; '),
    aplicacao: value.aplicacao,
  }
}
