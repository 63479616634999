import { Alert } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { Chart, ChartBody, ChartContainer, ChartFooter, DateRange, ValueRange } from 'components/chart'
import { format } from 'date-fns'
import moment, { Moment } from 'moment'
import React from 'react'
import { calculateIdadeGestacionalParaGraficos } from 'util/atendimento/gestante/calculateIdadeGestacional'

import { HistoricoPreNatalModel } from '../../../../types/model-historicoPreNatal'
import { PeriodoGestacaoModel } from '../../../../types/PeriodoGestacaoModel'
import { reduceMedicoesIguais } from '../../../util-medicoes'
import { LinhaTooltipGrafico } from '../../components/LinhaTooltipGrafico'
import { convertToGrafico, getReferenceAreas } from './converter-graficoAlturaUterina'
import { TickSemanaGestacional } from './TickSemanaGestacional'

export interface GraficoAlturaUterinaProps {
  dataConvertedPreNatal: HistoricoPreNatalModel
}

export function GraficoAlturaUterinaView(props: GraficoAlturaUterinaProps) {
  const { dataConvertedPreNatal } = props

  const dataInicioGestacao = dataConvertedPreNatal?.dataInicioGestacao

  if (dataInicioGestacao === null || dataInicioGestacao === undefined)
    return (
      <Alert type='info'>
        É preciso ter uma DUM registrada para visualizar este gráfico. O registro pode ser feito na seção Objetivo do
        SOAP.
      </Alert>
    )

  const medicoes = convertToGrafico(dataConvertedPreNatal?.medicoesPreNatal) ?? []

  const formatedDataInicioGestacao = format(dataInicioGestacao, 'yyyy-MM-dd')

  const gestacao: PeriodoGestacaoModel = {
    id: null,
    inicio: formatedDataInicioGestacao,
  }

  const medicoesComAlturaUterina =
    reduceMedicoesIguais(
      medicoes.filter((m) => m.valorAlturaUterina),
      'valorAlturaUterina'
    ) ?? []
  const seriesData = medicoesComAlturaUterina.map((m) => ({
    x: m.dataMedicao,
    y: m.valorAlturaUterina,
  }))
  const yRange: ValueRange = { init: 7, end: 39, step: 2 }
  const xRange: DateRange = {
    init: moment(gestacao.inicio).add(13, 'weeks'),
    end: moment(gestacao.inicio).add(41, 'weeks'),
    step: { amount: 2, unit: 'weeks' },
  }

  return (
    <ChartContainer>
      <ChartBody height={500}>
        <Chart<Moment>
          series={[{ name: 'Altura uterina', color: gray.c20, data: seriesData }]}
          referenceAreas={getReferenceAreas(xRange, formatedDataInicioGestacao)}
          xAxis={{
            title: 'Idade gestacional (Semanas)',
            domain: xRange,
            tickRenderer: (props) => <TickSemanaGestacional {...props} dataInicioGestacao={moment(gestacao.inicio)} />,
          }}
          yAxis={{ title: 'Altura uterina', unit: 'cm', domain: yRange }}
          showLegend={false}
          tooltip={{
            type: 'point',
            render: (points) => {
              if (!points?.length) return
              const momentX = moment(points[0].x)
              const { idadeGestacional, hasGestacaoPeriodo } = calculateIdadeGestacionalParaGraficos(
                [gestacao],
                momentX
              )
              return (
                <>
                  {points?.map((p) => (
                    <LinhaTooltipGrafico key={p.seriesName}>{`AU: ${p.y} cm`}</LinhaTooltipGrafico>
                  ))}
                  {hasGestacaoPeriodo && (
                    <LinhaTooltipGrafico>
                      {`IG: ${idadeGestacional} ${idadeGestacional === 1 ? 'semana' : 'semanas'}`}
                    </LinhaTooltipGrafico>
                  )}
                  <LinhaTooltipGrafico>{`Data: ${momentX.format('DD/MM/YYYY')}`}</LinhaTooltipGrafico>
                </>
              )
            },
          }}
        />
      </ChartBody>
      <ChartFooter>Fonte: Caderneta da Gestante - 2018</ChartFooter>
    </ChartContainer>
  )
}
