import { AtencaoDomiciliarCondutaDesfecho, AtencaoDomiciliarModalidade } from 'graphql/types.generated'
import moment from 'moment'
import { cns, cpf, createValidator, dataNasc, ErrorObject, nome, required } from 'util/validation'

import CidadaoADFormModel from './CidadaoADFormModel'
import { CuidadorFieldGroupModel } from './CuidadorDadosFieldGroup'
import { ResponsavelFieldGroupModel } from './ResponsavelDadosFieldGroup'

export const validateCidadaoADDadosForm = () =>
  createValidator<CidadaoADFormModel>(
    {
      responsavel: validateResponsavel,
      cuidador: validateCuidador,
    },
    validateDadosCidadaoAD()
  )

const validateResponsavel = createValidator<ResponsavelFieldGroupModel>(
  {
    nomeResponsavel: [nome()],
    dataNascimento: [dataNasc],
  },
  (values: ResponsavelFieldGroupModel) => {
    const errors: ErrorObject<ResponsavelFieldGroupModel> = {}
    const [lengthCpf, cpfRule] = cpf
    const [lengthCns, cnsRule] = cns

    if (values?.cpfOuCns) {
      if (lengthCpf && cpfRule(values.cpfOuCns) && lengthCns && cnsRule(values.cpfOuCns)) {
        return { cpfOuCns: 'CPF/CNS inválido.' }
      }
    }

    return errors
  }
)

const validateDadosCidadaoAD = () =>
  createValidator<CidadaoADFormModel>(
    {
      cidadao: [required],
      dataAdmissao: [required, dataNasc],
      modalidade: [required],
      cid10Principal: [required],
      dataObito: [dataNasc],
      dataSaida: [dataNasc],
    },
    (values: CidadaoADFormModel, errors) => {
      if (!errors.dataAdmissao && !errors.cidadao) {
        if (moment(values?.dataAdmissao).isBefore(values?.cidadao?.dataNascimento, 'date')) {
          errors.dataAdmissao = 'Deve ser maior que a Data de nascimento do Cidadão.'
        }
      }

      if (!errors.dataAdmissao && moment(values?.dataAdmissao).isAfter(values?.dataUltimaVisita)) {
        errors.dataAdmissao = `Deve ser menor ou igual à data da última visita, que foi realizada em ${moment(
          values.dataUltimaVisita
        ).format('DD/MM/YYYY')}.`
      }

      if (values?.modalidade === AtencaoDomiciliarModalidade.POS_OBITO) {
        errors.cidadaoAdEmObito = required(values.cidadaoAdEmObito)
        if (
          !errors.cidadao &&
          !errors.cidadaoAdEmObito &&
          values?.cidadao?.id === values?.cidadaoAdEmObito?.cidadao.id
        ) {
          const msgError = 'Cidadão em AD não pode ser o mesmo que o Cidadão em óbito.'
          errors.cidadao = msgError
          errors.cidadaoAdEmObito = msgError
        }
        if (
          !errors.cidadaoAdEmObito &&
          !errors.dataAdmissao &&
          moment(values?.cidadaoAdEmObito?.cidadao.dataObito).isAfter(values?.dataAdmissao)
        ) {
          errors.dataAdmissao = 'Deve ser maior ou igual que a Data de óbito do Cidadão em óbito.'
        }
      } else {
        errors.procedencia = required(values.procedencia)
      }

      if (values?.id && !errors.condutaDesfecho) {
        errors.condutaDesfecho = required(values.condutaDesfecho)
        if (values?.condutaDesfecho !== AtencaoDomiciliarCondutaDesfecho.PERMANENCIA) {
          errors.dataSaida = required(values.dataSaida)
          if (!errors.dataSaida && moment(values?.dataSaida).isBefore(values?.dataUltimaVisita)) {
            errors.dataSaida = `Deve ser maior ou igual à data da última visita, que foi realizada em ${moment(
              values.dataUltimaVisita
            ).format('DD/MM/YYYY')}.`
          }
          if (!errors.dataSaida && moment().isBefore(values?.dataSaida)) {
            errors.dataSaida = 'Deve ser menor ou igual à data atual.'
          }
          if (!errors.dataAdmissao && !errors.dataSaida && moment(values?.dataAdmissao).isAfter(values?.dataSaida)) {
            errors.dataSaida = 'Deve ser maior ou igual à Data de admissão.'
            errors.dataAdmissao = 'Deve ser menor ou igual à Data de saída.'
          }
          if (values?.condutaDesfecho === AtencaoDomiciliarCondutaDesfecho.OBITO) {
            errors.dataObito = required(values.dataObito)
            if (!errors.dataObito) {
              if (values?.dataObito && moment().isBefore(values?.dataObito)) {
                errors.dataObito = 'Deve ser menor ou igual à data atual.'
              }
              if (!errors.dataAdmissao && moment(values?.dataObito).isBefore(values?.dataAdmissao)) {
                errors.dataObito = 'Deve ser maior ou igual à Data de admissão.'
                errors.dataAdmissao = 'Deve ser menor ou igual à Data de óbito.'
              }
              if (moment(values?.dataObito).isBefore(values?.dataUltimaVisita)) {
                errors.dataObito = `Deve ser maior ou igual à data da última visita, que foi realizada em ${moment(
                  values.dataUltimaVisita
                ).format('DD/MM/YYYY')}.`
              }
            }
          }
        }
      }

      if (!errors.cid10Principal && !errors.cid10Secundario2 && !errors.cid10Secundario1) {
        if (values?.cid10Principal) {
          if (values?.cid10Principal?.id === values?.cid10Secundario1?.id) {
            const msgError = 'CID10 Principal e CID10 Secundário 1 não podem ser iguais.'
            errors.cid10Principal = msgError
            errors.cid10Secundario1 = msgError
          }

          if (values?.cid10Principal?.id === values?.cid10Secundario2?.id) {
            const msgError = 'CID10 Principal e CID10 Secundário 2 não podem ser iguais.'
            errors.cid10Principal = msgError
            errors.cid10Secundario2 = msgError
          }
        }

        if (values?.cid10Secundario1) {
          if (values?.cid10Secundario1?.id === values?.cid10Secundario2?.id) {
            const msgError = 'CID10 Secundário 1 e CID10 Secundário 2 não podem ser iguais.'
            errors.cid10Secundario1 = msgError
            errors.cid10Secundario2 = msgError
          }
        }
      }

      return errors
    }
  )

const validateCuidador = createValidator<CuidadorFieldGroupModel>(
  {
    nomeCuidador: [nome()],
    dataNascimento: [dataNasc],
  },
  (values: CuidadorFieldGroupModel) => {
    const errors: ErrorObject<CuidadorFieldGroupModel> = {}
    const [lengthCpf, cpfRule] = cpf
    const [lengthCns, cnsRule] = cns

    if (values?.cpfOuCns) {
      if (lengthCpf && cpfRule(values.cpfOuCns) && lengthCns && cnsRule(values.cpfOuCns)) {
        return { cpfOuCns: 'CPF/CNS inválido.' }
      }
    }

    return errors
  }
)
