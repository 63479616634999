import { Cell, FormLabel, TabItem, Tabs, Tooltip, VFlow } from 'bold-ui'
import { ErrorField } from 'components/form'
import { TipoFrequencia } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import TipoFrequenciaContent from '../components/TipoFrequenciaContent'
import { MedicamentoFormModel, TipoDoseEnum } from '../model-prescricao'

const path = metaPath<MedicamentoFormModel>()

export interface FrequenciaTabsProps {
  tipoDose: TipoDoseEnum
  tipoFrequencia: TipoFrequencia
  handleOnChangeTab(tipoFrequencia: TipoFrequencia, tipoDose: TipoDoseEnum): void
  hasFrequenciaDuracaoError: boolean
  hasTotalDoseError: boolean
  hasTurnosError: boolean
}

export default function FrequenciaTabs(props: FrequenciaTabsProps) {
  const {
    tipoDose,
    tipoFrequencia,
    handleOnChangeTab,
    hasFrequenciaDuracaoError,
    hasTotalDoseError,
    hasTurnosError,
  } = props

  const isDoseFracionada = tipoDose === TipoDoseEnum.FRACIONADA
  const isNotDoseComum = tipoDose !== TipoDoseEnum.COMUM

  return useMemo(
    () => (
      <>
        <Cell size={12}>
          <VFlow vSpacing={0.5}>
            <FormLabel label='Periodicidade da dose' required={tipoDose !== TipoDoseEnum.UNICA} />
            <Tooltip text={tipoDose === TipoDoseEnum.UNICA && 'Medicamento definido como dose única'}>
              <Tabs>
                <Tooltip text={isDoseFracionada && 'Medicamento definido como dose fracionada'}>
                  <TabItem
                    active={tipoFrequencia === TipoFrequencia.INTERVALO}
                    onClick={() => handleOnChangeTab(TipoFrequencia.INTERVALO, tipoDose)}
                    disabled={isNotDoseComum}
                    data-cy='IntervaloTab'
                  >
                    Intervalo
                  </TabItem>
                </Tooltip>
                <Tooltip text={isDoseFracionada && 'Medicamento definido como dose fracionada'}>
                  <TabItem
                    active={tipoFrequencia === TipoFrequencia.FREQUENCIA}
                    onClick={() => handleOnChangeTab(TipoFrequencia.FREQUENCIA, tipoDose)}
                    disabled={isNotDoseComum}
                    data-cy='FrequenciaTab'
                  >
                    Frequência
                  </TabItem>
                </Tooltip>
                <Tooltip text={isDoseFracionada && 'Medicamento definido como dose fracionada'}>
                  <TabItem
                    active={tipoFrequencia === TipoFrequencia.TURNO}
                    onClick={() => handleOnChangeTab(TipoFrequencia.TURNO, tipoDose)}
                    disabled={isNotDoseComum}
                    data-cy='TurnoTab'
                  >
                    Turno
                  </TabItem>
                </Tooltip>
                <Tooltip text={tipoDose === TipoDoseEnum.COMUM && 'Medicamento definido como dose comum'}>
                  <TabItem
                    active={tipoFrequencia === TipoFrequencia.FRACIONADO}
                    onClick={() => handleOnChangeTab(TipoFrequencia.FRACIONADO, tipoDose)}
                    disabled={tipoDose !== TipoDoseEnum.FRACIONADA}
                  >
                    Fracionado por turno
                  </TabItem>
                </Tooltip>
              </Tabs>
            </Tooltip>
          </VFlow>
        </Cell>
        {hasFrequenciaDuracaoError && (
          <Cell size={12} data-cy='AlertDuracaoTratamento'>
            <ErrorField name={path.frequenciaDuracao} ignoreTouchedAndDirty type='alert' />
          </Cell>
        )}
        <Cell size={12} data-cy='FrequenciaDose'>
          <TipoFrequenciaContent
            tipoFrequencia={tipoFrequencia}
            path={path}
            tipoDose={tipoDose}
            hasTotalDoseError={hasTotalDoseError}
            hasTurnosError={hasTurnosError}
          />
        </Cell>
      </>
    ),
    [
      tipoDose,
      isDoseFracionada,
      tipoFrequencia,
      isNotDoseComum,
      hasFrequenciaDuracaoError,
      hasTotalDoseError,
      hasTurnosError,
      handleOnChangeTab,
    ]
  )
}
