import { useIsProcessTerritorioRunningLazyQuery } from 'graphql/hooks.generated'
import { useCallback, useEffect, useRef, useState } from 'react'

interface AsyncTerritorioPollingProps {
  onProcessFinished: () => void
  key: string
  timeOutMs: number
}

export const useAsyncTerritorioProcessingPolling = ({
  key,
  timeOutMs,
  onProcessFinished,
}: AsyncTerritorioPollingProps) => {
  const [isPolling, setIsPolling] = useState(false)

  const intervalIdRef = useRef(null)

  const [executeIsProcessTerritorioRunningQuery] = useIsProcessTerritorioRunningLazyQuery({
    onCompleted: (data) => {
      if (data?.isProcessamentoTerritorioRunning === false) {
        stopPolling()
        onProcessFinished()
      }
    },
  })

  const pollTerritorioProcess = useCallback(() => {
    executeIsProcessTerritorioRunningQuery({
      variables: { input: key },
    })
  }, [executeIsProcessTerritorioRunningQuery, key])

  const startPolling = useCallback(() => {
    if (!isPolling) {
      setIsPolling(true)
      intervalIdRef.current = setInterval(() => {
        pollTerritorioProcess()
      }, timeOutMs)
    }
  }, [isPolling, pollTerritorioProcess, timeOutMs])

  const stopPolling = useCallback(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = null
      setIsPolling(false)
    }
  }, [])

  const resetPolling = useCallback(() => {
    stopPolling()
    startPolling()
  }, [stopPolling, startPolling])

  useEffect(() => {
    return () => {
      stopPolling()
    }
  }, [stopPolling])

  return { startPolling, stopPolling, resetPolling }
}
