/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel, VFlow } from 'bold-ui'
import { Fragment } from 'react'
import { tipoEdemaRecord, tipoGravidezRecord } from 'types/enums'
import { isEmpty } from 'util/validation/Util'
import HistoricoDetailTable from 'view/atendimentos/detail/historico/components/table/HistoricoDetailTable'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { AtendimentoProfissionalPreNatalModel } from '../consulta/HistoricoObjetivoPecPanel'

interface HistoricoMedicoesPreNatalTableBoxProps {
  medicoes: HistoricoMedicaoModel[]
  atendProfPreNatal: AtendimentoProfissionalPreNatalModel
}

export default function HistoricoMedicoesPreNatalTableBox(props: HistoricoMedicoesPreNatalTableBoxProps) {
  const { medicoes, atendProfPreNatal } = props

  return (
    <HistoricoDetailTable title='Medições pré-natal' sectionStyle='O'>
      <VFlow>
        <Grid>
          {medicoes.map((medicao) => (
            <Fragment>
              {medicao.tipoGravidez && (
                <Cell size={2}>
                  <InfoLabel title='Tipo de gravidez'>
                    {tipoGravidezRecord[medicao.tipoGravidez.tipoGravidez]}
                  </InfoLabel>
                </Cell>
              )}
              {medicao.valorAlturaUterina && (
                <Cell size={2}>
                  <InfoLabel title='Altura uterina'>{medicao.valorAlturaUterina} cm</InfoLabel>
                </Cell>
              )}
              {medicao.valorBatimentoCardiacoFetal && (
                <Cell size={2}>
                  <InfoLabel title='Batimento cardíaco fetal'>{medicao.valorBatimentoCardiacoFetal} bpm</InfoLabel>
                </Cell>
              )}
            </Fragment>
          ))}
          {atendProfPreNatal?.tipoEdema && (
            <Cell size={2}>
              <InfoLabel title='Edema'>{tipoEdemaRecord[atendProfPreNatal.tipoEdema]}</InfoLabel>
            </Cell>
          )}
          {!isEmpty(atendProfPreNatal?.movimentacaoFetal) && (
            <Cell size={2}>
              <InfoLabel title='Movimento fetal'>{atendProfPreNatal.movimentacaoFetal ? 'Sim' : 'Não'}</InfoLabel>
            </Cell>
          )}
          {!isEmpty(atendProfPreNatal?.gravidezPlanejada) && (
            <Cell size={2}>
              <InfoLabel title='Gravidez planejada'>{atendProfPreNatal.gravidezPlanejada ? 'Sim' : 'Não'}</InfoLabel>
            </Cell>
          )}
        </Grid>
      </VFlow>
    </HistoricoDetailTable>
  )
}
