/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Theme, useTheme } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { startOfDay } from 'date-fns'
import { IdadeGestacionalAcompanhamentoPreNatal } from 'graphql/types.generated'
import { useHeight, useWidth } from 'hooks/useMeasure'
import { useServerTime } from 'hooks/useServerTime'
import { AsideView } from 'view/atendimentos/detail/soap/aside/AsideView'
import { ASIDE_ATENDIMENTO_STATUSES_ALL_FALSE } from 'view/atendimentos/detail/soap/aside/model-aside'

import { CidadaoCuidadoCompartilhado } from '../model-cuidadoCompartilhado'

interface DiscussaoCasoPageContentProps {
  cidadao: CidadaoCuidadoCompartilhado
  idadeGestacional: IdadeGestacionalAcompanhamentoPreNatal
  headerHeight: number
  children: JSX.Element
}

export const DiscussaoCasoPageContent = (props: DiscussaoCasoPageContentProps) => {
  const { cidadao, idadeGestacional, headerHeight, children } = props
  const { getServerTimeNow } = useServerTime()

  const [asideRef, asideHeight] = useHeight()
  const [asideContainerRef, asideContainerWidth] = useWidth()
  const theme = useTheme()
  const styles = createStyles(theme, asideHeight)

  return (
    <PageContent fluid type='filled'>
      <Grid gap={2}>
        <Cell size={3} style={styles.cellAside}>
          <div ref={asideContainerRef} css={styles.containerLateral}>
            <AsideView
              ref={asideRef}
              prontuarioId={cidadao.prontuario.id}
              dataAtendimento={Number(startOfDay(getServerTimeNow()))}
              cidadao={cidadao}
              gestacoes={cidadao.prontuario.gestacoes}
              isGestante={cidadao.isGestante}
              width={asideContainerWidth ?? 0}
              headerHeight={headerHeight}
              idadeGestacional={idadeGestacional}
              readOnly
              ivcfAtendimentoAtual={null}
              atendimentoStatuses={ASIDE_ATENDIMENTO_STATUSES_ALL_FALSE}
            />
          </div>
        </Cell>
        <Cell size={9}>{children}</Cell>
      </Grid>
    </PageContent>
  )
}

const createStyles = (theme: Theme, asideHeight: number) => ({
  cellAside: css`
    padding-right: 0;
    border-right: 1px solid ${theme.pallete.divider};
  `,
  containerLateral: css`
    width: 100%;
    height: 100%;
    min-height: ${asideHeight}px;
  `,
})
