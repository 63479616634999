import { DateRange } from 'bold-ui'
import { CidadaoAtendimentoSelectFieldModel, EquipeSelectModel } from 'components/form'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { TipoServicoSelectFieldModel } from 'components/form/field/select/TipoServicoSelectField'
import {
  CondutaCuidadoCompartilhadoEnum,
  GarantiaAcessoOrigemRegistroEnum,
  GarantiaAcessoSortEnum,
  GarantiaAcessoTableQuery,
  ListaEsperaMotivoSaidaEnum,
  PageParams,
} from 'graphql/types.generated'

import { TipoAtendimentoSelectModel } from './components/TipoAtendimentoSelectField'

export interface GarantiaAcessoModel {
  id: ID
  cidadao: CidadaoAtendimentoSelectFieldModel
  tipoAtendimento?: TipoAtendimentoSelectModel
  tipoServico?: TipoServicoSelectFieldModel
  equipe?: EquipeSelectModel
  motivo?: string
  dataEntrada?: Instant
}

export interface GarantiaAcessoRemocaoModel {
  garantiaAcessoId: ID
  motivoSaida: ListaEsperaMotivoSaidaEnum
  tentativasContato: number
  observacaoSaida: string
}

export interface GarantiaAcessoEdicaoModel {
  garantiaAcessoId: ID
  equipe?: EquipeSelectModel
  tipoAtendimento?: TipoAtendimentoSelectModel
  tipoServico?: TipoServicoSelectFieldModel
  motivo: string
}

export type GarantiaAcessoFilterPopperFormModel = {
  lotacoes?: LotacaoAndEstagioSelectFieldModel[]
  equipes?: EquipeSelectModel[]
  tiposAtendimento?: TipoAtendimentoSelectModel[]
  periodo?: DateRange
}

export type GarantiaAcessoFilterModel = GarantiaAcessoFilterPopperFormModel & {
  nomeCpfCns?: string
  tiposServico?: TipoServicoSelectFieldModel[]
  origem: GarantiaAcessoOrigemRegistroEnum[]
  ordenacao: GarantiaAcessoSortEnum
  pageParams?: PageParams
}

export type GarantiaAcessoItem = GarantiaAcessoTableQuery['garantiaAcessoByUnidadeSaudeId']['content'][0]

export type CidadaoGarantiaAcesso = GarantiaAcessoTableQuery['garantiaAcessoByUnidadeSaudeId']['content'][0]['cidadao']

export interface GarantiaAcessoFormModel extends Omit<GarantiaAcessoModel, 'id' | 'dataEntrada'> {}

export const motivoSaidaRecord: Record<ListaEsperaMotivoSaidaEnum, string> = {
  ATENDIDO_OUTRO_ESTABELECIMENTO: 'Atendido em outro estabelecimento',
  ATENDIMENTO_AGENDADO: 'Atendimento agendado',
  MUDOU_TERRITORIO: 'Mudou-se de território',
  NAO_RESPONDEU_TENTATIVAS_CONTATO: 'Não respondeu à tentativas de contato',
  OUTROS: 'Outros',
  OBITO: 'Óbito do cidadão',
}

export const GarantiaAcessoOrigemRegistroRecord: Record<GarantiaAcessoOrigemRegistroEnum, string> = {
  GARANTIA_ACESSO: 'Garantia do acesso',
  CUIDADO_COMPARTILHADO: 'Cuidado compartilhado',
}

export const LabelEnviarGarantiaAcessoRecord: Partial<Record<CondutaCuidadoCompartilhadoEnum, string>> = {
  AGENDADO_CONSULTA_CIDADAO_PRESENCIAL: 'Agendamento de consulta com o cidadão',
  AGENDADO_INTERCONSULTA_COM_CIDADAO: 'Agendamento entre profissionais com o cidadão',
  AGENDADO_TELEINTERCONSULTA_COM_CIDADAO: 'Agendamento entre profissionais com o cidadão',
}
