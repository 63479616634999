/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, Theme, useTheme } from 'bold-ui'
import { AccordionField } from 'components/form/field/AccordionField'
import { useEffect, useMemo, useState } from 'react'
import { AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'
import { MetaArray } from 'util/metaPath'

import { FormAccordionButton } from './FormSectionAccordionButton'

export interface FormSectionAccordionProps {
  title: string
  children: React.ReactNode
  uuid: string
  name: MetaArray<string | number>
  style?: ExternalStyles
  buttonStyle?: ExternalStyles
  textStyle?: ExternalStyles
  dangerouslySetExpanded?: boolean
}

export function FormSectionAccordion(props: FormSectionAccordionProps) {
  const { title, children, name, uuid, style, buttonStyle, textStyle, dangerouslySetExpanded } = props
  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme), [theme])

  const [isDangerouslyExpanded, setIsDangerouslyExpanded] = useState(true)

  useEffect(() => {
    setIsDangerouslyExpanded(dangerouslySetExpanded || null)
  }, [dangerouslySetExpanded])

  const handleClick = () => {
    if (dangerouslySetExpanded) {
      setIsDangerouslyExpanded(!isDangerouslyExpanded)
    }
  }

  return (
    <div css={css({ margin: '1rem 0' }, style)}>
      <AccordionField name={name} allowZeroExpanded>
        <AccordionItem uuid={uuid} dangerouslySetExpanded={isDangerouslyExpanded}>
          <FormAccordionButton text={title} style={buttonStyle} textStyle={textStyle} onClick={handleClick} />
          <AccordionItemPanel css={classes.panel}>{children}</AccordionItemPanel>
        </AccordionItem>
      </AccordionField>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  panel: css`
    border: 1px ${theme.pallete.divider} solid;
    border-top-width: 0;
  `,
})
