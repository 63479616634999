import { pink } from 'bold-ui/lib/styles/colors'

import {
  getEmptyReferenceArea,
  getExpectedReferenceArea,
  getNotExpectedReferenceArea,
  getUnderExpectedReferenceArea,
} from '../converter-graficoGanhoPeso'

const SOBREPESO_P10: ReadonlyArray<number> = [
  -3.97,
  -3.77,
  -3.56,
  -3.34,
  -3.12,
  -2.89,
  -2.66,
  -2.42,
  -2.18,
  -1.93,
  -1.67,
  -1.41,
  -1.14,
  -0.87,
  -0.59,
  -0.3,
  0,
  0.3,
  0.61,
  0.92,
  1.24,
  1.57,
  1.91,
  2.25,
  2.6,
  2.96,
  3.33,
  3.7,
  4.09,
  4.47,
  4.87,
]

const SOBREPESO_P18: ReadonlyArray<number> = [
  -2.31,
  -2.08,
  -1.84,
  -1.61,
  -1.36,
  -1.11,
  -0.86,
  -0.6,
  -0.34,
  -0.07,
  0.21,
  0.49,
  0.78,
  1.07,
  1.37,
  1.68,
  1.99,
  2.31,
  2.64,
  2.97,
  3.31,
  3.66,
  4.02,
  4.39,
  4.77,
  5.15,
  5.54,
  5.94,
  6.35,
  6.77,
  7.2,
]

const SOBREPESO_P27: ReadonlyArray<number> = [
  -1.28,
  -1.03,
  -0.77,
  -0.51,
  -0.24,
  0.03,
  0.31,
  0.59,
  0.88,
  1.18,
  1.48,
  1.78,
  2.09,
  2.41,
  2.73,
  3.06,
  3.39,
  3.74,
  4.09,
  4.44,
  4.81,
  5.18,
  5.56,
  5.95,
  6.35,
  6.75,
  7.17,
  7.59,
  8.03,
  8.47,
  8.92,
]

const SOBREPESO_P50: ReadonlyArray<number> = [
  0.11,
  0.43,
  0.74,
  1.07,
  1.4,
  1.73,
  2.07,
  2.42,
  2.77,
  3.13,
  3.49,
  3.86,
  4.24,
  4.62,
  5.01,
  5.4,
  5.8,
  6.21,
  6.62,
  7.04,
  7.47,
  7.9,
  8.34,
  8.78,
  9.23,
  9.69,
  10.16,
  10.63,
  11.11,
  11.6,
  12.09,
]

const SOBREPESO_P90: ReadonlyArray<number> = [
  4.16,
  4.58,
  5.0,
  5.43,
  5.86,
  6.3,
  6.74,
  7.19,
  7.65,
  8.11,
  8.58,
  9.06,
  9.54,
  10.03,
  10.53,
  11.03,
  11.53,
  12.04,
  12.56,
  13.08,
  13.61,
  14.14,
  14.68,
  15.22,
  15.77,
  16.33,
  16.89,
  17.47,
  18.05,
  18.63,
  19.23,
]

const REFERENCE_AREA_P90_SOBREPESO = getNotExpectedReferenceArea(SOBREPESO_P90, 'P90', pink)
const REFERENCE_AREA_P50_SOBREPESO = getNotExpectedReferenceArea(SOBREPESO_P50, 'P50', pink)

const REFERENCE_AREA_P27_SOBREPESO = getExpectedReferenceArea(SOBREPESO_P27, 'P27', 'P18', pink)
const REFERENCE_AREA_ABAIXO_P18_SOBREPESO = getUnderExpectedReferenceArea(SOBREPESO_P18, pink)

const REFERENCE_AREA_P10_SOBREPESO = getNotExpectedReferenceArea(SOBREPESO_P10, 'P10', pink)
const REFERENCE_AREA_ABAIXO_P10_SOBREPESO = getEmptyReferenceArea(SOBREPESO_P10)

export const REFERENCE_AREAS_SOBREPESO = [
  REFERENCE_AREA_ABAIXO_P10_SOBREPESO,
  REFERENCE_AREA_P10_SOBREPESO,
  REFERENCE_AREA_ABAIXO_P18_SOBREPESO,
  REFERENCE_AREA_P27_SOBREPESO,
  REFERENCE_AREA_P50_SOBREPESO,
  REFERENCE_AREA_P90_SOBREPESO,
]
