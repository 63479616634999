import { blue, ColorScale, gray, orange, pink, purple, turquoise } from 'bold-ui/lib/styles/colors'
import { RangeArea, ReferenceArea, ValueRange } from 'components/chart'

import { REFERENCE_AREAS_BAIXO_PESO } from './percentis/percentis-baixoPeso'
import { REFERENCE_AREAS_EUTROFIA } from './percentis/percentis-eutrofia'
import { REFERENCE_AREAS_OBESIDADE } from './percentis/percentis-obesidade'
import { REFERENCE_AREAS_SOBREPESO } from './percentis/percentis-sobrepeso'

export const GANHO_PESO_EIXO_X: ValueRange = { init: 10, end: 40, step: 2 }
export const GANHO_PESO_EIXO_Y: ValueRange = { init: -10, end: 25, step: 1 }

const GANHO_PESO_RANGE_AREA_TERCEIRO_TRIMESTRE: Readonly<RangeArea<number>> = {
  name: '3º trimestre',
  init: 27.05,
  end: 40,
  fillColor: 'none',
  tickColor: blue.c60,
}

const GANHO_PESO_RANGE_AREA_SEGUNDO_TRIMESTRE: Readonly<RangeArea<number>> = {
  name: '2º trimestre',
  init: 13.05,
  end: 26.95,
  fillColor: 'none',
  tickColor: blue.c60,
}

const GANHO_PESO_RANGE_AREA_PRIMEIRO_TRIMESTRE: Readonly<RangeArea<number>> = {
  name: '1º trimestre',
  init: 10,
  end: 12.95,
  fillColor: 'none',
  tickColor: blue.c60,
}

const GANHO_PESO_REFERENCE_LINE_PRIMEIRO_SEGUNDO_TRIMESTRE: Readonly<RangeArea<number>> = {
  name: '',
  init: 13,
  end: null,
  fillColor: 'none',
  strokeColor: gray.c60,
}

const GANHO_PESO_REFERENCE_LINE_SEGUNDO_TERCEIRO_TRIMESTRE: Readonly<RangeArea<number>> = {
  name: '',
  init: 27,
  end: null,
  fillColor: 'none',
  strokeColor: gray.c60,
}

export const GANHO_PESO_RANGE_AREAS: RangeArea<number>[] = [
  GANHO_PESO_RANGE_AREA_PRIMEIRO_TRIMESTRE,
  GANHO_PESO_REFERENCE_LINE_PRIMEIRO_SEGUNDO_TRIMESTRE,
  GANHO_PESO_RANGE_AREA_SEGUNDO_TRIMESTRE,
  GANHO_PESO_REFERENCE_LINE_SEGUNDO_TERCEIRO_TRIMESTRE,
  GANHO_PESO_RANGE_AREA_TERCEIRO_TRIMESTRE,
]

export enum CategoriaImcPreGestacionalEnum {
  OBESIDADE = 'Obesidade',
  SOBREPESO = 'Sobrepeso',
  EUTROFIA = 'Eutrofia',
  BAIXO_PESO = 'Baixo peso',
}

type CategoriaImcPreGestacionalInfo = {
  referenceAreas: Array<ReferenceArea<number>>
  color: ColorScale
  imcMin?: number
  imcMax?: number
}

// Obesidade
export const IMC_MIN_OBESIDADE = 30
// Sobrepeso
export const IMC_MIN_SOBREPRESO = 25
export const IMC_MAX_SOBREPESO = 30
// Eutrofia
export const IMC_MIN_EUTROFIA = 18.5
export const IMC_MAX_EUTROFIA = 25
// Baixo peso
export const IMC_MAX_BAIXO_PESO = 18.5

export const categoriaImcPreGestacionalRecord: Record<
  CategoriaImcPreGestacionalEnum,
  CategoriaImcPreGestacionalInfo
> = {
  [CategoriaImcPreGestacionalEnum.OBESIDADE]: {
    referenceAreas: REFERENCE_AREAS_OBESIDADE,
    color: orange,
    imcMin: IMC_MIN_OBESIDADE,
  },
  [CategoriaImcPreGestacionalEnum.SOBREPESO]: {
    referenceAreas: REFERENCE_AREAS_SOBREPESO,
    color: pink,
    imcMin: IMC_MIN_SOBREPRESO,
    imcMax: IMC_MAX_SOBREPESO,
  },
  [CategoriaImcPreGestacionalEnum.EUTROFIA]: {
    referenceAreas: REFERENCE_AREAS_EUTROFIA,
    color: turquoise,
    imcMin: IMC_MIN_EUTROFIA,
    imcMax: IMC_MAX_EUTROFIA,
  },
  [CategoriaImcPreGestacionalEnum.BAIXO_PESO]: {
    referenceAreas: REFERENCE_AREAS_BAIXO_PESO,
    color: purple,
    imcMax: IMC_MAX_BAIXO_PESO,
  },
}
