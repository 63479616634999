/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, DropdownItem, HFlow, Icon, Theme, Tooltip } from 'bold-ui'
import { DropdownButton } from 'components/dropdown'
import { HideOnScreenSize } from 'components/responsive'
import theme from 'config/theme'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useViewportType } from 'hooks/useViewportType'
import { ReactComponent as SvgPictureInPicture } from 'images/videochamada/picture-in-picture-filled.svg'
import { Fragment, useState } from 'react'

import { SUPPORTS_PICTURE_IN_PICTIRE } from '../hooks/usePictureInPicture'
import { ConfiguracoesVideochamadaModel } from '../model-videochamada'
import { AcaoVideochamadaButton } from './AcaoVideochamadaButton'
import { CompartilharChamadaModal } from './CompartilharChamadaModal'
import { ConfiguracoesVideochamadaFormModal } from './ConfiguracoesVideochamadaFormModal'

type VideoChamadaFooterProps = {
  isOwner: boolean
  audioEnabled: boolean
  audioDeviceAvailable: boolean
  setAudioEnabled(newVal: boolean): void
  videoEnabled: boolean
  videoDeviceAvailable: boolean
  setVideoEnabled(newVal: boolean): void
  chatOpen: boolean
  setChatOpen(newVal: boolean): void
  onConfiguracoesChange(config: ConfiguracoesVideochamadaModel): void
  configuracoes: ConfiguracoesVideochamadaModel
  sharingScreen: boolean
  onShareScreenClick: () => void
  hasRemoteParticipant: boolean
  hasRemoteVideo: boolean
  pipActive: boolean
  setPipActive(newVal: boolean): void
  hasNewMessages: boolean
  onClickSairVideochamada: () => void
}

export function VideoChamadaFooter(props: VideoChamadaFooterProps) {
  const { analytics } = useFirebase()

  const {
    isOwner,
    audioDeviceAvailable,
    audioEnabled,
    setAudioEnabled,
    videoDeviceAvailable,
    videoEnabled,
    setVideoEnabled,
    onConfiguracoesChange,
    configuracoes,
    chatOpen,
    setChatOpen,
    sharingScreen,
    onShareScreenClick,
    hasRemoteParticipant,
    hasRemoteVideo,
    pipActive,
    setPipActive,
    hasNewMessages,
    onClickSairVideochamada,
  } = props

  const styles = createStyles(theme)

  const [isCompartilharChamadaModalOpen, setCompartilharChamadaModalOpen] = useState(false)
  const [isConfiguracoesModalOpen, setConfiguracoesModalOpen] = useState(false)
  const { isTablet } = useViewportType()

  const pipDisabled = !SUPPORTS_PICTURE_IN_PICTIRE || !hasRemoteParticipant || !hasRemoteVideo

  const pipTooltip = !SUPPORTS_PICTURE_IN_PICTIRE
    ? 'Esse navegador não suporta o recurso picture-in-picture.'
    : !hasRemoteParticipant
    ? 'Picture-in-picture será habilitado quando outro participante entrar na chamada.'
    : !hasRemoteVideo
    ? 'Picture-in-picture será habilitado quando o outro participante ligar a câmera.'
    : ''

  return (
    <Fragment>
      <CompartilharChamadaModal
        open={isCompartilharChamadaModalOpen}
        onClose={() => setCompartilharChamadaModalOpen(false)}
      />
      <ConfiguracoesVideochamadaFormModal
        open={isConfiguracoesModalOpen}
        onClose={() => setConfiguracoesModalOpen(false)}
        initialConfigurations={configuracoes}
        onSubmit={onConfiguracoesChange}
      />
      <div css={styles.container}>
        <HFlow
          justifyContent='flex-start'
          style={css`
            flex: 5;
          `}
        ></HFlow>
        <HFlow
          justifyContent='center'
          alignItems='center'
          style={css`
            flex: 2;
          `}
        >
          <AcaoVideochamadaButton
            unavailable={!audioDeviceAvailable}
            state={audioDeviceAvailable && audioEnabled ? 'on' : 'off'}
            onClick={() => setAudioEnabled(!audioEnabled)}
            icon='mic'
          />
          <AcaoVideochamadaButton
            unavailable={!videoDeviceAvailable}
            state={videoDeviceAvailable && videoEnabled ? 'on' : 'off'}
            onClick={() => setVideoEnabled(!videoEnabled)}
            icon='video'
          />
          <HideOnScreenSize down='sm'>
            <HFlow>
              {!isTablet && (
                <AcaoVideochamadaButton
                  state={sharingScreen ? 'on' : 'off'}
                  onClick={onShareScreenClick}
                  icon='screen'
                />
              )}
              <Tooltip text='Mais opções'>
                <DropdownButton style={styles.optionsButton} skin='default' iconColor='white'>
                  {isOwner && (
                    <DropdownItem onClick={() => setCompartilharChamadaModalOpen(true)}>
                      <HFlow alignItems='center' hSpacing={0.5}>
                        <Icon icon='link' />
                        Compartilhar chamada
                      </HFlow>
                    </DropdownItem>
                  )}
                  <Tooltip text={pipTooltip}>
                    <DropdownItem
                      disabled={pipDisabled}
                      onClick={() => {
                        if (!pipActive) analytics.logEvent('TELE_videochamadas_PiP_ativado')
                        setPipActive(!pipActive)
                      }}
                    >
                      <HFlow alignItems='center' hSpacing={0.5}>
                        <SvgPictureInPicture fill={pipDisabled ? theme.pallete.gray.c70 : theme.pallete.gray.c20} />
                        {pipActive ? 'Fechar picture-in-picture' : 'Iniciar picture-in-picture'}
                      </HFlow>
                    </DropdownItem>
                  </Tooltip>
                  <DropdownItem onClick={() => setConfiguracoesModalOpen(true)}>
                    <HFlow alignItems='center' hSpacing={0.5}>
                      <Icon icon='gearFilled' />
                      Configurações
                    </HFlow>
                  </DropdownItem>
                </DropdownButton>
              </Tooltip>
            </HFlow>
          </HideOnScreenSize>

          <AcaoVideochamadaButton
            state={chatOpen ? 'on' : 'off'}
            onClick={() => setChatOpen(!chatOpen)}
            icon='chat'
            hasNotification={hasNewMessages}
          />
        </HFlow>
        <HFlow
          justifyContent='flex-end'
          style={css`
            flex: 5;
          `}
        >
          <HideOnScreenSize down='sm'>
            <Tooltip text='Sair da chamada'>
              <Button kind='danger' style={styles.button} onClick={onClickSairVideochamada}>
                <Icon icon='phoneFilled' color='white' style={styles.buttonIcon} />
                Sair
              </Button>
            </Tooltip>
          </HideOnScreenSize>
        </HFlow>
      </div>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    flex: 1;
    display: flex;
    justify-content: space-between;
  `,
  infoText: css`
    color: ${theme.pallete.gray.c100};
    font-size: ${theme.typography.sizes.button};
    width: 12.5rem;
    text-align: end;
    align-self: center;
    margin-right: 1rem;
  `,
  button: css`
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.875rem 1rem;
    white-space: nowrap;
  `,
  buttonIcon: css`
    margin-right: 0.625rem;
  `,
  optionsButton: css`
    border: none;
    padding: 0.5rem;
    background-color: ${theme.pallete.gray.c30};
    &:not(:disabled):hover {
      background-color: ${theme.pallete.gray.c20};
    }
  `,
})
