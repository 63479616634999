import { Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { FormPrompt } from 'components/form'
import { Form, FormProps } from 'components/form/final-form'
import { getFormGroupErrors } from 'components/form/final-form/util'
import changeValue from 'components/form/mutators/changeValue'
import { format } from 'date-fns'
import arrayMutators from 'final-form-arrays'
import { IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { isEmpty } from 'lodash'
import React from 'react'
import { Fragment, useMemo } from 'react'
import { metaPath } from 'util/metaPath'
import { isObjectDeepFalsy } from 'util/object'

import { ResultadoExameQueryModel, ResultadosExamesModel, SolicitacaoExameQueryModel } from '../model-resultadosExames'
import { addPrefix } from '../util-resultadosExames'
import { SolicitacaoExameField } from './exames-com-solicitacao/SolicitacaoExameField'
import { ExamesSemSolicitacaoField } from './exames-sem-solicitacao/ExamesSemSolicitacaoField'
import { adicionarResultadosExamesValidator } from './validator-ResultadosExamesObjetivo'

interface AdicionarResultadosExamesModalFormProps
  extends Pick<FormProps<ResultadosExamesModel>, 'initialValues' | 'onSubmit'> {
  editing: boolean
  solicitacoes: SolicitacaoExameQueryModel[]
  resultadosSolicitados: Record<ID, ResultadoExameQueryModel>
  cidadaoSexo: SexoEnum
  cidadaoIdentidadeGeneroDbEnum: IdentidadeGeneroEnum
  cidadaoDataNascimento: LocalDate
  goBack(): void
}

const path = metaPath<ResultadosExamesModel>()

export const AdicionarResultadosExamesModalForm = (props: AdicionarResultadosExamesModalFormProps) => {
  const {
    editing,
    solicitacoes,
    resultadosSolicitados,
    cidadaoSexo,
    cidadaoIdentidadeGeneroDbEnum,
    cidadaoDataNascimento,
    initialValues,
    onSubmit,
    goBack,
  } = props

  const {
    atendimentoProfissional: { iniciadoEm },
    observacao: { isAtendimentoObservacao },
  } = useAtendimentoContext()

  const dataInicioAtendimento = format(iniciadoEm, 'yyyy-MM-dd')

  const resultadosSolicitadosIds: ID[] = useMemo(
    () => solicitacoes?.flatMap((solicitacao) => solicitacao.exames).map(({ id }) => addPrefix(id)),
    [solicitacoes]
  )

  return (
    <Form<ResultadosExamesModel>
      onSubmit={onSubmit}
      onSubmitSucceeded={goBack}
      initialValues={initialValues}
      validate={useMemo(
        () =>
          adicionarResultadosExamesValidator(
            cidadaoDataNascimento,
            resultadosSolicitados,
            dataInicioAtendimento,
            isAtendimentoObservacao
          ),
        [cidadaoDataNascimento, resultadosSolicitados, dataInicioAtendimento, isAtendimentoObservacao]
      )}
      mutators={{ ...arrayMutators, changeValue }}
      render={(formProps) => {
        const errors = { ignoringTouchedAndDirtyErrors: [], allErrors: [] }

        resultadosSolicitadosIds.forEach((id) => {
          const error = !isObjectDeepFalsy(getFormGroupErrors(path.resultadosComSolicitacao?.[id], formProps.form))
          const errorIgnoringTouchedAndDirty = !isObjectDeepFalsy(
            getFormGroupErrors(path.resultadosComSolicitacao?.[id], formProps.form, true)
          )

          if (error) {
            errors.allErrors.push(id)
          }

          if (errorIgnoringTouchedAndDirty) {
            errors.ignoringTouchedAndDirtyErrors.push(id)
          }
        })

        return (
          <Fragment>
            <FormPrompt />
            <Modal open onClose={goBack}>
              <ModalBody>
                <VFlow vSpacing={1.5}>
                  <Heading level={1}>{editing ? 'Editar' : 'Adicionar'} resultados de exames</Heading>

                  <ExamesSemSolicitacaoField
                    name={path.resultadosSemSolicitacao}
                    replicateDatePath={path.replicateSemSolicitacao}
                    cidadaoSexo={cidadaoSexo}
                    cidadaoIdentidadeGeneroEnum={cidadaoIdentidadeGeneroDbEnum}
                    dataInicioAtendimento={iniciadoEm}
                    formApi={formProps.form}
                    changeValue={formProps.form.mutators.changeValue}
                  />

                  {!isEmpty(solicitacoes) && (
                    <VFlow vSpacing={1.5}>
                      {solicitacoes?.map((solicitacao) => (
                        <SolicitacaoExameField
                          key={solicitacao.id}
                          name={path.resultadosComSolicitacao}
                          replicateDatesFieldName={path.replicateComSolicitacao}
                          solicitacao={solicitacao}
                          changeValue={formProps.form.mutators.changeValue}
                          value={formProps.values.resultadosComSolicitacao}
                          errors={errors}
                          dataInicioAtendimento={iniciadoEm}
                        />
                      ))}
                    </VFlow>
                  )}
                </VFlow>
              </ModalBody>

              <ModalFooter>
                <HFlow justifyContent='flex-end'>
                  <FooterButton kind='normal' onClick={goBack}>
                    Cancelar
                  </FooterButton>
                  <FooterButton kind='primary' onClick={formProps.handleSubmit}>
                    Salvar
                  </FooterButton>
                </HFlow>
              </ModalFooter>
            </Modal>
          </Fragment>
        )
      }}
    />
  )
}
