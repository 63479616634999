/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tabs } from 'bold-ui'
import { TabLink } from 'components/route'
import { TipoEnderecoEnum } from 'graphql/types.generated'
import { useRouteMatch } from 'react-router'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'

interface VisualizarImovelHeaderTabsProps {
  tipoEndereco: TipoEnderecoEnum
  isStatusRecusaCadastro: boolean
}

export function VisualizarImovelHeaderTabs(props: VisualizarImovelHeaderTabsProps) {
  const { tipoEndereco, isStatusRecusaCadastro } = props
  const match = useRouteMatch()

  return (
    <Tabs
      style={css`
        margin-top: 0.5rem;
        border-bottom-width: 0;
      `}
    >
      <TabLink to={`${match.url}/informacoes`}>Informações cadastrais</TabLink>
      {!isStatusRecusaCadastro && <TabLink to={`${match.url}/familias`}>Famílias e moradores</TabLink>}
      {!tipoEnderecoIndigena.includes(tipoEndereco) && !isStatusRecusaCadastro && (
        <TabLink to={`${match.url}/visitas`}>Últimas visitas</TabLink>
      )}
    </Tabs>
  )
}
