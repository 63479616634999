/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { CheckboxField, Form, FormRenderProps, NumberField, SubmitButton } from 'components/form'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { confirm } from 'components/modals/confirm'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import { Fragment, useState } from 'react'

import { AtestadoAssinaturaDigitalView } from '../assinatura-digital/AtestadoAssinaturaDigitalView'
import { AtestadoConfirmarAssinaturaDigitalModal } from '../assinatura-digital/AtestadoConfirmarAssinaturaDigitalModal'
import { MESSAGE_TOOLTIP_SUBMIT_ATESTADO_DISABLED } from '../assinatura-digital/model-atestadoAssinaturaDigital'
import { useAtestadoAssinaturaDigitalForm } from '../assinatura-digital/useAtestadoAssinaturaDigitalForm'
import { AtestadoContentBox } from '../components/AtestadoContentBox'
import AtestadoModel, { CidadaoAtestado } from '../components/model-atestado'
import { cpfCnsDecorator, name } from './LicencaMaternidadeForm'
import { getLicencaMaternidadeText } from './LicencaMaternidadeTextBox'
import { licencaMaternidadeFormValidator } from './validator'

export interface LicencaMaternidadeFormModalProps {
  onSubmit?(values: AtestadoModel): void
  cidadao: CidadaoAtestado
  initialValues: AtestadoModel
  handleModalClose(): void
  atendimentoId: ID
  prontuarioId: ID
  dataAtendimento: Instant
}

export default function LicencaMaternidadeFormModal(props: LicencaMaternidadeFormModalProps) {
  const { cidadao, initialValues, onSubmit, handleModalClose, atendimentoId, prontuarioId, dataAtendimento } = props

  const possuiCpfCns = cidadao.cpf || cidadao.cns
  const alert = useAlert()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [isEmailCidadaoDirty, setIsEmailCidadaoDirty] = useState(false)

  const tipoAtestado = TipoAtestadoEnum.LICENCA_MATERNIDADE

  const handleSubmit = (values: AtestadoModel) => {
    onSubmit(values)
    handleModalClose()
    alert('success', 'Licença maternidade salva com sucesso')
  }

  const {
    emailCidadao,
    handleSubmitWrapper,
    isAssinando,
    isModalOpen,
    onConfirm,
    onCancel,
    errorMessage,
    onAlertClose,
  } = useAtestadoAssinaturaDigitalForm({
    cidadaoId: cidadao.id,
    atendimentoId,
    prontuarioId,
    tipoAtestado,
    dataAtendimento,
    onSubmit: handleSubmit,
  })

  const handleCancel = (dirty: boolean) => {
    if (dirty) {
      confirm({
        title: 'Deseja cancelar a edição?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar edição',
        confirmLabel: 'Sim, cancelar edição',
        onConfirm: () => {
          handleModalClose()
          alert('success', 'Edição de licença maternidade cancelada com sucesso.')
        },
      })()
    } else {
      handleModalClose()
      alert('success', 'Edição de licença maternidade cancelada com sucesso.')
    }
  }

  const renderLicencaMaternidadeForm = (formProps: FormRenderProps<AtestadoModel>) => {
    const values = resolveValue(formProps.values, name) || ({} as AtestadoModel)
    values.descricao = getLicencaMaternidadeText(cidadao, values.cpfCns, values.naoPossui, initialValues.data)

    return (
      <Fragment>
        <Modal open onClose={() => handleCancel(formProps.dirty || isEmailCidadaoDirty)}>
          <ModalBody>
            <VFlow>
              <Heading level={1}>Editar licença maternidade</Heading>
              {!possuiCpfCns && (
                <Grid>
                  <Cell size={6}>
                    <NumberField
                      name={name.cpfCns}
                      label='CPF/CNS'
                      disabled={values.naoPossui}
                      required={!values.naoPossui}
                      maxLength={15}
                    />
                  </Cell>
                  <Cell>
                    <CheckboxField
                      name={name.naoPossui}
                      label='Não possui'
                      style={css`
                        margin-top: 1.75rem;
                      `}
                    />
                  </Cell>
                </Grid>
              )}
              <AtestadoAssinaturaDigitalView
                name={name.assinadoDigitalmente}
                cidadaoId={cidadao.id}
                cidadaoEmail={emailCidadao}
                onEmailCidadaoChange={setIsSubmitDisabled}
                hasCid10={!!formProps.values.cid10}
                onDirtyChange={setIsEmailCidadaoDirty}
              />
              <AtestadoContentBox title='LICENÇA MATERNIDADE' content={values.descricao} />
            </VFlow>
          </ModalBody>
          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <Button onClick={() => handleCancel(formProps.dirty)}>Cancelar</Button>
              <Tooltip text={isSubmitDisabled && MESSAGE_TOOLTIP_SUBMIT_ATESTADO_DISABLED}>
                <SubmitButton
                  title='Salvar'
                  kind='primary'
                  handleSubmit={formProps.handleSubmit}
                  disabled={isSubmitDisabled || isAssinando}
                  loading={isAssinando}
                >
                  Salvar
                </SubmitButton>
              </Tooltip>
            </HFlow>
          </ModalFooter>
        </Modal>
        {isModalOpen && (
          <AtestadoConfirmarAssinaturaDigitalModal
            emailCidadao={emailCidadao}
            onConfirm={onConfirm}
            onCancel={onCancel}
            isAssinando={isAssinando}
            errorMessage={errorMessage}
            onAlertClose={onAlertClose}
            tipoAtestado={tipoAtestado}
            descricao={formProps.values.descricao}
          />
        )}
      </Fragment>
    )
  }

  return (
    <Form<AtestadoModel>
      initialValues={initialValues}
      render={renderLicencaMaternidadeForm}
      onSubmit={handleSubmitWrapper}
      validate={!possuiCpfCns && licencaMaternidadeFormValidator}
      decorators={[cpfCnsDecorator]}
      suppressNotificationError
    />
  )
}
