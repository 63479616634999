import { useUrlQueryParams } from 'hooks/useUrlQueryParams'
import { useMemo } from 'react'
import { ImovelUrlParams } from 'view/visualizacao-imovel/model-visualizacao-imovel'

import { CADASTRAR_IMOVEL_PATH, EDITAR_IMOVEL_PATH } from '../constants-cadastroImovel'

export function useGetCallbackUrlToCadastroImovel(imovelId?: ID): string {
  const cadImovelUrlParams = useUrlQueryParams<ImovelUrlParams>()

  const callBackUrl = useMemo(() => {
    let path = imovelId ? EDITAR_IMOVEL_PATH : CADASTRAR_IMOVEL_PATH

    if (cadImovelUrlParams)
      for (const [key, value] of Object.entries(cadImovelUrlParams)) {
        path += `&cadImovelUrlParams[${key}]=${value}`
      }

    if (imovelId) {
      path += `&cadImovelUrlParams[imovelId]=${imovelId}`
    }
    return path
  }, [cadImovelUrlParams, imovelId])

  return callBackUrl
}
