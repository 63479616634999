import { Alert, Button, Cell, FormControl, Grid, HFlow, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { RadioField, SelectField } from 'components/form'
import { CampoRelatorioGerencialSelectField } from 'components/form/field/select/CampoRelatorioGerencialSelectField/CampoRelatorioGerencialSelectField'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import changeValue from 'components/form/mutators/changeValue'
import { InfoIcon } from 'components/InfoIcon'
import { ValidationErrors } from 'final-form'
import { PosicaoCampoRelatorioGerencialEnum, TipoModuloEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { MetaArray } from 'util/metaPath'
import { isObjectDeepFalsy } from 'util/object'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { EditableListForm, EditableListFormRenderProps } from 'view/atendimentos/detail/components/EditableListForm'

import { KeyMapping } from '../../../common/keyMapping-relatorioGerencial'
import { getCamposDisponiveis } from '../utils-modeloPersonalizado'
import { ModeloPersonalizadoRelatorioGerencialCampoModel } from './model-modeloPersonalizadoRelatorioGerencialForm'
import { ModeloPersonalizadoRelatorioGerencialCampoDataTable } from './ModeloPersonalizadoRelatorioGerencialCampoDataTable'
import { modeloPersonalizadoRelatorioGerencialCamposFormValidator } from './validator-modeloPersonalizadoRelatorioGerencialCampos'

interface ModeloPersonalizadoRelatorioGerencialCamposFormProps {
  meta: MetaArray<ModeloPersonalizadoRelatorioGerencialCampoModel>
  keyMapping: Map<string, KeyMapping>
  campos?: ModeloPersonalizadoRelatorioGerencialCampoModel[]
  isPublico?: boolean
  errors?: ValidationErrors
  modulo: TipoModuloEnum
  hasCampoInconsistente: boolean
  hasCampoInvalido: boolean
  setFormHasBeenTouched: (state: boolean) => void
}

const PREFIX = 'campo'

export const ModeloPersonalizadoRelatorioGerencialCamposForm = (
  props: ModeloPersonalizadoRelatorioGerencialCamposFormProps
) => {
  const {
    meta,
    keyMapping,
    campos,
    isPublico,
    errors,
    modulo,
    hasCampoInconsistente,
    hasCampoInvalido,
    setFormHasBeenTouched,
  } = props

  const theme = useTheme()
  const styles = createStyles(theme)
  const { hasAuthorization, isGestorEstadual } = useSession()

  const initialValues = useMemo<ModeloPersonalizadoRelatorioGerencialCampoModel>(
    () => ({
      _id: 'new',
      key: null,
      posicao: PosicaoCampoRelatorioGerencialEnum.FILTRO,
      items: [],
      isAdicionadoAgora: true,
    }),
    []
  )

  const validator = useMemo(() => modeloPersonalizadoRelatorioGerencialCamposFormValidator(campos), [campos])

  const camposDisponiveis = useMemo(
    () => getCamposDisponiveis(keyMapping, modulo, campos, isPublico, isGestorEstadual, hasAuthorization),
    [campos, hasAuthorization, isGestorEstadual, isPublico, keyMapping, modulo]
  )

  const { handleSubmit, removeItem } = useEditableListField({ name: meta, subscription: { value: true } })

  const renderForm = (props: EditableListFormRenderProps<ModeloPersonalizadoRelatorioGerencialCampoModel>) => {
    const {
      name,
      values,
      touched,
      handleSubmit,
      form: {
        mutators: { changeValue },
      },
    } = props

    const resetItems = () => {
      changeValue(name.items, [])
    }

    setFormHasBeenTouched(!isObjectDeepFalsy(touched))

    return (
      <VFlow>
        <FormControl label={selecaoCamposLabel} error={errors?.campos}>
          <div style={styles.selecaoCampos}>
            <Grid justifyContent='space-between'>
              <Cell size={6}>
                <VFlow vSpacing={0.3}>
                  <Text variant='h5'>Campos disponíveis</Text>
                  <SelectField
                    name={name.key}
                    items={camposDisponiveis}
                    itemToString={(item) => keyMapping.get(item)?.keyName}
                    placeholder='Selecione um campo'
                    onChange={resetItems}
                    value={values.key}
                  />
                </VFlow>
              </Cell>
              <Cell size={6}>
                <VFlow vSpacing={0.3}>
                  <Text variant='h5'>Itens</Text>
                  <CampoRelatorioGerencialSelectField
                    name={name.items}
                    disabled={isUndefinedOrNull(values.key)}
                    queryDocument={keyMapping.get(values.key)?.queryDocument}
                    formatter={keyMapping.get(values?.key)?.formatter}
                    tipoModulo={modulo}
                  />
                </VFlow>
              </Cell>
              <Cell size={6}>
                <VFlow>
                  <Text variant='h5'>Modo de uso</Text>
                  <HFlow style={{ alignContent: 'space-between' }}>
                    <HFlow>
                      <RadioField
                        name={name.posicao}
                        label={
                          <HFlow hSpacing={0.3} alignItems='center'>
                            <Icon icon='filterOutline' />
                            <Text>Filtro</Text>
                          </HFlow>
                        }
                        value={PosicaoCampoRelatorioGerencialEnum.FILTRO}
                      />
                      <RadioField
                        name={name.posicao}
                        label={
                          <HFlow hSpacing={0.3} alignItems='center'>
                            <Icon icon='hamburguerMenu' style={styles.colunaIcon} />
                            <Text>Coluna</Text>
                          </HFlow>
                        }
                        value={PosicaoCampoRelatorioGerencialEnum.COLUNA}
                      />
                      <RadioField
                        name={name.posicao}
                        label={
                          <HFlow hSpacing={0.3} alignItems='center'>
                            <Icon icon='hamburguerMenu' />
                            <Text>Linha</Text>
                          </HFlow>
                        }
                        value={PosicaoCampoRelatorioGerencialEnum.LINHA}
                      />
                    </HFlow>
                  </HFlow>
                </VFlow>
              </Cell>
              <Cell flexShrink={1} alignSelf='flex-end'>
                <Button onClick={handleSubmit} kind='primary' size='small'>
                  Adicionar
                </Button>
              </Cell>
            </Grid>
          </div>
        </FormControl>

        {hasCampoInconsistente && (
          <Alert inline type='warning' style={styles.alert}>
            Os campos inconsistentes não serão exibidos no relatório. Exclua-os para resolver as inconsistências.
          </Alert>
        )}

        <ModeloPersonalizadoRelatorioGerencialCampoDataTable
          keyMapping={keyMapping}
          campos={campos}
          removeItem={removeItem}
        />

        {hasCampoInvalido && (
          <Alert inline type='danger' style={styles.alert}>
            Um modelo público não pode ter campos privados. Exclua-os antes de salvar.
          </Alert>
        )}
      </VFlow>
    )
  }

  return (
    <EditableListForm<ModeloPersonalizadoRelatorioGerencialCampoModel>
      render={renderForm}
      prefix={PREFIX}
      onSubmit={handleSubmit}
      validate={validator}
      initialValues={initialValues}
      mutators={{ changeValue }}
    />
  )
}

const selecaoCamposLabel = (
  <HFlow hSpacing={0.3}>
    <RequiredLabel label='Seleção de campos' inline />
    <InfoIcon icon='infoCircleFilled' text='Adicione pelo menos uma linha/coluna' />
  </HFlow>
)

const createStyles = (theme: Theme) => ({
  selecaoCampos: {
    padding: `0.7rem 1rem 1rem 1rem`,
    border: `1px solid ${theme.pallete.gray.c80}`,
  },
  colunaIcon: {
    transform: 'rotate(90deg)',
  },
  alert: {
    width: '100%',
  },
})
