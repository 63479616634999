import { turquoise } from 'bold-ui/lib/styles/colors'

import {
  getEmptyReferenceArea,
  getExpectedReferenceArea,
  getNotExpectedReferenceArea,
  getUnderExpectedReferenceArea,
} from '../converter-graficoGanhoPeso'

const EUTROFIA_P10: ReadonlyArray<number> = [
  -2.36,
  -2.19,
  -2.0,
  -1.8,
  -1.58,
  -1.34,
  -1.06,
  -0.75,
  -0.41,
  -0.04,
  0.36,
  0.77,
  1.18,
  1.58,
  1.97,
  2.35,
  2.71,
  3.07,
  3.42,
  3.76,
  4.1,
  4.45,
  4.8,
  5.17,
  5.54,
  5.92,
  6.29,
  6.66,
  7.03,
  7.4,
  7.79,
]

const EUTROFIA_P34: ReadonlyArray<number> = [
  0.01,
  0.21,
  0.42,
  0.65,
  0.9,
  1.17,
  1.49,
  1.84,
  2.24,
  2.67,
  3.13,
  3.6,
  4.07,
  4.54,
  4.99,
  5.44,
  5.86,
  6.28,
  6.7,
  7.11,
  7.51,
  7.92,
  8.34,
  8.78,
  9.23,
  9.68,
  10.13,
  10.58,
  11.03,
  11.49,
  11.95,
]

const EUTROFIA_P50: ReadonlyArray<number> = [
  1.01,
  1.22,
  1.45,
  1.69,
  1.96,
  2.26,
  2.59,
  2.97,
  3.39,
  3.85,
  4.34,
  4.84,
  5.35,
  5.84,
  6.33,
  6.8,
  7.26,
  7.71,
  8.16,
  8.6,
  9.03,
  9.47,
  9.93,
  10.4,
  10.88,
  11.37,
  11.86,
  12.34,
  12.83,
  13.33,
  13.83,
]

const EUTROFIA_P90: ReadonlyArray<number> = [
  5.15,
  5.39,
  5.64,
  5.92,
  6.23,
  6.58,
  6.97,
  7.42,
  7.92,
  8.47,
  9.04,
  9.64,
  10.24,
  10.83,
  11.41,
  11.98,
  12.53,
  13.07,
  13.61,
  14.14,
  14.66,
  15.19,
  15.75,
  16.32,
  16.9,
  17.5,
  18.09,
  18.68,
  19.28,
  19.89,
  20.5,
]

const REFERENCE_AREA_P90 = getNotExpectedReferenceArea(EUTROFIA_P90, 'P90', turquoise)
const REFERENCE_AREA_P50 = getNotExpectedReferenceArea(EUTROFIA_P50, 'P50', turquoise)

const REFERENCE_AREA_P34 = getExpectedReferenceArea(EUTROFIA_P34, 'P34', 'P10', turquoise)
const REFERENCE_AREA_P10 = getUnderExpectedReferenceArea(EUTROFIA_P10, turquoise)

const REFERENCE_AREA_ABAIXO_P10 = getEmptyReferenceArea(EUTROFIA_P10)

export const REFERENCE_AREAS_EUTROFIA = [
  REFERENCE_AREA_ABAIXO_P10,
  REFERENCE_AREA_P10,
  REFERENCE_AREA_P34,
  REFERENCE_AREA_P50,
  REFERENCE_AREA_P90,
]
