import { LoadingIndicator } from 'components/loading'
import { useCidadaoFciVisualizacaoQuery } from 'graphql/hooks.generated'
import React from 'react'

import { CidadaoFciDadosPessoaisSection } from './CidadaoFciDadosPessoaisSection'

interface CidadaoFciInformacoesViewProps {
  cidadaoId: ID
}

export function CidadaoFciInformacoesView(props: CidadaoFciInformacoesViewProps) {
  const { cidadaoId } = props

  const {
    data: { cidadao },
    loading,
  } = useCidadaoFciVisualizacaoQuery({
    variables: {
      id: cidadaoId,
    },
    fetchPolicy: 'network-only',
  })

  if (loading) return <LoadingIndicator />

  return <CidadaoFciDadosPessoaisSection cidadao={cidadao} />
}
