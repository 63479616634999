import { Alert, HeadingSection, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { NacionalidadeEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'

import { CidadaoFciForm } from './CidadaoFciForm'
import { CidadaoFciFormModel } from './model-cidadaoFci'

export function CidadaoFciCreateView() {
  const initialValues = useMemo(() => defaultValues, [])

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Cadastro do cidadão' />
      <HeadingSection level={1} title='Cadastro do cidadão' />
      <VFlow>
        <Alert type='info'>
          O módulo tem como objetivo atualizar ou registrar os dados cadastrais do cidadão no sistema. Esses dados
          também podem ser provenientes das informações da Ficha de Cadastro Individual ou Ficha de Avaliação de
          Elegibilidade após seu processamento.
        </Alert>
        <CidadaoFciForm initialValues={initialValues} />
      </VFlow>
    </PageContent>
  )
}

const defaultValues = {
  dadosPessoais: {
    nacionalidadeCidadao: {
      nacionalidade: NacionalidadeEnum.BRASILEIRA,
    },
  },
} as CidadaoFciFormModel
