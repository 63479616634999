import { HeadingSection, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { BoxGroup } from 'components/BoxGroup'
import { PageLoading } from 'components/loading'
import { useConfiguracoesAvancadasQuery } from 'graphql/hooks.generated'
import React from 'react'
import Permissions from 'types/Permissions'
import { isObjectDeepFalsy } from 'util/object'

import { ConfiguracoesBuscaCidadaoPropriedadesBox } from './busca-cidadao-propriedades/ConfiguracoesBuscaCidadaoPropriedadesBox'
import { ConfiguracoesBloqueioFcdtViaCdsBox } from './desabilita-fcdt/ConfiguracaoCadastroDomiciliarBox'
import { ConfiguracoesPerformanceBox } from './quantidade-requisicoes/ConfiguracoesPerformanceBox'

export const ConfiguracoesAvancadasView = () => {
  const { data, loading } = useConfiguracoesAvancadasQuery()

  if (loading) {
    return <PageLoading message='Carregando...' />
  }

  if (isObjectDeepFalsy(data)) {
    return null
  }

  return (
    <VFlow>
      <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarNumeroDeRequisicoesSimultaneas}>
        <VFlow vSpacing={1}>
          <HeadingSection level={2} title='Performance'>
            <BoxGroup>
              <ConfiguracoesPerformanceBox qtdRequisicoes={data.avancada.performance.qtdRequisicoesSimultaneas} />
            </BoxGroup>
            <BoxGroup>
              <ConfiguracoesBuscaCidadaoPropriedadesBox
                habilitado={data.avancada.performance.buscaCidadaoPorPropriedades.habilitado}
              />
            </BoxGroup>
          </HeadingSection>
          <HeadingSection level={2} title='Cadastros via CDS'>
            <BoxGroup>
              <ConfiguracoesBloqueioFcdtViaCdsBox habilitado={data.avancada.cadastroDomiciliarViaCds.habilitado} />
            </BoxGroup>
          </HeadingSection>
        </VFlow>
      </CheckPermission>
    </VFlow>
  )
}
