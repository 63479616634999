import { HFlow, Text } from 'bold-ui'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_PAGE_PARAM,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { SelectField } from 'components/form/final-form'
import { CboTrocaExecutanteSelectFieldDocument } from 'graphql/hooks.generated'
import {
  Cbo,
  CboTrocaExecutanteSelectFieldQuery,
  CboTrocaExecutanteSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

export type CboSelectModel = Cbo

export interface CboTrocaExecutanteSelectFieldProps extends AsyncSelectFieldProps<CboSelectModel> {
  estabelecimentoReferenciaId?: ID
  equipeSolicitanteId?: ID
  equipeExecutanteId?: ID
  excludeProfissionaisIds?: ID[]
  onlyVinculacaoEquipe?: boolean
}

export function CboTrocaExecutanteSelectField(props: CboTrocaExecutanteSelectFieldProps) {
  const {
    estabelecimentoReferenciaId,
    equipeSolicitanteId,
    equipeExecutanteId,
    excludeProfissionaisIds,
    onlyVinculacaoEquipe = true,
    ...rest
  } = props

  const { selectProps } = useAsyncQuerySelect<
    CboSelectModel,
    CboTrocaExecutanteSelectFieldQuery,
    CboTrocaExecutanteSelectFieldQueryVariables
  >({
    query: CboTrocaExecutanteSelectFieldDocument,
    extractItems: (data) => data?.cbosTrocaExecutante?.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        estabelecimentoReferenciaId,
        equipeSolicitanteId,
        equipeExecutanteId,
        excludeProfissionaisIds,
        onlyVinculacaoEquipe,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
      },
    }),
  })

  return <SelectField<CboSelectModel> itemToString={itemToString} renderItem={renderItem} {...selectProps} {...rest} />
}

const itemToString = (item: CboSelectModel) => item?.nome

const renderItem = (item: CboSelectModel) => (
  <>
    <Text fontWeight='bold'>{item.nome}</Text>
    <HFlow hSpacing={0.5}>
      <Text fontWeight='bold'>Código:</Text>
      {item.cbo2002}
    </HFlow>
  </>
)
