import { AsideViewAtendimentoStatuses } from './model-aside'

export function convertToStatuses(
  isAtendimentoProcedimentos: boolean,
  isAtendimentoObservacao: boolean,
  isObservacaoAndAuxiliar: boolean,
  isAtendimentoPreNatal: boolean,
  isRetificacao: boolean
): AsideViewAtendimentoStatuses {
  return {
    isAtendimentoProcedimentos: isAtendimentoProcedimentos ?? false,
    isAtendimentoObservacao: isAtendimentoObservacao ?? false,
    isObservacaoAndAuxiliar: isObservacaoAndAuxiliar ?? false,
    isAtendimentoPreNatal: isAtendimentoPreNatal ?? false,
    isRetificacao: isRetificacao ?? false,
  }
}
