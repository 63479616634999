/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DateRange, HFlow } from 'bold-ui'
import { RadioField, RadioFieldProps } from 'components/form'
import { OpcaoSelecionadaRelatorioGerencialEnum, UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'

import { relatorioGerencialPeriodoRecord } from '../../common/model-relatorioGerencial'
import { RelatorioGerencialRangeField } from './RelatorioGerencialRangeField'

interface PeriodoRadioFieldProps extends Pick<RadioFieldProps, 'disabled' | 'onChange'> {
  unidadeTempoValue: UnidadePeriodoRelatorioGerencialEnum
  opcaoSelecionadaValue: OpcaoSelecionadaRelatorioGerencialEnum
  nameOpcaoSelecionada: MetaPath<OpcaoSelecionadaRelatorioGerencialEnum>
  namePeriodo?: MetaPath<DateRange>
  isModal?: Boolean
  hasError?: boolean
}

export function RelatorioGerencialPeriodoRadioField(props: PeriodoRadioFieldProps) {
  const {
    unidadeTempoValue,
    opcaoSelecionadaValue,
    nameOpcaoSelecionada,
    namePeriodo,
    disabled,
    isModal,
    hasError,
    onChange,
  } = props

  const opcoes = relatorioGerencialPeriodoRecord[unidadeTempoValue]

  return (
    <HFlow>
      <RadioField
        name={nameOpcaoSelecionada}
        value={OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA}
        label={opcoes[OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA].label}
        key={OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA}
        onChange={onChange}
        disabled={disabled}
        wrapperStyle={styles.item}
      />
      <RadioField
        name={nameOpcaoSelecionada}
        value={OpcaoSelecionadaRelatorioGerencialEnum.SEGUNDA}
        label={opcoes[OpcaoSelecionadaRelatorioGerencialEnum.SEGUNDA].label}
        key={OpcaoSelecionadaRelatorioGerencialEnum.SEGUNDA}
        onChange={onChange}
        disabled={disabled}
        wrapperStyle={styles.item}
      />
      <RadioField
        name={nameOpcaoSelecionada}
        value={OpcaoSelecionadaRelatorioGerencialEnum.TERCEIRA}
        label={opcoes[OpcaoSelecionadaRelatorioGerencialEnum.TERCEIRA].label}
        key={OpcaoSelecionadaRelatorioGerencialEnum.TERCEIRA}
        onChange={onChange}
        disabled={disabled}
        wrapperStyle={styles.item}
      />
      {!isModal && (
        <RadioField
          name={nameOpcaoSelecionada}
          value={OpcaoSelecionadaRelatorioGerencialEnum.OUTRO}
          label={opcoes[OpcaoSelecionadaRelatorioGerencialEnum.OUTRO].label}
          key={OpcaoSelecionadaRelatorioGerencialEnum.OUTRO}
          onChange={onChange}
          disabled={disabled}
          wrapperStyle={styles.item}
        />
      )}
      {!isModal && (
        <RelatorioGerencialRangeField
          unidadePeriodoSelecionada={unidadeTempoValue}
          name={namePeriodo}
          disabled={disabled || opcaoSelecionadaValue !== OpcaoSelecionadaRelatorioGerencialEnum.OUTRO}
          onChange={onChange}
          style={!hasError ? styles.item : null}
        />
      )}
    </HFlow>
  )
}

const styles = {
  item: css`
    margin-bottom: 1.25rem;
  `,
}
