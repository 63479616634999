import { Heading, HFlow, Icon, Modal, ModalBody, Text, VFlow } from 'bold-ui'
import React from 'react'

export interface CidadaoObitoModalProps {
  isModalOpen: boolean
  onClose(): void
}

export const CidadaoObitoModal = (props: CidadaoObitoModalProps) => {
  const { onClose, isModalOpen } = props

  return (
    <Modal open={isModalOpen} size='auto' onClose={onClose} closeOnBackdropClick>
      <ModalBody>
        <VFlow vSpacing={0}>
          <Heading level={2}>
            <HFlow alignItems='center'>
              <div style={{ marginBlockEnd: '1rem' }}>
                <Icon icon='exclamationTriangleOutline' fill='alert' size={3} />
              </div>
              Cidadão veio a óbito, reconduza o<br />
              cuidado para finalizá-lo.
            </HFlow>
          </Heading>
          <div style={{ marginLeft: '4rem' }}>
            <Text>
              Este cuidado compartilhado será finalizado automaticamente quando o<br /> cuidado for reconduzido ao
              solicitante. Acesse a tela de discussão de caso
              <br /> para reconduzir o cuidado.
            </Text>
          </div>
        </VFlow>
      </ModalBody>
    </Modal>
  )
}
