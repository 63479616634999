import { useLocalStorageState } from 'hooks/useStorage'
import { useMemo } from 'react'

import { CadastroImovelCacheState } from '../model-cadastroImovel'

export const useCadastroImovelCache = (acessoId: string, redirect?: string, cidadaoId?: ID, endereco?: string) => {
  const [cacheState, setCacheState, deleteCacheState] = useLocalStorageState<CadastroImovelCacheState>(
    `${acessoId}/cadastro-imovel/`
  )
  // TODO NP - Refatorar useLocalStorage quando for integrado o pr da issue #17870

  const { atualizacaoCidadao, cadastroCidadaoModal, cadastroFamiliaModal } = cacheState?.familiaCidadaoModalState || {}

  const previousEndereco = cacheState?.previousEndereco

  const shouldRenderCache = useMemo(
    () =>
      (!!atualizacaoCidadao ||
        !!cadastroCidadaoModal?.modalOpen ||
        !!cadastroFamiliaModal?.modalOpen ||
        !!cacheState?.listaLogradouroState?.fillEnderecoFields) &&
      (cidadaoId !== undefined || redirect !== undefined || endereco === 'true'),
    [atualizacaoCidadao, cacheState, cadastroCidadaoModal, cadastroFamiliaModal, cidadaoId, endereco, redirect]
  )

  /* TODO (NP) #23449: essa hook deleta a cache sempre que o form de Cadastro do imóvel é "descarregado", dai acontece de perder todas as informações quando saimos do Cadastro do imóvel para o módulo do Cidadão e voltamos */
  // useOnBeforeUnload(() => deleteCacheState())

  return {
    cacheState,
    shouldRenderCache,
    previousEndereco,
    setCacheState,
    deleteCacheState,
  }
}
