/** @jsx jsx */
import { jsx } from '@emotion/core'
import { VFlow } from 'bold-ui'

import { IVCF_SUBGRUPOS, IvcfSubGrupoEnum } from '../../model-ivcf'
import { IvcfSubgrupoWrapper } from '../components/IvcfSubgrupoWrapper'
import { IvcfPerguntaField } from './IvcfPerguntaField'

interface IvcfSubgrupoFieldProps {
  ivcfPath: any
  subgrupo: IvcfSubGrupoEnum
  isEditing?: boolean
  readOnly?: boolean
}

export function IvcfSubgrupoField(props: IvcfSubgrupoFieldProps) {
  const { ivcfPath, subgrupo, isEditing, readOnly } = props
  const { perguntas } = IVCF_SUBGRUPOS[subgrupo]

  return (
    <IvcfSubgrupoWrapper subgrupo={subgrupo} isEditing={isEditing}>
      <VFlow vSpacing={0}>
        {perguntas.map((pergunta) => (
          <IvcfPerguntaField
            key={pergunta}
            ivcfPath={ivcfPath}
            pergunta={pergunta}
            isEditing={isEditing}
            readOnly={readOnly}
          />
        ))}
      </VFlow>
    </IvcfSubgrupoWrapper>
  )
}
