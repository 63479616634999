/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, Heading, HFlow, Tag, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { Telefone } from 'components/label'
import { ACOMPANHAMENTO_TERRITORIO_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { LoadingIndicator } from 'components/loading'
import { format } from 'date-fns'
import { css } from 'emotion'
import { useVisualizarImovelHeaderQuery } from 'graphql/hooks.generated'
import { useHistory, useParams } from 'react-router'
import { applyMask, masks } from 'util/mask'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'
import { StatusCadastroImovelTag } from 'view/visualizacaoTerritorial/components/list/StatusCadastroImovelTag'
import { getTipoImovelIcon } from 'view/visualizacaoTerritorial/utils/visualizacaoTerritorialUtils'

import { ImovelUrlParams } from '../model-visualizacao-imovel'
import { getCallbackTabByString } from '../utils-visualizacao-imovel'
import { MapLink } from './MapLink'
import { VisualizarImovelHeaderTabs } from './VisualizarImovelHeaderTabs'

export function VisualizarImovelHeader() {
  const { imovelId } = useParams<ImovelUrlParams>()
  const { data, loading } = useVisualizarImovelHeaderQuery({
    variables: {
      input: imovelId,
    },
    fetchPolicy: 'network-only',
  })

  const {
    tipoImovel,
    bairro,
    cep,
    logradouro,
    tipoLogradouro,
    municipio,
    complemento,
    numero,
    telefoneContato,
    telefoneResidencial,
    condicoesMoradia,
    dataUltimaAtualizacao,
    microarea,
    isCadastroCompleto,
    latitude,
    longitude,
    tipoEndereco,
    numeroResidenciaIndigena,
    aldeia,
    poloBase,
    dsei,
    statusRecusa,
  } = data?.imovel || {}

  const isTipoEnderecoIndigena = tipoEnderecoIndigena.includes(tipoEndereco)

  const history = useHistory()

  const callbackTab = getCallbackTabByString(history.location.pathname.split('/').pop())

  const { HeaderIcon, tooltip } = getTipoImovelIcon(tipoImovel, condicoesMoradia?.tipoDomicilio)

  const handleAtualizarImovelClick = () => {
    setTimeout(() => history.push(`${ACOMPANHAMENTO_TERRITORIO_PATH}/editarImovel/${imovelId}?redirect=${callbackTab}`))
  }

  return loading ? (
    <LoadingIndicator />
  ) : (
    <VFlow>
      <HFlow
        style={css`
          padding: 1rem;
        `}
        justifyContent='space-between'
      >
        <HFlow>
          <HeaderIcon
            style={{
              width: '100%',
              height: '100%',
            }}
          />
          <VFlow vSpacing={0.4}>
            <HFlow>
              {isTipoEnderecoIndigena ? (
                <Heading level={1}>
                  {aldeia.nome.titleCase()}, {numeroResidenciaIndigena}
                </Heading>
              ) : (
                <Heading level={1}>
                  {`${tipoLogradouro?.nome?.titleCase()}`} {logradouro?.titleCase()}, {numero ?? 'S/N'}
                </Heading>
              )}
              <Tag
                type='normal'
                style={css`
                  margin-top: 0.4rem;
                `}
              >
                {tooltip}
              </Tag>
            </HFlow>
            {isTipoEnderecoIndigena ? (
              <Text>{`DSEI: ${dsei.nome.titleCase()} | Polo base: ${poloBase.nome.titleCase()}`}</Text>
            ) : (
              <Text>
                {`${
                  complemento ? `${complemento.titleCase()} - ` : ''
                }${bairro.titleCase()} - ${municipio.nome.titleCase()}/${municipio.uf.sigla.toUpperCase()}  
              ${cep ? ' - ' + applyMask(cep, masks.cep) : ''}`}
              </Text>
            )}

            <HFlow>
              <HLabel title='Microárea'>{microarea === 'S/N' ? 'Fora de área' : microarea}</HLabel>
              <HLabel title='Telefone de contato'>
                <Telefone value={telefoneContato} />
              </HLabel>
              <HLabel title='Telefone residencial'>
                <Telefone value={telefoneResidencial} />
              </HLabel>
              <HLabel title='Geolocalização'>
                {latitude && longitude ? <MapLink latitude={latitude} longitude={longitude} /> : undefined}
              </HLabel>
            </HFlow>
          </VFlow>
        </HFlow>
        <VFlow
          vSpacing={0.4}
          style={css`
            align-items: flex-end;
          `}
        >
          <Button type='button' kind='primary' size='small' onClick={handleAtualizarImovelClick}>
            Atualizar cadastro
          </Button>
          <Text>{`Última atualização em ${dataUltimaAtualizacao && format(dataUltimaAtualizacao, 'dd/MM/yyyy')}`}</Text>
          <HFlow hSpacing={0.5}>
            <Text
              style={css`
                margin-top: 0.2rem;
              `}
            >
              Situação de cadastro:
            </Text>
            <StatusCadastroImovelTag isCadastroCompleto={isCadastroCompleto} isStatusRecusaCadastro={statusRecusa} />
          </HFlow>
        </VFlow>
      </HFlow>

      <VisualizarImovelHeaderTabs tipoEndereco={tipoEndereco} isStatusRecusaCadastro={statusRecusa} />
    </VFlow>
  )
}
