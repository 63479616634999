import { Cell, Grid, InfoLabel, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import {
  FatoAtendDomProblemaCondicao,
  FatoAtendimentoIndividualProblemas,
  FatoAtendOdontoProblemas,
} from 'graphql/types.generated'
import React, { Fragment } from 'react'
import { formatDate } from 'util/date/formatDate'
import { isEmpty } from 'util/validation/Util'
import { situacaoProblemaRecord } from 'view/atendimentos/detail/soap/aside/types/ProblemaModel'

import StatusSquare from '../../StatusSquare'
import HistoricoDetailTable from '../HistoricoDetailTable'

interface HistoricoAvaliacaoProblemasDwTableContentProps {
  problemasCondicoes: Array<
    FatoAtendimentoIndividualProblemas | FatoAtendOdontoProblemas | FatoAtendDomProblemaCondicao
  >
}

export default function HistoricoAvaliacaoProblemasCondicoesDwTableContent({
  problemasCondicoes,
}: HistoricoAvaliacaoProblemasDwTableContentProps) {
  return (
    <HistoricoDetailTable
      title='Problemas e/ou condições avaliados e registrados neste atendimento'
      sectionStyle='A'
      hasLines
    >
      <VFlow>
        {problemasCondicoes.map(
          ({ id, dimensaoCiap, dimensaoCid10, dimensaoSituacaoProblema, dataInicioProblema, dataFimProblema }) => {
            const hasCiap = !isEmpty(dimensaoCiap)
            const hasCid10 = !isEmpty(dimensaoCid10)
            const situacaoProblemaStyleProps = situacaoProblemaRecord[dimensaoSituacaoProblema?.descricao.toUpperCase()]

            return (
              <Grid gap={2} key={id}>
                <Cell size={6}>
                  {hasCiap && (
                    <Fragment>
                      <Text fontWeight='bold'>{`${dimensaoCiap.nomeCiap} - ${dimensaoCiap.codigoCiap} `}</Text>
                      <Text>(CIAP 2)</Text>
                    </Fragment>
                  )}
                  {hasCiap && hasCid10 && <Text> | </Text>}
                  {hasCid10 && (
                    <Fragment>
                      <Text fontWeight='bold'>{`${dimensaoCid10.nomeCid10} - ${dimensaoCid10.codigoCid10} `}</Text>
                      <Text>(CID 10)</Text>
                    </Fragment>
                  )}
                </Cell>
                <Cell size={3}>
                  <VFlow>
                    <InfoLabel title='Situação'>
                      {dimensaoSituacaoProblema ? (
                        <StatusSquare
                          color={situacaoProblemaStyleProps.background}
                          description={situacaoProblemaStyleProps.descricao}
                        />
                      ) : (
                        '-'
                      )}
                    </InfoLabel>
                    {(dataInicioProblema || dataFimProblema) && (
                      <Fragment>
                        <HLabel title='Data de início:'>{formatDate(dataInicioProblema)}</HLabel>
                        <HLabel title='Data de fim:'>{formatDate(dataFimProblema)}</HLabel>
                      </Fragment>
                    )}
                  </VFlow>
                </Cell>
                <Cell size={3}>
                  <InfoLabel title='Lista de problemas/condições'>Não incluído</InfoLabel>
                </Cell>
              </Grid>
            )
          }
        )}
      </VFlow>
    </HistoricoDetailTable>
  )
}
