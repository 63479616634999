import { SelectField, SelectFieldProps } from 'components/form'
import React from 'react'

type RecordStringSelectFieldProps<T extends string> = Omit<SelectFieldProps<T>, 'items' | 'itemToString'> & {
  record: Record<T, string>
  subset?: T[]
}

export function RecordStringSelectField<T extends string>(props: RecordStringSelectFieldProps<T>) {
  const { record, subset, ...rest } = props

  return (
    <SelectField<T> items={subset || (Object.keys(record) as T[])} itemToString={(value) => record[value]} {...rest} />
  )
}
