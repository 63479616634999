import { Calculation } from 'final-form-calculate'
import { MetaRoot } from 'util/metaPath'

import { RndsErrosImpressaoModel } from './model-rndsErros'

export const calculatorRndsErrosImpressao = (meta: MetaRoot<RndsErrosImpressaoModel>): Calculation[] => [
  {
    field: meta.todos.absolutePath(),
    updates: {
      [meta.rac.absolutePath()]: (todos: boolean, allValues: RndsErrosImpressaoModel) =>
        todos ? todos : allValues.rac,
      [meta.rpm.absolutePath()]: (todos: boolean, allValues: RndsErrosImpressaoModel) =>
        todos ? todos : allValues.rpm,
      [meta.riacERiar.absolutePath()]: (todos: boolean, allValues: RndsErrosImpressaoModel) =>
        todos ? todos : allValues.riacERiar,
    },
  },
  {
    field: meta.rac.absolutePath(),
    updates: {
      [meta.todos.absolutePath()]: (rac: boolean, allValues: RndsErrosImpressaoModel) =>
        !rac ? false : allValues.todos,
    },
  },
  {
    field: meta.rpm.absolutePath(),
    updates: {
      [meta.todos.absolutePath()]: (rpm: boolean, allValues: RndsErrosImpressaoModel) =>
        !rpm ? false : allValues.todos,
    },
  },
  {
    field: meta.riacERiar.absolutePath(),
    updates: {
      [meta.todos.absolutePath()]: (riarERiac: boolean, allValues: RndsErrosImpressaoModel) =>
        !riarERiac ? false : allValues.todos,
    },
  },
  {
    field: meta.periodo.startDate.absolutePath(),
    updates: {
      [meta.todos.absolutePath()]: () => false,
    },
  },
  {
    field: meta.periodo.endDate.absolutePath(),
    updates: {
      [meta.todos.absolutePath()]: () => false,
    },
  },
]
