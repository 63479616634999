/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Icon, Text, Tooltip } from 'bold-ui'
import { ErrorField, FractionalField, NumberField, RadioField } from 'components/form'
import { TipoFrequencia, TurnoEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { MetaRoot } from 'util/metaPath'

import { handleKeyEnter } from '../../../util'
import { MedicamentoFormModel, TipoDoseEnum } from '../model-prescricao'
import CheckButtonField from './CheckButtonField'
import { PeriodoTratamentoSelectField } from './PeriodoTratamentoSelectField'

export interface TipoFrequenciaContentProps {
  tipoFrequencia: TipoFrequencia
  path: MetaRoot<MedicamentoFormModel>
  tipoDose: TipoDoseEnum
  hasTotalDoseError: boolean
  hasTurnosError: boolean
}

export default function TipoFrequenciaContent({
  tipoFrequencia,
  path,
  tipoDose,
  hasTotalDoseError,
  hasTurnosError,
}: TipoFrequenciaContentProps) {
  const doseUnicaOuFracionada = tipoDose === TipoDoseEnum.UNICA || tipoDose === TipoDoseEnum.FRACIONADA

  return useMemo(
    () => (
      <Tooltip text={tipoDose === TipoDoseEnum.UNICA && 'Medicamento definido como dose única'}>
        <span>
          {tipoFrequencia === TipoFrequencia.INTERVALO && (
            <Grid gap={1}>
              <Cell
                style={css`
                  padding-top: 0.9rem;
                `}
              >
                <Text fontWeight='bold'>A cada</Text>
              </Cell>
              <Cell>
                <HFlow hSpacing={0.5}>
                  <CheckButtonField
                    name={path.intervaloDose}
                    originalValue={4}
                    disabled={doseUnicaOuFracionada}
                    dataTestId='4hCheckButton'
                  >
                    4h
                  </CheckButtonField>
                  <CheckButtonField name={path.intervaloDose} originalValue={6} disabled={doseUnicaOuFracionada}>
                    6h
                  </CheckButtonField>
                  <CheckButtonField name={path.intervaloDose} originalValue={8} disabled={doseUnicaOuFracionada}>
                    8h
                  </CheckButtonField>
                  <CheckButtonField name={path.intervaloDose} originalValue={12} disabled={doseUnicaOuFracionada}>
                    12h
                  </CheckButtonField>
                  <CheckButtonField
                    name={path.intervaloDose}
                    originalValue={24}
                    disabled={doseUnicaOuFracionada}
                    dataTestId='24hCheckButton'
                  >
                    24h
                  </CheckButtonField>
                </HFlow>
              </Cell>
              <Cell size={2}>
                <NumberField
                  name={path.intervaloDose}
                  maxLength={2}
                  disabled={doseUnicaOuFracionada}
                  data-cy='IntervaloTextField'
                />
              </Cell>
              <Cell
                style={css`
                  padding-top: 0.9rem;
                `}
              >
                <Text fontWeight='bold'>horas</Text>
              </Cell>
            </Grid>
          )}
          {tipoFrequencia === TipoFrequencia.FREQUENCIA && (
            <Grid gap={0}>
              <Cell size={5}>
                <HFlow hSpacing={0.5}>
                  <CheckButtonField
                    name={path.frequenciaDose}
                    originalValue={1}
                    disabled={doseUnicaOuFracionada}
                    dataTestId='1xCheckButton'
                  >
                    1x
                  </CheckButtonField>
                  <CheckButtonField
                    name={path.frequenciaDose}
                    originalValue={2}
                    disabled={doseUnicaOuFracionada}
                    dataTestId='2xCheckButton'
                  >
                    2x
                  </CheckButtonField>
                  <CheckButtonField name={path.frequenciaDose} originalValue={3} disabled={doseUnicaOuFracionada}>
                    3x
                  </CheckButtonField>
                  <CheckButtonField name={path.frequenciaDose} originalValue={4} disabled={doseUnicaOuFracionada}>
                    4x
                  </CheckButtonField>
                  <NumberField
                    name={path.frequenciaDose}
                    maxLength={2}
                    disabled={doseUnicaOuFracionada}
                    data-cy='FrequenciaDoseTextField'
                  />
                </HFlow>
              </Cell>
              <Cell size={7}>
                <Grid gap={0.5}>
                  <Cell
                    size={4}
                    style={css`
                      padding-top: 1rem;
                      text-align: center;
                    `}
                  >
                    <Text fontWeight='bold'>vez(es) a cada</Text>
                  </Cell>
                  <Cell size={3}>
                    <NumberField
                      name={path.quantidadePeriodoFrequenciaTurno}
                      maxLength={2}
                      disabled={doseUnicaOuFracionada}
                      data-cy='QuantidadePeriodoFrequenciaTextField'
                    />
                  </Cell>
                  <Cell size={5}>
                    <PeriodoTratamentoSelectField
                      name={path.unidadeMedidaTempoFrequenciaTurno}
                      onKeyUp={handleKeyEnter}
                      disabled={doseUnicaOuFracionada}
                      data-cy='UnidadeMedidaTempoFrequencia'
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          )}
          {tipoFrequencia === TipoFrequencia.TURNO && (
            <Grid gap={0.5}>
              <ErrorField
                name={path.turno}
                ignoreTouchedAndDirty
                style={css`
                  padding-left: 0.5rem;
                `}
              />
              <Grid gap={0.5}>
                <Cell
                  size={5}
                  style={css`
                    padding-top: 1.35rem;
                    padding-bottom: 1rem;
                  `}
                >
                  <HFlow justifyContent='space-evenly'>
                    <RadioField
                      name={path.turno}
                      value={TurnoEnum.MANHA}
                      label='Manhã'
                      disabled={doseUnicaOuFracionada}
                      data-cy='TurnoManhaRadio'
                    />
                    <RadioField
                      name={path.turno}
                      value={TurnoEnum.TARDE}
                      label='Tarde'
                      disabled={doseUnicaOuFracionada}
                      data-cy='TurnoTardeRadio'
                    />
                    <RadioField
                      name={path.turno}
                      value={TurnoEnum.NOITE}
                      label='Noite'
                      disabled={doseUnicaOuFracionada}
                    />
                  </HFlow>
                </Cell>
                <Cell
                  size={7}
                  style={css`
                    padding-top: 1rem;
                  `}
                >
                  <Grid gap={0.5}>
                    <Cell
                      size={2}
                      style={css`
                        text-align: end;
                        padding-top: 0.9rem;
                      `}
                    >
                      <Text fontWeight='bold'>a cada</Text>
                    </Cell>
                    <Cell size={3}>
                      <NumberField
                        name={path.quantidadePeriodoFrequenciaTurno}
                        maxLength={2}
                        disabled={doseUnicaOuFracionada}
                        data-cy='TurnoTextField'
                      />
                    </Cell>
                    <Cell size={5}>
                      <PeriodoTratamentoSelectField
                        name={path.unidadeMedidaTempoFrequenciaTurno}
                        onKeyUp={handleKeyEnter}
                        disabled={doseUnicaOuFracionada}
                        data-cy='UnidadeMedidaTempoTurno'
                      />
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Grid>
          )}
          {tipoFrequencia === TipoFrequencia.FRACIONADO && (
            <Grid gap={0.5}>
              <HFlow alignItems='center' hSpacing={0.5}>
                <Icon icon='infoCircleOutline' fill='info' size={1} />
                <Text color='info'>Insira a quantidade de dose fracionada. Preencha dois ou três turnos.</Text>
              </HFlow>
              <Cell size={5}>
                <HFlow justifyContent='space-evenly'>
                  <FractionalField
                    name={path.qtDoseManha}
                    label='Manhã'
                    maxLength={5}
                    precisaoDecimal={2}
                    precisaoFracionaria={1}
                    disabled={tipoDose !== TipoDoseEnum.FRACIONADA}
                    invalid={hasTotalDoseError || hasTurnosError}
                  />
                  <FractionalField
                    name={path.qtDoseTarde}
                    label='Tarde'
                    maxLength={5}
                    precisaoDecimal={2}
                    precisaoFracionaria={1}
                    disabled={tipoDose !== TipoDoseEnum.FRACIONADA}
                    invalid={hasTotalDoseError || hasTurnosError}
                  />
                  <FractionalField
                    name={path.qtDoseNoite}
                    label='Noite'
                    maxLength={5}
                    precisaoDecimal={2}
                    precisaoFracionaria={1}
                    disabled={tipoDose !== TipoDoseEnum.FRACIONADA}
                    invalid={hasTotalDoseError || hasTurnosError}
                  />
                </HFlow>
              </Cell>
              <Cell
                size={7}
                style={css`
                  padding-top: 2rem;
                `}
              >
                <Grid gap={0.5}>
                  <Cell
                    size={2}
                    style={css`
                      text-align: end;
                      padding-top: 0.9rem;
                    `}
                  >
                    <Text fontWeight='bold'>a cada</Text>
                  </Cell>
                  <Cell size={3}>
                    <NumberField
                      name={path.quantidadePeriodoFrequenciaTurno}
                      maxLength={2}
                      disabled={tipoDose !== TipoDoseEnum.FRACIONADA}
                      data-cy='TurnoTextField'
                    />
                  </Cell>
                  <Cell size={5}>
                    <PeriodoTratamentoSelectField
                      name={path.unidadeMedidaTempoFrequenciaTurno}
                      onKeyUp={handleKeyEnter}
                      disabled={tipoDose !== TipoDoseEnum.FRACIONADA}
                      data-cy='UnidadeMedidaTempoTurno'
                    />
                  </Cell>
                </Grid>
              </Cell>
              {hasTotalDoseError && (
                <Cell size={12}>
                  <ErrorField name={path.totalDoseError} ignoreTouchedAndDirty type='form-error' />
                </Cell>
              )}
              {hasTurnosError && (
                <Cell size={12}>
                  <ErrorField name={path.turnosError} ignoreTouchedAndDirty type='form-error' />
                </Cell>
              )}
            </Grid>
          )}
        </span>
      </Tooltip>
    ),
    [
      doseUnicaOuFracionada,
      path.frequenciaDose,
      path.intervaloDose,
      path.qtDoseManha,
      path.qtDoseNoite,
      path.qtDoseTarde,
      path.quantidadePeriodoFrequenciaTurno,
      path.turno,
      path.unidadeMedidaTempoFrequenciaTurno,
      path.totalDoseError,
      path.turnosError,
      hasTotalDoseError,
      hasTurnosError,
      tipoDose,
      tipoFrequencia,
    ]
  )
}
