export enum RetificacaoAtendimentoOpcaoEnum {
  EDITAR_REGISTRO = 'EDITAR_REGISTRO',
  REMOVER_REGISTRO = 'REMOVER_REGISTRO',
}

export interface RetificacaoAtendimentoModel {
  id: ID
  tipo: RetificacaoAtendimentoOpcaoEnum
  justificativa: string
}

export interface LotacaoRetificacaoAtendimentoButtonModel {
  profissional?: {
    id: string
  }
  cbo?: {
    id: string
  }
  unidadeSaude?: {
    id: string
  }
  equipe?: {
    id: string
  }
}
