import { DataTable, Heading, InfoLabel, Text, VFlow } from 'bold-ui'
import { TableColumnConfig } from 'bold-ui/lib/components/Table/DataTable/DataTable'
import { DateTime } from 'components/date'
import { createRender } from 'components/table/render'
import React from 'react'
import { tipoGlicemiaRecord } from 'types/enums'
import { isUndefinedOrNull } from 'util/checks'
import { formatNumber } from 'util/number'
import { naoRegistradoNesseAtendimento } from 'view/atendimentos/detail/historico/utils/utils-historico'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

interface HistoricoMedicoesObservacaoPanelProps {
  medicoes: HistoricoMedicaoModel[]
}

const antropometriaColumns: TableColumnConfig<HistoricoMedicaoModel>[] = [
  {
    header: 'Data da medição',
    name: 'dataMedicao',
    render: (medicao) => <DateTime value={medicao.dataMedicao} format={`DD/MM/YYYY`} />,
  },
  {
    header: 'Peso (kg)',
    name: 'valorPeso',
    render: (medicao) => createRender(medicao, 'valorPeso', (value) => formatNumber(value, 2)),
  },
  {
    header: 'Altura (cm)',
    name: 'valorAltura',
    render: (medicao) => createRender(medicao, 'valorAltura', (value) => formatNumber(value, 1)),
  },
  {
    header: 'IMC (kg/m²)',
    name: 'valorImc',
    render: (medicao) => createRender(medicao, 'valorImc', (value) => formatNumber(value, 2)),
  },
  {
    header: 'Per. cefálico (cm)',
    name: 'valorPerimetroCefalico',
    render: (medicao) => createRender(medicao, 'valorPerimetroCefalico', (value) => formatNumber(value, 1)),
  },
  {
    header: 'Circ. abdominal (cm)',
    name: 'valorCircunferenciaAbdominal',
    render: (medicao) => createRender(medicao, 'valorCircunferenciaAbdominal', (value) => formatNumber(value, 1)),
  },
  {
    header: 'Per. panturrilha (cm)',
    name: 'perimetroPanturrilha',
    render: (medicao) => createRender(medicao, 'perimetroPanturrilha', (value) => formatNumber(value, 1)),
  },
]

const sinaisVitaisColumns: TableColumnConfig<HistoricoMedicaoModel>[] = [
  {
    header: 'Data da medição',
    name: 'dataMedicao',
    render: (medicao) => <DateTime value={medicao.dataMedicao} format={`DD/MM/YYYY`} />,
  },
  {
    header: 'Pressão arterial (mmHg)',
    name: 'valorPressaoArterial',
    render: (medicao) => createRender(medicao, 'valorPressaoArterial', renderDefault),
  },
  {
    header: 'Freq. respiratória (mpm)',
    name: 'valorFrequenciaRespiratoria',
    render: (medicao) => createRender(medicao, 'valorFrequenciaRespiratoria', renderDefault),
  },
  {
    header: 'Freq. cardíaca (bpm)',
    name: 'valorFrequenciaCardiaca',
    render: (medicao) => createRender(medicao, 'valorFrequenciaCardiaca', renderDefault),
  },
  {
    header: 'Temperatura (°C)',
    name: 'valorTemperatura',
    render: (medicao) => createRender(medicao, 'valorTemperatura', (value) => formatNumber(value, 1)),
  },
  {
    header: 'Saturação de O2 (%)',
    name: 'valorSaturacaoO2',
    render: (medicao) => createRender(medicao, 'valorSaturacaoO2', renderDefault),
  },
]

const glicemiaColumns: TableColumnConfig<HistoricoMedicaoModel>[] = [
  {
    header: 'Data da medição',
    name: 'dataMedicao',
    render: (medicao) => <DateTime value={medicao.dataMedicao} format={`DD/MM/YYYY`} />,
  },
  {
    header: 'Glicemia capilar (mg/dL)',
    name: 'valorGlicemia',
    render: (medicao) => createRender(medicao, 'valorGlicemia', renderDefault),
  },
  {
    header: 'Momento da coleta',
    name: 'tipoGlicemia',
    render: (medicao) => createRender(medicao, 'tipoGlicemia', (value) => <Text>{tipoGlicemiaRecord[value]}</Text>),
  },
]

export const HistoricoMedicoesObservacaoPanel = (props: HistoricoMedicoesObservacaoPanelProps) => {
  const { medicoes } = props

  const sortedItems = medicoes.sort((a, b) => a.dataMedicao - b.dataMedicao)

  const {
    antropometria: antropometriaRows,
    glicemia: glicemiaRows,
    sinaisVitais: sinaisVitaisRows,
  } = partitionMedicoes(sortedItems)

  const { dum, vacinacaoEmDia } = resolveDumAndVacinacaoEmDia(sortedItems.reverse())

  return (
    <VFlow>
      {dum && <InfoLabel title='DUM'>{<DateTime value={dum} format='DD/MM/YYYY' />}</InfoLabel>}

      <VFlow vSpacing={0.5}>
        <Heading level={5}>Antropometria</Heading>

        {antropometriaRows.isNotEmpty() ? (
          <DataTable<HistoricoMedicaoModel> columns={antropometriaColumns} rows={antropometriaRows} />
        ) : (
          <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
        )}
      </VFlow>

      <VFlow vSpacing={0.5}>
        <Heading level={5}>Sinais vitais</Heading>

        {sinaisVitaisRows.isNotEmpty() ? (
          <DataTable<HistoricoMedicaoModel> columns={sinaisVitaisColumns} rows={sinaisVitaisRows} />
        ) : (
          <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
        )}
      </VFlow>

      <VFlow vSpacing={0.5}>
        <Heading level={5}>Glicemia</Heading>

        {glicemiaRows.isNotEmpty() ? (
          <DataTable<HistoricoMedicaoModel> columns={glicemiaColumns} rows={glicemiaRows} />
        ) : (
          <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
        )}
      </VFlow>

      {vacinacaoEmDia && <InfoLabel title='Vacinação em dia'>Sim</InfoLabel>}
    </VFlow>
  )
}

const renderDefault = (value: string | number) => {
  return <Text>{value}</Text>
}

const partitionMedicoes = (
  items: HistoricoMedicaoModel[]
): {
  antropometria: HistoricoMedicaoModel[]
  sinaisVitais: HistoricoMedicaoModel[]
  glicemia: HistoricoMedicaoModel[]
} => {
  let antropometriaItems: HistoricoMedicaoModel[] = []
  let glicemiaItems: HistoricoMedicaoModel[] = []
  let sinaisVitaisItems: HistoricoMedicaoModel[] = []

  items.forEach((item) => {
    if (isMedicaoDeAntropometria(item)) antropometriaItems.push(item)
    if (isMedicaoDeGlicemia(item)) glicemiaItems.push(item)
    if (isMedicaoDeSinaisVitais(item)) sinaisVitaisItems.push(item)
  })

  return {
    antropometria: antropometriaItems,
    glicemia: glicemiaItems,
    sinaisVitais: sinaisVitaisItems,
  }
}

const isMedicaoDeAntropometria = (item: HistoricoMedicaoModel): boolean => {
  return !!(
    item.valorGlicemia ||
    item.valorAltura ||
    item.valorPeso ||
    item.valorImc ||
    item.valorPerimetroCefalico ||
    item.valorCircunferenciaAbdominal ||
    item.perimetroPanturrilha
  )
}

const isMedicaoDeSinaisVitais = (item: HistoricoMedicaoModel): boolean => {
  return !!(
    item.valorPressaoArterial ||
    item.valorFrequenciaRespiratoria ||
    item.valorFrequenciaCardiaca ||
    item.valorSaturacaoO2 ||
    item.valorTemperatura
  )
}

const isMedicaoDeGlicemia = (item: HistoricoMedicaoModel): boolean => {
  return !!(item.valorGlicemia || item.tipoGlicemia)
}

const resolveDum = (items: HistoricoMedicaoModel[]) => {
  if (items.isEmpty()) return null

  const dums = items?.map((item) => item.dum)

  return dums.find((dum) => !isUndefinedOrNull(dum))
}

const resolveVacinacaoEmDia = (items: HistoricoMedicaoModel[]) => {
  if (items.isEmpty()) return null

  const values = items?.map((item) => item.valorVacinacaoEmDia)

  return values.find((vacinacaoEmDia) => !isUndefinedOrNull(vacinacaoEmDia))
}

const resolveDumAndVacinacaoEmDia = (items: HistoricoMedicaoModel[]) => {
  const dum = resolveDum(items)
  const vacinacaoEmDia = resolveVacinacaoEmDia(items)

  return { dum, vacinacaoEmDia }
}
