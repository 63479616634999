import { Cell, Grid, Heading, HFlow, Text } from 'bold-ui'
import { CheckboxField, CnsField, CpfField, DateField, SexoSelectField, TextField } from 'components/form'
import { DeclaracaoObitoField } from 'components/form/field/DeclaracaoObitoField'
import { EstadoCivilSelectField } from 'components/form/field/select/EstadoCivilSelectField'
import { EtniaSelectField } from 'components/form/field/select/EtniaSelectField'
import { RacaCorSelectField } from 'components/form/field/select/RacaCorSelectField'
import { TipoSanguineoSelectField } from 'components/form/field/select/TipoSanguineoSelectField'
import { InfoIcon } from 'components/InfoIcon'
import { css } from 'emotion'
import { RacaCorDbEnum } from 'graphql/types.generated'
import React, { Fragment } from 'react'
import { onlyNumbers } from 'util/mask'
import { MetaPath } from 'util/metaPath'

import { DadosPessoaisFormModel } from '../model-cidadaoFci'
import { NacionalidadeFieldGroup } from './NacionalidadeFieldGroup'

interface DadosPessoaisSectionProps {
  meta: MetaPath<DadosPessoaisFormModel>
  values: DadosPessoaisFormModel
  serverTime: Date
  isEditCnsEnabled: boolean
}

export const DadosPessoaisSection = ({ meta, values, serverTime, isEditCnsEnabled }: DadosPessoaisSectionProps) => {
  const isIndigena = values?.racaCor?.value === RacaCorDbEnum.INDIGENA

  const desconheceMae = values?.desconheceNomeMae
  const desconhecePai = values?.desconheceNomePai

  return (
    <Grid>
      <Cell size={12}>
        <Heading
          level={2}
          style={css`
            margin-top: 1.5rem;
          `}
        >
          Dados Pessoais
        </Heading>
      </Cell>
      <Cell size={3}>
        <CpfField name={meta.cpf} label='CPF' />
      </Cell>
      <Cell size={3}>
        <CnsField name={meta.cns} label='CNS' disabled={!isEditCnsEnabled} />
      </Cell>
      <Cell size={3}>
        <TextField name={meta.nisPisPasep} parse={onlyNumbers} maxLength={11} minLength={11} label='N°NIS(PIS/PASEP)' />
      </Cell>
      <Cell size={3} />
      <Cell size={6}>
        <TextField name={meta.nome} label='Nome completo' required />
      </Cell>
      <Cell size={6}>
        <TextField
          name={meta.nomeSocial}
          label={
            <HFlow hSpacing={0.5} alignItems='center'>
              <Text>Nome social</Text>
              <InfoIcon
                icon='infoCircleFilled'
                text='O nome social do cidadão cadastrado pelo PEC não será enviado ao CADSUS. Atualmente, só é possível incluir o nome social no CADSUS a partir do cadastro na Receita Federal.'
              />
            </HFlow>
          }
        />
      </Cell>
      <Cell size={3}>
        <DateField name={meta.dataNascimento} maxDate={serverTime} label='Data de nascimento' required />
      </Cell>
      <Cell size={3}>
        <SexoSelectField name={meta.sexo} label='Sexo' required />
      </Cell>
      <Cell size={3}>
        <EstadoCivilSelectField name={meta.estadoCivil} label='Estado civil' />
      </Cell>
      <Cell size={3}>
        <TipoSanguineoSelectField name={meta.tipoSanguineo} label='Tipo sanguíneo' />
      </Cell>
      <Cell size={6}>
        <RacaCorSelectField name={meta.racaCor} label='Raça/Cor' required />
      </Cell>
      <Cell size={6}>
        <EtniaSelectField name={meta.etnia} label='Etnia' required={isIndigena} disabled={!isIndigena} />
      </Cell>
      <Cell size={6}>
        <TextField name={meta.nomeMae} label='Nome da mãe' required={!desconheceMae} disabled={desconheceMae} />
      </Cell>
      <Cell size={6}>
        <CheckboxField
          name={meta.desconheceNomeMae}
          label='Desconhece essa informação'
          style={css`
            margin-top: 1.75rem;
          `}
        />
      </Cell>
      <Cell size={6}>
        <TextField name={meta.nomePai} label='Nome do pai' required={!desconhecePai} disabled={desconhecePai} />
      </Cell>
      <Cell size={6}>
        <CheckboxField
          name={meta.desconheceNomePai}
          label='Desconhece essa informação'
          style={css`
            margin-top: 1.75rem;
          `}
        />
      </Cell>
      <Cell size={12}>
        <NacionalidadeFieldGroup
          meta={meta.nacionalidadeCidadao}
          maxDate={serverTime}
          values={values?.nacionalidadeCidadao}
        />
      </Cell>
      <Cell size={12}>
        <CheckboxField
          name={meta.faleceu}
          label='Cidadão faleceu'
          style={css`
            margin-top: 0.5rem;
          `}
        />
      </Cell>
      {values?.faleceu && (
        <Fragment>
          <Cell size={3}>
            <DateField name={meta.dataObito} maxDate={serverTime} label='Date de óbito' required />
          </Cell>
          <Cell size={3}>
            <DeclaracaoObitoField
              name={meta.numeroDeclaracaoObito}
              label='Número da declaração de óbito'
              style={css`
                margin-top: 0.1rem;
              `}
            />
          </Cell>
          <Cell size={6} />
        </Fragment>
      )}
    </Grid>
  )
}
