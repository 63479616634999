import { LotacaoAndEstagioSelectFieldModel } from '../field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { LotacaoEditableTableModel } from './LotacaoEditableTable'

const sortByNomeProfissional = (a: LotacaoAndEstagioSelectFieldModel, b: LotacaoAndEstagioSelectFieldModel) =>
  a.profissional.nome.localeCompare(b.profissional.nome)

const mapItems = (item: LotacaoAndEstagioSelectFieldModel, index: number): LotacaoEditableTableModel => ({
  _id: index,
  lotacao: item,
})

export const convertLotacoesToLotacaoEditableTableModel = (
  lotacoes?: LotacaoAndEstagioSelectFieldModel[]
): LotacaoEditableTableModel[] => lotacoes?.slice().sort(sortByNomeProfissional).reverse().map(mapItems)
