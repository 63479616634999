import { PecSwitch } from 'components/route'
import React from 'react'
import { match, Redirect, Route } from 'react-router'
import { emptyArray } from 'util/array'
import { CidadaoMedicoes } from 'view/atendimentos/detail/soap/aside/medicoes/model'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { PeriodoGestacaoModel } from '../types/PeriodoGestacaoModel'
import { convertMedicaoOutrosAtendimentos, convertMedicoesNoNascimento } from './converter-medicoes'
import { HistoricoMedicoesAntropometriaView } from './HistoricoMedicoesAntropometriaView'
import { HistoricoMedicoesGlicemiaView } from './HistoricoMedicoesGlicemiaView'
import { HistoricoMedicoesSinaisVitaisView } from './HistoricoMedicoesSinaisVitaisView'
import { MedicoesAtendimentoAnterior, MedicoesNascimento } from './model-medicoes'
import { HistoricoMedicoesPreNatalView } from './pre-natal/HistoricoMedicoesPreNatalView'
import { getMedicoesAntropometricasPreNatal } from './util-medicoes'

export interface ReadOnlyHistoricoMedicoesModalBodyProps {
  initialRoute: string
  match: match
  cidadao: CidadaoMedicoes
  prontuarioId: ID
  medicoes: ReadonlyArray<MedicoesAtendimentoAnterior>
  medicoesNoNascimento: MedicoesNascimento
  gestacoes: ReadonlyArray<PeriodoGestacaoModel>
  isAtendimentoObservacao: boolean
}

export function ReadOnlyHistoricoMedicoesModalBody({
  initialRoute,
  match,
  cidadao,
  prontuarioId,
  medicoes = emptyArray,
  medicoesNoNascimento,
  isAtendimentoObservacao,
  gestacoes,
}: ReadOnlyHistoricoMedicoesModalBodyProps) {
  const medicoesExibir: HistoricoMedicaoModel[] = [
    ...[...medicoes].sort((a, b) => b.dataMedicao - a.dataMedicao).map(convertMedicaoOutrosAtendimentos),
    ...(medicoesNoNascimento ? [convertMedicoesNoNascimento(medicoesNoNascimento, cidadao.dataNascimento)] : []),
  ]

  return (
    <PecSwitch>
      <Redirect exact path={match.url} to={`${match.url}${initialRoute}`} />
      <Route
        path={`${match.url}/pre-natal`}
        render={(renderProps) => (
          <HistoricoMedicoesPreNatalView
            {...renderProps}
            prontuarioId={prontuarioId}
            medicoesAntropometricasPreNatal={getMedicoesAntropometricasPreNatal(medicoesExibir)}
          />
        )}
      />
      <Route
        path={`${match.url}/antropometria`}
        render={(renderProps) => (
          <HistoricoMedicoesAntropometriaView
            {...renderProps}
            medicoes={medicoesExibir}
            cidadao={cidadao}
            gestacoes={gestacoes}
            canEditOrAdd={false}
            isAtendimentoObservacao={isAtendimentoObservacao}
          />
        )}
      />
      <Route
        path={`${match.url}/sinaisVitais`}
        render={(renderProps) => (
          <HistoricoMedicoesSinaisVitaisView {...renderProps} medicoes={medicoesExibir} gestacoes={gestacoes} />
        )}
      />
      <Route
        path={`${match.url}/glicemia`}
        render={(renderProps) => (
          <HistoricoMedicoesGlicemiaView {...renderProps} medicoes={medicoesExibir} gestacoes={gestacoes} />
        )}
      />
    </PecSwitch>
  )
}
