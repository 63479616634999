/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, HFlow, Icon, Text, Theme, useTheme } from 'bold-ui'

import { AccordionButton, AccordionButtonProps } from './AccordionButton'

export interface FormSectionAccordionButtonProps extends Omit<AccordionButtonProps, 'children'> {
  text: string
  textStyle?: ExternalStyles
}

export function FormAccordionButton(props: FormSectionAccordionButtonProps) {
  const { style, text, textStyle, ...rest } = props
  const theme = useTheme()
  const classes = createStyles(theme)

  return (
    <AccordionButton wrapperStyle={{ paddingBottom: 0 }} style={css(classes.button, style)} {...rest}>
      <HFlow alignItems='center'>
        <Icon icon='angleDown' size={1.5} />
        <Text component='span' fontWeight='bold' style={textStyle}>
          {text}
        </Text>
      </HFlow>
    </AccordionButton>
  )
}

const createStyles = (theme: Theme) => ({
  button: css`
    display: flex;
    padding: 1rem 1.8rem;
    border: 1px ${theme.pallete.divider} solid;
    background-color: ${theme.pallete.gray.c100};
    margin: 0;
    position: relative;
  `,
})
