/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Alert,
  Button,
  Cell,
  DropdownItem,
  Grid,
  Heading,
  HFlow,
  Icon,
  InfoLabel,
  Tag,
  Text,
  Tooltip,
  useTheme,
  VFlow,
} from 'bold-ui'
import { DateTime } from 'components/date'
import { DropdownButton } from 'components/dropdown'
import { descriptions } from 'components/form/field/select/RendaFamiliarSelectField'
import { confirm } from 'components/modals/confirm'
import { isBefore, parseISO } from 'date-fns'
import { isEmpty } from 'lodash'
import { Fragment, useState } from 'react'
import { MetaArray } from 'util/metaPath'

import { MENSAGEM_TOOLTIP_FAMILIA_MUDOUSE } from '../constants-cadastroImovel'
import {
  CadastroImovelCacheState,
  CidadaoCadastroImovel,
  CidadaoTerritorioFormModel,
  FamiliaFormModel,
  UpdateCadastroImovelCacheState,
} from '../model-cadastroImovel'
import { CidadaoSection } from './CidadaoSection'

interface NucleoFamiliarWrapperProps {
  familia: FamiliaFormModel
  cidadaosImovel: Array<CidadaoCadastroImovel>
  cidadaoModal: CidadaoCadastroImovel
  cacheState: CadastroImovelCacheState
  shouldRenderCache: boolean
  hasPermission: boolean
  cadastroImovelPath: string
  name: MetaArray<CidadaoTerritorioFormModel>
  onEditarFamilia: (familia: FamiliaFormModel) => void
  onRemoverFamilia: (familia: FamiliaFormModel) => void
  onSetFamiliaIdEdit: (familia: ID) => void
  onChange: UpdateCadastroImovelCacheState
  onScrollAfterRedirect: () => void
  isCidadaoPresenteFamilias(cidadaoId: ID): boolean
  dataColeta: string
}

export const NucleoFamiliarWrapper = (props: NucleoFamiliarWrapperProps) => {
  const {
    familia,
    name,
    cidadaosImovel,
    cidadaoModal,
    cacheState,
    shouldRenderCache,
    hasPermission,
    cadastroImovelPath,
    onEditarFamilia,
    onRemoverFamilia,
    onSetFamiliaIdEdit,
    onScrollAfterRedirect,
    onChange,
    isCidadaoPresenteFamilias,
    dataColeta,
  } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const theme = useTheme()

  const handleDefinirResponsavelFamiliar = (cidadaoId: ID) => {
    onEditarFamilia({ ...familia, responsavelId: cidadaoId })
  }

  const handleFamiliaMudouSe = confirm({
    title: `Confirmar mudança da família`,
    body: (
      <Text> Ao confirmar essa ação, todos os cidadãos vinculados a esta família serão removidos do domicílio.</Text>
    ),
    onConfirm: () => changeFamiliaMudouSe(),
  })

  const changeFamiliaMudouSe = () => onEditarFamilia({ ...familia, isMudouSe: !familia.isMudouSe })

  const handleRemoverFamilia = () => {
    onRemoverFamilia(familia)
  }

  const handleEditFamilia = (familiaId: ID) => onSetFamiliaIdEdit(familiaId)

  const isFamiliaMudouSe = familia.isMudouSe
  const responsavelCidadaoCadastro = cidadaosImovel?.find((cidadao) => cidadao.id === familia?.responsavelId)
  const responsavelCidadaoCard = familia.cidadaos?.find((cidadao) => cidadao.id === familia?.responsavelId)

  const isResponsavelValido =
    responsavelCidadaoCadastro &&
    !responsavelCidadaoCadastro?.faleceu &&
    isEmpty(responsavelCidadaoCard?.registroObito) &&
    !responsavelCidadaoCard?.mudouSe

  const isResponsavelInconsistente =
    (!familia.isResponsavelCadastrado || !familia.isResponsavelDeclarado) && !familia.isNovoCadastro

  return (
    <Fragment>
      <Grid
        style={css`
          margin: 1.5rem 0;
        `}
      >
        <Cell size={12}>
          <HFlow>
            <Heading level={2}>
              Família
              {isResponsavelValido
                ? ` de ${(responsavelCidadaoCadastro?.nomeSocial ?? responsavelCidadaoCadastro?.nome).titleCase()}`
                : ` sem responsável ${responsavelCidadaoCadastro ? 'válido' : 'cadastrado'}`}
            </Heading>
            {isFamiliaMudouSe && (
              <Tag
                style={css`
                  margin-top: 0.3rem;
                `}
              >
                Mudou-se
              </Tag>
            )}
          </HFlow>
        </Cell>
        {!isResponsavelValido && (
          <Cell size={12}>
            <Alert inline type='danger'>
              {responsavelCidadaoCadastro
                ? `Um novo responsável deve ser informado para a família de ${(
                    responsavelCidadaoCadastro?.nomeSocial ?? responsavelCidadaoCadastro?.nome
                  ).titleCase()}.`
                : 'É obrigatório informar um responsável para a família.'}
            </Alert>
          </Cell>
        )}
        {isResponsavelInconsistente && (
          <Cell size={12}>
            <Alert inline type='info'>
              Havia inconsistências para esta família que serão automaticamente corrigidas ao salvar o cadastro do
              imóvel. Nenhuma ação adicional é necessária.
            </Alert>
          </Cell>
        )}
        <Cell size={3}>
          <InfoLabel title='Nº do prontuário familiar'>{familia?.numeroProntuarioFamiliar}</InfoLabel>
        </Cell>
        <Cell size={2}>
          <InfoLabel title='Renda'>{descriptions[familia?.rendaFamiliar]}</InfoLabel>
        </Cell>
        <Cell size={3}>
          <InfoLabel title='Reside desde'>
            <VFlow vSpacing={0}>
              <DateTime value={familia?.resideDesde} format='MM/YYYY' />
              {isBefore(parseISO(dataColeta), parseISO(familia?.resideDesde)) && (
                <Text color='danger'>Deve ser anterior ou igual à Data de coleta.</Text>
              )}
            </VFlow>
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <HFlow justifyContent='flex-end'>
            <Tooltip text={isFamiliaMudouSe ? MENSAGEM_TOOLTIP_FAMILIA_MUDOUSE : ''}>
              <Button size='small' onClick={() => setIsModalOpen(true)} disabled={isFamiliaMudouSe}>
                <Icon icon='plus' /> Adicionar cidadão à família
              </Button>
            </Tooltip>
            <DropdownButton
              style={css`
                border: 1px solid ${theme.pallete.gray.c40};
                padding-top: 0.3rem;
              `}
            >
              <DropdownItem onClick={() => handleEditFamilia(familia?._id)}>Editar Família</DropdownItem>
              {!familia.isNovoCadastro && (
                <DropdownItem onClick={isFamiliaMudouSe ? changeFamiliaMudouSe : handleFamiliaMudouSe}>
                  {isFamiliaMudouSe ? 'Desfazer mudou-se' : 'Familia mudou-se'}
                </DropdownItem>
              )}
              {familia.isNovoCadastro && <DropdownItem onClick={handleRemoverFamilia}>Remover família</DropdownItem>}
            </DropdownButton>
          </HFlow>
        </Cell>
        <Cell size={12}>
          <CidadaoSection
            name={name}
            isModalOpen={isModalOpen}
            familia={familia}
            cidadaoModal={cidadaoModal}
            cacheState={cacheState}
            shouldRenderCache={shouldRenderCache}
            hasPermission={hasPermission}
            cadastroImovelPath={cadastroImovelPath}
            isCidadaoPresenteFamilias={isCidadaoPresenteFamilias}
            onCloseModal={() => setIsModalOpen(false)}
            onDefinirResponsavelFamiliar={handleDefinirResponsavelFamiliar}
            onScrollAfterRedirect={onScrollAfterRedirect}
            onChange={onChange}
            cidadaos={cidadaosImovel}
            dataColeta={dataColeta}
          />
        </Cell>
      </Grid>
    </Fragment>
  )
}
