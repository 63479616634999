import { ListaEsperaMotivoSaidaEnum } from 'graphql/types.generated'
import { createValidator, empty, ErrorObject, maxLength, range, required, validate } from 'util/validation'

import { GarantiaAcessoRemocaoModel } from '../model-garantiaAcesso'

export const removerCidadaoGarantiaAcessoValidator = (
  cidadoFaleceu: boolean,
  garantiaAcessoCidadaoObitoEnabled: boolean
) =>
  createValidator<GarantiaAcessoRemocaoModel>(
    {
      motivoSaida: [required],
      observacaoSaida: [maxLength(200)],
    },
    (values: GarantiaAcessoRemocaoModel, errors: ErrorObject<GarantiaAcessoRemocaoModel>) => {
      const validarMotivoInvalidoCidadaoObito = () => {
        if (garantiaAcessoCidadaoObitoEnabled && cidadoFaleceu) {
          errors.motivoSaida = 'Motivo de remoção informado não é válido para registros de cidadão em óbito.'
        }
      }

      switch (values.motivoSaida) {
        case ListaEsperaMotivoSaidaEnum.OUTROS:
          errors.observacaoSaida = required(values.observacaoSaida)
          errors.tentativasContato = empty(values.tentativasContato)
          validarMotivoInvalidoCidadaoObito()
          break

        case ListaEsperaMotivoSaidaEnum.NAO_RESPONDEU_TENTATIVAS_CONTATO:
          errors.tentativasContato = validate(values.tentativasContato, [required, range(1, 100)])
          validarMotivoInvalidoCidadaoObito()
          break

        case ListaEsperaMotivoSaidaEnum.OBITO:
          if (!garantiaAcessoCidadaoObitoEnabled) {
            errors.motivoSaida = 'Motivo de remoção informado é inválido.'
          } else if (!cidadoFaleceu) {
            errors.motivoSaida = 'Motivo de remoção informado só é válido para registros de cidadão em óbito.'
          }
          errors.tentativasContato = empty(values.tentativasContato)
          break

        default:
          errors.tentativasContato = empty(values.tentativasContato)
          validarMotivoInvalidoCidadaoObito()
          break
      }
      return errors
    }
  )
