import Axios, { AxiosPromise } from 'axios'
import { TerritorioMicroareaQueryInput } from 'graphql/types.generated'

export class AcompanhamentoTerritorio {
  gerarCsvTerritorioMicroarea(input: TerritorioMicroareaQueryInput): AxiosPromise<any> {
    return Axios.post('/api/visualizacaoTerritorial/gerarCsvTerritorioMicroarea', input, {
      responseType: 'blob',
    })
  }
}
