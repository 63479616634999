import { Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { isEmpty } from 'lodash'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { HistoricoAtendimentoModel } from 'view/atendimentos/detail/historico/model/model-historico'
import {
  lotacaoCuidadoCompartilhadoDwFormatter,
  lotacaoCuidadoCompartilhadoPecFormatter,
} from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

interface ProfissionaisHistoricoHeaderLabelProps {
  historicoAtendimento: HistoricoAtendimentoModel
  isCuidadoCompartilhado: boolean
  hideDefaultIfEmpty?: boolean
}

export default function ProfissionaisHistoricoHeaderLabel(props: ProfissionaisHistoricoHeaderLabelProps) {
  const { historicoAtendimento, isCuidadoCompartilhado, hideDefaultIfEmpty = false } = props
  const isFromDw = !isUndefinedOrNull(historicoAtendimento.codigoUnicoRegistro)

  if (isCuidadoCompartilhado) {
    const textoSolicitante = isFromDw
      ? lotacaoCuidadoCompartilhadoDwFormatter(
          historicoAtendimento.nomeSolicitante,
          historicoAtendimento.cboSolicitante,
          historicoAtendimento.ineSolicitante,
          historicoAtendimento.siglaEquipeSolicitante,
          historicoAtendimento.nomeUbsSolicitante
        )
      : lotacaoCuidadoCompartilhadoPecFormatter(
          historicoAtendimento.cuidadoCompartilhado.lotacaoSolicitante,
          historicoAtendimento.cuidadoCompartilhado.lotacaoSolicitante.cbo.nome,
          historicoAtendimento.cuidadoCompartilhado.lotacaoSolicitante.unidadeSaude.nome
        )
    const textoExecutante = isFromDw
      ? lotacaoCuidadoCompartilhadoDwFormatter(
          historicoAtendimento.nomeExecutante,
          historicoAtendimento.cboExecutante,
          historicoAtendimento.ineExecutante,
          historicoAtendimento.siglaEquipeExecutante,
          historicoAtendimento.nomeUbsExecutante
        )
      : lotacaoCuidadoCompartilhadoPecFormatter(
          historicoAtendimento.cuidadoCompartilhadoEvolucao.lotacaoExecutante,
          historicoAtendimento.cuidadoCompartilhadoEvolucao.cboExecutante.nome,
          historicoAtendimento.cuidadoCompartilhadoEvolucao.unidadeSaudeExecutante.nome
        )

    if (!isEmpty(textoSolicitante) || !isEmpty(textoExecutante)) {
      return (
        <VFlow vSpacing={0}>
          <HLabel title='Solicitante:'>{textoSolicitante}</HLabel>
          <HLabel title='Executante:'>{textoExecutante}</HLabel>
        </VFlow>
      )
    }
  }

  return !isCuidadoCompartilhado || !hideDefaultIfEmpty ? (
    <Text>
      {`${historicoAtendimento.profissional?.nome ?? `CNS: ${historicoAtendimento.cnsProfissional}`} ${
        historicoAtendimento.profissional?.nomeSocial ? '(Nome social)' : ''
      } - ${historicoAtendimento.cbo.nome.capitalize()} - ${historicoAtendimento.cbo.cbo2002} ${
        historicoAtendimento.equipe
          ? `| Equipe ${historicoAtendimento.equipe?.ine} - ${historicoAtendimento.equipe?.nome}`
          : ''
      }${
        historicoAtendimento.profissional?.nomeSocial
          ? ` | Nome civil - ${historicoAtendimento.profissional.nomeCivil}`
          : ''
      }`}
    </Text>
  ) : null
}
