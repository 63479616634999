/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, TableCell, TableRow, Text, Tooltip } from 'bold-ui'
import { DateTime } from 'components/date'
import { ButtonLink } from 'components/route'
import { Fragment } from 'react'
import { useRouteMatch } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'
import { Observacao } from 'view/atendimentos/detail/components/Observacao'

import { renderProcedimentoEvolucao } from '../../../historico-evolucoes-odontologicas/HistoricoEvolucoesOdontoTableRow'
import { EvolucoesPeriodontaisTableItemModel, URL_EDITAR_PROCEDIMENTOS_PERIODONTAIS } from '../model'

interface EvolucoesPeriodontaisTableRowProps {
  row: EvolucoesPeriodontaisTableItemModel
  onRemove(id: ID): void
}

export default function EvolucoesPeriodontaisTableRow(props: EvolucoesPeriodontaisTableRowProps) {
  const { row, onRemove } = props

  const match = useRouteMatch()

  const hasObservacao = !isUndefinedOrNull(row.observacao)
  const classes = styles(hasObservacao, row.isRegistradoAgora)

  return (
    <Fragment>
      <TableRow>
        <TableCell style={classes.cell}>
          <HFlow hSpacing={0.25}>
            <DateTime value={row.lastUpdate} format='DD/MM/YYYY' />

            {row.isRegistradoAgora && (
              <Tooltip text='Adicionado agora'>
                <Icon size={1} icon='clockOutline' fill='primary' />
              </Tooltip>
            )}
          </HFlow>
        </TableCell>
        <TableCell style={classes.cell}>
          <Text>{row.local}</Text>
        </TableCell>
        <TableCell style={classes.cell}>{renderProcedimentoEvolucao(row.procedimentos)}</TableCell>
        <TableCell style={classes.cell}>
          {row.isRegistradoAgora && (
            <HFlow hSpacing={0.5} justifyContent='flex-end'>
              <Tooltip text='Editar'>
                <ButtonLink
                  size='small'
                  skin='ghost'
                  to={`${match.url}${URL_EDITAR_PROCEDIMENTOS_PERIODONTAIS}${row.cacheId}`}
                >
                  <Icon icon='penOutline' />
                </ButtonLink>
              </Tooltip>
              <Tooltip text='Excluir'>
                <Button size='small' skin='ghost' onClick={() => onRemove(row.cacheId)}>
                  <Icon icon='trashOutline' />
                </Button>
              </Tooltip>
            </HFlow>
          )}
        </TableCell>
      </TableRow>

      {hasObservacao && (
        <TableRow>
          <TableCell colSpan={4} style={[classes.profissionalOrObservacaoCell, classes.observacaoCell]}>
            <HFlow hSpacing={0.2}>
              <Text>
                <Text fontWeight='bold'>Observações: </Text>
                <Text style={classes.wordBreakAll} dangerouslySetInnerHTML={{ __html: row.observacao }} />
              </Text>
            </HFlow>
            <Observacao />
          </TableCell>
        </TableRow>
      )}

      {!row.isRegistradoAgora && (
        <TableRow>
          <TableCell colSpan={4} style={classes.profissionalOrObservacaoCell}>
            <HFlow hSpacing={0.2}>
              <Text fontWeight='bold'>Profissional: </Text>
              <Text style={classes.wordBreakAll}>{`${row.lotacao.profissional.nome} - ${row.lotacao.cbo.nome}`}</Text>
            </HFlow>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  )
}

const styles = (hasObservacao: boolean, isRegistradoAgora: boolean) => ({
  cell: css`
    padding: 1.25rem 1rem;
    ${(hasObservacao || !isRegistradoAgora) && 'border-bottom: 0;'}
  `,
  profissionalOrObservacaoCell: css`
    height: auto;
    border-top: 0;
    padding: 0.5rem 1rem;
  `,
  observacaoCell: css`
    ${!isRegistradoAgora && 'border-bottom: 0;'}
  `,
  wordBreakAll: css`
    word-break: break-all;
  `,
})
