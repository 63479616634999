export interface AsideViewAtendimentoStatuses {
  isAtendimentoProcedimentos: boolean
  isAtendimentoObservacao: boolean
  isObservacaoAndAuxiliar: boolean
  isAtendimentoPreNatal: boolean
  isRetificacao: boolean
}

export const ASIDE_ATENDIMENTO_STATUSES_ALL_FALSE: AsideViewAtendimentoStatuses = {
  isAtendimentoProcedimentos: false,
  isAtendimentoObservacao: false,
  isObservacaoAndAuxiliar: false,
  isAtendimentoPreNatal: false,
  isRetificacao: false,
}
