import qs from 'qs'
import { useHistory, useLocation } from 'react-router'
import { CADASTRAR_IMOVEL_PATH, EDITAR_IMOVEL_PATH } from 'view/cadastro-imovel/constants-cadastroImovel'
import { ImovelUrlParams } from 'view/visualizacao-imovel/model-visualizacao-imovel'

interface QueryParams {
  callbackUrl: string
  callbackParams: string
  cadImovelUrlParams: ImovelUrlParams
}

export function useNavigateToCidadaoCallbackUrl() {
  const location = useLocation()
  const history = useHistory()

  const urlParams: QueryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  return (cidadaoId: ID) => {
    try {
      //callback para módulos antigos (falha quando callbackUrl não é base64) - remover try/catch quando os módulos forem refeitos
      const url = atob(urlParams.callbackUrl)
      const decodedParams = atob(urlParams.callbackParams)
      const path = url === '/pec/user/registrotardiolisting/registrotardiodetail' ? 'registroTardio' : ''
      history.push(
        `/${path}?iframeUrl=${url}?${btoa(decodedParams + '&' + qs.stringify({ cidadaoCadastradoId: cidadaoId }))}`
      )
    } catch {
      /* TODO (NP) #23449: Reavaliar o Switch, além disso, alguns desses cases não funcionam, os módulos que supostamente são responsáveis
       por eles, acabam redirecionando para o valor default (ainda funciona) */
      switch (urlParams?.callbackUrl) {
        case '/registroTardio':
          history.push(`/${urlParams.callbackUrl}/${urlParams.callbackParams}`)
          break
        case 'registroTardio/cidadao-ausente':
          history.push(`/registroTardio?${urlParams.callbackParams}`)
          break
        case 'lista-atendimento/cidadao-ausente':
          history.push(`/lista-atendimento?${urlParams.callbackParams}`)
          break
        case 'cadastro-cidadao':
          history.push(`/lista-atendimento/atendimento/${urlParams.callbackParams}/${urlParams.callbackUrl}`)
          break
        case '/acompanhamentos/territorio':
          history.push(`${urlParams.callbackUrl}?redirect=true`)
          break
        case 'lista-atendimento/atendimento':
          history.push(`/${urlParams.callbackUrl}/${urlParams.callbackParams}`)
          break
        case EDITAR_IMOVEL_PATH:
          history.push(
            `${urlParams.callbackUrl}/${urlParams.cadImovelUrlParams.imovelId}?cidadaoId=${cidadaoId}&redirect=${urlParams.cadImovelUrlParams.redirect}`
          )
          break
        case CADASTRAR_IMOVEL_PATH:
          history.push(
            `${urlParams.callbackUrl}?cidadaoId=${cidadaoId}&redirect=${urlParams.cadImovelUrlParams?.redirect}${
              !!urlParams.cadImovelUrlParams?.endereco ? '&endereco=true' : ''
            }`
          )
          break
        case 'visualizar-escuta':
        case 'escuta-inicial':
          history.push(`/lista-atendimento/${urlParams.callbackUrl}/${urlParams.callbackParams}/cadastro-cidadao`)
          break
        default:
          history.push(`${urlParams.callbackUrl}?cidadaoId=${cidadaoId}`)
          break
      }
    }
  }
}
