import { getSingleAgeRangeValue } from 'components/date/AgeRange'
import { FaixaEtariaEnum } from 'graphql/types.generated'
import { createValidator, ErrorObject, required } from 'util/validation'

import { AcompanhamentoCondicaoSaudeFormModel } from './model-acompanhamentoCondicaoSaude'

export const validate = createValidator<AcompanhamentoCondicaoSaudeFormModel>(
  {
    equipeResponsavel: required,
  },
  (values: AcompanhamentoCondicaoSaudeFormModel, errors: ErrorObject<AcompanhamentoCondicaoSaudeFormModel>) => {
    const { faixaEtariaRange, tipoFaixaEtaria } = values

    if (FaixaEtariaEnum.OUTRA === tipoFaixaEtaria) {
      errors.faixaEtariaRange = required(getSingleAgeRangeValue(faixaEtariaRange))
    }

    return errors
  }
)
