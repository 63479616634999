import { confirm } from 'components/modals/confirm'
import { useAgendamentosConflitantesQuery } from 'graphql/hooks.generated'
import { every } from 'lodash'
import { useCallback } from 'react'
import { formatDate, formatDateAndHoursMinutes } from 'util/date/formatDate'
import { isObjectDeepEmpty } from 'util/object'

import { LotacaoAgendamentoConflitanteInput } from '../model-agenda'

export function useVerificarAgendamentosConflitantes() {
  const { loading, refetch: refetchAgendamentosConflitantes } = useAgendamentosConflitantesQuery({
    skip: true,
  })

  const verificarAgendamentosConflitantes = useCallback(
    async (
      cidadaoId: ID,
      agendamentosInput: LotacaoAgendamentoConflitanteInput[],
      nomeCidadao: string,
      agendamentoIdDesconsiderar?: ID
    ) => {
      if (!cidadaoId || every(agendamentosInput, (value) => isObjectDeepEmpty(value))) return true

      const agendamentosQueryInput = agendamentosInput.map(({ profissionalId, ...rest }) => rest)
      const response = await refetchAgendamentosConflitantes({
        input: {
          cidadaoId,
          agendamentos: agendamentosQueryInput,
          agendamentoIdDesconsiderar,
        },
      })

      const {
        nomesOutrosProfissionaisAgendamentosConflitantes,
        possuiAgendamentoMesmoProfissionalDataFutura,
      } = response.data

      let confirmed = true

      for (const profissionalAgendamentoConflitante of nomesOutrosProfissionaisAgendamentosConflitantes) {
        if (!confirmed) {
          break
        }

        const { nomesProfissionais, horarioAgendamentoConflitante } = profissionalAgendamentoConflitante
        confirmed = await confirmProfissionaisConflitantes(
          nomesProfissionais,
          horarioAgendamentoConflitante,
          nomeCidadao
        )
      }

      if (confirmed) {
        const horarioInputByProfissionalId = new Map(
          agendamentosInput.map((input) => [input.profissionalId, input.horario])
        )
        const horariosByProfissionalId = new Map(
          possuiAgendamentoMesmoProfissionalDataFutura.map((agendamento) => [
            agendamento.profissionalId,
            {
              horarioInput: horarioInputByProfissionalId.get(agendamento.profissionalId),
              dataOutroAgendamento: agendamento.dataFutura,
            },
          ])
        )

        for (const horarios of horariosByProfissionalId.values()) {
          if (!confirmed) {
            break
          }

          const { horarioInput, dataOutroAgendamento } = horarios
          if (horarioInput) {
            confirmed = await confirmAgendamentoMesmoProfissional(horarioInput, nomeCidadao, dataOutroAgendamento)
          }
        }
      }

      return confirmed
    },
    [refetchAgendamentosConflitantes]
  )

  return {
    verificarAgendamentosConflitantes,
    loading,
  }
}

const confirmProfissionaisConflitantes = (
  nomesOutrosProfissionaisAgendamentosConflitantes: string[],
  horario: number,
  nomeCidadao: string
) =>
  confirm({
    title: `Deseja salvar um agendamento no dia ${formatDateAndHoursMinutes(horario)}?`,
    body: `${nomeCidadao.titleCase()} já possui agendamento com ${nomesOutrosProfissionaisAgendamentosConflitantes
      .join(', ')
      .replaceLastOccurrence(',', ' e')
      .titleCase()} na mesma data.`,
    confirmLabel: 'Salvar agendamento',
    depthLevel: 2,
  })()

const confirmAgendamentoMesmoProfissional = (horario: number, nomeCidadao: string, dataOutroAgendamento: Instant) =>
  confirm({
    title: `Deseja salvar um agendamento no dia ${formatDateAndHoursMinutes(horario)}?`,
    body: `${nomeCidadao.titleCase()} já possui um agendamento com o mesmo profissional no dia ${formatDate(
      dataOutroAgendamento
    )}.`,
    confirmLabel: 'Salvar agendamento',
    depthLevel: 2,
  })()
