import { TickProps } from 'components/chart'
import React from 'react'

interface TickSemanaGestacionalProps extends TickProps {}

export function TickSemanaGestacional(props: TickSemanaGestacionalProps) {
  const {
    payload: { value },
    ...rest
  } = props

  return (
    <text dy={15} {...rest}>
      {value}
    </text>
  )
}
