/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, HFlow, Spinner, Text, useTheme } from 'bold-ui'
import { useIsBeneficiarioBolsaFamiliaQuery } from 'graphql/hooks.generated'

interface FolhaRostoBolsaFamiliaAlertProps {
  cidadaoId: ID
}

export function FolhaRostoBolsaFamiliaAlert(props: FolhaRostoBolsaFamiliaAlertProps) {
  const { cidadaoId } = props

  const { data, loading } = useIsBeneficiarioBolsaFamiliaQuery({
    variables: { cidadaoId },
    context: { newRequest: true },
  })
  const theme = useTheme()

  if (loading) {
    return (
      <HFlow>
        <Spinner size={1} style={{ color: theme.pallete.primary.main }} />
        <Text color='info'>Verificando se o cidadão é beneficiário do bolsa família</Text>
      </HFlow>
    )
  }

  if (!data?.cidadao?.beneficiarioBolsaFamilia) {
    return null
  }

  return (
    <Alert inline type='info' styles={{ container: styles.container }}>
      <Text color='info' fontWeight='bold'>
        Cidadão beneficiário do programa bolsa família
      </Text>
      <br />
      Esse cidadão consta como beneficiário do bolsa família na vigência mais recente importada. Verifique o
      acompanhamento das condicionalidades de saúde.
    </Alert>
  )
}

const styles = {
  container: css`
    align-items: center;
    margin: 0.25rem 0;
  `,
}
