/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, TableFooter, Text } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import useSession from 'components/auth/useSession'
import { DateTime } from 'components/date'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import theme from 'config/theme'
import { useEncaminhamentosExternosEspecializadosQuery, useEncaminhamentosExternosQuery } from 'graphql/hooks.generated'
import {
  EncaminhamentoExternoEspecializadoFragment,
  EncaminhamentosExternosEspecializadosQuery,
  EncaminhamentosExternosQuery,
  EspecialidadeSisreg,
  TipoEncaminhamentoExternoEnum,
} from 'graphql/types.generated'
import { noop } from 'lodash'
import React, { useState } from 'react'
import { getEditAtendProfId } from 'view/atendimentos/atendimento-individual/util-atendimentoIndividual'
import { LotacaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { grupoCboVisualizarDetalhesEncaminhamentosEspecializados } from 'view/cidadao/acessos'

import { convertEncaminhamentoExternoEspecializadoToImpressao } from '../impressao/converter'
import { downloadEncaminhamentoExterno } from '../impressao/DownloadEncaminhamentoExterno'
import {
  EncaminhamentoExternoEspecializadoModel,
  EncaminhamentoExternoEspecializadoPlanoModel,
  EncaminhamentoExternoFilterModel,
  EncaminhamentoExternoRowModel,
  tipoEncaminhamentoExternoEnumRecord,
} from '../model'
import { mergeAndFilterEncaminhamentos } from '../util/mergeAndFilterEncaminhamentos'
import EncaminhamentoExternoDetalhesPanel from './detalhes/EncaminhamentoExternoDetalhesPanel'
import EncaminhamentoExternoEspecializadoDetalhesPanel from './detalhes/EncaminhamentoExternoEspecializadoDetalhesPanel'
import { EncaminhamentoExternoFilter } from './EncaminhamentoExternoFilter'
import { EncaminhamentoExternoTableButtons } from './EncaminhamentoExternoTableButtons'
import { StatusClassificacaoRiscoEncaminhamento } from './StatusClassificacaoRiscoEncaminhamento'

export type EncaminhamentosModel = EncaminhamentosExternosEspecializadosQuery['encaminhamentosEspecializados']
export type EncaminhamentoExternoEspecializadoQueryModel = EncaminhamentoExternoEspecializadoFragment
export type EncaminhamentoExternoQueryModel = EncaminhamentosExternosQuery['encaminhamentos'][0]

export interface EncaminhamentoExternoTableProps {
  atendimentoId?: ID
  prontuarioId: ID
  dataReferencia: Instant
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
  encaminhamentosCache?: EncaminhamentoExternoEspecializadoPlanoModel
  atendimentoProfissionalId?: ID
  isAtendimentoObservacao?: boolean
  removeItem?(itemToRemove: EncaminhamentoExternoEspecializadoModel): void
  readOnly?: boolean
}

export default function EncaminhamentoExternoTable(props: EncaminhamentoExternoTableProps) {
  const {
    atendimentoId,
    prontuarioId,
    dataReferencia,
    lotacao,
    atendimentoProfissionalId,
    encaminhamentosCache = {},
    isAtendimentoObservacao = false,
    removeItem = noop,
    readOnly,
  } = props

  const editAtendProfId = getEditAtendProfId(isAtendimentoObservacao, atendimentoProfissionalId)

  const { isExpanded, handleAccordionItemClick, resetExpandedItems, ...accordionProps } = useAccordionControl({
    allowMultipleExpanded: true,
  })

  const [filters, setFilters] = useState<EncaminhamentoExternoFilterModel>({})

  const {
    data: { profissional },
    hasCboAuth,
  } = useSession()

  const mostrarDetalhesEncaminhamentoEspecializado = (row: EncaminhamentoExternoRowModel) =>
    hasCboAuth(grupoCboVisualizarDetalhesEncaminhamentosEspecializados) &&
    row?.tipoEncaminhamento === TipoEncaminhamentoExternoEnum.SERVICO_ESPECIALIZADO

  const {
    data: { encaminhamentos },
  } = useEncaminhamentosExternosQuery({
    variables: {
      prontuarioId,
      editAtendProfId,
    },
  })

  const {
    data: { encaminhamentosEspecializados },
  } = useEncaminhamentosExternosEspecializadosQuery({
    variables: {
      prontuarioId,
      editAtendProfId,
    },
  })

  const encaminhamentosExibir: EncaminhamentoExternoRowModel[] = mergeAndFilterEncaminhamentos(
    encaminhamentosEspecializados?.content,
    encaminhamentos,
    encaminhamentosCache,
    profissional.id,
    filters,
    atendimentoProfissionalId,
    dataReferencia,
    lotacao
  )

  const especialidadesUnicas = [
    ...(encaminhamentosEspecializados?.content
      .map((item) => [item.especialidadeSisreg.id, item.especialidadeSisreg])
      .concat(
        encaminhamentosCache.encaminhamentosEspecializadosRecentes?.map((item) => [
          item.especialidadeSisreg.id,
          item.especialidadeSisreg,
        ])
      )
      .filter((item) => !!item)
      .reduce((acc, item) => acc.set(item[0], item[1]), new Map())
      ?.values() ?? []),
  ] as EspecialidadeSisreg[]

  const handleImpressao = (value: EncaminhamentoExternoRowModel) => {
    if (value.isRegistradoAgora) {
      downloadEncaminhamentoExterno({
        encaminhamentoAtual: convertEncaminhamentoExternoEspecializadoToImpressao(
          encaminhamentosCache.encaminhamentosEspecializadosRecentes.find((enc) => enc._id === value.id)
        ),
        atendimentoProfissionalId: atendimentoProfissionalId,
        atendimentoId,
        prontuarioId,
      })
    } else {
      downloadEncaminhamentoExterno({
        id: value.id,
        atendimentoProfissionalId: value.atendimentoProfissional?.id,
        atendimentoId,
        prontuarioId,
      })
    }
  }

  const renderButtons = (row: EncaminhamentoExternoRowModel) =>
    mostrarDetalhesEncaminhamentoEspecializado(row) && (
      <EncaminhamentoExternoTableButtons
        encaminhamento={row}
        onImprimir={handleImpressao}
        removeItem={removeItem}
        readOnly={readOnly}
      />
    )

  const { paginatedItems, tableProps } = usePagination({
    items: encaminhamentosExibir,
    sizeOptions: [5, 10, 30],
    onChange: resetExpandedItems,
  })

  return (
    <TableBox
      header={
        <EncaminhamentoExternoFilter
          onChange={setFilters}
          especialidadeSelectFieldValues={especialidadesUnicas}
          filter={filters}
          dataReferencia={dataReferencia}
        />
      }
    >
      <AccordionDataTable<EncaminhamentoExternoRowModel>
        {...accordionProps}
        isExpanded={isExpanded}
        handleAccordionItemClick={handleAccordionItemClick}
        rows={paginatedItems}
        columns={[
          {
            name: 'data',
            header: 'Data do encaminhamento',
            render: renderData,
            size: 3,
          },
          {
            name: 'risco',
            header: 'Risco',
            render: renderRisco,
            style: classes.riscoColumn,
            size: 1,
          },
          {
            name: 'especialidade',
            header: 'Tipo de encaminhamento',
            render: renderTipoEncaminhamento,
            style: classes.especialidadeColumn,
            size: 4,
          },
          { name: 'status', render: renderStatus, size: 2, style: classes.statusColumn },
          { name: 'buttons', render: renderButtons, size: 2 },
        ]}
        components={{
          AccordionPanel: ({ row }) =>
            mostrarDetalhesEncaminhamentoEspecializado(row) ? (
              row.isRegistradoAgora ? (
                <EncaminhamentoExternoEspecializadoDetalhesPanel
                  isRegistradoAgora
                  lotacao={lotacao}
                  encaminhamento={encaminhamentosCache.encaminhamentosEspecializadosRecentes.find(
                    (enc) => enc._id === row.id
                  )}
                />
              ) : (
                <EncaminhamentoExternoEspecializadoDetalhesPanel encaminhamentoId={row.id} isRegistradoAgora={false} />
              )
            ) : row.isRegistradoAgora ? (
              <EncaminhamentoExternoDetalhesPanel isRegistradoAgora lotacao={lotacao} />
            ) : (
              <EncaminhamentoExternoDetalhesPanel
                atendimentoProfissionalId={row.atendimentoProfissional.id}
                isRegistradoAgora={false}
              />
            ),
        }}
      />
      <TableFooter {...tableProps} style={classes.tableFooter} />
    </TableBox>
  )
}

const renderData = (row: EncaminhamentoExternoRowModel) => {
  return (
    <HFlow alignItems='center'>
      <DateTime value={row?.atendimentoProfissional?.iniciadoEm} format='DD/MM/YYYY' />
    </HFlow>
  )
}

const renderRisco = (row: EncaminhamentoExternoRowModel) => {
  return row?.classificacaoRiscoEncaminhamento ? (
    <HFlow justifyContent='center'>
      <StatusClassificacaoRiscoEncaminhamento status={row?.classificacaoRiscoEncaminhamento} />
    </HFlow>
  ) : (
    <Text>-</Text>
  )
}

const renderTipoEncaminhamento = (row: EncaminhamentoExternoRowModel) => {
  return row?.tipoEncaminhamento !== TipoEncaminhamentoExternoEnum.SERVICO_ESPECIALIZADO ? (
    <Text>{tipoEncaminhamentoExternoEnumRecord[row?.tipoEncaminhamento].descricao}</Text>
  ) : (
    <React.Fragment>
      <Text fontWeight='bold'>{tipoEncaminhamentoExternoEnumRecord.SERVICO_ESPECIALIZADO.descricao}</Text>
      <Text> - {row?.especialidadeSisreg?.descricao.capitalize()}</Text>
    </React.Fragment>
  )
}

const renderStatus = (row: EncaminhamentoExternoRowModel) => {
  return (
    row.isRegistradoAgora && (
      <HFlow alignItems='center' justifyContent='flex-end' hSpacing={0.5}>
        <Icon color={blue.c40} size={1} icon='clockOutline' />
        <span css={classes.statusText}>
          <Text
            color='primary'
            style={css`
              white-space: nowrap;
            `}
          >
            Registrado agora
          </Text>
        </span>
      </HFlow>
    )
  )
}

const classes = {
  especialidadeColumn: css`
    ${theme.breakpoints.down('lg')} {
      word-break: break-word;
    }
  `,

  statusColumn: css`
    text-align: right;
  `,

  riscoColumn: css`
    text-align: center;
  `,

  tableFooter: css`
    border-top-width: 0;
  `,

  statusText: css`
    ${theme.breakpoints.down('lg')} {
      display: none;
    }
  `,
}
