import { ReferenceArea, ReferenceAreaRange } from 'bold-ui/lib/components/Chart/model'
import { ColorScale } from 'bold-ui/lib/styles/colors'
import { range } from 'lodash'

const GANHO_PESO_VALORES_EIXO_X = range(10, 41)
const TEXT_Y_OFF_SET = -11

export function getNotExpectedReferenceArea(
  percentilEixoYValues: ReadonlyArray<number>,
  name: string,
  color: ColorScale
): ReferenceArea<number> {
  return {
    area: convertToReferenceAreaRange(percentilEixoYValues),
    name,
    color: color.c90,
    strokeColor: color.c60,
    tickColor: color.c60,
    textYOffset: TEXT_Y_OFF_SET,
  }
}

export function getUnderExpectedReferenceArea(
  percentilEixoYValues: ReadonlyArray<number>,
  color: ColorScale
): ReferenceArea<number> {
  return {
    area: convertToReferenceAreaRange(percentilEixoYValues),
    name: '',
    color: color.c90,
    strokeColor: color.c30,
    strokeType: 'line',
    tickColor: color.c60,
    textYOffset: TEXT_Y_OFF_SET,
  }
}

export function getExpectedReferenceArea(
  percentilEixoYValues: ReadonlyArray<number>,
  name: string,
  descriptionText: string,
  color: ColorScale
): ReferenceArea<number> {
  return {
    area: convertToReferenceAreaRange(percentilEixoYValues),
    name,
    color: color.c70,
    strokeColor: color.c30,
    strokeType: 'line',
    tickColor: color.c30,
    description: {
      text: descriptionText,
      color: color.c30,
      style: { fontWeight: 'bold' },
      align: 'bottom',
    },
    textYOffset: TEXT_Y_OFF_SET,
  }
}

export function getEmptyReferenceArea(percentilEixoYValues: ReadonlyArray<number>): ReferenceArea<number> {
  return {
    area: convertToReferenceAreaRange(percentilEixoYValues),
    name: 'Empty',
    color: 'none',
    strokeColor: 'none',
    tickColor: 'none',
  }
}

function convertToReferenceAreaRange(percentilEixoYValues: ReadonlyArray<number>): ReferenceAreaRange<number>[] {
  return percentilEixoYValues.map((y, index) => ({
    x: GANHO_PESO_VALORES_EIXO_X[index],
    upperLimit: y,
  }))
}
