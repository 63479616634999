/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, HFlow, Text, Theme, useTheme } from 'bold-ui'
import { SimNaoRadioGroupField } from 'components/form'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import { Meta, MetaArray } from 'util/metaPath'

interface SimNaoAccordionItemProps {
  name: string | MetaArray<any> | Meta<any>
  title: string
  required?: boolean
  borderBottom?: boolean
  clearable?: boolean
  style?: {
    hFlow?: ExternalStyles
    vFlow?: ExternalStyles
    radioField?: ExternalStyles
  }
}

export const SimNaoAccordionItem = ({
  name,
  title,
  required = false,
  borderBottom = false,
  clearable = false,
  style,
}: SimNaoAccordionItemProps) => {
  const theme = useTheme()
  const defaultStyles = createStyles(theme, borderBottom)

  return (
    <HFlow alignItems='center' justifyContent='space-between' style={defaultStyles.accordionItem}>
      <div css={defaultStyles.labelContainer}>
        {required ? <RequiredLabel fontWeight='bold' label={title} /> : <Text fontWeight='bold'>{title}</Text>}
      </div>
      <div css={defaultStyles.radioContainer}>
        <SimNaoRadioGroupField name={name} clearable={clearable} style={style} />
      </div>
    </HFlow>
  )
}

const createStyles = (theme: Theme, borderBottom: boolean) => ({
  accordionItem: css`
    ${borderBottom ? `border-bottom: 1px solid ${theme.pallete.divider}` : ''};
    height: 4.5rem;
  `,
  radioContainer: css`
    width: 170px;
    height: 50px;
    margin-top: 1rem;
    margin-left: 1rem;
  `,
  labelContainer: css`
    margin-left: 20px;
  `,
})
