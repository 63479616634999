import { EnderecoFieldGroupModel, MunicipioSelectModel, SimNaoEnum } from 'components/form'
import { EnderecoIndigenaFieldGroupModel } from 'components/form/field/enderecoindigena/model-enderecoIndigena'
import { AldeiaSelectFieldModel } from 'components/form/field/select/AldeiaSelectField'
import { DseiSelectFieldModel } from 'components/form/field/select/DseiSelectField'
import { PoloBaseSelectFieldModel } from 'components/form/field/select/PoloBaseSelectField'
import createDecorator, { Calculation, Updates } from 'final-form-calculate'
import { TipoEnderecoEnum, TipoImovelEnum, TipoLocalizacaoEnum } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath, MetaRoot } from 'util/metaPath'

import {
  AnimaisImovelFormModel,
  CondicoesMoradiaFormModel,
  ImovelFormModel,
  InstituicaoPermanenciaFormModel,
  tipoEnderecoIndigena,
} from './model-cadastroImovel'
import {
  isTipoImovelDomicilio,
  isTipoImovelResidencial,
  isTipoImovelResidencialExcetoDomicilio,
} from './utils-cadastroImovel'

const createTipoEnderecoCalculator = (
  metaEndereco: MetaPath<EnderecoFieldGroupModel>,
  metaEnderecoIndigena: MetaPath<EnderecoIndigenaFieldGroupModel>,
  statusForaArea: string,
  stMicroareaPoloBase: string,
  tipoEnderecoPath: string
): Calculation[] => [
  {
    field: tipoEnderecoPath,
    updates: {
      [metaEndereco.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, allValues: ImovelFormModel) =>
        tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : allValues.endereco,
      [metaEnderecoIndigena.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, allValues: ImovelFormModel) =>
        !tipoEnderecoIndigena.includes(tipoEndereco) ? null : allValues.enderecoIndigena,
      [statusForaArea]: (tipoEndereco: TipoEnderecoEnum, allValues: ImovelFormModel) =>
        tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : allValues.statusForaArea,
      [stMicroareaPoloBase]: (tipoEndereco: TipoEnderecoEnum, allValues: ImovelFormModel) =>
        !tipoEnderecoIndigena.includes(tipoEndereco) ? null : allValues.stMicroareaPoloBase,
    },
  },
]

const createEnderecoIndigenaCalculator = (meta: MetaPath<EnderecoIndigenaFieldGroupModel>): Calculation[] => [
  {
    field: meta.dsei.absolutePath(),
    updates: {
      [meta.poloBase.absolutePath()]: (dsei: DseiSelectFieldModel, allValues: ImovelFormModel) =>
        dsei?.id !== allValues?.enderecoIndigena?.poloBase?.dsei.id ? null : allValues?.enderecoIndigena?.poloBase,
    },
  },
  {
    field: meta.poloBase.absolutePath(),
    updates: {
      [meta.dsei.absolutePath()]: (poloBase: PoloBaseSelectFieldModel, allValues: ImovelFormModel) =>
        !!poloBase?.dsei ? poloBase.dsei : allValues?.enderecoIndigena?.dsei,
      [meta.aldeia.absolutePath()]: (poloBase: PoloBaseSelectFieldModel, allValues: ImovelFormModel) =>
        poloBase?.id !== allValues?.enderecoIndigena?.aldeia?.poloBase?.id ? null : allValues?.enderecoIndigena?.aldeia,
    },
  },
  {
    field: meta.aldeia.absolutePath(),
    updates: {
      [meta.poloBase.absolutePath()]: (aldeia: AldeiaSelectFieldModel, allValues: ImovelFormModel) =>
        !!aldeia?.poloBase ? aldeia.poloBase : allValues?.enderecoIndigena?.poloBase,
      [meta.dsei.absolutePath()]: (aldeia: AldeiaSelectFieldModel, allValues: ImovelFormModel) =>
        !!aldeia?.poloBase?.dsei ? aldeia.poloBase.dsei : allValues?.enderecoIndigena?.dsei,
      [meta.municipio.absolutePath()]: (aldeia: AldeiaSelectFieldModel) => {
        if (aldeia?.id) {
          if (aldeia.municipio.length > 1) {
            return null
          } else {
            return aldeia.municipio[0]
          }
        } else return undefined
      },
      [meta.uf.absolutePath()]: (aldeia: AldeiaSelectFieldModel) => {
        if (aldeia?.id) {
          if (aldeia.municipio.length > 1) {
            return null
          } else {
            return aldeia.municipio[0].uf
          }
        } else return undefined
      },
    },
  },
  {
    field: meta.municipio.absolutePath(),
    updates: {
      [meta.uf.absolutePath()]: (municipio: MunicipioSelectModel) => (!!municipio?.uf ? municipio.uf : undefined),
    },
  },
]

const createAnimaisDomicilioCalculator = (
  meta: MetaPath<AnimaisImovelFormModel>,
  tipoImovelPath: string
): Calculation[] => [
  {
    field: meta.possuiAnimais.absolutePath(),
    updates: {
      [meta.animais.absolutePath()]: (possuiAnimais: SimNaoEnum, allValues: ImovelFormModel) =>
        possuiAnimais === SimNaoEnum.NAO ? null : allValues?.animaisNoDomicilio?.animais,
      [meta.quantidadeAnimais.absolutePath()]: (possuiAnimais: SimNaoEnum, allValues: ImovelFormModel) =>
        possuiAnimais === SimNaoEnum.NAO ? null : allValues?.animaisNoDomicilio?.quantidadeAnimais,
    },
  },
  {
    field: tipoImovelPath,
    updates: {
      [meta.absolutePath()]: (tipoImovel: TipoImovelEnum, allValues: ImovelFormModel) =>
        !isTipoImovelDomicilio(tipoImovel) ? null : allValues.animaisNoDomicilio,
    },
  },
]

const createCondicoesMoradiaCalculator = (
  meta: MetaPath<CondicoesMoradiaFormModel>,
  tipoImovelPath: string
): Calculation[] => [
  {
    field: tipoImovelPath,
    updates: (tipoImovel: TipoImovelEnum) => {
      let updates: Updates = {}

      if (!isTipoImovelDomicilio(tipoImovel)) {
        updates = {
          [meta.tipoSituacaoMoradia.absolutePath()]: null,
          [meta.tipoPosseTerra.absolutePath()]: null,
          [meta.numeroComodos.absolutePath()]: null,
          [meta.tipoMaterialParede.absolutePath()]: null,
          [meta.tipoAcessoDomicilio.absolutePath()]: null,
          [meta.tipoDomicilio.absolutePath()]: null,
        }
      }

      if (!isTipoImovelResidencial(tipoImovel) || isUndefinedOrNull(tipoImovel)) {
        updates = {
          ...updates,
          [meta.tipoLocalizacao.absolutePath()]: null,
          [meta.tipoAbastecimentoAgua.absolutePath()]: null,
          [meta.tipoTratamentoAgua.absolutePath()]: null,
          [meta.tipoEscoamentoSanitario.absolutePath()]: null,
          [meta.tipoDestinoLixo.absolutePath()]: null,
          [meta.isEnergiaEletricaDisponivel.absolutePath()]: null,
          [meta.tipoOrigemEnergiaEletrica.absolutePath()]: null,
        }
      }
      return updates
    },
  },
  {
    field: meta.tipoLocalizacao.absolutePath(),
    updates: {
      [meta.tipoPosseTerra.absolutePath()]: (tipoLocalizacao: TipoLocalizacaoEnum, allValues: ImovelFormModel) =>
        tipoLocalizacao !== TipoLocalizacaoEnum.RURAL ? null : allValues?.condicoesMoradia?.tipoPosseTerra,
    },
  },
  {
    field: meta.isEnergiaEletricaDisponivel.absolutePath(),
    updates: {
      [meta.tipoOrigemEnergiaEletrica.absolutePath()]: (stEnergiaEletrica: SimNaoEnum, allValues: ImovelFormModel) =>
        stEnergiaEletrica === SimNaoEnum.NAO ? null : allValues?.condicoesMoradia?.tipoOrigemEnergiaEletrica,
    },
  },
]

const createInstituicaoPermanenciaCalculator = (
  meta: MetaPath<InstituicaoPermanenciaFormModel>,
  tipoImovelPath: string
): Calculation[] => [
  {
    field: tipoImovelPath,
    updates: {
      [meta.absolutePath()]: (tipoImovel: TipoImovelEnum, allValues: ImovelFormModel) =>
        !isTipoImovelResidencialExcetoDomicilio(tipoImovel) ? null : allValues?.instituicaoPermanencia,
    },
  },
]

export const createCadastroImovelCalculator = (meta: MetaRoot<ImovelFormModel>) =>
  createDecorator(
    {
      field: meta.statusForaArea.absolutePath(),
      updates: {
        [meta.microarea.absolutePath()]: (statusForaArea: boolean, allValues: ImovelFormModel) =>
          statusForaArea ? null : allValues.microarea,
      },
    },
    {
      field: meta.stMicroareaPoloBase.absolutePath(),
      updates: {
        [meta.microarea.absolutePath()]: (microareaPoloBase: boolean, allValues: ImovelFormModel) => {
          if (tipoEnderecoIndigena.includes(allValues.tipoEndereco)) {
            return microareaPoloBase ? null : allValues.microarea
          }
          return allValues.statusForaArea ? null : allValues.microarea
        },
      },
    },
    {
      field: meta.endereco.semNumero.absolutePath(),
      updates: {
        [meta.endereco.numero.absolutePath()]: (semNumero: boolean, allValues: ImovelFormModel) =>
          semNumero ? 'S/N' : allValues.endereco?.numero === 'S/N' ? null : allValues.endereco?.numero,
      },
    },
    {
      field: meta.tipoImovel.absolutePath(),
      updates: {
        [meta.numeroMoradores.absolutePath()]: (tipoImovel: TipoImovelEnum, allValues: ImovelFormModel) =>
          !isTipoImovelResidencial(tipoImovel) ? null : allValues?.numeroMoradores,
        [meta.familias.absolutePath()]: (tipoImovel: TipoImovelEnum, allValues: ImovelFormModel) =>
          !isTipoImovelDomicilio(tipoImovel) ? null : allValues?.familias,
        [meta.instituicaoPermanencia.absolutePath()]: (tipoImovel: TipoImovelEnum, allValues: ImovelFormModel) =>
          !isTipoImovelResidencialExcetoDomicilio(tipoImovel) ? null : allValues?.instituicaoPermanencia,
        [meta.familias.absolutePath()]: (tipoImovel: TipoImovelEnum, allValues: ImovelFormModel) =>
          !isTipoImovelDomicilio(tipoImovel) ? null : allValues?.familias,
        [meta.telefoneResidencial.absolutePath()]: (tipoImovel: TipoImovelEnum, allValues: ImovelFormModel) =>
          !isTipoImovelDomicilio(tipoImovel) ? null : allValues?.telefoneResidencial,
        [meta.statusRecusa.absolutePath()]: (tipoImovel: TipoImovelEnum, allValues: ImovelFormModel) =>
          !isTipoImovelResidencial(tipoImovel) ? false : allValues?.statusRecusa,
      },
    },
    {
      field: meta.statusRecusa.absolutePath(),
      updates: {
        [meta.condicoesMoradia.absolutePath()]: (statusRecusa: boolean, allValues: ImovelFormModel) =>
          statusRecusa ? null : allValues?.condicoesMoradia,
        [meta.animaisNoDomicilio.absolutePath()]: (statusRecusa: boolean, allValues: ImovelFormModel) =>
          statusRecusa ? null : allValues?.animaisNoDomicilio,
        [meta.instituicaoPermanencia.absolutePath()]: (statusRecusa: boolean, allValues: ImovelFormModel) =>
          statusRecusa ? null : allValues?.instituicaoPermanencia,
        [meta.familias.absolutePath()]: (statusRecusa: boolean, allValues: ImovelFormModel) =>
          statusRecusa ? null : allValues?.familias,
        [meta.numeroMoradores.absolutePath()]: (statusRecusa: boolean, allValues: ImovelFormModel) =>
          statusRecusa ? null : allValues?.numeroMoradores,
      },
    },
    ...createEnderecoIndigenaCalculator(meta.enderecoIndigena),
    ...createTipoEnderecoCalculator(
      meta.endereco,
      meta.enderecoIndigena,
      meta.statusForaArea.absolutePath(),
      meta.stMicroareaPoloBase.absolutePath(),
      meta.tipoEndereco.absolutePath()
    ),
    ...createAnimaisDomicilioCalculator(meta.animaisNoDomicilio, meta.tipoImovel.absolutePath()),
    ...createCondicoesMoradiaCalculator(meta.condicoesMoradia, meta.tipoImovel.absolutePath()),
    ...createInstituicaoPermanenciaCalculator(meta.instituicaoPermanencia, meta.tipoImovel.absolutePath())
  )
