import { RelatorioGerencialAbsenteismoInput } from 'graphql/types.generated'
import { dateAsYyyyMmDd } from 'util/date/formatDate'

import { RelatorioGerencialFiltrosFormModel } from '../components/form/model-relatorioGerencialFiltrosForm'

export function convertToRelatorioGerencialAbsenteismoInput(
  filtros: RelatorioGerencialFiltrosFormModel
): RelatorioGerencialAbsenteismoInput {
  if (!filtros) {
    return null
  }

  const { periodo, unidade } = filtros

  if (!periodo) {
    return null
  }

  const { startDate, endDate } = periodo

  if (!startDate || !endDate) {
    return null
  }

  return {
    dateRange: {
      startDate: dateAsYyyyMmDd(startDate),
      endDate: dateAsYyyyMmDd(endDate),
    },
    periodUnit: unidade,
  }
}
