import { isEmpty, Text } from 'bold-ui'
import { UltimoArquivoTemporarioProntoEmProcessamentoQuery } from 'graphql/types.generated'
import React from 'react'
import StatusArquivoTemporarioLabel from 'view/arquivo/StatusArquivoTemporarioLabel'

interface UltimoRelatorioStatusProps {
  data: UltimoArquivoTemporarioProntoEmProcessamentoQuery
  loading: boolean
}

export default function UltimoRelatorioStatus({ data, loading }: UltimoRelatorioStatusProps) {
  return !isEmpty(data.ultimoArquivoTemporarioProntoEmProcessamento) ? (
    <StatusArquivoTemporarioLabel
      arquivoTemporarioStatus={data.ultimoArquivoTemporarioProntoEmProcessamento?.status}
      dataInicioGerado={data.ultimoArquivoTemporarioProntoEmProcessamento.dataInicioGerado}
      isApenasUltimoRelatorio
    />
  ) : (
    !loading && <Text>Nenhum relatório gerado nos últimos 7 dias</Text>
  )
}
