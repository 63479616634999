import { Button, Icon, Tooltip } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import React from 'react'

import { LotacaoRetificacaoAtendimentoButtonModel } from './model-retificacaoAtendimento'

interface RetificacaoAtendimentoButtonProps {
  lotacao: LotacaoRetificacaoAtendimentoButtonModel
  onClick(): void
}

export function RetificacaoAtendimentoButton(props: RetificacaoAtendimentoButtonProps) {
  const { lotacao, onClick } = props
  const { acesso, profissional: profissionalAcesso } = useAcessoLotacaoOrEstagio()

  const isAcessoLotacaoCriadora =
    lotacao.profissional?.id === profissionalAcesso.id &&
    lotacao.equipe?.id === acesso.equipe?.id &&
    lotacao.cbo?.id === acesso.cbo?.id &&
    lotacao.unidadeSaude?.id === acesso.unidadeSaude?.id

  const isMesmoProfissionalCriadorOutraLotacao =
    lotacao.profissional?.id === profissionalAcesso.id && !isAcessoLotacaoCriadora

  const isRetificacaoAtendimentoDisabled = !isAcessoLotacaoCriadora || isMesmoProfissionalCriadorOutraLotacao

  const retificarAtendimentoTooltip = isAcessoLotacaoCriadora
    ? 'Retificar registro'
    : isMesmoProfissionalCriadorOutraLotacao
    ? 'É necessário acessar com a lotação que você realizou o registro para retificá-lo'
    : 'Somente a lotação responsável pelo registro pode retificá-lo'

  const handleButtonClick = (e: Event) => {
    e.stopPropagation()
    onClick()
  }

  const handleButtonKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      onClick()
    }
  }

  return (
    <Tooltip text={retificarAtendimentoTooltip}>
      <Button
        size='small'
        skin='ghost'
        disabled={isRetificacaoAtendimentoDisabled}
        onClick={(e) => handleButtonClick(e)}
        onKeyDown={handleButtonKeyDown}
      >
        <Icon icon='penOutline' size={1.5} />
      </Button>
    </Tooltip>
  )
}
