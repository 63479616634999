import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import {
  AcessoCbo,
  ClassificacaoPrioridadeCuidadoEnum,
  Estagio,
  Lotacao,
  LotacaoHistoricoCuidadoCompartilhadoHeaderFragment,
  StatusCuidadoCompartilhadoEnum,
  TipoEstabelecimentoEnum,
} from 'graphql/types.generated'
import Permissions, { Permission } from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'
import { grupoCboVisualizarProntuario } from 'view/cidadao/acessos'

import {
  CuidadoCompartilhadoModel,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoFormModel,
  DiscussaoCasoSolicitanteFieldsModel,
  ResponsavelCuidadoCompartilhado,
} from './model-cuidadoCompartilhado'

type LotacaoFormatterModel = Omit<LotacaoHistoricoCuidadoCompartilhadoHeaderFragment, 'unidadeSaude'> & {
  unidadeSaude: Omit<LotacaoHistoricoCuidadoCompartilhadoHeaderFragment['unidadeSaude'], 'cnes'>
}

export const getResponsabilidadeCuidadoCompartilhado = (
  lotacaoAcessoId: ID,
  lotacaoExecutanteId: ID,
  lotacaoSolicitanteId: ID
): ResponsavelCuidadoCompartilhado => {
  if (lotacaoAcessoId === lotacaoExecutanteId) {
    return 'EXECUTANTE'
  } else if (lotacaoAcessoId === lotacaoSolicitanteId) {
    return 'SOLICITANTE'
  }
  return null
}

export const isResponsavelCuidadoCompartilhado = (
  lotacaoAcessoId: ID,
  lotacaoExecutanteId: ID,
  lotacaoSolicitanteId: ID
) => {
  return getResponsabilidadeCuidadoCompartilhado(lotacaoAcessoId, lotacaoExecutanteId, lotacaoSolicitanteId)!!
}

export const formatCiapCid = (ciap?: CiapSelectFieldModel, cid10?: CidSelectFieldModel) => {
  const separator = ciap ? ' | ' : ''

  const ciapStr = ciap ? `${ciap.descricao.capitalize()} - ${ciap.codigo} (CIAP 2)` : ''
  const cidStr = cid10 ? `${separator + cid10.nome.capitalize()} - ${cid10.codigo} (CID 10)` : ''

  return ciapStr + cidStr
}

export const lotacaoCuidadoCompartilhadoPecFormatter = (
  lotacao: LotacaoFormatterModel,
  nomeCbo: string,
  nomeUnidadeSaude?: string
) => {
  const nomeSocial = lotacao?.profissional?.nomeSocial || ''
  const nomeCivil = lotacao?.profissional?.nomeCivil || ''
  const nomeFinal = nomeSocial ? `${nomeSocial} (Nome social) | ` : `${nomeCivil} | `
  const nomeCivilFinal = nomeSocial ? `| Nome civil - ${nomeCivil}` : ''

  const cbo = `${nomeCbo.toUpperCase()} `
  const equipe = lotacao?.equipe ? `| Equipe ${lotacao.equipe.ine} - ${lotacao.equipe.nome} ` : ''
  const unidadeSaude = !isUndefinedOrNull(nomeUnidadeSaude) ? ` | ${nomeUnidadeSaude}` : ''

  return `${nomeFinal}${cbo}${equipe}${unidadeSaude}${nomeCivilFinal}`
}

export const lotacaoCuidadoCompartilhadoDwFormatter = (
  nomeProf: string,
  cbo: string,
  ine: string,
  nomeEquipe: string,
  cnes: string
) => {
  const nome = nomeProf ? `${nomeProf} | ` : ''
  const cboUpper = cbo ? `${cbo.toUpperCase()} ` : ''
  const equipe = ine && nomeEquipe ? `| Equipe ${ine} - ${nomeEquipe}` : ''
  const unidadeSaude = cnes ? ` | ${cnes}` : ''

  return `${nome}${cboUpper}${equipe}${unidadeSaude}`
}

export const getFieldsByResponsavel = (
  responsavel: ResponsavelCuidadoCompartilhado,
  values: DiscussaoCasoFormModel
): DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel => {
  return responsavel === 'EXECUTANTE' ? values.executanteFields : values.solicitanteFields
}

export const getLotacoesConsultaCompartilhadaCuidadoCompartilhado = (
  lotacaoId: ID,
  cuidadoCompartilhado: CuidadoCompartilhadoModel
) => {
  const { lotacaoExecutanteAtual, lotacaoSolicitante } = cuidadoCompartilhado

  const responsabilidade = getResponsabilidadeCuidadoCompartilhado(
    lotacaoId,
    lotacaoExecutanteAtual.id,
    lotacaoSolicitante.id
  )

  if (responsabilidade === 'EXECUTANTE') {
    return {
      lotacaoResponsavel: lotacaoExecutanteAtual,
      lotacaoConvidada: lotacaoSolicitante,
    }
  }

  if (responsabilidade === 'SOLICITANTE') {
    return {
      lotacaoResponsavel: lotacaoSolicitante,
      lotacaoConvidada: lotacaoExecutanteAtual,
    }
  }

  return { lotacaoResponsavel: null, lotacaoConvidada: null }
}

export const isReclassificandoCuidadoCompartilhado = (
  prioridadeAtual: ClassificacaoPrioridadeCuidadoEnum,
  prioridadeReclassificada: ClassificacaoPrioridadeCuidadoEnum
) => {
  return (
    !!prioridadeReclassificada &&
    !isUndefinedOrNull(prioridadeReclassificada) &&
    prioridadeReclassificada !== prioridadeAtual
  )
}

export const podeAcessarVisualizacaoCuidadoCompartilhado = (
  hasAuthorization: (permission: Permission) => boolean,
  hasCboAuth: (acessos: AcessoCbo[]) => boolean
) => {
  return hasAuthorization(Permissions.visualizarProntuario) && hasCboAuth(grupoCboVisualizarProntuario)
}

export const podeAcessarDiscussaoCuidadoCompartilhado = (
  acesso: Estagio | Lotacao,
  cuidadoCompartilhado: CuidadoCompartilhadoModel
) => {
  return podeAcessarCuidadoCompartilhado(acesso, cuidadoCompartilhado)
}

const podeAcessarCuidadoCompartilhado = (
  acesso: Estagio | Lotacao,
  cuidadoCompartilhado: CuidadoCompartilhadoModel
): boolean => {
  const isExecutante = acesso.id === cuidadoCompartilhado.lotacaoExecutanteAtual.id
  const isSolicitante = acesso.id === cuidadoCompartilhado.lotacaoSolicitante.id
  const isStatusValido =
    (isExecutante && cuidadoCompartilhado.status === StatusCuidadoCompartilhadoEnum.RESPONDIDO) ||
    (isSolicitante && cuidadoCompartilhado.status === StatusCuidadoCompartilhadoEnum.AGUARDANDO)

  return (isExecutante || isSolicitante) && !isStatusValido
}

export const getTiposEstabelecimentoCuidadoCompartilhado = (flag: boolean) =>
  !flag ? [TipoEstabelecimentoEnum.UBS] : null
