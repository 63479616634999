/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { isEmpty, Spinner, Text, VFlow } from 'bold-ui'
import { AccordionCompositeItem } from 'components/accordion/AccordionCompositeItem'
import theme from 'config/theme'
import { useMedicamentoUsoModalQuery } from 'graphql/hooks.generated'
import { ReceitaMedicamento } from 'graphql/types.generated'
import React from 'react'
import { Accordion } from 'react-accessible-accordion'
import { getEditAtendProfId } from 'view/atendimentos/atendimento-individual/util-atendimentoIndividual'

import { MedicamentoInterromperFormModel } from './InterromperTratamentoModal'

interface MedicamentosAccordionViewOnlyProps {
  renderItemBody: (receita: ReceitaMedicamento) => JSX.Element
  renderItemHeader: (
    interrompidosAgora?: MedicamentoInterromperFormModel[]
  ) => (receita: ReceitaMedicamento) => JSX.Element

  prontuarioId: ID
  atendimentoProfissionalId: ID
  isAtendimentoObservacao: boolean
}

function MedicamentosAccordionViewOnly(props: MedicamentosAccordionViewOnlyProps) {
  const {
    renderItemBody,
    renderItemHeader,
    prontuarioId,
    atendimentoProfissionalId,
    isAtendimentoObservacao = false,
  } = props

  const editAtendProfId = getEditAtendProfId(isAtendimentoObservacao, atendimentoProfissionalId)

  const {
    data: { listaMedicamento: listaMedicamentosAtivos },
    loading: loadingAtivos,
  } = useMedicamentoUsoModalQuery({
    variables: {
      input: { prontuarioId, editAtendProfId, usoContinuo: false, ativo: true, pageParams: { unpaged: true } },
    },
  })
  return (
    <div>
      <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
        <VFlow vSpacing={0.5}>
          {loadingAtivos && (
            <div css={styles.loadingContainer}>
              <Spinner />
              {'Carregando resultados'}
            </div>
          )}
          {!loadingAtivos && isEmpty(listaMedicamentosAtivos?.content) && (
            <Text
              style={css`
                color: ${theme.pallete.text.secondary};
                font-style: italic;
                text-align: center;
              `}
            >
              {isEmpty(listaMedicamentosAtivos?.content) && 'Nenhum medicamento em uso'}
            </Text>
          )}
          {listaMedicamentosAtivos?.content?.map((item: ReceitaMedicamento) => (
            <AccordionCompositeItem key={item.id} header={renderItemHeader()(item)} body={renderItemBody(item)} />
          ))}
        </VFlow>
      </Accordion>
    </div>
  )
}

export default React.memo(MedicamentosAccordionViewOnly)

const styles = {
  loadingContainer: css`
    color: ${theme.pallete.primary.main};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    & > *:first-of-type {
      margin-right: 0.5rem;
    }
  `,
}
