/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Cell, Grid, Icon, Text, Tooltip, VFlow } from 'bold-ui'

import { HistoricoAcessosTable } from './HistoricoAcessosTable'

const timezone = -(new Date().getTimezoneOffset() / 60)

const infoHistoricoAcesso = `Esse recurso é uma medida para sua segurança: 
  você pode analisar os acessos e verificar se há 
  algum comportamento fora do esperado, como várias 
  tentativas mal sucedidas de acesso. Com isso, você 
  pode alterar sua senha e se manter seguro.`

export function HistoricoAcessosView() {
  return (
    <VFlow>
      <Grid justifyContent='center' gap={0} style={styles.grid} alignItems='center' direction='column'>
        <Cell size={12}>
          <Text variant='h1'>Histórico de acessos</Text>
          <br />
          <Text variant='h5' fontWeight='normal'>
            Este recurso fornece informações como a data e a hora dos acessos (bem ou malsucedidos) do seu login no
            e-SUS APS dos últimos 30 dias. Só você pode visualizar essas informações.
            <Tooltip text={infoHistoricoAcesso} style={styles.tooltipInfo}>
              <Icon icon='infoCircleFilled' size={0.75} style={styles.iconInfo} />
            </Tooltip>
          </Text>
        </Cell>
        <Cell size={12}>
          <div css={styles.alertContainer}>
            <Alert type='info'>
              A data e hora do acesso é exibido em seu fuso horário (
              <Text component='span' color='info' fontWeight='bold'>
                GMT{timezone}
              </Text>
              ).
            </Alert>
          </div>
        </Cell>
        <Cell size={12} flexGrow={1}>
          <HistoricoAcessosTable />
        </Cell>
      </Grid>
    </VFlow>
  )
}

const styles = {
  grid: css`
    flex: 1;
    padding: 2rem 2.5rem;
    margin: 0;
  `,
  tooltipInfo: css`
    width: 28rem;
    max-width: 28rem;
  `,
  iconInfo: css`
    margin-left: 0.5rem;
    vertical-align: middle;
  `,
  alertContainer: css`
    width: fit-content;
  `,
}
