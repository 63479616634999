import { Button, ButtonProps, Dropdown, DropdownProps, Icon, IconProps, Portal } from 'bold-ui'
import { ButtonKind, ButtonSize, ButtonSkin } from 'bold-ui/lib/components/Button/Button/ButtonSkins'
import React, { MouseEvent, useState } from 'react'

export interface DropdownButtonProps extends ButtonProps {
  text?: string
  icon?: IconProps['icon']
  iconColor?: string
  children: DropdownProps['children']
  stopPropagationOnClick?: boolean
  isInsideModal?: boolean
  size?: ButtonSize
  skin?: ButtonSkin
  kind?: ButtonKind
}

export function DropdownButton(props: DropdownButtonProps) {
  const {
    text,
    icon,
    iconColor,
    children,
    isInsideModal = false,
    stopPropagationOnClick = false,
    size = 'small',
    skin = 'ghost',
    kind = 'normal',
    ...rest
  } = props

  const [buttonRef, setButtonRef] = useState<HTMLButtonElement>()

  const [open, setOpen] = useState(false)

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    stopPropagationOnClick && e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    buttonRef?.focus()
  }
  const dropdown = (
    <Dropdown anchorRef={buttonRef} open={open} onClose={handleClose}>
      {children}
    </Dropdown>
  )

  return (
    <>
      <Button innerRef={setButtonRef} size={size} skin={skin} kind={kind} onClick={handleClick} {...rest}>
        {text}
        <Icon icon={icon} color={iconColor} />
      </Button>
      {isInsideModal ? dropdown : <Portal>{dropdown}</Portal>}
    </>
  )
}

DropdownButton.defaultProps = {
  icon: 'dots',
} as Partial<DropdownButtonProps>
