import { Alert, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { PageContent } from 'components/layout/PageContent'
import { PageLoading } from 'components/loading'
import { useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery } from 'graphql/hooks.generated'
import { Action, CuidadoCompartilhadoQueryFilterEnum, CuidadosCompartilhadoQueryInput } from 'graphql/types.generated'
import React, { useMemo } from 'react'

import {
  CuidadoCompartilhadoItemActions,
  CuidadoCompartilhadoSort,
  CuidadoCompartilhadoSortEnum,
  ListaCuidadoCompartilhadoFilterModel,
} from '../model-cuidadoCompartilhado'
import { ListaCuidadoCompartilhadoHeader } from './ListaCuidadoCompartilhadoHeader'
import { ListaCuidadoCompartilhadoView } from './ListaCuidadoCompartilhadoView'

const filterToQueryInput = (
  profissionalId: ID,
  isUbs: boolean,
  value: ListaCuidadoCompartilhadoFilterModel
): CuidadosCompartilhadoQueryInput => {
  const hasExecutante = value.profissionalExecutante?.id
  return {
    query: value.query,
    status: value.status,
    cboId: value.cbo?.id,
    dataInicio: value.periodo?.startDate,
    dataFim: value.periodo?.endDate,
    executadosPelaMinhaEquipe: isUbs ? (hasExecutante ? false : !value.switch) : false,
    executadosPorProfissionalId: isUbs ? hasExecutante ?? (value.switch ? profissionalId : null) : profissionalId,
    solicitadosPorProfissionalId: value.profissionalSolicitante?.id,
    unidadeSaudeId: value.unidadeSaude?.id,
    somenteUnidadeExecutante: !isUbs,
    somenteSemExecutante: false,
    solicitadosPelaMinhaEquipe: false,
    somenteUnidadesBasicas: isUbs,
    pageParams: { ...value.pageParams, sort: CuidadoCompartilhadoSort[value.pageParams.sort] },
  }
}

const resolveDiscutirAction = (actions: CuidadoCompartilhadoItemActions): Action => actions?.discutirCasoExecutante

const createFilterDefault = (isEstabelecimentoEspecializado: boolean): ListaCuidadoCompartilhadoFilterModel => ({
  status: [],
  periodo: null,
  profissionalExecutante: null,
  profissionalSolicitante: null,
  unidadeSaude: null,
  equipe: [],
  cbo: null,
  switch: isEstabelecimentoEspecializado ? null : true,
})

export const ListaCuidadoCompartilhadoExecutanteView = () => {
  const {
    acesso: { unidadeSaude },
    profissional,
  } = useAcessoLotacaoOrEstagio()

  const {
    data: { isEstabelecimentoAllowedAsReferenciaVinculacaoServico },
    loading: loadingVinculacaoServicoAllowed,
  } = useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery({
    variables: { tipoCoMs: unidadeSaude.tipo.codigoMs, subtipoCoMs: unidadeSaude.subtipo?.codigoMs },
    fetchPolicy: 'cache-first',
  })

  const isEstabelecimentoEspecializado =
    !loadingVinculacaoServicoAllowed && isEstabelecimentoAllowedAsReferenciaVinculacaoServico

  const filterDefault = useMemo(() => createFilterDefault(isEstabelecimentoEspecializado), [
    isEstabelecimentoEspecializado,
  ])

  const filterToQuery = (value: ListaCuidadoCompartilhadoFilterModel) =>
    filterToQueryInput(profissional.id, !isEstabelecimentoEspecializado, value)

  const alertMessage = !isEstabelecimentoEspecializado
    ? 'A lista apresenta todos os cuidados compartilhados com você e com a sua equipe.'
    : 'A lista apresenta todos os cuidados compartilhados com você.'

  if (loadingVinculacaoServicoAllowed) {
    return <PageLoading message='Carregando dados de cuidados compartilhados...' />
  }

  return (
    <>
      <ListaCuidadoCompartilhadoHeader />
      <PageContent fluid type='transparent'>
        <VFlow vSpacing={0.5}>
          <Alert type='info' inline>
            {alertMessage}
          </Alert>
          <ListaCuidadoCompartilhadoView
            filterDefault={filterDefault}
            initialSort={CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_EXECUTANTE}
            itemsToSort={itemsToSort}
            filterSwitchLabel={!isEstabelecimentoEspecializado ? 'Ver somente cuidados compartilhados comigo.' : null}
            role={CuidadoCompartilhadoQueryFilterEnum.EXECUTANTE}
            filterToQueryInput={filterToQuery}
            resolveDiscutirAction={resolveDiscutirAction}
          />
        </VFlow>
      </PageContent>
    </>
  )
}

const itemsToSort = [
  CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_EXECUTANTE,
  CuidadoCompartilhadoSortEnum.STATUS_EXECUTANTE,
]
