import { css } from '@emotion/core'
import { HFlow, Text } from 'bold-ui'
import React, { Fragment } from 'react'

interface NomeProfissionalTitleProps {
  nomeCivil: string
  nomeSocial: string
}

export default function NomeProfissionalTitle({ nomeCivil, nomeSocial }: NomeProfissionalTitleProps) {
  return (
    <HFlow hSpacing={0.5} alignItems='center'>
      <Text variant='h1'>{(nomeSocial ? nomeSocial : nomeCivil).titleCase()}</Text>
      {!!nomeSocial && (
        <Fragment>
          <Text variant='h3'> (Nome Social) </Text>
          <Text variant='h4' fontWeight='normal' style={styles.nomeCivil}>
            {nomeCivil.titleCase()}
          </Text>
        </Fragment>
      )}
    </HFlow>
  )
}

const styles = {
  nomeCivil: css`
    padding-top: 0.25rem;
  `,
}
