import { CidadaoTerritorioBasico, Familia, TipoDomicilioEnum, TipoImovelEnum } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { AnimaisImovelFormModel } from 'view/cadastro-imovel/model-cadastroImovel'

import { domicilioTipoImovelRecord, TipoImovelListModel, tipoImovelRecord } from '../model'

export const getTipoImovelIcon = (tipoImovel: TipoImovelEnum, tipoDomicilioId: string): TipoImovelListModel => {
  if (!isUndefinedOrNull(tipoImovel)) {
    if (tipoImovel !== TipoImovelEnum.DOMICILIO) {
      return tipoImovelRecord[tipoImovel]
    }
    if (!isUndefinedOrNull(tipoDomicilioId)) {
      return domicilioTipoImovelRecord[tipoDomicilioId]
    } else {
      return domicilioTipoImovelRecord[TipoDomicilioEnum.CASA]
    }
  }
  return tipoImovelRecord[TipoImovelEnum.OUTROS]
}

export const getResponsavelDependentesFamilia = (familia: Familia) => {
  return familia.cidadaos.reduce(
    (acc: { responsavel: CidadaoTerritorioBasico; dependentes: CidadaoTerritorioBasico[] }, cidadao) => {
      if (cidadao.cpf === familia.cpfCnsResponsavel || cidadao.cns === familia.cpfCnsResponsavel) {
        acc.responsavel = cidadao
      } else {
        acc.dependentes.push(cidadao)
      }

      return acc
    },
    { responsavel: null, dependentes: [] }
  )
}

export const getTiposAnimaisStringListCapitalized = (animaisImovel: AnimaisImovelFormModel) => {
  const list = [
    animaisImovel.possuiCachorro ? 'Cachorro' : null,
    animaisImovel.possuiGato ? 'Gato' : null,
    animaisImovel.possuiPassaro ? 'Pássaro' : null,
    animaisImovel.possuiGalinha ? 'Galinha' : null,
    animaisImovel.possuiPorco ? 'Porco' : null,
    animaisImovel.possuiMacaco ? 'Macaco' : null,
    animaisImovel.possuiRepteis ? 'Répteis' : null,
    animaisImovel.possuiOutros ? 'Outros' : null,
  ].filterNotNull()

  return list?.isNotEmpty() ? list : null
}
