import { useAlertasSdcQuery } from 'graphql/hooks.generated'
import { useLocalStorage, UseStorageOptions } from 'hooks/useStorage'
import React, { useCallback, useEffect, useMemo } from 'react'
import { createContext, useContext, useState } from 'react'

import { AlertaSuporteDecisaoClinica, SdcAtendimentoValues } from './model-sdc'

interface AlertasSdcProviderProps {
  cacheKey?: string
  children: React.ReactNode
  options?: UseStorageOptions<AlertaSuporteDecisaoClinica[]>
}

interface SdcContextModel {
  alertasSdc: AlertaSuporteDecisaoClinica[]
  hasAlertaNaoLido: boolean
  setSdcAtendimentoValues(value: SdcAtendimentoValues): void
  marcarTodosAlertasComoLidos(): void
  marcarAlertaComoLido(alerta: AlertaSuporteDecisaoClinica): void
  marcarAlertaComoExibido(alerta: AlertaSuporteDecisaoClinica): void
}

const AlertasSdcContext = createContext<SdcContextModel>(undefined)

export const AlertasSdcProvider = (props: AlertasSdcProviderProps) => {
  const { children, cacheKey, options } = props
  const [getCachedValue, setCachedValue] = useLocalStorage(cacheKey, options)

  const [hasAlertaNaoLido, setHasAlertaNaoLido] = useState(false)
  const [sdcAtendimentoValues, setSdcAtendimentoValues] = useState<SdcAtendimentoValues>()
  const [alertasSdc, setAlertasSdc] = useState<AlertaSuporteDecisaoClinica[]>([])

  const problemasCondicoes = sdcAtendimentoValues?.problemasCondicoes
  const alertasSdcInput = useMemo(
    () => ({
      ciaps2Cids10: problemasCondicoes?.map((problemaCondicao) => {
        return {
          cid10: problemaCondicao.cid?.codigo,
          ciap2: problemaCondicao.ciap?.codigo,
        }
      }),
      prontuarioId: sdcAtendimentoValues?.prontuarioId,
      atendProfData: sdcAtendimentoValues?.atendProfData,
    }),
    [problemasCondicoes, sdcAtendimentoValues]
  )

  useEffect(() => {
    const cachedAlertas = getCachedValue()
    setAlertasSdc(cachedAlertas || [])
  }, [getCachedValue])

  useEffect(() => {
    setHasAlertaNaoLido(alertasSdc?.some((alerta) => !alerta.visualizado))
  }, [alertasSdc])

  useAlertasSdcQuery({
    variables: { input: alertasSdcInput },
    fetchPolicy: 'cache-first',
    skip: !sdcAtendimentoValues,
    onCompleted: (data) => {
      if (!!sdcAtendimentoValues) {
        const novosAlertas = data?.alertasSdc
        const cachedAlertas = getCachedValue()
        const alertasVisualizadosById = new Map(
          [...(alertasSdc || []), ...(cachedAlertas || [])]?.map((alerta) => [alerta.id, alerta.visualizado])
        )
        novosAlertas?.forEach((novoAlerta: AlertaSuporteDecisaoClinica) => {
          if (!alertasVisualizadosById.has(novoAlerta.id)) {
            novoAlerta.visualizado = false
            novoAlerta.exibido = false
            !hasAlertaNaoLido && setHasAlertaNaoLido(true)
          } else {
            novoAlerta.visualizado = alertasVisualizadosById.get(novoAlerta.id)
          }
        })

        setAlertasSdc(novosAlertas)
        !!novosAlertas && setCachedValue(novosAlertas)
      }
    },
  })

  const marcarAlertaComoExibido = useCallback(
    (alerta: AlertaSuporteDecisaoClinica) => {
      alerta.exibido = true
      !!alertasSdc && setCachedValue(alertasSdc)
    },
    [alertasSdc, setCachedValue]
  )

  const marcarAlertaComoLido = useCallback(
    (alerta: AlertaSuporteDecisaoClinica) => {
      alerta.visualizado = true
      alerta.exibido = true
      setHasAlertaNaoLido(alertasSdc?.some((alerta) => !alerta.visualizado))
      !!alertasSdc && setCachedValue(alertasSdc)
    },
    [alertasSdc, setCachedValue]
  )

  const marcarTodosAlertasComoLidos = useCallback(() => {
    alertasSdc?.forEach((alerta) => {
      alerta.visualizado = true
      alerta.exibido = true
    })
    setHasAlertaNaoLido(false)
    !!alertasSdc && setCachedValue(alertasSdc)
  }, [alertasSdc, setHasAlertaNaoLido, setCachedValue])

  const value: SdcContextModel = useMemo(
    () => ({
      alertasSdc,
      setSdcAtendimentoValues,
      hasAlertaNaoLido,
      marcarTodosAlertasComoLidos,
      marcarAlertaComoLido,
      marcarAlertaComoExibido,
    }),
    [alertasSdc, hasAlertaNaoLido, marcarTodosAlertasComoLidos, marcarAlertaComoLido, marcarAlertaComoExibido]
  )

  return <AlertasSdcContext.Provider value={value}>{children}</AlertasSdcContext.Provider>
}

export const useAlertasSdc = () => useContext(AlertasSdcContext)
