/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, HFlow, Text } from 'bold-ui'
import { HLabel } from 'components/HLabel'

import { InformacoesAdministrativasProfissional } from '../../../model/model-historico'

interface HistoricoInfoProfissionalProps {
  profissional: InformacoesAdministrativasProfissional
  label?: string
}

export const HistoricoInfoProfissional = (props: HistoricoInfoProfissionalProps) => {
  const { profissional, label = 'Profissional:' } = props
  const hasDados = profissional?.nome || profissional?.cbo || profissional?.ineEquipe

  return hasDados ? (
    <Cell size={12}>
      <HFlow hSpacing={0} style={styles.separator}>
        {profissional.nome && (
          <HLabel title={label}>
            <Text>
              {profissional.nome}
              {profissional.nomeSocial && ' (Nome social)'}
              {` | ${profissional.cbo?.titleCase()}`}
              {profissional.ineEquipe ? ` | ${profissional.nomeEquipe} - ${profissional.ineEquipe}` : ' | Sem equipe'}
              {profissional.nomeSocial && ` | Nome civil - ${profissional.nomeCivil}`}
            </Text>
          </HLabel>
        )}
      </HFlow>
    </Cell>
  ) : null
}

const styles = {
  separator: css`
    & > :not(:last-child):after {
      content: '|';
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  `,
}
