import { Cell, Grid, Heading, Text, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { css } from 'emotion'
import { useFamiliasImovelQuery } from 'graphql/hooks.generated'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { InconsistenciasImovelList } from 'view/visualizacaoTerritorial/components/list/InconsistenciasImovelList'
import { calculateInconsistencias } from 'view/visualizacaoTerritorial/utils/calculateInconsistencias'

import { FamiliaSection } from './components/FamiliaSection'
import { ImovelUrlParams } from './model-visualizacao-imovel'

export function VisualizarFamiliasMoradoresView() {
  const { imovelId } = useParams<ImovelUrlParams>()
  const {
    data: { imovel },
    loading,
  } = useFamiliasImovelQuery({
    variables: {
      input: imovelId,
    },
    fetchPolicy: 'network-only',
  })

  const familiasResidentes = (imovel?.familias || []).filter(
    (familia) => familia.isDomicilioAtivo && familia.isInformacaoSuficiente && familia.isFamiliaAindaReside
  )
  const inconsistencias = useMemo(() => calculateInconsistencias(familiasResidentes), [familiasResidentes])

  return loading ? (
    <LoadingIndicator />
  ) : (
    <PageContent fluid type='filled'>
      <Grid no-wrap='true'>
        <Cell size={12}>
          <Heading level={2}>Famílias e cidadãos do imóvel</Heading>
        </Cell>
        {familiasResidentes.length > 0 ? (
          familiasResidentes.map((familia) => {
            return (
              <Cell
                size={12}
                style={css`
                  margin-left: -0.5rem;
                `}
                key={familia.id}
              >
                <FamiliaSection familia={familia} />
              </Cell>
            )
          })
        ) : (
          <Cell size={12}>
            <Text fontStyle='italic'>Este imóvel não possui família registrada.</Text>
            <Text fontWeight='bold'> Atualize o cadastro do imóvel</Text>
          </Cell>
        )}
        {inconsistencias.size > 0 && (
          <Cell size={12}>
            <VFlow>
              <Heading level={2}>Inconsistências de cadastro</Heading>
              <InconsistenciasImovelList inconsistencias={inconsistencias} />
            </VFlow>
          </Cell>
        )}
      </Grid>
    </PageContent>
  )
}
