/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Theme, useTheme, VFlow } from 'bold-ui'
import { ValidationErrors } from 'final-form'
import { isUndefinedOrNull } from 'util/checks'
import { MetaRoot } from 'util/metaPath'

import { RelatorioGerencialFiltrosFormModel } from '../form/model-relatorioGerencialFiltrosForm'
import { RelatorioGerencialPeriodoLabel } from './RelatorioGerencialPeriodoLabel'
import { RelatorioGerencialPeriodoRadioField } from './RelatorioGerencialPeriodoRadioField'
import { RelatorioGerencialUnidadePeriodoSelectField } from './RelatorioGerencialUnidadePeriodoSelectField'

interface RelatorioGerencialPeriodoFieldProps {
  values: RelatorioGerencialFiltrosFormModel
  loadingTableOrModelo: boolean
  name: MetaRoot<RelatorioGerencialFiltrosFormModel>
  errors?: ValidationErrors
  hideChildren: () => void
}

export function RelatorioGerencialPeriodoField(props: RelatorioGerencialPeriodoFieldProps) {
  const { values, loadingTableOrModelo, name, errors, hideChildren } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const hasError = !isUndefinedOrNull(errors.periodo)

  return (
    <VFlow>
      <RelatorioGerencialPeriodoLabel />
      <VFlow>
        <VFlow css={styles.periodoContainer}>
          <Grid wrap alignItems='center'>
            <Cell size={6}>
              <RelatorioGerencialUnidadePeriodoSelectField
                name={name.unidade}
                disabled={loadingTableOrModelo}
                style={styles.unidadePeriodoSelectField}
                onChange={hideChildren}
              />
            </Cell>
            <Cell size={12}>
              <RelatorioGerencialPeriodoRadioField
                nameOpcaoSelecionada={name.opcaoSelecionada}
                namePeriodo={name.periodo}
                opcaoSelecionadaValue={values?.opcaoSelecionada}
                unidadeTempoValue={values?.unidade}
                disabled={loadingTableOrModelo}
                hasError={hasError}
                onChange={hideChildren}
              />
            </Cell>
          </Grid>
        </VFlow>
      </VFlow>
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  periodoContainer: css`
    position: relative;
    padding: 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid ${theme.pallete.gray.c80};
  `,
  unidadePeriodoSelectField: css`
    position: absolute;
    margin-left: -0.5rem;
    top: -0.7rem;
    padding: 0 0.5rem;
    background-color: ${theme.pallete.surface.main};
  `,
})
