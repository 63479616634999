import { resolveName } from 'components/form/final-form/hooks/useField'
import { PrivateRoute } from 'components/route'
import { FormApi } from 'final-form'
import { TipoExameEnum } from 'graphql/types.generated'
import React from 'react'
import { useField } from 'react-final-form'
import { Switch, useHistory, useRouteMatch } from 'react-router'
import { metaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import { SolicitacaoExameQueryModel } from '../../objetivo/resultados-exames/model-resultadosExames'
import { grupoCboSolicitacaoExame } from '../acessos'
import { SolicitacaoExameModel } from './model'
import SolicitarExamesEditarModal from './SolicitarExamesEditarModal'
import SolicitarExamesFormModal from './SolicitarExamesFormModal'
import SolicitarExamesReplicarModal from './SolicitarExamesReplicarModal'

export interface SolicitarExamesRootViewProps {
  cidadao: CidadaoAtendimento
  initialValues?: Partial<SolicitacaoExameModel>
  adicionados: SolicitacaoExameModel[]
  existentes: SolicitacaoExameQueryModel[]
  iniciadoEm: Instant
  handleSubmit(formValues: SolicitacaoExameModel, formApi: FormApi): void
  handleUpdate(formValues: SolicitacaoExameModel): void
}

const urlSolicitacaoExame = '/solicitacao-exame/'
export const urlSolicitacaoExameComum = urlSolicitacaoExame + 'comum/'
export const urlSolicitacaoExameAltoCusto = urlSolicitacaoExame + 'alto-custo/'
export const urlSolicitacaoExameEditar = urlSolicitacaoExame + 'editar/'
export const urlSolicitacaoExameReplicar = urlSolicitacaoExame + 'replicar/'

const soapPath = metaPath<SoapState>()

export function SolicitarExamesRootView(props: SolicitarExamesRootViewProps) {
  const { cidadao, adicionados, existentes, initialValues, iniciadoEm, handleSubmit, handleUpdate } = props
  const match = useRouteMatch()
  const history = useHistory()

  const {
    input: { value: condicoesAvaliadasNoAtendimento },
  } = useField<ProblemaCondicaoModel[]>(resolveName(soapPath.avaliacao.problemasECondicoes), {
    subscription: { value: true },
  })

  let initialValue = initialValues as SolicitacaoExameModel

  if (condicoesAvaliadasNoAtendimento?.length > 0) {
    const condicoesCid10 = condicoesAvaliadasNoAtendimento.filter((condicao) => {
      return condicao.cid?.id !== undefined
    })

    if (condicoesCid10.length === 1) {
      initialValue = { ...initialValues, cid10: condicoesCid10[0].cid } as SolicitacaoExameModel
    }
  }

  return (
    <Switch>
      <PrivateRoute path={`${match.url}${urlSolicitacaoExameComum}`} permission={grupoCboSolicitacaoExame.adicionar}>
        <SolicitarExamesFormModal
          cidadao={cidadao}
          initialValues={{ ...initialValue, tipoExame: TipoExameEnum.COMUM }}
          editing={false}
          onSubmit={handleSubmit}
          adicionados={adicionados}
          iniciadoEm={iniciadoEm}
          onClose={() => history.push(match.url.replace(urlSolicitacaoExameComum, ''))}
        />
      </PrivateRoute>
      <PrivateRoute
        path={`${match.url}${urlSolicitacaoExameAltoCusto}`}
        permission={grupoCboSolicitacaoExame.adicionar}
      >
        <SolicitarExamesFormModal
          cidadao={cidadao}
          initialValues={{ ...initialValue, tipoExame: TipoExameEnum.ALTO_CUSTO }}
          editing={false}
          onSubmit={handleSubmit}
          adicionados={adicionados}
          iniciadoEm={iniciadoEm}
          onClose={() => history.push(match.url.replace(urlSolicitacaoExameAltoCusto, ''))}
        />
      </PrivateRoute>
      <PrivateRoute
        path={`${match.url}${urlSolicitacaoExameEditar}:id`}
        permission={grupoCboSolicitacaoExame.adicionar}
      >
        <SolicitarExamesEditarModal
          cidadao={cidadao}
          onSubmit={handleUpdate}
          adicionados={adicionados}
          iniciadoEm={iniciadoEm}
          onClose={() => history.push(match.url.split(urlSolicitacaoExameEditar)[0])}
        />
      </PrivateRoute>
      <PrivateRoute
        path={`${match.url}${urlSolicitacaoExameReplicar}:id`}
        permission={grupoCboSolicitacaoExame.adicionar}
      >
        <SolicitarExamesReplicarModal
          cidadao={cidadao}
          onSubmit={handleSubmit}
          existentes={existentes}
          onClose={() => history.push(match.url.split(urlSolicitacaoExameReplicar)[0])}
        />
      </PrivateRoute>
    </Switch>
  )
}
