import { Cell, Grid } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import React, { Fragment, useState } from 'react'
import { MetaPath } from 'util/metaPath'

import { EditarLotacaoResponsavelModal } from './EditarLotacaoResponsavelModal'
import { LotacaoResponsavelCard } from './LotacaoResponsavelCard'
import { LotacaoResponsavelFormModel } from './model-lotacaoResponsavel'

interface LotacaoResponsavelSectionProps {
  name: MetaPath<LotacaoResponsavelFormModel>
  isCadastroImovel?: boolean
  isAtividadeColetiva?: boolean
  loadItemsOnOpen?: boolean
}

export function LotacaoResponsavelSection(props: LotacaoResponsavelSectionProps) {
  const { name, isCadastroImovel, isAtividadeColetiva, loadItemsOnOpen } = props
  const { acesso } = useAcessoLotacaoOrEstagio()
  const [isModalResponsavelOpen, setIsModalResponsavelOpen] = useState(false)

  const {
    input: {
      value: { lotacao },
      onChange,
    },
  } = usePecField({ name: name.absolutePath() })

  const handleEditarLotacaoResponsavel = (novoResponsavel: LotacaoAndEstagioSelectFieldModel) => {
    onChange({ lotacao: novoResponsavel })
  }

  return (
    <Fragment>
      <Grid>
        <Cell size={12}>
          <LotacaoResponsavelCard responsavel={lotacao} onAlterarResponsavel={() => setIsModalResponsavelOpen(true)} />
        </Cell>
      </Grid>

      <EditarLotacaoResponsavelModal
        open={isModalResponsavelOpen}
        municipioId={acesso.municipio.id}
        onClose={() => setIsModalResponsavelOpen(false)}
        onResponsavelChange={handleEditarLotacaoResponsavel}
        isCadastroImovel={isCadastroImovel}
        isAtividadeColetiva={isAtividadeColetiva}
        loadItemsOnOpen={loadItemsOnOpen}
      />
    </Fragment>
  )
}
