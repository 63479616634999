import { isEmpty } from 'bold-ui'
import AccordionList from 'components/accordion/AccordionList'
import { useMedicamentoUsoModalQuery } from 'graphql/hooks.generated'
import { ListaMedicamentoAtivoConcluidoQueryInput, ReceitaMedicamento } from 'graphql/types.generated'
import React, { useState } from 'react'
import { getEditAtendProfId } from 'view/atendimentos/atendimento-individual/util-atendimentoIndividual'

import MedicamentosConcluidosTableFilter from './MedicamentosConcluidosTableFilter'

export interface MedicamentosConcluidosTableProps {
  prontuarioId: ID
  dataAtendimento: Instant
  atendimentoProfissionalId: ID
  isAtendimentoObservacao: boolean
  accordionItemHeader(row: ReceitaMedicamento): React.ReactNode
  accordionItemBody(row: ReceitaMedicamento): React.ReactNode
}

export default function MedicamentosConcluidosTable(props: MedicamentosConcluidosTableProps) {
  const {
    prontuarioId,
    dataAtendimento,
    atendimentoProfissionalId,
    isAtendimentoObservacao = false,
    accordionItemHeader,
    accordionItemBody,
  } = props

  const editAtendProfId = getEditAtendProfId(isAtendimentoObservacao, atendimentoProfissionalId)

  const [currentFilter, setCurrentFilter] = useState<ListaMedicamentoAtivoConcluidoQueryInput>({
    prontuarioId,
    editAtendProfId,
    dataAtendimento,
    usoContinuo: false,
    ativo: false,
    pageParams: {
      unpaged: true,
    },
  })

  const {
    data: { listaMedicamento: listaMedicamentosConcluidos },
    loading: loadingConcluidos,
  } = useMedicamentoUsoModalQuery({
    variables: {
      input: currentFilter,
    },
  })

  const noResultMedicamentoConcluidos =
    isEmpty(listaMedicamentosConcluidos?.content) && 'Nenhum medicamento com tratamento concluído'

  return (
    <AccordionList<ReceitaMedicamento>
      header={<MedicamentosConcluidosTableFilter initialValues={currentFilter} onChange={setCurrentFilter} />}
      loading={loadingConcluidos}
      data={listaMedicamentosConcluidos}
      onChange={setCurrentFilter}
      accordionItemHeader={accordionItemHeader}
      accordionItemBody={accordionItemBody}
      noResultPlaceholder={noResultMedicamentoConcluidos}
    />
  )
}
