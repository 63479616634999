import { Alert, Cell, Grid, Heading, HFlow, Icon, Tag, Tooltip, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import theme from 'config/theme'
import { useFlags } from 'config/useFlagsContext'
import { useUnificacaoBaseAtivaQuery } from 'graphql/hooks.generated'
import { ConexaoQuery } from 'graphql/types.generated'
import React, { useState } from 'react'
import { unificacaoAtivaAgendaOnlineList } from 'view/configuracoes/instalacao/unificacao/model'

import { ConfiguracaoAgendaOnlineDesabilitadoContent } from './ConfiguracaoAgendaOnlineDesabilitadoContent'
import { ConfiguracaoAgendaOnlineHabilitadoContent } from './ConfiguracaoAgendaOnlineHabilitadoContent'

export type ConfiguracaoAgendaOnline = ConexaoQuery['conexao']['agendaOnline']

export interface ConfiguracaoAgendaOnlineBoxProps {
  agendaOnline: ConfiguracaoAgendaOnline
  internetHabilitada: boolean
  refetch(): void
}

export const ConfiguracaoAgendaOnlineBox = (props: ConfiguracaoAgendaOnlineBoxProps) => {
  const { agendaOnline, internetHabilitada, refetch } = props
  const iconStyle = {
    '&:hover': {
      color: theme.pallete.primary.main,
    },
  }

  const [stateUnificandoBases, setStateUnificandoBases] = useState<boolean>(false)

  const { UNIFICACAO_BASE_ENABLED } = useFlags()

  const { loading, data } = useUnificacaoBaseAtivaQuery({
    skip: !UNIFICACAO_BASE_ENABLED,
    onCompleted: () => {
      if (UNIFICACAO_BASE_ENABLED) {
        const unificacoesAtivas = data.unificacoesBase?.filter((item) => {
          return unificacaoAtivaAgendaOnlineList.includes(item.status)
        })

        if (data.pedidoEnvioBaseAtivo || unificacoesAtivas.length > 0) {
          setStateUnificandoBases(true)
        }
      }
    },
  })

  return (
    <Box>
      {!loading && (
        <VFlow>
          <Grid>
            <Cell flexGrow={1}>
              <Heading level={3}>
                <HFlow hSpacing={0.5} alignItems='center'>
                  Agenda online
                  <Tooltip
                    style={{ maxWidth: '595px' }}
                    text={
                      'Ao ativar a agenda online, os cidadãos irão' +
                      ' receber, no aplicativo Meu SUS Digital,' +
                      ' notificações sobre o agendamento e cancelamento' +
                      ' de consultas realizadas no PEC. Para' +
                      ' que os cidadãos possam realizar agendamentos' +
                      ' pelo aplicativo, o coordenador da UBS deve' +
                      ' configurar a agenda online da lotação do profissional' +
                      ' que irá realizar os atendimentos.'
                    }
                  >
                    <Icon icon='infoCircleFilled' size={1} style={iconStyle} />
                  </Tooltip>
                </HFlow>
              </Heading>
            </Cell>
            <Cell>
              {agendaOnline.ativa ? (
                <Tag type='success' icon='checkCircleFilled'>
                  Habilitada
                </Tag>
              ) : (
                <Tag type='normal' icon='banFilled'>
                  Desabilitada
                </Tag>
              )}
            </Cell>
          </Grid>

          {internetHabilitada ? (
            <>
              {agendaOnline.ativa ? (
                <ConfiguracaoAgendaOnlineHabilitadoContent agendaOnline={agendaOnline} refetch={refetch} />
              ) : (
                <>
                  {stateUnificandoBases ? (
                    <Alert type='info' inline>
                      A Agenda Online não pode ser habilitada quando há uma Unificação de Bases em andamento.
                    </Alert>
                  ) : (
                    <ConfiguracaoAgendaOnlineDesabilitadoContent agendaOnline={agendaOnline} refetch={refetch} />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {agendaOnline.ativa ? (
                <Alert type='info' inline>
                  Não pode ser desabilitada sem conexão com a internet.
                </Alert>
              ) : (
                <Alert type='info' inline>
                  Não pode ser habilitada sem conexão com a internet.
                </Alert>
              )}
            </>
          )}
        </VFlow>
      )}
    </Box>
  )
}
