import { Grid } from 'bold-ui'
import { FilterItem } from 'components/filteritem'
import { simNaoEnumToBoolean } from 'components/form'
import {
  tipoAbastecimentoAguaEnumRecord,
  tipoDestinoLixoEnumRecord,
  tipoDomicilioEnumRecord,
  tipoEscoamentoSanitarioEnumRecord,
  tipoImovelEnumRecord,
  tipoMaterialParedeEnumRecord,
  tipoPosseTerraEnumRecord,
  tipoSituacaoMoradiaEnumRecord,
  tipoTratamentoAguaEnumRecord,
} from 'components/form/field/select/CondicoesMoradiaSelectFields/condicoes-moradia-model'
import React, { Fragment } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { stringGrouper } from 'util/string/string-grouper'
import { tipoLocalizacaoRecord, VisualizacaoMicroareasFilterModel } from 'view/visualizacaoTerritorial/model'
import { getTiposAnimaisStringListCapitalized } from 'view/visualizacaoTerritorial/utils/visualizacaoTerritorialUtils'

interface MicroareasFilterTagsProps {
  microareasFilter: VisualizacaoMicroareasFilterModel
}

export function MicroareasFilterTags(props: MicroareasFilterTagsProps) {
  const { microareasFilter } = props
  const stringTiposAnimaisImovel =
    microareasFilter?.animaisImovel && getTiposAnimaisStringListCapitalized(microareasFilter?.animaisImovel)

  const isCadastroAtualizadoMenosDeUmAno = !isUndefinedOrNull(microareasFilter?.isCadastroAtualizadoMenosDeUmAno) && (
    <FilterItem
      label='Atualizado há:'
      value={microareasFilter.isCadastroAtualizadoMenosDeUmAno ? 'Menos de um ano' : 'Um ano ou mais'}
    />
  )

  const isCadastroCompleto = !isUndefinedOrNull(microareasFilter?.isCadastroCompleto) && (
    <FilterItem label='Status do cadastro:' value={microareasFilter.isCadastroCompleto ? 'Completo' : 'Incompleto'} />
  )

  const isImovelVisitadoMenosDeUmMes = !isUndefinedOrNull(microareasFilter?.isImovelVisitadoMenosDeUmMes) && (
    <FilterItem
      label='Última visita há:'
      value={microareasFilter.isImovelVisitadoMenosDeUmMes ? 'Menos de um mês' : 'Um mês ou mais'}
    />
  )

  const tipoDomicilio = !isUndefinedOrNull(microareasFilter?.condicoesMoradia?.tipoDomicilio) && (
    <FilterItem
      label='Tipo de domicílio:'
      value={tipoDomicilioEnumRecord[microareasFilter.condicoesMoradia.tipoDomicilio]}
    />
  )

  const tipoImovel = !isUndefinedOrNull(microareasFilter?.tipoImovel) && (
    <FilterItem label='Tipo de imóvel:' value={tipoImovelEnumRecord[microareasFilter.tipoImovel]} />
  )

  const tipoMaterialParede = !isUndefinedOrNull(microareasFilter?.condicoesMoradia?.tipoMaterialParede) && (
    <FilterItem
      label='Material predominante:'
      value={tipoMaterialParedeEnumRecord[microareasFilter.condicoesMoradia.tipoMaterialParede]}
    />
  )

  const tipoAbastecimentoAgua = !isUndefinedOrNull(microareasFilter?.condicoesMoradia?.tipoAbastecimentoAgua) && (
    <FilterItem
      label='Abastecimento de água:'
      value={tipoAbastecimentoAguaEnumRecord[microareasFilter.condicoesMoradia.tipoAbastecimentoAgua]}
    />
  )

  const tipoTratamentoAgua = !isUndefinedOrNull(microareasFilter?.condicoesMoradia?.tipoTratamentoAgua) && (
    <FilterItem
      label='Água para consumo:'
      value={tipoTratamentoAguaEnumRecord[microareasFilter.condicoesMoradia.tipoTratamentoAgua]}
    />
  )

  const tipoDestinoLixo = !isUndefinedOrNull(microareasFilter?.condicoesMoradia?.tipoDestinoLixo) && (
    <FilterItem
      label='Destino do lixo:'
      value={tipoDestinoLixoEnumRecord[microareasFilter.condicoesMoradia.tipoDestinoLixo]}
    />
  )

  const tipoLocalizacao = !isUndefinedOrNull(microareasFilter?.condicoesMoradia?.tipoLocalizacao) && (
    <FilterItem label='Localização:' value={tipoLocalizacaoRecord[microareasFilter.condicoesMoradia.tipoLocalizacao]} />
  )

  const tipoEscoamentoSanitario = !isUndefinedOrNull(microareasFilter?.condicoesMoradia?.tipoEscoamentoSanitario) && (
    <FilterItem
      label='Escoamento de banheiro:'
      value={tipoEscoamentoSanitarioEnumRecord[microareasFilter.condicoesMoradia.tipoEscoamentoSanitario]}
    />
  )

  const isEnergiaEletricaDisponivel = !isUndefinedOrNull(
    microareasFilter?.condicoesMoradia?.isEnergiaEletricaDisponivel
  ) && (
    <FilterItem
      label='Possui energia elétrica?:'
      value={simNaoEnumToBoolean[microareasFilter.condicoesMoradia.isEnergiaEletricaDisponivel] ? 'Sim' : 'Não'}
    />
  )

  const tipoSituacaoMoradia = !isUndefinedOrNull(microareasFilter?.condicoesMoradia?.tipoSituacaoMoradia) && (
    <FilterItem
      label='Situação da moradia:'
      value={tipoSituacaoMoradiaEnumRecord[microareasFilter.condicoesMoradia.tipoSituacaoMoradia]}
    />
  )

  const tipoPosseTerra = !isUndefinedOrNull(microareasFilter?.condicoesMoradia?.tipoPosseTerra) && (
    <FilterItem
      label='Posse da terra:'
      value={tipoPosseTerraEnumRecord[microareasFilter.condicoesMoradia.tipoPosseTerra]}
    />
  )

  const possuiAnimais = !isUndefinedOrNull(microareasFilter?.animaisImovel?.possuiAnimais) && (
    <Fragment>
      <FilterItem
        label='Possui animais:'
        value={simNaoEnumToBoolean[microareasFilter.animaisImovel.possuiAnimais] ? 'Sim' : 'Não'}
      />
      {microareasFilter?.animaisImovel?.possuiAnimais && !isUndefinedOrNull(stringTiposAnimaisImovel) && (
        <FilterItem label='Tipos de animais no domicílio:' value={stringGrouper(...stringTiposAnimaisImovel)} />
      )}
    </Fragment>
  )

  return (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={true}>
      {isCadastroAtualizadoMenosDeUmAno}
      {isImovelVisitadoMenosDeUmMes}
      {isCadastroCompleto}
      {tipoMaterialParede}
      {tipoAbastecimentoAgua}
      {tipoTratamentoAgua}
      {tipoImovel}
      {tipoDestinoLixo}
      {tipoLocalizacao}
      {tipoEscoamentoSanitario}
      {tipoDomicilio}
      {isEnergiaEletricaDisponivel}
      {tipoSituacaoMoradia}
      {tipoPosseTerra}
      {possuiAnimais}
    </Grid>
  )
}
