import { calculateAge } from './calculateAge'

export const HOJE = 'Hoje'

export function humanizeDate(date: LocalDate | number, refDate: LocalDate | number = Date.now()) {
  const { years, months, days } = calculateAge(date, refDate)

  if (years === 0 && months === 0 && days === 0) {
    return HOJE
  } else if (years < 1 && months < 1) {
    return `${days} ${days === 1 ? 'dia' : 'dias'}`
  } else if (years < 1) {
    return `${months} ${months === 1 ? 'mês' : 'meses'}${days > 0 ? ` e ${days} ${days === 1 ? 'dia' : 'dias'}` : ''}`
  } else if (years >= 1 && months === 0) {
    if (days > 0) {
      return `${years} ${years === 1 ? 'ano' : 'anos'} e ${days} ${days === 1 ? 'dia' : 'dias'}`
    } else {
      return `${years} ${years === 1 ? 'ano' : 'anos'}`
    }
  } else {
    if (days > 0) {
      return `${years} ${years === 1 ? 'ano' : 'anos'}, ${months} ${months === 1 ? 'mês' : 'meses'} e ${days} ${
        days === 1 ? 'dia' : 'dias'
      }`
    } else {
      return `${years} ${years === 1 ? 'ano' : 'anos'} e ${months} ${months === 1 ? 'mês' : 'meses'}`
    }
  }
}

export function humanizeDateTime(date: LocalDate | number, refDate: LocalDate | number = Date.now()) {
  const { years, months, days, hours, minutes } = calculateAge(date, refDate)

  if (years === 0 && months === 0 && days === 0) {
    if (hours === 0) {
      return `${minutes} ${minutes <= 1 ? 'minuto' : 'minutos'}`
    } else {
      return `${hours} ${hours <= 1 ? 'hora' : 'horas'}`
    }
  } else {
    return humanizeDate(date, refDate)
  }
}
