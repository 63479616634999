import { Text, Tooltip } from 'bold-ui'
import { DateTime } from 'components/date'
import { format } from 'date-fns'
import React from 'react'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

const TooltipMedicao = (props: { medicoes: HistoricoMedicaoModel; children: React.ReactElement }) => (
  <Tooltip
    text={
      props.medicoes.dataRegistro
        ? props.medicoes.tipoMedicao === 'antecedente'
          ? `Medições ao nascer`
          : `Registrado por ${props.medicoes.nomeResponsavel} - ${format(
              props.medicoes.dataRegistro,
              'dd/MM/yy HH:mm'
            )}`
        : 'Registrado no atendimento atual'
    }
  >
    {props.children}
  </Tooltip>
)
export const renderMedicao = (medicaoGetter: (x: HistoricoMedicaoModel) => number | string) => (
  medicoes: HistoricoMedicaoModel
) => (
  <TooltipMedicao medicoes={medicoes}>
    <Text component='p'>{medicaoGetter(medicoes) ?? '-'}</Text>
  </TooltipMedicao>
)

export const renderDataHoraMedicao = (medicoes: HistoricoMedicaoModel) => (
  <TooltipMedicao medicoes={medicoes}>
    <Text component='p' style={{ margin: '0.5rem 0' }}>
      <DateTime
        value={medicoes.dataMedicao}
        format={`DD/MM/YYYY ${medicoes.tipoMedicao === 'anterior' ? '' : 'HH:mm'}`}
      />
      {medicoes.tipoMedicao === 'anterior' && (
        <Text component='span' style={{ fontStyle: 'italic' }}>
          Reg. tardio
        </Text>
      )}
    </Text>
  </TooltipMedicao>
)

export const renderDataMedicao = (medicoes: HistoricoMedicaoModel) => (
  <TooltipMedicao medicoes={medicoes}>
    <Text component='p' style={{ margin: '0.5rem 0' }}>
      <DateTime value={medicoes.dataMedicao} format={`DD/MM/YYYY`} />
    </Text>
  </TooltipMedicao>
)
