import { simNaoEnumToBoolean } from 'components/form'
import {
  ATUALIZADO_MAIS_DE_UM_ANO,
  ATUALIZADO_MENOS_DE_UM_ANO,
} from 'components/form/field/select/PeriodoUltimaAtualizacaoSelectField'
import {
  VISITA_MENOS_DE_UM_MES,
  VISITA_UM_MES_OU_MAIS,
} from 'components/form/field/select/PeriodoUltimaVisitaSelectField'
import { COMPLETO, INCOMPLETO } from 'components/form/field/select/StatusCadastroSelectField'
import { AnimaisDomicilioEnum, TerritorioEquipeQueryInput } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { AnimaisImovelFormModel } from 'view/cadastro-imovel/model-cadastroImovel'

import {
  AnimaisImovelFilterModel,
  LogradouroEditFormModel,
  LogradouroTerritorio,
  VisualizacaoMicroareasFilterFormModel,
  VisualizacaoMicroareasFilterModel,
  VisualizacaoMicroareaTerritorioInput,
  VisualizacaoTerritorialBuscaFormModel,
} from './model'

export const convertVisualizacaoFormModelToInput = (
  model: VisualizacaoTerritorialBuscaFormModel
): TerritorioEquipeQueryInput => {
  return {
    cnes: model.unidadeSaude?.cnes,
    ine: model.equipe?.ine,
    bairro: model.bairro,
    cep: model.cep,
    logradouro: model.logradouro,
    tipoLogradouroId: model.tipoLogradouro?.id,
    tipoEndereco: model.tipoEndereco,
    dseiId: model.dsei?.id,
    poloBaseId: model.poloBase?.id,
    aldeiaId: model.aldeia?.id,
  }
}

export const convertVisualizacaoMicroareaFilterToInput = (
  visualizacaoMicroareaFilter: VisualizacaoMicroareasFilterModel
): VisualizacaoMicroareaTerritorioInput => {
  let visualizacaoInput: VisualizacaoMicroareaTerritorioInput = {}

  visualizacaoInput =
    visualizacaoMicroareaFilter?.condicoesMoradia &&
    !Object.values(visualizacaoMicroareaFilter?.condicoesMoradia).every((value) => isUndefinedOrNull(value))
      ? {
          ...visualizacaoInput,
          condicoesMoradia: {
            ...visualizacaoMicroareaFilter?.condicoesMoradia,
            isEnergiaEletricaDisponivel:
              simNaoEnumToBoolean[visualizacaoMicroareaFilter?.condicoesMoradia?.isEnergiaEletricaDisponivel],
          },
        }
      : null

  if (visualizacaoMicroareaFilter?.animaisImovel) {
    visualizacaoInput = {
      ...visualizacaoInput,
      animaisImovel: {
        ...visualizacaoMicroareaFilter?.animaisImovel,
        possuiAnimais: simNaoEnumToBoolean[visualizacaoMicroareaFilter?.animaisImovel?.possuiAnimais],
      },
    }
  }

  return {
    ...visualizacaoInput,
    isCadastroAtualizadoMenosDeUmAno: visualizacaoMicroareaFilter?.isCadastroAtualizadoMenosDeUmAno,
    isCadastroCompleto: visualizacaoMicroareaFilter?.isCadastroCompleto,
    isImovelVisitadoMenosDeUmMes: visualizacaoMicroareaFilter?.isImovelVisitadoMenosDeUmMes,
    tipoImovel: visualizacaoMicroareaFilter?.tipoImovel,
  }
}

export const convertLogradouroDtoToLogradouroEditFormModel = (item: LogradouroTerritorio): LogradouroEditFormModel => {
  return {
    id: item.id,
    bairro: {
      nome: item.bairro,
    },
    tipoLogradouro: {
      __typename: 'TipoLogradouro',
      id: item.tipoLogradouroId,
      nome: item.tipoLogradouroNome,
      numeroDne: item.tipoLogradouroDne,
    },
    logradouro: item.logradouro,
    cep: item.cep,
    tipoEndereco: item.tipoEndereco,
    dsei: item.dsei,
    poloBase: item.poloBase,
    aldeia: item.aldeia,
  }
}

export const convertToInitialValuesMicroareasFilter = (
  microareasFilter: VisualizacaoMicroareasFilterModel
): VisualizacaoMicroareasFilterFormModel => {
  let initialValues: VisualizacaoMicroareasFilterFormModel = {
    isCadastroAtualizadoMenosDeUmAno: undefined,
    isCadastroCompleto: undefined,
    isImovelVisitadoMenosDeUmMes: undefined,
    animaisImovelFilter: undefined,
    condicoesMoradia: undefined,
    tipoImovel: undefined,
  }

  if (!isUndefinedOrNull(microareasFilter?.isCadastroAtualizadoMenosDeUmAno)) {
    initialValues = {
      ...initialValues,
      isCadastroAtualizadoMenosDeUmAno: microareasFilter.isCadastroAtualizadoMenosDeUmAno
        ? ATUALIZADO_MENOS_DE_UM_ANO
        : ATUALIZADO_MAIS_DE_UM_ANO,
    }
  }

  if (!isUndefinedOrNull(microareasFilter?.isCadastroCompleto)) {
    initialValues = {
      ...initialValues,
      isCadastroCompleto: microareasFilter.isCadastroCompleto ? COMPLETO : INCOMPLETO,
    }
  }

  if (!isUndefinedOrNull(microareasFilter?.isImovelVisitadoMenosDeUmMes)) {
    initialValues = {
      ...initialValues,
      isImovelVisitadoMenosDeUmMes: microareasFilter.isImovelVisitadoMenosDeUmMes
        ? VISITA_MENOS_DE_UM_MES
        : VISITA_UM_MES_OU_MAIS,
    }
  }

  if (!isUndefinedOrNull(microareasFilter?.condicoesMoradia)) {
    initialValues = {
      ...initialValues,
      condicoesMoradia: {
        ...microareasFilter?.condicoesMoradia,
      },
    }
  }

  if (!isUndefinedOrNull(microareasFilter?.animaisImovel)) {
    initialValues = {
      ...initialValues,
      animaisImovelFilter: convertAnimaisImovelFormModelToFilter(microareasFilter.animaisImovel),
    }
  }

  return {
    ...initialValues,
    tipoImovel: !isUndefinedOrNull(microareasFilter?.tipoImovel) ? microareasFilter.tipoImovel : undefined,
  }
}

export const convertAnimaisImovelFilterToFormModel = (
  animaisImovelFilter: AnimaisImovelFilterModel
): AnimaisImovelFormModel => {
  let animaisFormModel: AnimaisImovelFormModel

  const possuiAnimais = (animal) => {
    return animaisImovelFilter?.animaisNoImovel?.includes(animal) ? true : undefined
  }
  if (simNaoEnumToBoolean[animaisImovelFilter?.possuiAnimais] && animaisImovelFilter?.animaisNoImovel) {
    animaisFormModel = {
      possuiCachorro: possuiAnimais(AnimaisDomicilioEnum.CACHORRO),
      possuiGato: possuiAnimais(AnimaisDomicilioEnum.GATO),
      possuiPassaro: possuiAnimais(AnimaisDomicilioEnum.PASSARO),
      possuiGalinha: possuiAnimais(AnimaisDomicilioEnum.GALINHA),
      possuiMacaco: possuiAnimais(AnimaisDomicilioEnum.MACACO),
      possuiPorco: possuiAnimais(AnimaisDomicilioEnum.PORCO),
      possuiRepteis: possuiAnimais(AnimaisDomicilioEnum.REPTEIS),
      possuiOutros: possuiAnimais(AnimaisDomicilioEnum.OUTRO),
    }
  }

  return animaisImovelFilter && animaisImovelFilter?.possuiAnimais
    ? {
        ...animaisFormModel,
        possuiAnimais: animaisImovelFilter?.possuiAnimais,
      }
    : null
}

export const convertAnimaisImovelFormModelToFilter = (
  animaisImovelModel: AnimaisImovelFormModel
): AnimaisImovelFilterModel => {
  let animaisImovelFilter: AnimaisImovelFilterModel = {
    possuiAnimais: undefined,
    animaisNoImovel: undefined,
  }

  if (simNaoEnumToBoolean[animaisImovelModel.possuiAnimais]) {
    animaisImovelFilter = {
      ...animaisImovelFilter,
      animaisNoImovel: [
        animaisImovelModel.possuiCachorro ? AnimaisDomicilioEnum.CACHORRO : undefined,
        animaisImovelModel.possuiGato ? AnimaisDomicilioEnum.GATO : undefined,
        animaisImovelModel.possuiPassaro ? AnimaisDomicilioEnum.PASSARO : undefined,
        animaisImovelModel.possuiGalinha ? AnimaisDomicilioEnum.GALINHA : undefined,
        animaisImovelModel.possuiMacaco ? AnimaisDomicilioEnum.MACACO : undefined,
        animaisImovelModel.possuiPorco ? AnimaisDomicilioEnum.PORCO : undefined,
        animaisImovelModel.possuiRepteis ? AnimaisDomicilioEnum.REPTEIS : undefined,
        animaisImovelModel.possuiOutros ? AnimaisDomicilioEnum.OUTRO : undefined,
      ].filterNotNull(),
    }
  }

  return animaisImovelModel
    ? {
        ...animaisImovelFilter,
        possuiAnimais: animaisImovelModel.possuiAnimais,
      }
    : null
}
