import { HFlow, SelectMenuItem, Text } from 'bold-ui'
import { SelectDownshiftMenuProps } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import { SelectField } from 'components/form/final-form'
import { useFlags } from 'config/useFlagsContext'
import { CboCuidadoCompartilhadoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CboCuidadoCompartilhadoSelectFieldQuery,
  CboCuidadoCompartilhadoSelectFieldQueryVariables,
  CbosCuidadoCompartilhadoDto,
} from 'graphql/types.generated'
import { partition } from 'lodash'
import { Fragment, useMemo } from 'react'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'
import { CboCuidadoCompartilhadoRenderGrupoHeader } from './CboCuidadoCompartilhadoRenderGrupoHeader'

export type CboCuidadoCompartilhadoSelectModel = CbosCuidadoCompartilhadoDto & { id?: string }

interface CboCuidadoCompartilhadoSelectFieldProps extends AsyncSelectFieldProps<CboCuidadoCompartilhadoSelectModel> {
  isAtendimentoOdonto?: boolean
}

const ID_GRUPOS_CBOS_VINCULADOS = 'grupoCbosVinculados'
const ID_GRUPOS_CBOS_NAO_VINCULADOS = 'grupoCbosNaoVinculados'

const GRUPO_CBO_VINCULADOS_HEADER_ITEM: CboCuidadoCompartilhadoSelectModel = {
  id: ID_GRUPOS_CBOS_VINCULADOS,
  cbo: null,
  isVinculado: null,
}

const GRUPO_CBO_NAO_VINCULADOS_HEADER_ITEM: CboCuidadoCompartilhadoSelectModel = {
  id: ID_GRUPOS_CBOS_NAO_VINCULADOS,
  cbo: null,
  isVinculado: null,
}

export function CboCuidadoCompartilhadoSelectField(props: CboCuidadoCompartilhadoSelectFieldProps) {
  const { isAtendimentoOdonto = false, ...rest } = props
  const { PILOTO_VINCULACAO_CUIDADO_ENABLED } = useFlags()

  const {
    selectProps: { items, ...asyncPropsRest },
  } = useAsyncQuerySelect<
    CboCuidadoCompartilhadoSelectModel,
    CboCuidadoCompartilhadoSelectFieldQuery,
    CboCuidadoCompartilhadoSelectFieldQueryVariables
  >({
    query: CboCuidadoCompartilhadoSelectFieldDocument,
    extractItems: (data) => data?.cbosVinculacoesServico?.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        onlyEmulti: isAtendimentoOdonto,
        pageParams: pageParams,
      },
    }),
  })

  const [cbosComVinculacao, cbosSemVinculacao] = partition(items, (item) => item.isVinculado)

  const newItems: CboCuidadoCompartilhadoSelectModel[] = useMemo(
    () =>
      PILOTO_VINCULACAO_CUIDADO_ENABLED && !isAtendimentoOdonto
        ? [
            GRUPO_CBO_VINCULADOS_HEADER_ITEM,
            ...cbosComVinculacao,
            GRUPO_CBO_NAO_VINCULADOS_HEADER_ITEM,
            ...cbosSemVinculacao,
          ]
        : items,
    [PILOTO_VINCULACAO_CUIDADO_ENABLED, cbosComVinculacao, cbosSemVinculacao, isAtendimentoOdonto, items]
  )

  const hasCboVinculados = cbosComVinculacao.length > 0
  const hasCboNaoVinculados = cbosSemVinculacao.length > 0

  const Item = (
    itemProps: SelectDownshiftMenuProps<CboCuidadoCompartilhadoSelectModel> & {
      item: CboCuidadoCompartilhadoSelectModel
      index: number
    }
  ) => {
    const {
      index,
      downshift: { getItemProps, highlightedIndex },
      item,
      renderItem,
    } = itemProps

    const renderGrupoCbosVinculados = itemProps.item?.id === ID_GRUPOS_CBOS_VINCULADOS
    const renderGrupoCbosNaoVinculados = itemProps.item?.id === ID_GRUPOS_CBOS_NAO_VINCULADOS

    return (
      <Fragment>
        {renderGrupoCbosVinculados || renderGrupoCbosNaoVinculados ? (
          <CboCuidadoCompartilhadoRenderGrupoHeader
            isGrupoCboVinculado={renderGrupoCbosVinculados}
            itemProps={getItemProps({ item, disabled: true })}
            hasCboVinculados={hasCboVinculados}
            hasCboNaoVinculados={hasCboNaoVinculados}
          />
        ) : (
          <SelectMenuItem selected={highlightedIndex === index} {...getItemProps({ item })}>
            {renderItem(item)}
          </SelectMenuItem>
        )}
      </Fragment>
    )
  }
  return (
    <SelectField<CboCuidadoCompartilhadoSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      items={newItems}
      components={{
        Item,
      }}
      {...asyncPropsRest}
      {...rest}
    />
  )
}

const pageParams = {
  ...DEFAULT_SELECT_PAGE_PARAM,
  sort: ['nome'],
}

const itemToString = (item: CboCuidadoCompartilhadoSelectModel) => item?.cbo?.nome

const renderItem = (item: CboCuidadoCompartilhadoSelectModel) => (
  <Fragment>
    <Text fontWeight='bold'>{item.cbo.nome}</Text>
    <HFlow hSpacing={0.5}>
      <Text fontWeight='bold'>Código:</Text>
      {item.cbo.cbo2002}
    </HFlow>
  </Fragment>
)
