/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Tag, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import React from 'react'
import { formatDate } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import { MENSAGEM_FICHA_NAO_PROCESSADA_CIDADAO } from 'view/visualizacaoTerritorial/visualizacao-territorial-constants'

import { CidadaoCardModel } from '../model-cadastroImovel'

interface CidadaoFamiliaCardProps {
  cidadao: CidadaoCardModel
  isResponsavel: boolean
  buttonComponent?: React.ReactNode
  possuiFichaNaoProcessada: boolean
  errorMessage?: string
}

export const CidadaoFamiliaCard = (props: CidadaoFamiliaCardProps) => {
  const { cidadao, buttonComponent, possuiFichaNaoProcessada, isResponsavel = false, errorMessage = '' } = props

  const { cns, cpf, dataNascimento, nome, nomeSocial, sexo, mudouSe, registroObito } = cidadao

  const theme = useTheme()
  const style = createStyles(theme)

  return (
    <HFlow style={style.card} alignItems='center' justifyContent='space-between'>
      <VFlow vSpacing={0.5}>
        {(isResponsavel || registroObito || mudouSe) && (
          <HFlow hSpacing={0.5}>
            {isResponsavel && (
              <Tag icon='userFilled' style={style.tag}>
                Responsável familiar
              </Tag>
            )}

            {registroObito && <Tag>Óbito</Tag>}

            {mudouSe && <Tag>Mudou-se</Tag>}
          </HFlow>
        )}
        <HFlow hSpacing={0.2}>
          <Text fontWeight='bold'>{(nomeSocial ?? nome).titleCase()}</Text>
          {sexo !== 'IGNORADO' ? <Text>{` | ${sexo.titleCase()}`}</Text> : <Text>{' | Sexo não informado'}</Text>}
        </HFlow>
        <HFlow>
          {dataNascimento ? (
            <Text>
              {humanizeAge(dataNascimento)} | Nasceu em
              {` ${formatDate(dataNascimento)}`}
            </Text>
          ) : (
            <HLabel title='Data nascimento'>-</HLabel>
          )}
          {cpf ? (
            <HLabel title='CPF'>
              <Cpf value={cpf} />
            </HLabel>
          ) : (
            <HLabel title='CNS'>{cns}</HLabel>
          )}
        </HFlow>
        {errorMessage && <Text color='danger'>{errorMessage}</Text>}
        {possuiFichaNaoProcessada && (
          <HFlow hSpacing={0.25} alignItems='center'>
            <Icon icon='exclamationTriangleOutline' color={theme.pallete.status.info.main} />
            <Text color='info'>{MENSAGEM_FICHA_NAO_PROCESSADA_CIDADAO}</Text>
          </HFlow>
        )}
      </VFlow>
      {buttonComponent}
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  tag: css`
    color: ${theme.pallete.primary.c40};
    background-color: transparent;
    border: 1px solid;
  `,
  card: css`
    border: 1px solid ${theme.pallete.gray.c80};
    padding: 1rem;
  `,
})
