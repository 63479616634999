/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Dropdown,
  DropdownDivider,
  DropdownItem,
  focusBoxShadow,
  Heading,
  HFlow,
  Icon,
  Text,
  Theme,
  Tooltip,
  useTheme,
  VFlow,
} from 'bold-ui'
import { LogoutLinkWrapper } from 'components/auth/LogoutLinkWrapper'
import useSession from 'components/auth/useSession'
import { isEstagio, isLotacaoOrEstagio } from 'components/auth/useSessionUtils'
import { Ellipsis } from 'components/Ellipsis'
import { useErrorHandler } from 'components/error'
import { useFlags } from 'config/useFlagsContext'
import { useExibirNotificacaoNovidadesMutation, useUltimoAcessoQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { tipoAcesso } from 'types/enums'
import { humanizeDateTime } from 'util/date/humanize-date'

import { ReactComponent as AngleDownAlert } from '../../images/novidades/icons/angle_down_alert.svg'
import { ReactComponent as AngleUpAlert } from '../../images/novidades/icons/angle_up_alert.svg'
import { ReactComponent as NotificationCircle } from '../../images/novidades/icons/notification_circle.svg'

export function UserMenu() {
  const theme = useTheme()
  const styles = createStyles(theme)

  const [dropdownButtonRef, setDropdownButtonRef] = useState<HTMLDivElement>()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { data: session } = useSession({ fetchPolicy: 'cache-only' })
  const { analytics } = useFirebase()
  const handleRejection = useErrorHandler()
  const [exibeNotificacaoMutation] = useExibirNotificacaoNovidadesMutation()

  const { ULTIMOS_ACESSOS_ENABLED } = useFlags()

  const {
    data: { ultimoAcesso },
  } = useUltimoAcessoQuery({
    errorPolicy: 'ignore',
    skip: !ULTIMOS_ACESSOS_ENABLED,
  })

  const handleOnNovidadesDaVersaoClicked = () => {
    exibeNotificacaoMutation()
      .then(() => handleUserMenuClick('Novidades da versão'))
      .catch(handleRejection)
  }
  const handleUserMenuClick = (opcao: string) => {
    analytics.logEvent('user_menu_click', { modulo_selecionado: opcao })
  }
  const handleClose = () => setDropdownOpen(false)
  const handleClick = () => setDropdownOpen((state) => !state)
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      dropdownButtonRef.click()
      e.preventDefault()
    }
  }

  const { acesso } = session
  const nomeProfissional = session.profissional.nome
  const nomeCbo = isLotacaoOrEstagio(acesso) && acesso.cbo.nome
  const nomeSupervisor = isEstagio(acesso) && acesso.lotacaoSupervisora.profissional.nome
  const nomeUnidade = isLotacaoOrEstagio(acesso) && acesso.unidadeSaude.nome
  const nomeEquipe = isLotacaoOrEstagio(acesso) && acesso.equipe?.nome
  const tipoDoAcesso = !isLotacaoOrEstagio(acesso) && tipoAcesso[acesso.tipo]
  const notificaNovidades = session.profissional.usuario.notificaNovidadesVersao

  return (
    <Fragment>
      <div
        ref={setDropdownButtonRef}
        css={styles.user}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        aria-label='Menu do usuário'
        role='button'
      >
        <div css={[styles.accessContainer, styles.ellipsisContainer]}>
          <Tooltip text={nomeProfissional}>
            <Ellipsis maxWidth='24rem' component='p' color={theme.pallete.gray.c100} style={styles.ellipsis}>
              {nomeProfissional}
            </Ellipsis>
          </Tooltip>
          <HFlow hSpacing={0.2} justifyContent='flex-end'>
            <Tooltip text={nomeUnidade || tipoDoAcesso}>
              <Ellipsis
                fontWeight='bold'
                maxWidth='16rem'
                component='p'
                color={theme.pallete.gray.c100}
                style={styles.ellipsis}
              >
                {nomeUnidade || tipoDoAcesso}
              </Ellipsis>
            </Tooltip>
            {nomeEquipe && (
              <Fragment>
                <Text component='span' fontWeight='bold' style={styles.equipePipe}>
                  {'|'}
                </Text>
                <Tooltip text={nomeEquipe}>
                  <Ellipsis
                    fontWeight='bold'
                    maxWidth='8rem'
                    component='p'
                    color={theme.pallete.gray.c100}
                    style={styles.ellipsis}
                  >
                    {nomeEquipe}
                  </Ellipsis>
                </Tooltip>
              </Fragment>
            )}
          </HFlow>
        </div>
        <Text component='span' style={styles.indicator}>
          {notificaNovidades ? (
            dropdownOpen ? (
              <AngleUpAlert css={styles.notificationIcon} />
            ) : (
              <AngleDownAlert css={styles.notificationIcon} />
            )
          ) : dropdownOpen ? (
            <Icon icon='angleUp' />
          ) : (
            <Icon icon='angleDown' />
          )}
        </Text>
      </div>

      <Dropdown
        anchorRef={dropdownButtonRef}
        open={dropdownOpen}
        onClose={handleClose}
        popperProps={{ placement: 'bottom-end' }}
        style={css`
          min-width: ${dropdownButtonRef?.clientWidth}px;
          width: 15rem;
          padding: 0 0 calc(0.5rem - 1px) 0;
        `}
      >
        <div css={styles.dropdownContainer}>
          <VFlow vSpacing={0.5}>
            <HFlow hSpacing={0.5} alignItems='center'>
              <Icon icon='mapMarkerFilled' size={1} fill='primary' />
              <Heading level={4}>Acesso</Heading>
            </HFlow>
            <div css={styles.ellipsisContainer}>
              {nomeCbo && <Ellipsis>{nomeCbo.capitalize()}</Ellipsis>}
              {nomeEquipe && <Ellipsis>{nomeEquipe}</Ellipsis>}
              {nomeSupervisor && <Ellipsis>{`Supervisor ${nomeSupervisor}`}</Ellipsis>}
              <Ellipsis>{nomeUnidade || tipoDoAcesso}</Ellipsis>
              {ULTIMOS_ACESSOS_ENABLED &&
                ultimoAcesso?.data &&
                `Último acesso: Há ${humanizeDateTime(ultimoAcesso.data)}`}
            </div>
          </VFlow>
        </div>
        {session.profissional.acessos?.length > 1 && (
          <Fragment>
            <DropdownItem component={Link} to='/selecaoAcesso' data-cy='UserMenu.selecaoAcesso'>
              Trocar acesso
            </DropdownItem>
            {ULTIMOS_ACESSOS_ENABLED && (
              <DropdownItem
                component={Link}
                to='/historicoAcessos'
                onClick={() => handleUserMenuClick('Histórico de acessos')}
                data-cy='UserMenu.historico_acessos_PEC'
              >
                Ver histórico de acessos
              </DropdownItem>
            )}
            <DropdownDivider />
          </Fragment>
        )}

        <DropdownItem
          component={Link}
          to='/alterarSenha'
          data-cy='UserMenu.alterarSenha'
          onClick={() => handleUserMenuClick('Alterar senha')}
        >
          Alterar senha do e-SUS APS PEC
        </DropdownItem>
        <DropdownItem
          component={Link}
          to='/linksUteis'
          data-cy='UserMenu.liksUteis'
          onClick={() => handleUserMenuClick('Links úteis')}
        >
          Links úteis
        </DropdownItem>
        <DropdownItem
          component={Link}
          to='/novidades'
          data-cy='UserMenu.novidadesVersao'
          onClick={() => handleOnNovidadesDaVersaoClicked()}
        >
          <HFlow hSpacing={0.5} alignItems='center'>
            {notificaNovidades && <NotificationCircle />}
            Novidades da versão
          </HFlow>
        </DropdownItem>
        <DropdownItem
          component={Link}
          to='/sobre'
          data-cy='UserMenu.sobre'
          onClick={() => handleUserMenuClick('Sobre')}
        >
          Sobre
        </DropdownItem>
        <DropdownItem
          component={Link}
          to='/termosUso'
          data-cy='UserMenu.termoUso'
          onClick={() => handleUserMenuClick('Termos de uso')}
        >
          Termos de uso
        </DropdownItem>
        <DropdownItem
          component={Link}
          to='/escolhaModulo'
          data-cy='UserMenu.escolhaModulo'
          onClick={() => handleUserMenuClick('Alterar módulo inicial')}
        >
          Alterar módulo inicial
        </DropdownItem>
        <DropdownDivider />
        <LogoutLinkWrapper>
          <DropdownItem data-cy='UserMenu.sair'>
            <HFlow hSpacing={0.5} alignItems='center'>
              <Icon icon='openDoor' />
              <Text>Sair</Text>
            </HFlow>
          </DropdownItem>
        </LogoutLinkWrapper>
      </Dropdown>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  user: css`
    display: flex;
    align-items: center;
    padding: 0.75rem 0.5rem 0.75rem 2rem;
    transition: 0.2s all;
    &:hover {
      background: ${theme.pallete.primary.c20};
      cursor: pointer;
    }
    &:focus {
      outline: 0;
      border-radius: ${theme.radius.button}px;
      box-shadow: ${focusBoxShadow(theme, 'primary', 'inset')};
    }
  `,
  accessContainer: css`
    min-width: 10.5rem;
  `,
  ellipsisContainer: css`
    display: flex;
    flex-flow: column;
  `,
  userInfo: css`
    color: ${theme.pallete.gray.c100};
  `,
  dropdownContainer: css`
    padding: calc(0.5rem - 2px) 1rem;
    background-color: ${theme.pallete.surface.background};
  `,
  indicator: css`
    margin-left: 1rem;
    color: ${theme.pallete.gray.c100};
  `,
  notificationIcon: css`
    path:not(:last-child) {
      fill: ${theme.pallete.gray.c100};
    }
  `,
  ellipsis: css`
    text-align: right;
  `,
  equipePipe: css`
    color: ${theme.pallete.gray.c100};
  `,
})
