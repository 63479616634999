import { Text, Theme, useTheme, VFlow } from 'bold-ui'
import { FormSectionAccordion } from 'components/accordion/FormSectionAccordion'
import { Box } from 'components/Box'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import { css } from 'emotion'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { InformacoesSocioEconomicasFormModel } from '../model-cidadaoFci'
import { SimNaoAccordionItem } from './SimNaoAccordionItem'

interface InformacoesSocioEconomicasSectionProps {
  meta: MetaPath<InformacoesSocioEconomicasFormModel>
  values: InformacoesSocioEconomicasFormModel
}

export const InformacoesSocioEconomicasSection = ({ meta, values }: InformacoesSocioEconomicasSectionProps) => {
  const required =
    !!values?.alimentosAcabaramAntesTerDinheiroComprarMais || !!values?.comeuAlgunsAlimentosDinheiroAcabou

  const theme = useTheme()
  const style = createStyles(theme, required)

  return (
    <FormSectionAccordion
      name={meta.openedAccordion}
      title='Informações Socioeconômicas'
      uuid='informacoesSocioEconomicas'
      textStyle={css`
        font-size: 1rem;
      `}
    >
      <VFlow vSpacing={0.1}>
        <Box style={style.labelContainer}>
          {required ? (
            <RequiredLabel
              fontWeight='bold'
              label='Triagem para Risco de Insegurança Alimentar (TRIA)'
              style={style.labelText}
            />
          ) : (
            <Text style={style.labelText} fontWeight='bold'>
              Triagem para Risco de Insegurança Alimentar (TRIA)
            </Text>
          )}
        </Box>
        <SimNaoAccordionItem
          name={meta.alimentosAcabaramAntesTerDinheiroComprarMais}
          title='Nos últimos três meses, os alimentos acabaram antes que você tivesse dinheiro para comprar mais comida?'
          borderBottom
          required={required}
          clearable
          style={{
            hFlow: {
              marginLeft: '-2rem',
            },
          }}
        />
        <SimNaoAccordionItem
          name={meta.comeuAlgunsAlimentosDinheiroAcabou}
          title='Nos últimos três meses, você comeu apenas alguns alimentos que ainda tinha, porque o dinheiro acabou?'
          required={required}
          clearable
          style={{
            hFlow: {
              marginLeft: '-2rem',
            },
          }}
        />
      </VFlow>
    </FormSectionAccordion>
  )
}

const createStyles = (theme: Theme, required) => ({
  labelContainer: css`
    background-color: ${theme.pallete.gray.c90};
    padding: ${required ? '0px' : '5px'} 10px;
  `,
  labelText: css`
    font-size: 14px;
    margin-left: 6px;
    padding: 5px 0;
  `,
})
