import useSession from 'components/auth/useSession'
import { PageLoading } from 'components/loading'
import { useAtendimentoViewQuery } from 'graphql/hooks.generated'
import { StatusAtendimento, TipoAtendimentoProfissional } from 'graphql/types.generated'
import { AlertasSdcProvider } from 'hooks/sdc/useAlertasSdc'
import React from 'react'
import { useParams, useRouteMatch } from 'react-router'
import { Redirect } from 'react-router-dom'

import { urlFromRegistroTardio } from '../../registro-tardio/RegistroTardioRootView'
import { AtendimentoIndividualView } from '../atendimento-individual/AtendimentoIndividualView'
import { isResponsavelAtendimentoProfissional } from '../util'

export interface AtendimentoViewParams {
  atendimentoId: string
}

interface AtendimentoViewProps {
  isRetificacao?: boolean
}

export function AtendimentoView(props: AtendimentoViewProps) {
  const { isRetificacao = false } = props
  const { atendimentoId } = useParams<AtendimentoViewParams>()
  const { data, loading } = useAtendimentoViewQuery({ variables: { id: atendimentoId } })
  const { data: sessao } = useSession()
  const { url } = useRouteMatch()

  const fromRegistroTardio = urlFromRegistroTardio(url)
  const moduloInvalido =
    (fromRegistroTardio && !data.atendimento?.isRegistroTardio) ||
    (!fromRegistroTardio && data.atendimento?.isRegistroTardio)

  const statusInvalido =
    (!isRetificacao && data?.atendimento?.statusAtendimento === StatusAtendimento.ATENDIMENTO_REALIZADO) ||
    data?.atendimento?.statusAtendimento === StatusAtendimento.NAO_AGUARDOU

  const tipoInvalido =
    data?.atendimento?.atendimentoProfissional?.tipo === TipoAtendimentoProfissional.ESCUTA_INICIAL ||
    data?.atendimento?.atendimentoProfissional?.tipo === TipoAtendimentoProfissional.VACINACAO

  if (loading) {
    return <PageLoading message='Carregando atendimento...' />
  } else if (statusInvalido || tipoInvalido || !data?.atendimento?.atendimentoProfissional || moduloInvalido) {
    return <Redirect to='/404' />
  } else if (!isResponsavelAtendimentoProfissional(sessao.acesso, data?.atendimento?.atendimentoProfissional)) {
    return <Redirect to='/403' />
  } else {
    return (
      <AlertasSdcProvider
        cacheKey={`ALERTA_SDC|${atendimentoId}|${data?.atendimento?.atendimentoProfissional.lotacao.id}`}
      >
        <AtendimentoIndividualView atendimentoId={atendimentoId} isRetificacao={isRetificacao} />
      </AlertasSdcProvider>
    )
  }
}
