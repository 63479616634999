import { ConselhoClasseImpressao, PrinterType } from '../model-print'

export const convertDadosProfissionalPrint = (session: PrinterType) => {
  const acesso = session.acesso.__typename === 'Estagio' ? session.acesso.lotacaoSupervisora : session.acesso
  const profissional =
    session.acesso.__typename === 'Estagio' ? session.acesso.lotacaoSupervisora.profissional : session.profissional

  return {
    nomeProfissional: profissional.nome,
    nomeCbo: acesso.__typename === 'Lotacao' && acesso.cbo?.nome,
    nomeUnidadeSaude: acesso.__typename === 'Lotacao' && acesso.unidadeSaude.nome,
    nomeMunicipio: acesso.municipio.nome,
    municipioUf: acesso.municipio.uf,
    conselhoClasse:
      acesso.__typename === 'Lotacao' &&
      ({
        numero: profissional.numeroConselhoClasse,
        sigla: profissional.conselhoClasse?.sigla,
        uf: {
          sigla: profissional.ufEmissoraConselhoClasse?.sigla,
        },
      } as ConselhoClasseImpressao),
  }
}
