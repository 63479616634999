/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Text, VFlow } from 'bold-ui'
import { blue, gray } from 'bold-ui/lib/styles/colors'
import { InfoIcon } from 'components/InfoIcon'
import { formatDate } from 'util/date/formatDate'

import { ContentCardSection } from '../components/ContentCardSection'

interface AcompanhamentoVulnerabilidadeCardProps {
  dataRegistro: LocalDate
  isBeneficiarioBolsaFamilia: boolean
}

export const AcompanhamentoVulnerabilidadeCard = (props: AcompanhamentoVulnerabilidadeCardProps) => {
  const { dataRegistro, isBeneficiarioBolsaFamilia } = props

  return (
    <ContentCardSection>
      <VFlow vSpacing={0.5}>
        {isBeneficiarioBolsaFamilia && (
          <HFlow hSpacing={0.5} justifyContent='flex-start' alignItems='center'>
            <Icon icon='creditCardFilled' css={styles.icon} />
            <Text css={styles.title}>
              Beneficiário do programa Bolsa Família
              <InfoIcon icon='infoCircleOutline' text={BOLSA_FAMILIA_TOOLTIP_TEXT} css={styles.infoIcon} />
            </Text>
          </HFlow>
        )}

        <HFlow hSpacing={0.5} justifyContent='flex-start' alignItems='center'>
          <Icon icon='appleFilled' css={styles.icon} />
          <div css={styles.container}>
            <Text css={styles.title}>Risco para insegurança alimentar</Text>
            <Text>{`Observado em ${formatDate(dataRegistro)}`}</Text>
          </div>
        </HFlow>
      </VFlow>
    </ContentCardSection>
  )
}

const BOLSA_FAMILIA_TOOLTIP_TEXT =
  'Esse cidadão consta como beneficiário do bolsa família na vigência mais recente importada. Verifique o acompanhamento das condicionalidades de saúde.'

const styles = {
  icon: css`
    fill: ${gray.c40};
    width: 1.5rem;
    height: 1.5rem;
  `,
  infoIcon: css`
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    width: 1rem;
    height: 1rem;
    fill: ${blue.c40};
  `,
  title: css`
    font-weight: bold;
    margin-right: 0.375rem;
  `,
  container: css`
    display: flex;
    flex-wrap: wrap;
  `,
}
