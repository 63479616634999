import { createValidator, required } from 'util/validation'

import { AtividadeColetivaFormModel } from '../model-atividadeColetiva'
import { getAtividadeColetivaCommonValidationRules } from '../validator-atividadeColetivaCommonForm'

export const atividadeColetivaReuniaoFormValidator = (now: Date) =>
  createValidator<AtividadeColetivaFormModel>({
    ...getAtividadeColetivaCommonValidationRules(now),
    temasReuniao: [required],
  })
