import { alert } from 'components/alert'
import { FORM_ERROR } from 'final-form'
import { ErrorObject } from 'util/validation'

import { getValidationError, getValidationErrorMessage, isValidationError } from './util-error'

interface HandleErrorProps {
  error: any
  suppressNotificationError?: boolean
  suppressValidationError?: boolean
  supressFieldValidationNotificationError?: boolean
}

export const handleError = (props: HandleErrorProps) => {
  const {
    error,
    suppressNotificationError = false,
    suppressValidationError = false,
    supressFieldValidationNotificationError = false,
  } = props

  if (error?.graphQLErrors) {
    error.graphQLErrors.forEach((graphqlError) =>
      onGraphQLError(graphqlError, suppressNotificationError, supressFieldValidationNotificationError)
    )

    const firstError = error.graphQLErrors[0]
    if (!suppressValidationError) {
      return handleValidationError(firstError)
    }
  }

  if (error?.networkError) {
    onNetworkError(error.networkError)
  }

  if (!error?.graphQLErrors && !error?.networkError) {
    onGenericError(error)
  }
}

export const handleValidationError = (error: any): ErrorObject<any> => {
  const validationError = isValidationError(error) && getValidationError(error)

  if (validationError) {
    if (typeof validationError === 'string') {
      return { [FORM_ERROR]: validationError }
    } else {
      let message = Object.values(validationError)[0]
      while (typeof message !== 'string') {
        if (Object.keys(message).length === 1) {
          message = Object.values(message)[0]
        } else {
          message = error.message
        }
      }
      return { ...validationError, [FORM_ERROR]: message }
    }
  } else {
    return { [FORM_ERROR]: 'Ocorreu um erro inesperado.' }
  }
}

const onGraphQLError = (
  error: any,
  suppressNotification: boolean,
  supressFieldValidationNotificationError: boolean
) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('[GraphQL error]', error)
  }

  if (!suppressNotification) {
    if (isValidationError(error)) {
      alert('danger', getValidationErrorMessage(error, supressFieldValidationNotificationError))
    } else if (error.message) {
      alert('danger', error.message)
    } else {
      alert('danger', 'Ocorreu um erro inesperado')
      throw error
    }
  }
}

const onNetworkError = (error: any) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('[Network error]', error)
  }

  alert('danger', 'Não foi possível se conectar ao servidor. Cheque sua conexão e tente novamente.')
}

const onGenericError = (error: any) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('[Error]', error)
  }

  alert('danger', error?.response?.data ?? error?.message ?? error)
}
