/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { Ellipsis } from 'components/Ellipsis'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'
import { useCallback } from 'react'

export interface SdcAvisosCardProps {
  alerta: AlertaSuporteDecisaoClinica
  onClick(AlertaSuporteDecisaoClinica): void
  selecionado: boolean
}

export const SdcAvisosCard = (props: SdcAvisosCardProps) => {
  const { alerta, onClick, selecionado = false } = props

  const theme = useTheme()
  const styles = createStyles(theme, selecionado)
  const handleClick = useCallback(() => onClick(alerta), [onClick, alerta])

  return (
    <div onClick={handleClick}>
      <HFlow style={styles.wrapper} alignItems='center' justifyContent='space-between'>
        <VFlow vSpacing={0}>
          <HFlow alignItems='baseline'>
            {!alerta.visualizado && <div css={styles.marker} />}
            <Text fontWeight='bold'>{`${alerta.titulo}:`}</Text>
          </HFlow>
          <Text>
            <Ellipsis maxLines={2}>{alerta.descricao}</Ellipsis>
          </Text>
        </VFlow>
        <Icon icon='angleRight' />
      </HFlow>
    </div>
  )
}

const createStyles = (theme: Theme, selecionado: boolean) => ({
  marker: css`
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
    background-color: ${theme.pallete.primary.main};
  `,
  wrapper: css`
    border: 1px solid;
    border-color: ${selecionado ? theme.pallete.primary.main : theme.pallete.gray.c100};
    background-color: ${selecionado ? theme.pallete.status.info.c90 : theme.pallete.gray.c100};
    padding: 1rem;
    width: 18rem;
    height: 6rem;
    cursor: pointer;
  `,
})
