import { useLocalStorage } from 'hooks/useStorage'
import { useCallback } from 'react'

export function useListaAtendimentoActionsLoading() {
  const [isLoading, setIsLoading] = useLocalStorage<boolean>('lista-atendimento-loading', { defaultValue: false })

  const startLoading = useCallback(() => setIsLoading(true), [setIsLoading])
  const stopLoading = useCallback(() => setIsLoading(false), [setIsLoading])

  return { isActionsLoading: isLoading(), startLoading, stopLoading }
}
