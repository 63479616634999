import { Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { FooterButton } from 'components/footer-button'
import { ErrorField, FormPrompt } from 'components/form'
import { CboCuidadoCompartilhadoSelectField } from 'components/form/field/select/CboCuidadoCompartilhadoSelectField/CboCuidadoCompartilhadoSelectField'
import { useFlags } from 'config/useFlagsContext'
import { FORM_ERROR, FormApi } from 'final-form'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { Fragment, useMemo, useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import { emptyArray } from 'util/array'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath } from 'util/metaPath'

import {
  CuidadoCompartilhadoPlanoModel,
  EDITAR_CUIDADO_COMPARTILHADO_URL,
  TipoCompartilhamentoCuidadoPlanoEnum,
} from '../model-cuidadocompartilhado'
import { CuidadoCompartilhadoForm } from './CuidadoCompartilhadoForm'
import { CuidadoCompartilhadoFormFields } from './CuidadoCompartilhadoFormFields'

interface EditarCuidadoCompartilhadoModalFormProps {
  compartilhamentosCuidado: CuidadoCompartilhadoPlanoModel[]
  isAtendimentoOdonto: boolean
  onSubmit: (values: CuidadoCompartilhadoPlanoModel) => void
}

const path = metaPath<CuidadoCompartilhadoPlanoModel>()

export const EditarCuidadoCompartilhadoModalForm = (props: EditarCuidadoCompartilhadoModalFormProps) => {
  const { compartilhamentosCuidado, isAtendimentoOdonto = false, onSubmit } = props

  const { id } = useParams<{ id: string }>()
  const match = useRouteMatch()
  const history = useHistory()
  const alert = useAlert()
  const { analytics } = useFirebase()
  const { PILOTO_VINCULACAO_CUIDADO_ENABLED } = useFlags()

  const [disabled, setDisabled] = useState<boolean>(false)

  const itemToEdit: CuidadoCompartilhadoPlanoModel = useMemo(
    () => (compartilhamentosCuidado || emptyArray).find((cuidado) => cuidado._id === id),
    [compartilhamentosCuidado, id]
  )

  const encaminhamentos = useMemo(
    () =>
      Array.isArray(compartilhamentosCuidado)
        ? compartilhamentosCuidado?.filter(
            (item) => item.tipo === TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO
          )
        : emptyArray,
    [compartilhamentosCuidado]
  )

  const handleClose = () => {
    history.push(match.url.split(EDITAR_CUIDADO_COMPARTILHADO_URL)[0])
  }

  const handleSubmit = (values: CuidadoCompartilhadoPlanoModel, form: FormApi<CuidadoCompartilhadoPlanoModel>) => {
    onSubmit({ ...values })
    if (values.tipo === TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO) {
      alert('success', 'Guia de encaminhamento alterada com sucesso.')
    } else {
      alert('success', 'Cuidado compartilhado alterado com sucesso.')
    }
  }

  const onSubmitSucceeded = () => {
    analytics.logEvent('CC_editado')
    handleClose()
  }

  if (isUndefinedOrNull(itemToEdit)) {
    handleClose()
  }

  const renderForm = (renderProps: FormRenderProps<CuidadoCompartilhadoPlanoModel>) => {
    return (
      <Fragment>
        <FormPrompt />
        <Modal open onClose={handleClose}>
          <ModalBody>
            <VFlow>
              <Heading level={1}>Editar compartilhamento de cuidado</Heading>
              {PILOTO_VINCULACAO_CUIDADO_ENABLED && !isAtendimentoOdonto ? (
                <VFlow>
                  <Grid>
                    <Cell size={6}>
                      <CboCuidadoCompartilhadoSelectField name={path.cbo} label='Compartilhar com a CBO' required />
                    </Cell>
                  </Grid>

                  <ErrorField name={FORM_ERROR} inline />

                  <CuidadoCompartilhadoFormFields
                    path={path}
                    isAtendimentoOdonto={isAtendimentoOdonto}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    isEdit
                  />
                </VFlow>
              ) : (
                <CuidadoCompartilhadoFormFields path={path} isAtendimentoOdonto={isAtendimentoOdonto} isEdit />
              )}
            </VFlow>
          </ModalBody>
          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <FooterButton kind='normal' onClick={handleClose}>
                Cancelar
              </FooterButton>
              <FooterButton kind='primary' onClick={renderProps.handleSubmit}>
                Salvar
              </FooterButton>
            </HFlow>
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }

  return (
    <CuidadoCompartilhadoForm
      path={path}
      initialValues={itemToEdit}
      render={renderForm}
      onSubmit={handleSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
      encaminhamentos={encaminhamentos}
      isAtendimentoOdonto={isAtendimentoOdonto}
      isEdit
    />
  )
}
