import api from 'api'
import { defaultHandleError, downloadFile } from 'util/downloadFile'

import { ErrosRndsInput } from './model-rndsErros'

export const downloadRelatorioErrosRndsCsv = (input: ErrosRndsInput) => {
  return api.rnds
    .gerarRelatorioErrosRnds(input)
    .then((responseBody) => {
      downloadFile(new Blob([responseBody.data]), `relatorio-erros-rnds.csv`)
    })
    .catch(defaultHandleError)
}
