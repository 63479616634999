/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Text, VFlow } from 'bold-ui'
import moment from 'moment'
import { PeriodoGestacaoModel } from 'view/atendimentos/detail/components/modals/types/PeriodoGestacaoModel'
import { CidadaoMedicoes } from 'view/atendimentos/detail/soap/aside/medicoes/model'

import { MedicaoProntuarioModel } from '../../types/MedicaoModel'
import { AlertImc } from './AlertImc'

interface MedicoesDescriptionProps {
  values: ReadonlyArray<MedicaoProntuarioModel>
  unidadeMedida?: string
  isMedicaoAnterior?: boolean
  isIMC?: boolean
  cidadao: CidadaoMedicoes
  gestacoes: ReadonlyArray<PeriodoGestacaoModel>
}

const asFormatDate = (val) => moment(val).format('DD/MM/YYYY')
const asFormatTime = (val) => moment(val).format('HH[h]mm[min]')
const asFormatDateTime = (val) => moment(val).format('DD/MM/YYYY | HH[h]mm[min]')

export const MedicoesDescription = (props: MedicoesDescriptionProps) => {
  const { values, unidadeMedida, isMedicaoAnterior, isIMC, cidadao, gestacoes } = props

  return (
    <VFlow vSpacing={0}>
      {values.map((item, index, array) => (
        <Grid key={item.key} gap={1} gapVertical={0} alignItems='flex-start' justifyContent='flex-start'>
          <Cell>
            <Text>
              {`${item.valor} ${unidadeMedida || ''}`}
              <span>
                {' '}
                {`${item.observacao ? '(' + item.observacao + ')' : ''} 
                  ${!isMedicaoAnterior && array.length > 1 ? `- ${asFormatTime(item.dataMedicao)}` : ''}
                  ${
                    isMedicaoAnterior
                      ? array.length > 1
                        ? `- ${asFormatDateTime(item.dataMedicao)}`
                        : `- ${asFormatDate(item.dataMedicao)}`
                      : ''
                  }
                `}
              </span>
            </Text>
          </Cell>

          {isIMC && index === array.length - 1 && (
            <Cell>
              <AlertImc
                imc={item.valor}
                dataMedicao={item.dataMedicao}
                dataNascimento={cidadao.dataNascimento}
                sexo={cidadao.sexo}
                identidadeGenero={cidadao.identidadeGeneroDbEnum}
                gestacoes={gestacoes}
                serializedStyles={styles}
              />
            </Cell>
          )}
        </Grid>
      ))}
    </VFlow>
  )
}

const styles = {
  alertWrapper: css`
    background-color: transparent;
    border-width: 0;
    padding: 0;
    min-height: 0;
  `,
}
