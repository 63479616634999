import { parseISO } from 'date-fns'
import moment from 'moment'
import { createValidator, richTextMaxLength } from 'util/validation'
import { MSG_ERRO_PREENCHIMENTO_OBRIGATORIO } from 'util/validation/messages/model'
import { isEmpty, isValid } from 'util/validation/Util'
import { medicoesPanelValidator } from 'view/atendimentos/components/MedicoesPanel/validator'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { FormAtivoObjetivoEnum, SwitchButtonObjetivoFormModel } from './components/SwitchButtonObjetivoForm'
import { marcadoresConsumoAlimentarValidator } from './marcadores-consumo-alimentar/validator'
import { ObjetivoFormModel } from './ObjetivoForm'
import { puericulturaValidator } from './puericultura/validator'

export const objetivoValidator = (
  cidadao: CidadaoAtendimento,
  dataAtendimento: Instant,
  hasIvcfPreenchidoEmAtendObservacaoAnterior: boolean
) =>
  createValidator<ObjetivoFormModel>(
    {
      texto: [richTextMaxLength(4000)],
      atendimentosEspecificos: atendimentosEspecificosValidator(cidadao.dataNascimento, dataAtendimento),
      medicoes: medicoesPanelValidator,
      marcadoresConsumoAlimentar: marcadoresConsumoAlimentarValidator(
        parseISO(cidadao?.dataNascimento),
        new Date(dataAtendimento)
      ),
    },
    (values, error) => {
      if (cidadao.informarDum && values?.dum && isValid(error.dum)) {
        if (moment(values?.dum).isBefore(cidadao.dataNascimento)) {
          error.dum = 'Deve ser posterior à data de nascimento do cidadão'
        } else if (moment(values?.dum).isAfter(dataAtendimento)) {
          error.dum = 'Deve ser anterior à data do atendimento'
        }
      }

      const isBotaoIvcfHabilitado = values.atendimentosEspecificos?.formAtivo === FormAtivoObjetivoEnum.IVCF
      const isIvcfNaoPreenchido = isEmpty(values.atendimentosEspecificos?.ivcf)

      if (isBotaoIvcfHabilitado && isIvcfNaoPreenchido && !hasIvcfPreenchidoEmAtendObservacaoAnterior) {
        error.atendimentosEspecificos = {
          ivcf: MSG_ERRO_PREENCHIMENTO_OBRIGATORIO,
        }
      } else if (values.atendimentosEspecificos?.ivcf?.isEditing) {
        error.atendimentosEspecificos = {
          ivcf: 'Os campos do IVCF-20 estão em edição, salve ou cancele para continuar.',
        }
      }
      return error
    }
  )

const atendimentosEspecificosValidator = (dataNascimento: LocalDate, dataAtendimento: Instant) =>
  createValidator<SwitchButtonObjetivoFormModel>({
    puericultura: puericulturaValidator(dataNascimento, dataAtendimento),
  })
