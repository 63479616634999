/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HeadingSection, HFlow, Icon, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { ButtonLink } from 'components/route'
import { useSolicitacoesExameUnpagedQuery } from 'graphql/hooks.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { isEmpty } from 'lodash'
import { Fragment, useMemo } from 'react'
import { useRouteMatch } from 'react-router'
import { MetaArray } from 'util/metaPath'
import { getEditAtendProfId } from 'view/atendimentos/atendimento-individual/util-atendimentoIndividual'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { grupoCboSolicitacaoExame } from '../acessos'
import { convertSolicitacaoFromAtendToSolicitacaoWithInfo } from './converter'
import { downloadSolicitacaoExames } from './DownloadSolicitacaoExames'
import SolicitacaoExamesTable from './ExamesTable'
import { SolicitacaoExameModel } from './model'
import {
  SolicitarExamesRootView,
  urlSolicitacaoExameAltoCusto,
  urlSolicitacaoExameComum,
} from './SolicitarExamesRootView'
import { convertHistoricoSolicitacaoExameToImpressao, joinSolicitacoesExame } from './utils'

export interface ExamesViewProps {
  name: MetaArray<SolicitacaoExameModel>
  cidadao: CidadaoAtendimento
  prontuarioId: ID
  atendimentoId: ID
  atendimentoProfissional: AtendimentoProfissional
  isObservacaoAndAuxiliar: boolean
}

export function ExamesView(props: ExamesViewProps) {
  const { name, cidadao, prontuarioId, atendimentoId, atendimentoProfissional, isObservacaoAndAuxiliar } = props

  const match = useRouteMatch()

  const alert = useAlert()

  const { analytics } = useFirebase()

  const {
    observacao: { isAtendimentoObservacao },
    tiposAtendimento: { isAtendimentoProcedimentos },
  } = useAtendimentoContext()

  const {
    handleSubmit,
    removeItem,
    handleRowChanged,
    input: { value: solicitacoesExameFromAtend },
  } = useEditableListField<SolicitacaoExameModel>({
    name: name,
  })

  const editAtendProfId = getEditAtendProfId(isAtendimentoObservacao, atendimentoProfissional.id)

  const {
    data: { solicitacoesExame },
    loading,
  } = useSolicitacoesExameUnpagedQuery({
    variables: {
      input: {
        prontuarioId,
        editAtendProfId,
      },
    },
  })

  const hasSolicitacaoExames = !isEmpty(solicitacoesExameFromAtend)

  const itemsToPrint = convertSolicitacaoFromAtendToSolicitacaoWithInfo(solicitacoesExameFromAtend, {
    dataReferencia: atendimentoProfissional.iniciadoEm,
    atendimentoProfissionalId: atendimentoProfissional.id,
    lotacao: atendimentoProfissional.lotacao,
  })

  const allContent = useMemo(
    () =>
      joinSolicitacoesExame(solicitacoesExame?.content, solicitacoesExameFromAtend, {
        dataReferencia: atendimentoProfissional.iniciadoEm,
        atendimentoProfissionalId: atendimentoProfissional.id,
        lotacao: atendimentoProfissional.lotacao,
      }),
    [
      atendimentoProfissional.id,
      atendimentoProfissional.iniciadoEm,
      atendimentoProfissional.lotacao,
      solicitacoesExame,
      solicitacoesExameFromAtend,
    ]
  )

  const handleImpressao = (cidadaoId: ID, prontuarioId: ID, atendimentoId?: ID) => {
    analytics.logEvent('imprimir_solicitacoes_EXM')
    downloadSolicitacaoExames(
      convertHistoricoSolicitacaoExameToImpressao(itemsToPrint, prontuarioId, atendimentoId, cidadaoId),
      () => alert('success', `Geração da impressão de solicitações de exames ocorreu com sucesso.`)
    ).catch((_) => alert('danger', 'Falha na impressão da solicitação de exame'))
  }

  return (
    <Fragment>
      <HeadingSection level={4} vSpace={8} title='Solicitação de exames' />
      <VFlow>
        {!isAtendimentoProcedimentos && (
          <CheckPermission permission={grupoCboSolicitacaoExame.adicionar}>
            <HFlow hSpacing={0.5}>
              <ButtonLink type='button' kind='primary' size='small' to={`${match.url}${urlSolicitacaoExameComum}`}>
                <Icon
                  icon='plus'
                  style={css`
                    margin-right: 0.5rem;
                  `}
                />
                Adicionar exame comum
              </ButtonLink>
              <ButtonLink
                type='button'
                kind='primary'
                skin='outline'
                size='small'
                to={`${match.url}${urlSolicitacaoExameAltoCusto}`}
              >
                <Icon
                  icon='plus'
                  style={css`
                    margin-right: 0.5rem;
                  `}
                />
                Adicionar exame de alto custo
              </ButtonLink>
              <Tooltip
                text={
                  !hasSolicitacaoExames
                    ? 'Não foram realizadas solicitações de exames neste atendimento. '
                    : 'Imprimir todas as solicitações deste atendimento.'
                }
                placement='bottom'
              >
                <Button
                  kind='normal'
                  size='small'
                  onClick={() => handleImpressao(cidadao.id, prontuarioId, atendimentoId)}
                  disabled={!hasSolicitacaoExames}
                >
                  <Icon
                    icon='printerOutline'
                    style={css`
                      margin-right: 0.5rem;
                    `}
                  />
                  Imprimir
                </Button>
              </Tooltip>
            </HFlow>
          </CheckPermission>
        )}
        <SolicitarExamesRootView
          cidadao={cidadao}
          handleSubmit={handleSubmit}
          handleUpdate={handleRowChanged}
          adicionados={solicitacoesExameFromAtend}
          existentes={solicitacoesExame?.content}
          iniciadoEm={atendimentoProfissional.iniciadoEm}
        />

        <SolicitacaoExamesTable
          atendimentoId={atendimentoId}
          prontuarioId={prontuarioId}
          removeItem={removeItem}
          loading={loading}
          cidadaoId={cidadao.id}
          dataReferencia={atendimentoProfissional.iniciadoEm}
          lotacao={atendimentoProfissional.lotacao}
          isObservacaoAndAuxiliar={isObservacaoAndAuxiliar}
          items={allContent}
          solicitacoesExameFromAtend={solicitacoesExameFromAtend}
        />
      </VFlow>
    </Fragment>
  )
}
