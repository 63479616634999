import { NacionalidadeEnum, RacaCorDbEnum } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import {
  afterEqualTo,
  alfaNumerico,
  beforeEqualToday,
  cns,
  composeRules,
  cpf,
  createValidator,
  dataNasc,
  empty,
  ErrorObject,
  length,
  maxLength,
  nome,
  required,
  simpleName,
} from 'util/validation'

import {
  CidadaoFciFormModel,
  DadosPessoaisFormModel,
  InformacoesSocioEconomicasFormModel,
  NacionalidadeFormModel,
} from './model-cidadaoFci'

export const dadosPessoaisCidadaoFciValidator = () =>
  createValidator<DadosPessoaisFormModel>(
    {
      cpf: cpf,
      cns: cns,
      sexo: [required],
      racaCor: [required],
      nisPisPasep: [length(11)],
      dataNascimento: [required, dataNasc],
      nome: [required, nome(3, 70)],
      nomeSocial: [simpleName, maxLength(70)],
    },
    (values: DadosPessoaisFormModel, errors: ErrorObject<DadosPessoaisFormModel>) => {
      if (values.desconheceNomeMae !== true) {
        errors.nomeMae = composeRules([required, nome(3, 70)])(values.nomeMae)
      } else {
        errors.nomeMae = empty(values.nomeMae)
      }

      if (values.desconheceNomePai !== true) {
        errors.nomePai = composeRules([required, nome(3, 70)])(values.nomePai)
      } else {
        errors.nomePai = empty(values.nomePai)
      }

      if (values.racaCor?.value !== RacaCorDbEnum.INDIGENA && values.etnia) {
        errors.etnia = empty(values.etnia)
      }

      if (values.racaCor?.value === RacaCorDbEnum.INDIGENA) {
        errors.etnia = required(values.etnia)
      }

      if (values.faleceu) {
        errors.dataObito = dataObitoComposedRule(
          values.dataObito,
          values.dataNascimento,
          values.nacionalidadeCidadao.dataEntradaPais,
          values.nacionalidadeCidadao.dataNaturalizacao
        )
      } else {
        errors.dataObito = empty(values.dataObito)
        errors.numeroDeclaracaoObito = empty(values.numeroDeclaracaoObito)
      }

      errors.nacionalidadeCidadao = nacionalidadeFciValidator(values.dataNascimento)(values.nacionalidadeCidadao)

      return errors
    }
  )

export const nacionalidadeFciValidator = (dataNascimento: string) =>
  createValidator<NacionalidadeFormModel>(
    {
      nacionalidade: [required],
    },
    (values: NacionalidadeFormModel, errors: ErrorObject<NacionalidadeFormModel>) => {
      if (values.nacionalidade === NacionalidadeEnum.BRASILEIRA) {
        errors.municipioNascimento = required(values.municipioNascimento)
      }

      if (values.nacionalidade === NacionalidadeEnum.NATURALIZADA) {
        errors.portariaNaturalizacao = composeRules([required, maxLength(16), alfaNumerico])(
          values.portariaNaturalizacao
        )
        errors.dataNaturalizacao = composeRules([
          required,
          beforeEqualToday,
          checkIsAfterEqualNascimento(values.dataNaturalizacao, dataNascimento),
        ])(values.dataNaturalizacao)
      }

      if (values.nacionalidade === NacionalidadeEnum.ESTRANGEIRA) {
        errors.paisNascimento = required(values.paisNascimento)
        errors.dataEntradaPais = composeRules([
          required,
          beforeEqualToday,
          checkIsAfterEqualNascimento(values.dataEntradaPais, dataNascimento),
        ])(values.dataEntradaPais)
      }

      return errors
    }
  )

export const informacoesSocioEconomicasValidator = () =>
  createValidator<InformacoesSocioEconomicasFormModel>(
    {},
    (values: InformacoesSocioEconomicasFormModel, errors: ErrorObject<InformacoesSocioEconomicasFormModel>) => {
      if (
        !isUndefinedOrNull(values?.alimentosAcabaramAntesTerDinheiroComprarMais) ||
        !isUndefinedOrNull(values?.comeuAlgunsAlimentosDinheiroAcabou)
      ) {
        errors.alimentosAcabaramAntesTerDinheiroComprarMais = required(
          values.alimentosAcabaramAntesTerDinheiroComprarMais
        )
        errors.comeuAlgunsAlimentosDinheiroAcabou = required(values.comeuAlgunsAlimentosDinheiroAcabou)
      }

      return errors
    }
  )

export const cidadaoFciValidator = () =>
  createValidator<CidadaoFciFormModel>({
    dadosPessoais: [dadosPessoaisCidadaoFciValidator()],
    informacoesSocioEconomicas: [informacoesSocioEconomicasValidator()],
  })

const checkIsAfterEqualNascimento = (data: string, dataNascimento: string) => () =>
  afterEqualTo(data, dataNascimento, 'data nascimento')

const dataObitoComposedRule = (
  dataObito: string,
  dataNascimento: string,
  dataEntradaPais?: string,
  dataNaturalizacao?: string
) => {
  return composeRules([
    required,
    beforeEqualToday,
    checkIsAfterEqualNascimento(dataObito, dataNascimento),
    () => afterEqualTo(dataObito, dataEntradaPais, 'data de entrada no país'),
    () => afterEqualTo(dataObito, dataNaturalizacao, 'data de naturalização'),
  ])(dataObito)
}
