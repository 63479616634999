import { convertLogradouro } from 'components/form'
import { ProfissionalEditQuery, ProfissionalInput } from 'graphql/types.generated'

import ProfissionalFormModel from './components/ProfissionalFormModel'

export default ({ ...model }: ProfissionalFormModel): ProfissionalInput => ({
  ...model,
  conselhoClasse: model.conselhoClasse?.id,
  ufEmissoraConselhoClasse: model.ufEmissoraConselhoClasse?.id,
  endereco: model.endereco && {
    ...model.endereco,
    municipio: model.endereco.municipio?.id,
    bairro: model.endereco.bairro?.nome,
    tipoLogradouro: model.endereco.tipoLogradouro?.id,
    logradouro: convertLogradouro(model.endereco.logradouro),
    uf: model.endereco.uf?.id,
  },
})

export function convertToForm(profissional: ProfissionalEditQuery['profissional']): ProfissionalFormModel {
  const { lotacoes, estagios, nome, ...rest } = profissional
  return {
    ...rest,
    endereco: {
      ...rest?.endereco,
      bairro: rest?.endereco?.bairro && { nome: rest?.endereco?.bairro },
      logradouro: rest?.endereco?.logradouro && { nome: rest?.endereco?.logradouro },
    },
  }
}
