import { HFlow, Icon, PagedTable, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { DateTime } from 'components/date'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useLoadAtestadosQuery } from 'graphql/hooks.generated'
import { AtestadoQueryInput } from 'graphql/types.generated'
import { noop } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { emptyArray } from 'util/array'
import { getEditAtendProfId } from 'view/atendimentos/atendimento-individual/util-atendimentoIndividual'
import { CidadaoFolhaRosto, LotacaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import AtestadoModel from '../components/model-atestado'
import { AtestadoFilter } from './AtestadoFilter'
import AtestadoTableButtons from './AtestadoTableButtons'
import { convertAtestadosFromDatabase, convertRecentes, filterAtestados } from './formatAtestados'

export interface AtestadoTableProps {
  prontuarioId: ID
  atendimentoId: ID
  dataReferencia: Instant
  cidadao: CidadaoAtendimento | CidadaoFolhaRosto
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
  isAtendimentoObservacao?: boolean
  atendimentoProfissionalId?: ID
  atestadosRecentes?: AtestadoModel[]
  remove?(index: number): void
  update?(index: number, value: AtestadoModel): void
  readOnly?: boolean
}

export interface RegistroLista {
  id: ID
  recente: boolean
  assinadoDigitalmente: boolean
  atendimentoProfissional: {
    iniciadoEm: Instant
    lotacao: LotacaoAtendimento | LotacaoFolhaRosto
  }
}

export default function AtestadosTable(props: AtestadoTableProps) {
  const {
    prontuarioId,
    atendimentoId,
    dataReferencia,
    cidadao,
    lotacao,
    isAtendimentoObservacao = false,
    atendimentoProfissionalId,
    atestadosRecentes = emptyArray,
    remove = noop,
    update = noop,
    readOnly,
  } = props

  const {
    data: { profissional },
  } = useSession({ fetchPolicy: 'cache-only' })

  const [tableState, setTableState] = useState<AtestadoQueryInput>({
    prontuarioId: prontuarioId,
    pageParams: { page: 0, size: 5 },
  })

  const editAtendProfId = getEditAtendProfId(isAtendimentoObservacao, atendimentoProfissionalId)

  const {
    data: { atestados },
  } = useLoadAtestadosQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        prontuarioId,
        editAtendProfId,
        query: tableState.query,
        somenteMeusAtestados: tableState.somenteMeusAtestados,
        cboId: tableState.cboId,
        periodo: tableState.periodo,
      },
    },
  })

  const renderButtons = (row: RegistroLista) => {
    return (
      <AtestadoTableButtons
        registro={row}
        cidadao={cidadao}
        atestadoRecente={row.recente && atestadosRecentes[row.id]}
        remove={remove}
        update={update}
        atendimentoId={atendimentoId}
        prontuarioId={prontuarioId}
        readOnly={readOnly}
      />
    )
  }

  const atestadosAntigos = atestados?.content ?? []

  const atestadosExibir: RegistroLista[] = [
    ...atestadosRecentes
      .map((atestado, index) => convertRecentes(index, atestado, dataReferencia, lotacao))
      .reverse()
      .filter(filterAtestados(tableState, profissional.id)),
    ...(atestadosAntigos.map(convertAtestadosFromDatabase) ?? []),
  ]

  const { page: currentPage, size: itensPerPage } = tableState.pageParams
  const totalElements = atestadosExibir?.length ?? 0
  const totalPages = Math.ceil(totalElements / itensPerPage)
  const content = atestadosExibir?.slice(currentPage * itensPerPage, (currentPage + 1) * itensPerPage) ?? []
  const atestadosPage = {
    content: content,
    pageInfo: {
      number: currentPage,
      size: itensPerPage,
      totalPages: totalPages,
      totalElements: totalElements,
      first: currentPage === 0,
      last: currentPage === totalPages - 1,
      numberOfElements: content.length,
    },
    paginationOptions: [5, 10, 30],
  }

  const tableProps = usePagedTableProps({
    loading: false,
    onChange: setTableState,
    result: atestadosPage,
  })

  return (
    <TableBox header={<AtestadoFilter onChange={setTableState} filter={tableState} />}>
      <PagedTable<RegistroLista>
        {...tableProps}
        columns={[
          { name: 'data', header: 'Data', render: renderData },
          { name: 'profissional', header: 'Profissional', render: renderProfissional, style: { padding: '0.5rem' } },
          { name: 'unidadeSaude', header: 'Unidade de saúde', render: renderUnidadeSaude },
          { name: 'status', render: renderStatus },
          { name: 'botoes', render: renderButtons },
        ]}
      />
    </TableBox>
  )
}

const renderData = (row: RegistroLista) => {
  return (
    <HFlow hSpacing={0.5} alignItems='center'>
      <DateTime value={moment(row?.atendimentoProfissional?.iniciadoEm)} format={`DD/MM/YYYY`} />
      {row.recente && (
        <Tooltip text='Adicionado agora'>
          <Icon size={1} icon='clockOutline' fill='primary' />
        </Tooltip>
      )}
    </HFlow>
  )
}

const renderProfissional = (row: RegistroLista) => {
  return (
    <VFlow vSpacing={0}>
      <Text component='b'>{row?.atendimentoProfissional?.lotacao.profissional.nome}</Text>
      <Text>{row?.atendimentoProfissional?.lotacao.cbo.nome}</Text>
    </VFlow>
  )
}

const renderUnidadeSaude = (row: RegistroLista) => {
  return <Text>{row?.atendimentoProfissional?.lotacao.unidadeSaude.nome}</Text>
}

const renderStatus = (row: RegistroLista) => {
  return row.assinadoDigitalmente && <Tag>Atestado Digital</Tag>
}
