import { useCallback } from 'react'

import {
  CboAndPermissionAuthorization,
  hasPermissionByTipoEstabelecimento,
  PermissionAuthorization,
  TipoEstabelecimentoAuthorization,
} from './model-authorization'
import { useCheckPermissionAndCboAuthorization } from './useCheckModuleAuthorization'
import useSession from './useSession'

type CheckPermissionProps = ((PermissionAuthorization | CboAndPermissionAuthorization) &
  TipoEstabelecimentoAuthorization) & {
  children: any
  acceptEstagio?: boolean
  checkSupervisorPermission?: boolean
  onlyAtencaoPrimaria?: boolean
}

const CheckPermission = (props: CheckPermissionProps) => {
  const { acceptEstagio = true, onlyAtencaoPrimaria, checkSupervisorPermission, children } = props
  const {
    isEstagio,
    isEstabelecimentoAtencaoPrimaria,
    hasTipoEstabelecimentoAuth,
    checkSupervisorAuthorization,
  } = useSession({
    fetchPolicy: 'cache-only',
  })
  const checkPermissionAndCboAuthorization = useCheckPermissionAndCboAuthorization()

  const hasAuthorization = useCallback(
    (props: CheckPermissionProps) => {
      if (!acceptEstagio && isEstagio) {
        return false
      } else if (hasPermissionByTipoEstabelecimento(props) && !hasTipoEstabelecimentoAuth(props.tiposEstabelecimento)) {
        return false
      } else if (onlyAtencaoPrimaria && !isEstabelecimentoAtencaoPrimaria) {
        return false
      } else {
        return (
          checkPermissionAndCboAuthorization(props as PermissionAuthorization | CboAndPermissionAuthorization) &&
          (!checkSupervisorPermission ||
            !isEstagio ||
            checkSupervisorAuthorization((props as PermissionAuthorization)?.permission))
        )
      }
    },
    [
      acceptEstagio,
      isEstagio,
      hasTipoEstabelecimentoAuth,
      onlyAtencaoPrimaria,
      isEstabelecimentoAtencaoPrimaria,
      checkPermissionAndCboAuthorization,
      checkSupervisorPermission,
      checkSupervisorAuthorization,
    ]
  )

  return hasAuthorization(props) ? children : null
}

export default CheckPermission
