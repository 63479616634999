/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, Cell, Grid, Text, Tooltip, VFlow } from 'bold-ui'
import { TextField } from 'components/form'
import {
  CompartilharVideochamadaForm,
  CompartilharVideochamadaModel,
  SMTP_NAO_CONFIGURADO_MESSAGE,
} from 'components/videochamada'
import { useConfiguracoes } from 'hooks/configuracoes'
import { useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

interface EnviarEmailVideochamadaProps {
  videochamadaUuid: string
  handleSubmitSucceeded(): void
  handleSubmitFailed(): void
}

const meta = metaPath<CompartilharVideochamadaModel>()

export function EnviarEmailVideochamadaForm({
  videochamadaUuid,
  handleSubmitSucceeded,
  handleSubmitFailed,
}: EnviarEmailVideochamadaProps) {
  const [emailDestinatario, setEmailDestinatario] = useState('')

  const {
    configuracoes: { smtpConfigurado },
  } = useConfiguracoes()

  const render = ({ handleSubmit, submitting }: FormRenderProps<CompartilharVideochamadaModel>) => (
    <form onSubmit={handleSubmit} noValidate>
      <VFlow vSpacing={0.5}>
        <Text fontWeight='bold'>Enviar por e-mail:</Text>
        <Grid alignItems='center' gap={0.5}>
          <Cell flexGrow={1}>
            <TextField
              name={meta.emailDestinatario}
              placeholder='Adicione o e-mail do convidado'
              aria-label='Input de texto de email'
              disabled={!smtpConfigurado || submitting}
              onChange={(e) => setEmailDestinatario(e.currentTarget.value)}
            />
          </Cell>
          <Cell alignSelf='flex-start'>
            <Tooltip text={!smtpConfigurado && SMTP_NAO_CONFIGURADO_MESSAGE}>
              <Button
                type='submit'
                loading={submitting}
                kind='primary'
                size='small'
                aria-label='Enviar e-mail'
                disabled={!smtpConfigurado}
              >
                Enviar
              </Button>
            </Tooltip>
          </Cell>
        </Grid>
      </VFlow>
    </form>
  )

  return (
    <CompartilharVideochamadaForm
      videochamadaUuid={videochamadaUuid}
      emailDestinatario={emailDestinatario}
      render={render}
      onSubmitSucceeded={handleSubmitSucceeded}
      onSubmitFailed={handleSubmitFailed}
      suppressNotificationError
      suppressAlertonSuccess
      suppressAlertonFailed
      resetFormAndFieldStates
    ></CompartilharVideochamadaForm>
  )
}
