/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, ButtonGroup, Heading, VFlow } from 'bold-ui'
import { TextAreaField } from 'components/form'
import { resolveName } from 'components/form/final-form/hooks/useField'
import { useState } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { isObjectDeepFalsy } from 'util/object'

import EvolucoesPeriodontaisField from './evolucoes-periodontais/EvolucoesPeriodontaisField'
import { EvolucoesPeriodontaisFieldModel } from './evolucoes-periodontais/model'
import { PeriogramaCompletoModel } from './periograma-completo/model'
import { PeriogramaCompletoRootView } from './periograma-completo/PeriogramaCompletoRootView'
import PeriogramaSimplificadoView from './periograma-simplificado/PeriogramaSimpificadoView'
import { PeriogramaSimplificadoModel } from './periograma-simplificado/PeriogramaSimplificadoField'

export interface PeriodontiaModel {
  evolucoes?: EvolucoesPeriodontaisFieldModel[]
  periogramaSimplificado?: PeriogramaSimplificadoModel
  periogramaCompleto?: PeriogramaCompletoModel
}

interface PeriodontiaProps {
  name: MetaPath<PeriodontiaModel>
  prontuarioId: ID
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
}

enum ActiveTab {
  SIMPLIFICADO,
  COMPLETO,
}

export default function PeriodontiaView(props: PeriodontiaProps) {
  const { name, prontuarioId, cidadaoDataNascimento, dataAtendimento } = props

  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.SIMPLIFICADO)

  const {
    input: { value: periogramaCompletoValue },
  } = useField(resolveName(name.periogramaCompleto.dados), { subscription: { value: true } })

  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={4}>Registros Periodontais</Heading>

        <ButtonGroup>
          <Button
            size='small'
            kind={activeTab === ActiveTab.SIMPLIFICADO ? 'primary' : 'normal'}
            onClick={() => setActiveTab(ActiveTab.SIMPLIFICADO)}
          >
            Simplificado
          </Button>
          <Button
            size='small'
            kind={activeTab === ActiveTab.COMPLETO ? 'primary' : 'normal'}
            onClick={() => setActiveTab(ActiveTab.COMPLETO)}
          >
            Completo
          </Button>
        </ButtonGroup>
      </VFlow>

      {activeTab === ActiveTab.SIMPLIFICADO ? (
        <PeriogramaSimplificadoView name={name.periogramaSimplificado} prontuarioId={prontuarioId} />
      ) : (
        <VFlow>
          <PeriogramaCompletoRootView name={name.periogramaCompleto.dados} prontuarioId={prontuarioId} />

          <TextAreaField
            name={name.periogramaCompleto.observacao}
            label='Observações'
            placeholder='Insira informações adicionais sobre o Periograma Completo.'
            maxLength={400}
            style={css`
              resize: vertical;
            `}
            disabled={isObjectDeepFalsy(periogramaCompletoValue)}
          />
        </VFlow>
      )}

      <VFlow vSpacing={0.5}>
        <Heading level={4}>Registros de procedimentos</Heading>
        <EvolucoesPeriodontaisField
          name={name.evolucoes}
          cidadaoDataNascimento={cidadaoDataNascimento}
          dataAtendimento={dataAtendimento}
        />
      </VFlow>
    </VFlow>
  )
}
