import { SelectField } from 'components/form/final-form'
import { BairroSelectFieldDocument } from 'graphql/hooks.generated'
import { BairroSelectFieldQuery, BairroSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type BairroSelectFieldModel = Partial<BairroSelectFieldQuery['bairros']['content'][0]>

export interface BairrosSelectFieldProps extends AsyncSelectFieldProps<BairroSelectFieldModel> {
  municipioId?: ID
  ufId?: ID
}

export function BairroSelectField(props: BairrosSelectFieldProps) {
  const { ufId, municipioId, ...rest } = props

  const {
    selectProps: { loading, ...asyncProps },
  } = useAsyncQuerySelect<BairroSelectFieldModel, BairroSelectFieldQuery, BairroSelectFieldQueryVariables>({
    query: BairroSelectFieldDocument,
    extractItems: (data) => data && data.bairros && data.bairros.content,
    variables: (inputQuery: string) => ({
      input: {
        ufId,
        municipioId,
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: BairroSelectFieldModel) => item && item.nome.toUpperCase()
  const createNewItem = (text: string) => ({ nome: text })

  return (
    <SelectField<BairroSelectFieldModel>
      itemToString={itemToString}
      createNewItem={createNewItem}
      icon={null}
      {...asyncProps}
      {...rest}
    />
  )
}
